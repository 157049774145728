<script lang="ts" src="./FormSelectPicker.ts"></script>


<template>
  <div
    v-click-outside="close"
    :class="[
      'form-field-group',
      size,
      {
        'has-error': (errors?.length > 0),
        'has-data': (value?.length > 0),
        disabled: disabled,
        opened: flags.opened,
      },
    ]"
  >
    <!-- Input field -->
    <div ref="formField" class="form-field" @click="handleFieldClick($event)">
      <div v-if="selectedItems[0]?.icon || prependIcon" class="prepend">
        <m-icon
          :name="selectedItems[0]?.icon?.name ?? prependIcon?.name"
          :solid="selectedItems[0]?.icon?.solid ?? prependIcon?.solid"
          :type="selectedItems[0]?.icon?.type ?? prependIcon?.type"
        />
      </div>

      <input
        v-show="flags.opened"
        :id="id"
        ref="filterInput"
        v-model="filterStr"
        :disabled="disabled"
        :placeholder="placeholder ?? $trans.t('components.selectPicker.placeholder')"
        class="input"
        type="search"
      />
      <input
        v-show="!flags.opened"
        :placeholder="placeholder ?? $trans.t('components.selectPicker.placeholder')"
        :value="selectedLabel"
        class="input"
        type="text"
      />

      <div class="append">
        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="chevron-down" @click="handleChevronClick($event)"/>
      </div>
    </div>

    <!-- Dropdown -->
    <div ref="dropdown" :class="['dropdown', direction]">
      <ol
        :class="[
          'max-h-44 flex flex-col p-1 space-y-1 overflow-auto md:max-h-52 md:p-1.5 md:space-y-1.5',
          dropdownMaxCols > 1 ? 'lg:space-y-0 lg:grid lg:gap-1.5 lg:grid-cols-' + dropdownMaxCols : '',
        ]"
      >
        <!-- List items: -->
        <li
          v-for="item in visibleItems"
          :key="item.id"
          :class="{ 'col-span-full': item.id === undefined }"
        >
          <div v-if="item.id === undefined" class="item -mb-2.5 border-t">
            <span class="m-caption">{{ item.label }}</span>
          </div>
          <div
            v-else
            :class="[
              'item interactive',
              {
                checked: checkItemSelected(item),
              }
            ]"
            :dusk="item.id"
            @click="clickItem(item)"
          >
            <div class="inline-flex mr-2">
              <input
                :checked="checkItemSelected(item)"
                :disabled="disabled"
                :type="(multiselect) ? 'checkbox' : 'radio'"
                class="hidden"
              />

              <span :class="(multiselect) ? 'checkbox' : 'radio'">
                <m-icon :name="multiselect ? 'check' : 'circle'" :solid="true" :type="$const.IconTypes.FONT_AWESOME"/>
              </span>
            </div>

            <m-icon v-if="!omitItemIcons && item.icon" :name="item.icon?.name" :solid="item.icon?.solid" :type="item.icon?.type" class="text-base text-blue-400 mr-1"/>
            {{ item.label ?? item.id }}
          </div>
        </li>

        <!-- More/less buttons: -->
        <li v-if="visibleItems.length == 0" class="pl-4">
          <div class="item">
            {{ $trans.t('components.selectPicker.noResults') }}
          </div>
        </li>
        <li v-else-if="maxVisible > 0" class="pl-4">
          <div class="item">
            <button
              v-show="filteredItems.length > maxVisible"
              class="show-more"
              type="button"
              @click.prevent="showMore()"
            >
              {{ $trans.t('components.selectPicker.selectMore', {step: Math.min(maxVisibleStep, filteredItems.length - maxVisible)}) }}
            </button>
            <button
              v-show="filteredItems.length <= maxVisible && filteredItems.length > maxVisibleStep"
              class="show-more"
              type="button"
              @click.prevent="showLess()"
            >
              {{ $trans.t('components.selectPicker.selectLess') }}
            </button>
          </div>
        </li>
      </ol>

      <!-- Dropdown footer -->
      <div
        v-if="multiselect && visibleItems.length"
        class="flex flex-wrap align-center items-center border-t p-2 -ml-2 -mb-2"
      >
        <div class="inline-flex grow pl-2 pb-2">
          <m-button
            :appearance="$const.StyleAppearance.DANGER"
            :icon-before="{ name: 'xmark', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.TEXT"
            class="ml-1.5"
            @click.prevent="deselectAllItems()"
          >
            {{ $trans.t('components.selectPicker.clearAll') }}
          </m-button>
        </div>

        <div class="inline-flex flex-wrap flex-row items-center -mb-2 -ml-2 pb-2 pl-2">
          <div class="inline-flex pb-2 pl-2">
            <m-button
              :appearance="$const.StyleAppearance.PRIMARY"
              :icon-before="{ name: 'plus', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
              :size="$const.StyleSize.SMALL"
              :variant="$const.StyleVariants.GHOST"
              @click.prevent="selectAllItems()"
            >
              {{ $trans.t('components.selectPicker.selectAll') }}
            </m-button>
          </div>

          <div class="inline-flex pb-2 pl-2">
            <m-button
              :appearance="$const.StyleAppearance.PRIMARY"
              :size="$const.StyleSize.SMALL"
              @click.prevent="close()"
            >Select
            </m-button>
          </div>
        </div>
      </div>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>

    <!-- Selected items/badges (multiselect only) -->
    <div v-if="multiselect">
      <m-badge
        v-if="allLabel && allSelected"
        :appearance="$const.StyleAppearance.PRIMARY"
        class="mt-2 mr-2"
      >
        <span>{{ allLabel }}</span>
      </m-badge>
      <template v-else>
        <m-badge
          v-for="item in selectedItems.slice(0, 4)"
          :key="item.id"
          :appearance="$const.StyleAppearance.PRIMARY"
          :interactive="true"
          class="mt-2 mr-2"
          @click="deselectItem(item)"
        >
          <m-icon v-if="!omitItemIcons && item.icon" :name="item.icon.name" :solid="item.icon.solid" :type="item.icon.type" class="mr-1.5"/>
          <span>{{ item.label ?? item.id }}</span>
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="ml-2 hover:text-red-400 transition-colors" name="xmark"/>
        </m-badge>

        <m-badge
          v-if="selectedItems.length - 4 > 0"
          :appearance="$const.StyleAppearance.PRIMARY"
          :interactive="true"
          class="mt-2 mr-2"
          @click="handleFieldClick($event)"
        >
          {{ $trans.t('app.labels.moreCount', {count: selectedItems.length - 4}) }}
        </m-badge>
      </template>
    </div>
  </div>
</template>


<style lang="scss" scoped src="./FormFieldCheckbox.scss"></style>
<style lang="scss" scoped src="./FormFieldRadio.scss"></style>
<style lang="scss" scoped>

.dropdown {
  @apply flex flex-col absolute top-14 left-0 right-0 z-20 bg-neutral-0 border rounded-xl opacity-0 shadow-xl invisible transition-all;

  .item {
    @apply flex grow items-center p-1.5 text-tiny;

    &.interactive {
      @apply rounded-lg cursor-pointer hover:bg-blue-25 transition-colors ease-in-out;
    }

    &.checked {
      @apply bg-blue-50;
    }
  }

  .show-more {
    @apply font-bold text-green-400;
  }

  &.top {
    @apply bottom-12 top-auto;
  }
}

.form-field {
  @apply cursor-pointer;

  .input {
    @apply bg-transparent active:bg-transparent focus:bg-transparent;
  }

  .append .icon {
    @apply text-neutral-60 transform transition-transform;
  }
}

.opened {
  .append .icon {
    @apply rotate-180;
  }

  .dropdown {
    @apply opacity-100 visible;
  }
}

.form-field-group.x-small .dropdown {
  @apply top-10 rounded-lg;
}

.form-field-group.small .dropdown {
  @apply top-12;
}

</style>
