<script lang="ts" src="./Button.ts"></script>

<template>
  <m-link v-if="to" :class="clss" :disabled="disabled ? 'disabled' : null" :newWindow="newWindow" :to="to">
    <span class="content">
      <span class="inline-block">
        <m-icon v-if="iconBefore" :class="['icon before', 'type-' + iconBefore?.name]" :name="iconBefore.name" :solid="iconBefore.solid" :type="iconBefore.type"/>
        <slot></slot>
        <m-icon v-if="iconAfter" :class="['icon after', 'type-' + iconBefore?.name]" :name="iconAfter.name" :solid="iconAfter.solid" :type="iconAfter.type"/>
      </span>
    </span>
  </m-link>
  <button v-else-if="isSubmit" :class="clss" :disabled="disabled" type="submit">
    <m-icon v-if="iconBefore" :class="['icon before', 'type-' + iconBefore?.name]" :name="iconBefore.name" :solid="iconBefore.solid" :type="iconBefore.type"/>
    <slot></slot>
    <m-icon v-if="iconAfter" :class="['icon after', 'type-' + iconBefore?.name]" :name="iconAfter.name" :solid="iconAfter.solid" :type="iconAfter.type"/>
  </button>
  <a v-else :class="clss" :disabled="disabled ? 'disabled' : null">
    <span class="content">
      <span class="inline-block">
        <m-icon v-if="iconBefore" :class="['icon before', 'type-' + iconBefore?.name]" :name="iconBefore.name" :solid="iconBefore.solid" :type="iconBefore.type"/>
        <slot></slot>
        <m-icon v-if="iconAfter" :class="['icon after', 'type-' + iconBefore?.name]" :name="iconAfter.name" :solid="iconAfter.solid" :type="iconAfter.type"/>
      </span>
    </span>
  </a>
</template>

<style lang="scss" scoped>
::v-slotted(svg) {
  @apply inline-block leading-tight;
}
</style>
