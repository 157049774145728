<script lang="ts" src="./LabelModal.ts"></script>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="close()">
    <div class="modal-dialog w-small">
      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.labelModal.title') }}
        </h2>

        <a class="close" href="#" @click.prevent="close()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <div class="dialog-body">
        <div>
          <ul>
            <m-label-edit
              v-for="label in labels"
              :key="label.uuid"
              ref="labelEditItems"
              :label="label"
              :selected="selectedByUuid[label.uuid]"
              @delete="handleLabelDelete"
              @save="handleLabelSave"
              @select="handleLabelSelectChange"
            />
          </ul>

          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :icon-before="{ name: 'circle-plus', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
            :size="$const.StyleSize.SMALL"
            class="mt-2"
            @click="addNew()"
          >
            New label
          </m-button>

        </div>
      </div>

      <div class="dialog-footer justify-end">
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.dialog-footer {
  @apply flex flex-wrap -mb-3 -ml-3 justify-end rounded-b-xl;

  .item {
    @apply pb-3 pl-3;
  }
}

</style>
