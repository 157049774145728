/**
 * List of KeyboardEvent.code values.
 * The list can be extended when needed. The entire list of codes can be found here: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
 */
export const enum KeyboardCode {

    ENTER = 'Enter',
    ESCAPE = 'Escape',
    BACKSPACE = 'Backspace',
    LEFT_ARROW = 'ArrowLeft',
    UP_ARROW = 'ArrowUp',
    RIGHT_ARROW = 'ArrowRight',
    DOWN_ARROW = 'ArrowDown',
    Key_0 = 'Digit0',
    Key_1 = 'Digit1',
    Key_2 = 'Digit2',
    Key_3 = 'Digit3',
    Key_4 = 'Digit4',
    Key_5 = 'Digit5',
    Key_6 = 'Digit6',
    Key_7 = 'Digit7',
    Key_8 = 'Digit8',
    Key_9 = 'Digit9',
    KEY_A = 'KeyA',
    KEY_B = 'KeyB',
    KEY_C = 'KeyC',
    KEY_D = 'KeyD',
    KEY_E = 'KeyE',
    KEY_F = 'KeyF',
    KEY_G = 'KeyG',
    KEY_H = 'KeyH',
    KEY_I = 'KeyI',
    KEY_J = 'KeyJ',
    KEY_K = 'KeyK',
    KEY_L = 'KeyL',
    KEY_M = 'KeyM',
    KEY_N = 'KeyN',
    KEY_O = 'KeyO',
    KEY_P = 'KeyP',
    KEY_Q = 'KeyQ',
    KEY_R = 'KeyR',
    KEY_S = 'KeyS',
    KEY_T = 'KeyT',
    KEY_U = 'KeyU',
    KEY_V = 'KeyV',
    KEY_W = 'KeyW',
    KEY_X = 'KeyX',
    KEY_Y = 'KeyY',
    KEY_Z = 'KeyZ',
    NUMPAD_0 = 'Numpad0',
    NUMPAD_1 = 'Numpad1',
    NUMPAD_2 = 'Numpad2',
    NUMPAD_3 = 'Numpad3',
    NUMPAD_4 = 'Numpad4',
    NUMPAD_5 = 'Numpad5',
    NUMPAD_6 = 'Numpad6',
    NUMPAD_7 = 'Numpad7',
    NUMPAD_8 = 'Numpad8',
    NUMPAD_9 = 'Numpad9',

}
