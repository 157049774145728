export const enum PurchaseOrderPaymentStatus {

    OPEN = 'open',
    PENDING = 'pending',
    PAID = 'paid',
    COMPLETED = 'completed',
    REFUNDED = 'refunded',
    WAITING_FOR_MONEY_RECEIVED = 'waiting_for_money_received',

}
