import {PusherPrivateChannel} from 'laravel-echo/src/channel';
import {Settings} from '@/app/Settings';
import {PusherChannels} from '@/app/constants/PusherChannels';
import {Uuid} from '@/types/Types';

export class PusherHelper {

    public static enterChannel(channel: PusherChannels, uuid: Uuid): PusherPrivateChannel {
        const channelName: string = channel.replace('{UUID}', uuid);
        return <PusherPrivateChannel>window.Echo.private(Settings.envPrefix + channelName);
    }

    public static leaveChannel(channel: PusherChannels, uuid: Uuid): void {
        const channelName: string = channel.replace('{UUID}', uuid);
        window.Echo.leave(Settings.envPrefix + channelName);
    }
}
