import {Castable, CastableProperties} from '@/app/utils/Castable';
import {UserDTO} from '@/app/dto/UserDTO';

export class AuthDTO extends Castable {

    public token: string = null;
    public user: UserDTO = null;
    public isNewGoogleUser: boolean = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            user: UserDTO,
        };
    }

}
