import {AppStore} from '@/app/stores/AppStore';
import {CompanyStore} from '@/app/stores/CompanyStore';
import {LocationsStore} from '@/app/stores/LocationsStore';
import {UserStore} from '@/app/stores/UserStore';
import {MetaStore} from '@/app/stores/MetaStore';

/**
 *
 */
export class Store {

    private static _app: AppStore = null;

    public static get app(): AppStore {
        return Store._app ?? (Store._app = new AppStore());
    }

    private static _user: UserStore = null;

    public static get user(): UserStore {
        return Store._user ?? (Store._user = new UserStore());
    }

    private static _company: CompanyStore = null;

    public static get company(): CompanyStore {
        return Store._company ?? (Store._company = new CompanyStore());
    }

    private static _locations: LocationsStore = null;

    public static get locations(): LocationsStore {
        return Store._locations ?? (Store._locations = new LocationsStore());
    }

    private static _meta: MetaStore = null;

    public static get meta(): MetaStore {
        return Store._meta ?? (Store._meta = new MetaStore());
    }

}

// Export individual stores from this file so they can all be imported via the same Store file (like `import { AppStore } from '@/app/stores/Store';`):
export * from './AppStore';
export * from './UserStore';
export * from './CompanyStore';
export * from './LocationsStore';
export * from './MetaStore';
