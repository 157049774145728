import {Lang} from '@/app/lang/Lang';
import {Main} from '@/app/Main';

/**
 * A definition of a FromRule result .
 * The result is either `true` in case the rule succeeds, `-1` in case further rules won't need to be checked or a string containing an error.
 */
export type FormRuleResult = string | true | -1;

/**
 * A definition of a FromRule function.
 */
export type FormRule = (value: string | boolean | number | Date | unknown[], formData?: unknown) => FormRuleResult;

/**
 * A definition of the FromRules by field id.
 */
export type FormRulesById = { [key: string]: FormRule[] | FormRulesById | any };   // `Record<string, FormRule[]|FormRulesById>` doesn't work! See https://github.com/microsoft/TypeScript/issues/35164

/**
 * Static class with form rules.
 * @author Arno van Oordt
 * @version 1.5.4
 */

export class FormRules {

    /**
     * Check if the value is not empty; undefined, null, empty or false will result in fail. Works for strings and arrays.
     * Works for string, boolean and array.
     */
    public static required(message: string = null, allowFalse: boolean = false): FormRule {
        return (value: string | boolean | number | unknown[], formData: unknown): FormRuleResult => {
            if (value === undefined || value === null || (!allowFalse && value === false) || (typeof value !== 'boolean' && typeof value !== 'number' && value.length == 0)) {
                return (message) ? message : Main.trans.t(Lang.t.errors.requiredField);
            }
            return true;
        };
    }

    /**
     * Check if the value has a certain minimum length. Works for strings and arrays.
     * This doesn't check for an empty string value! Add a 'required' rule to check that as well!
     * Works for string and array.
     * In case of array's only non-null values will be counted (unless `countEmptyValue` is set to true)
     */
    public static minLength(minLength: number, message: string = null, countEmptyValues: boolean = false): FormRule {
        return (value: string | unknown[], formData: unknown): FormRuleResult => {
            // Remove null values from array:
            if (!countEmptyValues && value instanceof Array) {
                value = value.filter((obj) => {
                    return (obj !== undefined && obj !== null)
                });
            }

            if (value != null && value !== '' && value.length < minLength) {
                const errorId: string = (value instanceof Array) ? Lang.t.errors.minLengthList : Lang.t.errors.minLength;
                return (message) ? message : Main.trans.t(errorId, {min: minLength});
            }
            return true;
        };
    }

    /**
     * Check if the value has a certain maximum length. Works for strings and arrays.
     * This doesn't check for an empty string value! Add a 'required' rule to check that as well!
     * Works for string and array.
     * In case of array's only non-null values will be counted (unless `countEmptyValue` is set to true)
     */
    public static maxLength(maxLength: number, message: string = null, countEmptyValues: boolean = false): FormRule {
        return (value: string | unknown[], formData: unknown): FormRuleResult => {
            // Remove null values from array:
            if (!countEmptyValues && value instanceof Array) {
                value = value.filter((obj) => {
                    return (obj !== undefined && obj !== null)
                });
            }

            if (value != null && value.length > maxLength) {
                const errorId: string = (value instanceof Array) ? Lang.t.errors.maxLengthList : Lang.t.errors.maxLength;
                return (message) ? message : Main.trans.t(errorId, {max: maxLength});
            }
            return true;
        };
    }

    /**
     * Check if the value is not before the given date.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works only for Dates.
     */
    public static minDate(minDate: Date, message: string = null): FormRule {
        return (value: Date, formData: unknown): FormRuleResult => {
            if (value != null && value < minDate) {
                return (message) ? message : Main.trans.t(Lang.t.errors.minDate, {minDate: Main.trans.d(minDate)});
            }
            return true;
        };
    }

    /**
     * Check if the value is not after the given date.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works only for Dates.
     */
    public static maxDate(maxDate: Date, message: string = null): FormRule {
        return (value: Date, formData: unknown): FormRuleResult => {
            value = new Date(value.getTime());
            value.setHours(0, 0, 0, 0);    // Make sure we only compare the date and not the time!
            if (value != null && value > maxDate) {
                return (message) ? message : Main.trans.t(Lang.t.errors.maxDate, {maxDate: Main.trans.d(maxDate)});
            }
            return true;
        };
    }

    /**
     * Check if the value falls within the date range [minDate, maxDate].
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works only for Dates.
     */
    public static rangeDate(minDate: Date, maxDate: Date, message: string = null): FormRule {
        return (value: Date, formData: unknown): FormRuleResult => {
            value = new Date(value.getTime());
            value.setHours(0, 0, 0, 0);    // Make sure we only compare the date and not the time!
            if (value != null && (value < minDate || value > maxDate)) {
                minDate.addDays(1);
                // maxDate.addDays(-1);
                return (message) ? message : Main.trans.t(Lang.t.errors.rangeDate, {minDate: Main.trans.d(minDate), maxDate: Main.trans.d(maxDate)});
            }
            return true;
        };
    }

    /**
     * Check if the value has a minimum value.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works for string and number.
     */
    public static min(min: number, message: string = null): FormRule {
        return (value: string | number, formData: unknown): FormRuleResult => {
            if (typeof value === 'string') {
                value = parseInt(value);
            }
            if (value != null && value < min) {
                return (message) ? message : Main.trans.t(Lang.t.errors.min, {min: min});
            }
            return true;
        };
    }

    /**
     * Check if the value has a maximum value.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works for string and number.
     */
    public static max(max: number, message: string = null): FormRule {
        return (value: string | number, formData: unknown): FormRuleResult => {
            if (typeof value === 'string') {
                value = parseInt(value);
            }
            if (value != null && value > max) {
                return (message) ? message : Main.trans.t(Lang.t.errors.max, {max: max});
            }
            return true;
        };
    }

    /**
     * Check if the value falls within the range [min, max].
     * Basically a combination of the min and max rule but with the oprion for a combined error.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     */
    public static range(min: number, max: number, message: string = null): FormRule {
        return (value: string | number, formData: unknown): FormRuleResult => {
            if (typeof value === 'string') {
                value = parseInt(value);
            }
            if (value != null && (value < min || value > max)) {
                return (message) ? message : Main.trans.t(Lang.t.errors.range, {min: min, max: max});
            }
            return true;
        };
    }

    /**
     * Check if the value occurs in the given list.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works for string and number.
     */
    public static in(list: unknown[], message: string = null): FormRule {  // NOTE: giving list a type `string[]|number[]` will give a type error on the first argument of list.include()
        return (value: string | number, formData: unknown): FormRuleResult => {
            if (value != null && !list.includes(value)) {
                return (message) ? message : Main.trans.t(Lang.t.errors.in, {list: list});
            }
            return true;
        };
    }

    /**
     * Check if value can be parsed to JSON.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works for string.
     */
    public static json(message: string = null): FormRule {
        return (value: string, formData: unknown): FormRuleResult => {
            try {
                if (value != null && value != '') {
                    JSON.parse(value);
                }
            } catch (err) {
                return (message) ? message : Main.trans.t(Lang.t.errors.invalidJsonField);
            }
            return true;
        };
    }

    /**
     * Check if value is a valid e-mail address.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works for string.
     */
    public static email(message: string = null): FormRule {
        return (value: string, formData: unknown): FormRuleResult => {
            const re: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (value != null && value != '' && !re.test(value)) {
                return (message) ? message : Main.trans.t(Lang.t.errors.invalidEmail);
            }
            return true;
        };
    }

    /**
     * Check if value tests against the given regular expression.
     * This doesn't check for an empty value! Add a 'required' rule to check that as well!
     * Works for string.
     */
    public static match(regex: RegExp, message: string = null): FormRule {
        return (value: string, formData: unknown): FormRuleResult => {
            if (value != null && value != '' && !regex.test(value)) {
                return (message) ? message : Main.trans.t(Lang.t.errors.invalidValue);
            }
            return true;
        };
    }

    /**
     * Check if the given callback returns true.
     * Works for string, boolean and array.
     */
    public static test(callback: (value: string | boolean | number | unknown[], formData: unknown) => boolean, message: string = null): FormRule {
        return (value: string | boolean | number | unknown[], formData: unknown): FormRuleResult => {
            if (!callback(value, formData)) {
                return (message) ? message : Main.trans.t(Lang.t.errors.invalidValue);
            }
            return true;
        };
    }

    /**
     * Stop checking any rules after this one in case the callback returns true.
     * Rules executed before this check will stay in effect!
     */
    public static omitFurtherRules(callback: (value: string | boolean | number | unknown[], formData: unknown) => boolean, message: string = null): FormRule {
        return (value: string | boolean | number | unknown[], formData: unknown): FormRuleResult => {
            if (callback(value, formData)) {
                return -1;
            }
            return true;
        };
    }

}
