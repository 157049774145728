import {BaseDTO} from '@/app/dto/BaseDTO';
import {CastableProperties} from '@/app/utils/Castable';

export class PricingLineDTO extends BaseDTO {

    public moq: number = null;
    public price: number = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams
        };
    }

}
