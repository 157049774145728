import {Roles} from '@/app/constants/Roles';
import {BaseDTO} from '@/app/dto/BaseDTO';
import {Uuid} from '@/types/Types';

export class UserDTO extends BaseDTO {

    public email: string = null;
    public firstName: string = null;
    public lastName: string = null;
    public locale: string = null;

    public termsAccepted: boolean = null;

    public companyUuid: Uuid = null;

    public roles: Roles[] = [];
    public permissions: string[] = [];

    public hasLinkedAccount: boolean;
    public hasVerifiedEmail: boolean;
    public hasPassword: boolean;

}
