import {BaseDTO} from '@/app/dto/BaseDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {Uuid} from '@/types/Types';

export class BaseProductOfferDTO extends BaseDTO {

    public createdAt: Date = null;

    public companyUuid: Uuid = null;
    public collaborationUuid: Uuid = null;

    public price: number = null;
    public quantity: number = null;

    public deliveryDate: Date = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            deliveryDate: Date,
        };
    }

}
