import {Main} from "@/app/Main";

/**
 * Extend the Date class with some handy functions.
 * @author Arno van Oordt
 * @version 1.0.3
 */
interface Date {
    timeSince(): string;

    timeSinceHandler(index: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, type: 'future' | 'past', count: number, date: Date, payload?: any): string;

    timeSinceHumanRead(): string;

    toISODateString(): string;

    toFormattedTimeString(): string

    isSameYear(date: Date): boolean;

    isSameMonth(date: Date): boolean;

    isSameDay(date: Date): boolean;

    isSameHour(date: Date): boolean;

    isSameMinute(date: Date): boolean;

    diff(date: Date): number;

    addMilliseconds(val: number): void;

    addSeconds(val: number): void;

    addMinutes(val: number): void;

    addHours(val: number): void;

    addDays(val: number): void;

    addMonths(val: number): void;

    addYears(val: number): void;

    clone(): Date;
}

interface DateConstructor {
    SECOND: number;
    MINUTE: number;
    HOUR: number;
    DAY: number;
    WEEK: number;
    MONTH: number;
    YEAR: number;
    DECADE: number;
    CENTURY: number;
    MILLENNIUM: number;

    yesterday(): Date;

    today(): Date;

    tomorrow(): Date;

    yesterdayUTC(): Date;

    todayUTC(): Date;

    tomorrowUTC(): Date;
}

/**
 * Timeperiods in seconds.
 * Note that the number is not super accurate for months and longer periods since they differ depending on the specific month, year, decade, etc.
 */
Date.SECOND = 1000;
Date.MINUTE = 60 * Date.SECOND;
Date.HOUR = 60 * Date.MINUTE;
Date.DAY = 24 * Date.HOUR;
Date.WEEK = 7 * Date.DAY;
Date.MONTH = 30 * Date.DAY;
Date.YEAR = 365 * Date.DAY;
Date.DECADE = 10 * Date.YEAR;
Date.CENTURY = 100 * Date.YEAR;
Date.MILLENNIUM = 1000 * Date.YEAR;

/**
 * Get yesterday's date at 00:00 when the day started.
 * The date is in current timezone.
 * @return Yesterday as Date.
 */
Date.yesterday = function (): Date {
    const date: Date = new Date();
    date.setHours(0, 0, 0, 0);
    date.addDays(-1);
    return date;
}

/**
 * Get today date at 00:00 when the day started
 * The date is in current timezone.
 * @return Today as Date.
 */
Date.today = function (): Date {
    const date: Date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
}

/**
 * Get tomorrow's date at 00:00 when the day starts.
 * The date is in current timezone.
 * @return Tomorrow as Date.
 */
Date.tomorrow = function (): Date {
    const date: Date = new Date();
    date.setHours(0, 0, 0, 0);
    date.addDays(1);
    return date;
}

/**
 * Get yesterday's date at 00:00 when the day started.
 * The date is in UTC timezone.
 * @return Yesterday as Date.
 */
Date.yesterdayUTC = function (): Date {
    const date: Date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    date.addDays(-1);
    return date;
}

/**
 * Get today date at 00:00 when the day started
 * The date is in UTC timezone.
 * @return Today as Date.
 */
Date.todayUTC = function (): Date {
    const date: Date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    return date;
}

/**
 * Get tomorrow's date at 00:00 when the day starts.
 * The date is in UTC timezone.
 * @return Tomorrow as Date.
 */
Date.tomorrowUTC = function (): Date {
    const date: Date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    date.addDays(1);
    return date;
}

const units = [
    {max: 30 * Date.SECOND, divisor: 1,},   // less than 30 sec
    {max: Date.MINUTE, divisor: Date.SECOND,},   // less than 1 min
    {max: Date.HOUR, divisor: Date.MINUTE,},   // less than 1 hour
    {max: Date.DAY, divisor: Date.HOUR,},    // etc ..
    {max: Date.WEEK, divisor: Date.DAY,},
    {max: 4 * Date.WEEK, divisor: Date.WEEK,},
    {max: Date.YEAR, divisor: Date.MONTH,},
    {max: 100 * Date.YEAR, divisor: Date.YEAR,},
    {max: 1000 * Date.YEAR, divisor: 100 * Date.YEAR},
    {max: Infinity, divisor: 1000 * Date.YEAR},
];

/**
 * Human readable elapsed or remaining time (example: 3 minutes ago)
 * Before using this function please define a handler function first by setting Date.prototype.timeSinceHandler; e.g.:
 *  Date.prototype.timeSinceHandler = (index:0|1|2|3|4|5|6|7|8|9, type:'future'|'past', count:number, maxExceeded:boolean, date:Date, payload:any = null):string => {
 *          return (maxExceeded) ? this.$d(date, 'dateTime') : this.$tc(`dateTimeSince[${index}].${type}`, count);
 *      };
 * @param payload An optional payload to pass on to the timeSinceHandler function.
 * @return The value that the timeSinceHandler returned.
 * @see https://stackoverflow.com/a/67338038/938822
 */
Date.prototype.timeSince = function (payload: any = null): string {
    if (typeof this.timeSinceHandler !== 'function') {
        throw new Error('Please define `Date.prototype.timeSinceHandler` before calling `timeSince()`!');
    }

    const diff: number = Date.now() - this.getTime();
    const diffAbs: number = Math.abs(diff);
    for (let i: number = 0; i < units.length; i++) {
        if (diffAbs >= units[i].max) {
            continue;
        }
        const count: number = Math.round(diffAbs / units[i].divisor);
        return this.timeSinceHandler(<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9>i, (diff < 0) ? 'future' : 'past', count, this, payload);
    }
}

/**
 * Return the date as an international formatted string YYYY-MM-DD.
 * @see https://www.w3.org/QA/Tips/iso-date
 */
Date.prototype.toISODateString = function (): string {
    return this.toLocaleDateString('en-ca');
}

/**
 * Return the time as formatted string HH:MM.
 */
Date.prototype.toFormattedTimeString = function (): string {
    const hours = this.getHours() > 9 ? this.getHours() : '0' + this.getHours();
    const minutes = this.getMinutes() > 9 ? this.getMinutes() : '0' + this.getMinutes();
    return hours + ':' + minutes;
}

/**
 * Check if the year of this Date exactly matches the year of given Date.
 */
Date.prototype.isSameYear = function (date: Date): boolean {
    return this.getFullYear() === date.getFullYear();
}

/**
 * Check if the month of this Date exactly matches the month of given Date (including same year).
 */
Date.prototype.isSameMonth = function (date: Date): boolean {
    return this.isSameYear(date) && this.getMonth() === date.getMonth();
}

/**
 * Check if this Date exactly matches the date of the given Date (including year and month).
 */
Date.prototype.isSameDay = function (date: Date): boolean {
    return this.isSameMonth(date) && this.getDate() === date.getDate();
}

/**
 * Check if the hour of this date exactly matches the hour of the given Date (including year, month and day).
 */
Date.prototype.isSameHour = function (date: Date): boolean {
    return this.isSameDay(date) && this.getUTCHours() === date.getUTCHours();
}

/**
 * Check if the minute of this date exactly matches the minute of the given Date (including year, month, day and hour).
 */
Date.prototype.isSameMinute = function (date: Date): boolean {
    return this.isSameHour(date) && this.getUTCMinutes() === date.getUTCMinutes();
}

/**
 * The difference in ms.
 * If the given date is later than this the returned value will be positive.
 * If the given date is earlier the returned value will be negative.
 */
Date.prototype.diff = function (date: Date): number {
    return +this - +date;   // + to appease TypeScript; see: https://stackoverflow.com/a/67396238/1572330
}

/**
 * Human-readable format for dates.
 * Shows x seconds/minutes/weeks/etc. ago.
 */
Date.prototype.timeSinceHumanRead = function (): number {
    let date: Date = this;

    if (!(date instanceof Date && !isNaN(date.getTime()))) {
        date = new Date();
    }

    const seconds: number = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    const intervals = [
        {label: 'years', divisor: 31536000},
        {label: 'months', divisor: 2592000},
        {label: 'weeks', divisor: 604800},
        {label: 'days', divisor: 86400},
        {label: 'hours', divisor: 3600},
        {label: 'minutes', divisor: 60},
        {label: 'seconds', divisor: 1}
    ];

    let intervalType: string = 'seconds';
    for (const interval of intervals) {
        const intervalValue: number = Math.floor(seconds / interval.divisor);
        if (intervalValue >= 1) {
            intervalType = interval.label;
            return Main.trans.t(`pages.companyShortCard.timeSince.${intervalType}`, {count: intervalValue});
        }
    }

    return Main.trans.t(`pages.companyShortCard.timeSince.${intervalType}`, {count: seconds});
}

/**
 * Add the given amount of milliseconds.
 * Use negative numbers to subtract.
 */
Date.prototype.addMilliseconds = function (val: number): void {
    this.setUTCMilliseconds(this.getUTCMilliseconds() + val);
}

/**
 * Add the given amount of seconds.
 * Use negative numbers to subtract.
 */
Date.prototype.addSeconds = function (val: number): void {
    this.setUTCSeconds(this.getUTCSeconds() + val);
}

/**
 * Add the given amount of minutes.
 * Use negative numbers to subtract.
 */
Date.prototype.addMinutes = function (val: number): void {
    this.setUTCMinutes(this.getUTCMinutes() + val);
}

/**
 * Add the given amount of hours.
 * Use negative numbers to subtract.
 */
Date.prototype.addHours = function (val: number): void {
    this.setUTCHours(this.getUTCHours() + val);
}

/**
 * Add the given amount of days.
 * Use negative numbers to subtract.
 */
Date.prototype.addDays = function (val: number): void {
    this.setUTCDate(this.getUTCDate() + val);
}

/**
 * Add the given amount of months.
 * Use negative numbers to subtract.
 */
Date.prototype.addMonths = function (val: number): void {
    this.setUTCMonth(this.getUTCMonth() + val);
}

/**
 * Add the given amount of years.
 * Use negative numbers to subtract.
 */
Date.prototype.addYears = function (val: number): void {
    this.setUTCFullYear(this.getUTCFullYear() + val);
}

/**
 * Make a copy of the date object.
 */
Date.prototype.clone = function (): Date {
    return new Date(this.getTime());
}
