/**
 * Icon Types.
 */
export const enum IconTypes {

    FONT_AWESOME = 'fa',
    FONT_AWESOME_BRANDS = 'fab',
    HERO = 'h',
    MANUFY = 'm',

}
