import Bluebird from 'bluebird';
// import CryptoJS                             from 'crypto-js';
import {Signal} from 'typed-signals';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Roles} from '@/app/constants/Roles';
import {CompanyTypes} from '@/app/constants/CompanyTypes';
import {AuthDTO} from '@/app/dto/AuthDTO';
import {UserDTO} from '@/app/dto/UserDTO';
import {Store} from '@/app/stores/Store';
import {StoreBase} from '@/app/stores/StoreBase';
import {StorageHelper} from '@/app/utils/StorageHelper';
import {IMenuItem} from '@/app/views/components/layout/MenuItem';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {WebServiceError} from '@/libs/core/errors/WebServiceError';
import {Uuid} from '@/types/Types';

interface IUserState {
    authDTO: AuthDTO;
    currentUser: UserDTO;
    currentCompanyType: CompanyTypes;
}

/**
 *
 */
export class UserStore extends StoreBase<IUserState> {

    public flags = {
        remember: false,
    };

    public authChangedSignal: Signal<(authDTO: AuthDTO, prevAuthDTO: AuthDTO) => void> = new Signal();

    public currentPromise: Bluebird<AuthDTO> = null;

    constructor() {
        super();

        // Check auth:
        this.state.authDTO = null;

        let authDTO: AuthDTO = <AuthDTO>StorageHelper.getLocalVar('auth');
        if (authDTO) {
            this.flags.remember = true;
        } else {
            authDTO = <AuthDTO>StorageHelper.getSessionVar('auth');
        }

        this.setAuthToken(authDTO);
    }

    public get user(): UserDTO {
        // Returning the authDTO.user directly from will result in an endless loop of getting this user property somehow (this will only occur in development build!)
        return this.state.currentUser;
    }

    public get isProxied(): boolean {
        return (Routes.currentRoute.query.puuid != null);
    }

    /**
     * The proxy company uuid. In case an admin is representing this company.
     */
    public get puuid(): Uuid {
        return <Uuid>Routes.currentRoute.query.puuid;
    }

    /**
     * The current company's uuid. This can be the company of the current user or one that is being proxied as admin.
     */
    public get currentCompanyUuid(): Uuid {
        return <Uuid>Routes.currentRoute.query.puuid ?? this.state.currentUser?.companyUuid;
    }

    /**
     * The current company's type. NOTE: in contrast to the currentCompanyUuid this type is not proxied so if an admin is acting as a company this type will still be `admin`!
     * TODO: find a better solution for this; don't use this property anymore?
     */
    public get currentCompanyType(): CompanyTypes {
        return this.state.currentCompanyType;
    }

    /**
     * Whether the current user is a guest (meaning he has no company yet).
     */
    public get isGuest(): boolean {
        return this.state.currentCompanyType == null;   // Either not logged in or has no company yet
    }

    /**
     * Whether the current user is a buyer.
     */
    public get isBuyer(): boolean {
        return this.state.currentCompanyType == CompanyTypes.BUYER;
    }

    /**
     * Whether the current user is a seller.
     */
    public get isSeller(): boolean {
        return this.state.currentCompanyType == CompanyTypes.SELLER;
    }

    /**
     * Whether the current user is a Manufy admin.
     */
    public get isAdmin(): boolean {
        return this.state.currentCompanyType == CompanyTypes.MANUFY;
    }

    /**
     * Whether the current user is a Manufy super admin.
     */
    public get isSuperAdmin(): boolean {
        return this.hasRoles(Roles.MANUFY_SUPER);
    }

    public get bearerToken(): string {
        return this.state.authDTO?.token;
    }

    public get subtitle(): string {
        if (Store.company.name) {
            return Store.company.name;
        }

        if (Store.user.isSuperAdmin) {
            return 'Super Admin';
        }

        if (Store.user.isAdmin) {
            return 'Admin';
        }

        return 'Incomplete account';
    }

    public setAuthToken(authDTO: AuthDTO): void {
        if (authDTO?.user && authDTO.user.roles.length == 0) {  // In theory this shouldn't occur, but just in case.
            this.logout();
            return;
        }

        const prevAuthDto: AuthDTO = this.state.authDTO;
        this.state.authDTO = authDTO;

        this.state.currentUser = (authDTO) ? authDTO.user : null;

        // Set the current company mode:
        if (this.hasRoles(Roles.BUYER_USER)) {
            this.state.currentCompanyType = CompanyTypes.BUYER;
        } else if (this.hasRoles(Roles.SELLER_USER)) {
            this.state.currentCompanyType = CompanyTypes.SELLER;
        } else if (this.hasRoles(Roles.MANUFY_USER)) {
            this.state.currentCompanyType = CompanyTypes.MANUFY;
        } else {
            this.state.currentCompanyType = null;
        }

        if (authDTO) {
            Store.app.apiService.defaultHeaders['Authorization'] = 'Bearer ' + authDTO.token;
        } else {
            delete Store.app.apiService.defaultHeaders['Authorization'];
        }

        this.authChangedSignal.emit(this.state.authDTO, prevAuthDto);

        this.storeAuth();
    }

    /**
     * Try to login a user by its email and password.
     * @param email                 The email.
     * @param password              A non-encrypted password. The pass will later be encrypted using SHA256.
     * @param remember              A boolean indicating whether to remember the login in local storage (true) or just for this session (false).
     */
    public async login(email: string, password: string, remember: boolean = false): Promise<AuthDTO> {
        this.flags.remember = remember;

        const payload: Record<string, any> = {};
        payload.email = email;
        payload.password = password;
        payload.deviceId = Store.app.deviceId;
        payload.recaptchaToken = await Main.getRecaptchaToken();
        this.currentPromise?.cancel();
        this.currentPromise = Main.callApi('login', RequestMethods.POST, payload, AuthDTO);
        this.currentPromise = this.currentPromise.then(this.handleAuthResult.bind(this));
        this.currentPromise = this.currentPromise.catch(this.handleAuthError.bind(this));

        return this.currentPromise;
    }

    /**
     * Clear the current authToken; basically making the current user a visitor again.
     */
    public logout(): void {
        let promise: Bluebird<unknown> = Main.callApi('logout', RequestMethods.POST);    // No need to wait for response.
        promise = promise.catch(() => null);  // Fail gracefully in case already logged out
        this.setAuthToken(null);
    }

    /**
     *
     */
    public refreshUser(): Bluebird<AuthDTO> {
        if (!this.state.authDTO.user) {
            return;
        } // Can't update anything

        this.currentPromise?.cancel();
        this.currentPromise = Main.callApi('token/refresh', RequestMethods.POST, null, AuthDTO);
        this.currentPromise = this.currentPromise.then(this.handleAuthResult.bind(this));
        this.currentPromise = this.currentPromise.catch(this.handleRefreshUserError.bind(this));

        return this.currentPromise;
    }

    /**
     * Request a password reset.
     */
    public async requestPassword(email: string): Promise<unknown> {
        const payload: Record<string, any> = {};
        payload.email = email;
        payload.recaptchaToken = await Main.getRecaptchaToken();

        let promise: Bluebird<unknown> = Main.callApi('user/password/send', RequestMethods.POST, payload);
        // No need to wait for response here..
        return promise;
    }

    /**
     * Reset a password.
     */
    public resetPassword(password: string, resetToken: string, email: string): Bluebird<unknown> {
        const payload: Record<string, any> = {};
        // payload.password = CryptoJS.SHA256(password).toString();
        payload.password = password;
        payload.token = resetToken;
        payload.email = email;

        let promise: Bluebird<unknown> = Main.callApi('user/password', RequestMethods.PATCH, payload);
        // No need to wait for response here..
        return promise;
    }

    /**
     * Update the current user.
     */
    public updateUser(user: UserDTO): UserDTO {
        if (!this.state.authDTO.user) {
            return;
        } // Can't update anything

        this.state.authDTO.user = Object.assign(this.state.authDTO.user, user);
        this.storeAuth();

        Store.app.locale = user.locale; // in case the locale wasn't changed, nothing will happen

        return this.state.authDTO.user;
    }

    /**
     * Update the locale for the current user.
     * If there is no current user nothing will happen.
     */
    public updateLocale(locale: string): void {
        if (!this.user || this.user.locale === locale) {
            return;
        } // No need to update anything

        // Save server side:
        const payload: Record<string, any> = {};
        payload.uuid = this.user.uuid;
        payload.locale = locale;

        let promise: Bluebird<any> = Main.callApi('user/locale', RequestMethods.PATCH, payload);
        promise = promise.then(this.refreshUser.bind(this));
        promise = promise.catch(() => null);  // TODO: Handle fail. For now fail gracefully in case of error
    }

    /**
     * Check if the user has at least one of the given permissions in the current scope.
     * @return A boolean indicating if the user has at least one of the given permissions.
     */
    public can(...permissions: string[]): boolean {
        // if (!this.data.scopeDTO) {  // No scope so no permissions to begin with
        //     return false;
        // };
        // const userPermissions:string[] = this.data.scopeDTO.permissions;
        // for (let i:number = permissions.length; i--; ) {
        //     for (let j:number = userPermissions.length; j--; ) {
        //         if (permissions[i] === userPermissions[j]) {
        //             return true;
        //         }
        //     }
        // }
        return false;
    }

    /**
     * Check if the user has at least one of the given roles in the current scope.
     * @return A boolean indicating if the user has at least one of the given roles.
     */
    public hasRoles(...roles: Roles[]): boolean {
        return (this.state.authDTO) ? this.state.authDTO.user.roles.haveCommon(roles) : false;
    }

    public roles(): Roles[] {
        return this.state.authDTO.user.roles;
    }

    /**
     * Check if user has access to this item or at least one of its children.
     */
    public hasAccess(item: IMenuItem): boolean {
        if (item?.link) {
            const route = Routes.resolve(item.link);
            return (Routes.checkAccess(route) === true);
        }

        if (item?.children) {
            // Recursively check children:
            for (let i: number = item.children.length; i--;) {
                const hasAccess: boolean = this.hasAccess(item.children[i]);
                if (hasAccess) {   // Has access to at least one child
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Save the current auth state to local or session storage.
     */
    private storeAuth(): void {
        if (this.flags.remember) {
            StorageHelper.setSessionVar('auth', null);
            StorageHelper.setLocalVar('auth', this.state.authDTO);
        } else {
            StorageHelper.setLocalVar('auth', null);
            StorageHelper.setSessionVar('auth', this.state.authDTO);
        }
    }

    private handleAuthResult(result: AuthDTO): void {
        this.setAuthToken(result);
    }

    private handleAuthError(error: WebServiceError): void {
        throw error;
    }

    private handleRefreshUserError(error: WebServiceError): void {
        this.setAuthToken(null);
        throw error;
    }

}
