<script lang="ts" src="./AvailabilityStatusModal.ts"/>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-small secondary">

      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.companyAvailabilityStatusModal.title') }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <form @submit.prevent="submit()">
        <div class="dialog-body space-y-4 mb-3">
          <p class="-mt-5">{{ $trans.t('app.companyAvailabilityStatusModal.description') }}</p>

          <ul v-if="availabilityStatuses.length > 0" class="space-y-2">
            <li v-for="availabilityStatus in availabilityStatuses">
              <m-form-field-radio-button
                v-model="formData.model.availabilityStatus"
                :val="availabilityStatus.id"
                class="flex-1 w-full"
                group="useOwnCourier"
              >
                <m-icon :class="['text-sm mr-2', iconColor(availabilityStatus)]" :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="circle-dot"/>
                <span>{{ availabilityStatus.label }}</span>
              </m-form-field-radio-button>
            </li>
          </ul>
        </div>

        <div class="dialog-footer m-form__action-buttons mr-3 mb-3">
          <m-cancel-button :size="$const.StyleSize.SMALL" @cancel="hide()"/>
          <m-submit-button
            :disabled="flags.loading"
            :label="$const.FormButtonLabels.UPDATE_AVAILABILITY_STATUS"
            :size="$const.StyleSize.SMALL"
          />
        </div>
      </form>
    </div>
  </div>

</template>


<style lang="scss" scoped>

.dialog-footer {
  @apply flex-wrap justify-end;
}

</style>
