import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {StyleSize} from '@/app/constants/styles/StyleSize';

@Component({})
export default class LoadingSpinner extends Vue {

    @Prop({type: Boolean, default: false})
    public readonly loading: boolean;

    @Prop({type: String, default: StyleAppearance.PRIMARY})
    private readonly appearance: StyleAppearance;

    @Prop({type: String, default: StyleSize.MEDIUM})
    private readonly size: StyleSize;

    public get clss(): string {
        let clss: string = 'loading-spinner';
        if (this.appearance) {
            clss += ' ' + this.appearance;
        }
        if (this.size) {
            clss += ' ' + this.size;
        }

        return clss;
    }

}
