/**
 * Style: Appearance types.
 */
export const enum StyleAppearance {

    WHITE = 'white',
    DARK = 'dark',
    NEUTRAL = 'neutral',
    NEUTRAL_NEW = 'neutral-new',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    INFO = 'info',
    WARNING = 'warning',
    DANGER = 'danger',
    HELP = 'help',
    DISABLED = 'disabled',

}
