import {CastableProperties} from "@/app/utils/Castable";
import {Main} from "@/app/Main";
import {Lang} from "@/app/lang/Lang";
import {ManufyValueDTO} from "@/app/dto/ManufyValueDTO";
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";
import {FileDTO} from "@/app/dto/FileDTO";
import {TranslationDTO} from "@/app/dto/TranslationDTO";

export class PublicSellerShortDTO extends CompanyShortDTO {

    public introductionBrief: TranslationDTO = new TranslationDTO();

    public averageReviewScore = null;
    public countReviews = null;

    public manufyValues: ManufyValueDTO[] = [];
    public categories: string[] = [];
    public fabrics: string[] = [];

    public profileImages: FileDTO[] = null;

    public avatarUrl: string = null;
    public objectID: string = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            introductionBrief: TranslationDTO,
            profileImages: [FileDTO],
        };
    }

    public getReviewString(): string {
        if (this.averageReviewScore != 0 && this.countReviews != 0) {
            const reviewCount: string = this.numberFormatter(this.countReviews, 1);
            let reviewScore: string = this.averageReviewScore.toFixed(1);

            return reviewScore + ' <span class="text-neutral-80">(' + Main.trans.t('app.labels.numberOfReviews', {count: reviewCount}) + ')</span>'
        }

        return Main.trans.t('app.labels.notAvailable');
    }

    public getName(): string {
        if (!this) {
            return '';
        }

        if (this.name && this.name !== '') {
            return this.name
        }

        return Main.trans.t(Lang.t.pages.sellerReadPage.sellerName, {countryName: Main.trans.t(('meta.countries.' + this.primaryLocation.country))});
    }

    public imageLoaded(image: FileDTO): void {
        image.isLoading = true;
    }

    // Number formatter that returns the original number (if less than a thousand) or the number in thousands (or millions), in the form of a readable string.
    private numberFormatter(number: number, digits: number): string {
        const lookup = [
            {value: 1, symbol: ""},
            {value: 1e3, symbol: "k"},
            {value: 1e6, symbol: "m"}
        ];

        const regex: RegExp = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let item = lookup.slice().reverse().find(function (item) {
            return (number >= item.value);
        });

        return (item) ? (number / item.value).toFixed(digits).replace(regex, "$1") + item.symbol : "0";
    }
}
