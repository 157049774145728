import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Routes} from "@/app/Routes";
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';

type VatFormModel = {
    vatNumber?: string,
};

@Component({})
export default class VatValidationModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public formData: FormData<VatFormModel> = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(): void {
        this.flags.show = true;

        // define rules:
        const formRules: FormRulesById = {};
        formRules.vatNumber = [FormRules.required(), FormRules.maxLength(255)];

        // Reset form:
        this.formData = new FormData({}, formRules);
    }

    public submit(): void {
        const formIsValid: boolean = this.formData.validate();
        if (!formIsValid) {
            return;
        }

        this.flags.loading = true;

        const payload: Record<string, any> = {};
        payload.companyUuid = Store.user.isAdmin ? Routes.currentRoute.params.uuid : Store.user.currentCompanyUuid;
        payload.vatNumber = this.formData.model.vatNumber;

        let promise: Bluebird<any> = Main.callApi('company/vat/validate', RequestMethods.PATCH, payload);
        promise = promise.then(this.handleVatResult.bind(this));
        promise = promise.catch(this.handleVatError.bind(this));
        promise = promise.finally(() => this.flags.loading = false);
    }

    private handleVatResult() {
        Store.user.isAdmin
            ? Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.adminVatValidated.message), Main.trans.t(Lang.t.app.toast.success.adminVatValidated.title))
            : Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.vatValidated.message), Main.trans.t(Lang.t.app.toast.success.vatValidated.title));

        this.hide(true);

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    private handleVatError() {
        Main.app.toast.danger(Main.trans.t(Lang.t.app.toast.danger.vatValidated.message), Main.trans.t(Lang.t.app.toast.danger.vatValidated.title));
    }

}
