import {Component, Prop} from "vue-facing-decorator";
import {FormElementWrapper} from "@/app/views/components/misc/form/FormElementWrapper";

@Component({})
export default class FormFileUploadWrapper extends FormElementWrapper<[]> {

    @Prop({type: Array, default: []}) // Only valid files are placed in this list
    declare public modelValue: [];

    @Prop({type: Boolean, default: false}) // In case of single, the FileDTO is still stored in the modelValue array; the array will then always have at most 1 item!
    declare public multiple: boolean;

    @Prop({type: String, default: null})
    public readonly accept: string;

    @Prop({type: Number, default: null})
    public readonly aspectRatio: number;

    @Prop({type: Number, default: null})
    public readonly minWidth: number;

    @Prop({type: Number, default: null})
    public readonly minHeight: number;

}
