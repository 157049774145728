/**
 *
 * @author Arno van Oordt
 * @version 1.1.3
 */
export class WebServiceError extends Error {

    public code: number = null;
    public response: Response = null;
    public data: any = null;

    constructor(response: Response, data: any) {
        super();

        this.name = 'WebServiceError';
        this.response = response;
        this.code = (response) ? response.status : null;

        if (typeof data == 'string') {
            this.message = data.toString();
        } else if (data && data.message) {
            this.message = data.message;
        }
        this.data = data;
        this.response = response;

        try {
            this.stack = new Error().stack;
            // Error.captureStackTrace(this, WebServiceError);
        } catch (err) {
        }
    }

}
