<script lang="ts" src="./LabelEdit.ts"></script>

<template>
  <div class="flex flex-row justify-between my-1.5">
    <div class="flex flex-row">
      <m-form-field-checkbox v-model="flags.selected" class="mr-2 my-auto" @update:modelValue="handleSelectedChange"/>

      <div class="my-auto">
        <m-form-field
          v-if="flags.editMode"
          ref="editField"
          v-model="editLabel.name"
          :size="$const.StyleSize.XSMALL"
          type="text"
        />

        <span v-else>{{ editLabel.name }}</span>
      </div>
    </div>

    <div class="flex flex-row justify-end my-auto">
      <m-button
        v-if="!flags.editMode"
        :icon-before="{ name: 'pencil-square', solid: true, type: $const.IconTypes.HERO, }"
        :size="$const.StyleSize.SMALL"
        :variant="$const.StyleVariants.GHOST"
        @click="edit()"
      />
      <m-button
        v-if="flags.editMode"
        :icon-before="{ name: 'floppy-disk', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
        :size="$const.StyleSize.SMALL"
        :variant="$const.StyleVariants.GHOST"
        @click="save()"
      />
      <m-button
        :appearance="$const.StyleAppearance.DANGER"
        :icon-before="{ name: 'trash', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
        :size="$const.StyleSize.SMALL"
        :variant="$const.StyleVariants.GHOST"
        @click.stop="deleteLabel()"
      />
    </div>

  </div>

</template>

<style lang="scss" scoped>

</style>
