import {Component, Prop} from 'vue-facing-decorator';
import {FormItem} from '@/app/views/components/form/FormItem';
import {StyleSize} from "@/app/constants/styles/StyleSize";

@Component({})
export default class FormFieldCheckboxButton extends FormItem<boolean> {

    @Prop({type: [Boolean], default: null})
    declare public modelValue: boolean;

    @Prop({type: String, default: null})
    public readonly tooltip: string;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public size: StyleSize;

    declare public $refs: {
        checkbox: HTMLInputElement;
    };

    public handleInput(evt: Event): void {
        this.updateValue((evt.target as HTMLInputElement).checked);
    }

}
