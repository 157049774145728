import {RouteLocationRaw} from "vue-router";
import {Component, Prop, Vue} from "vue-facing-decorator";

@Component({})
export default class CancelButton extends Vue {

    @Prop({type: String, default: null})
    public readonly id: string;

    @Prop({type: String, default: null})
    public readonly tooltipLabel: string;

    @Prop({type: [String, Object], default: null})
    public readonly tooltipLink: RouteLocationRaw;

    @Prop({type: Boolean, default: false})
    public readonly disabled: boolean;

    @Prop({type: Boolean, default: null})
    public readonly required: boolean;

}
