import {Component, Prop} from 'vue-facing-decorator';
import {StyleSize} from '@/app/constants/Constants';
import {FormItem} from '@/app/views/components/form/FormItem';

@Component({})
export default class FormFieldReview extends FormItem<number> {

    @Prop({type: Number, default: null})
    declare public modelValue: number;

    @Prop({type: Number, default: 5})
    public readonly max: number = null;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: Boolean, default: null})
    public readonly clearable: boolean;

    @Prop({type: Number, default: null})
    public readonly oldRating: number = null;

    @Prop({type: [String], default: StyleSize.MEDIUM})
    public size: StyleSize;

    public selectedRating: number = 0;
    public currentRating: number = 0;
    public ratingSelected: boolean = false;

    public setRating(rating: number, persist: boolean): void {
        this.currentRating = rating;
        if (persist) {
            this.selectedRating = (this.clearable && this.currentRating === this.selectedRating) ? 0 : this.currentRating;
            this.ratingSelected = true;
        }

        super.updateValue(this.selectedRating);
    }

    public resetRating() {
        this.currentRating = this.selectedRating;
        this.ratingSelected = this.selectedRating > 0 ? true : false;
    }
}
