<script lang="ts" src="./CatalogueInquiryModal.ts"></script>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-small">
      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t("pages.productIndex.inquiry.title") }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <m-catalogue-inquiry :is-in-modal="true" :item="item" class="dialog-body space-y-4" @success="hide()"/>

      <div class="dialog-footer"></div>
    </div>
  </div>
</template>
