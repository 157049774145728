import {Component, Prop} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {StyleSize} from '@/app/constants/Constants';
import {FormItem} from '@/app/views/components/form/FormItem';
import {IIcon} from '@/app/views/components/misc/Icon';

/**
 * A definition of a select picker item.
 * The item needs to have at least an id property.
 * If the `label` property isn't set, the `id` will be used as label.
 */
export type FormSelectCardPickerItem<T extends string | number | boolean> = { [key: string]: any; } & { id?: T, label?: string, icon?: IIcon, searchable?: boolean };

@Component({})
export default class FormSelectCardPicker<U extends string | number | boolean = string> extends FormItem<U | U[]> {

    @Prop({type: String, default: null})
    public readonly searchPlaceholder: string;

    @Prop({type: String, default: null})
    public readonly searchLabel: string;

    @Prop({type: String, default: null})
    public readonly allLabel: string;

    @Prop({type: String, default: null})
    public readonly iconClass: string;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: StyleSize;

    @Prop({type: Boolean, default: false})
    public readonly multiselect: boolean;

    @Prop({type: Boolean, default: false})
    public readonly required: boolean;

    @Prop({type: Boolean, default: false})
    public readonly searchable: boolean;

    @Prop({type: Boolean, default: false})
    public readonly showControls: boolean;

    @Prop({type: Boolean, default: false})
    public readonly omitItemIcons: boolean;

    @Prop({type: Number, default: false})
    public readonly maxCols: number;

    @Prop({type: [String, Number, Boolean, Array], default: null})

    declare public readonly modelValue: U | U[];

    declare public readonly $refs: {
        filterInput: HTMLDivElement,
        formField: HTMLDivElement,
    };

    public readonly flags = {};

    public filterStr: string = null;

    @Prop({type: Array, required: true})
    private readonly items: FormSelectCardPickerItem<U>[];

    public get selectedItems(): FormSelectCardPickerItem<U>[] {
        const values: U[] = (this.value instanceof Array) ? this.value : [this.value];
        return this.items.filter((item: FormSelectCardPickerItem<U>) => {
            return values.includes(item.id);
        });
    }

    /**
     * Returns an array with all items based on the search value.
     */
    public get filteredItems(): FormSelectCardPickerItem<U>[] {
        if (this.filterStr) {
            const filterStr: string = this.filterStr.toLowerCase();
            return this.items.filter((value: FormSelectCardPickerItem<U>): boolean => {
                if (!value.id || value.searchable === false) {    // Is group or non-searchable
                    return false;
                }
                const label: string = value.label ?? '' + value.id;
                return label.toLowerCase().includes(filterStr);
            });
        }
        return Array.from(this.items);  // Always make a copy to make sure the original array isn't changed
    }

    /**
     * The label to be displayed in the field when it's not in search mode.
     * In case null is returned the searchPlaceholder value will be used.
     */
    public get selectedLabel(): string {
        let label: string = null;
        if (this.selectedItems.length > 0) {
            if (this.multiselect) {
                label = Main.trans.tc('components.selectPicker.multiselectLabel', this.selectedItems.length, {count: this.selectedItems.length});
            } else {
                label = this.selectedItems[0].label ?? '' + this.selectedItems[0].id;
            }
        }
        return label;
    }

    public get allSelected(): boolean {
        return (this.selectedItems.length == this.items.length);
    }

    public get allItemsCount(): number {
        return this.items.length;
    }

    public get gridCols(): string {
        switch (this.maxCols) {
            case 1:
                return 'sm:grid-cols-1';
            case 3:
                return 'sm:grid-cols-3';
            case 4:
                return 'sm:grid-cols-4';
            case 5:
                return 'sm:grid-cols-5';
            case 6:
                return 'sm:grid-cols-6';
            case 7:
                return 'sm:grid-cols-7';
            case 8:
                return 'sm:grid-cols-8';
            case 9:
                return 'sm:grid-cols-9';
            case 10:
                return 'sm:grid-cols-10';
            case 11:
                return 'sm:grid-cols-11';
            case 12:
                return 'sm:grid-cols-12';
            default:
                return 'sm:auto-cols-auto';
        }
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public clickItem(item: FormSelectCardPickerItem<U> = null): void {
        if (this.multiselect && this.checkItemSelected(item)) {
            this.deselectItem(item);
        } else {
            this.selectItem(item);
        }
    }

    public checkItemSelected(item: FormSelectCardPickerItem<U> = null): boolean {
        return (this.value === item.id || (this.value instanceof Array && this.value.includes(item.id)));
    }

    public selectItem(item: any): void {
        let v: U | U[] = this.value;
        if (v instanceof Array) {
            v.push(item.id);
        } else {
            v = item.id;
        }
        this.updateValue(v);
    }

    public deselectItem(item: any): void {
        let v: U | U[] = this.value;
        if (v instanceof Array) {
            v.remove(item.id);
        } else if (v === item.id) {
            v = null;
        }
        this.updateValue(v);
    }

    public selectAllItems(): void {
        let v: U[] = this.items.map((item) => {
            return item.id;
        });
        this.updateValue(v);
    }

    public deselectAllItems(): void {
        this.updateValue((this.value instanceof Array) ? [] : null);
    }

}
