export const enum CompanyAvailabilityStatus {

    FULL_CAPACITY = 'full_capacity',
    LOOKING_FOR_PARTNERS = 'looking_for_partners',
    NOT_ACTIVELY_SEARCHING = 'not_actively_searching',
    ON_HOLIDAY = 'on_holiday',
    OPEN_CAPACITY = 'open_capacity',
    PROJECT_IN_PROCESS = 'project_in_process',

}
