import {Component, Prop, Vue} from 'vue-facing-decorator';

@Component({})
export default class TextClamp extends Vue {

    declare public readonly $refs: {
        content: HTMLDivElement;
    };

    @Prop({type: Number, required: true})
    public readonly maxLines: number;

    public readonly flags = {
        expanded: false,
        needsClamping: false,
    };

    public created(): void {
    }

    public mounted(): void {
        new ResizeObserver(this.checkDivSize.bind(this)).observe(this.$refs.content);
        this.checkDivSize();
    }

    public showMore(): void {
        this.flags.expanded = true;
    }

    public showLess(): void {
        this.flags.expanded = false;
    }

    private checkDivSize(): void {
        if (!this.$refs.content) {
            return;
        }
        if (this.flags.expanded) {
            // Temporarily add clampable to do a height check...
            this.$refs.content.classList.add('clampable');
        }

        this.flags.needsClamping = (this.$refs.content.scrollHeight > this.$refs.content.clientHeight);
        if (this.flags.expanded) {
            // ...and remove again
            this.$refs.content.classList.remove('clampable');
        }
    }

}
