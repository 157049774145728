<script lang="ts" src="./Avatar.ts"></script>


<template>
  <div :class="clss">
    <div :class="['avatar', { default: showDefault || !url }]">
      <img :alt="alt" :src="(!showDefault && url) ? url : `${$settings.appPath}/images/logo.svg`" @error="replaceWithDefault"/>
    </div>

    <div class="badge-wrapper">
      <template v-if="partner">
        <m-tooltip :label="$trans.t('app.tooltips.partnerStatus')" class="tooltip">
          <partner-badge class="partner-badge"/>
        </m-tooltip>
      </template>

      <template v-else-if="verified">
        <m-tooltip :label="$trans.t('app.tooltips.verifiedStatus')" class="tooltip">
          <verified-badge class="verified-badge"/>
        </m-tooltip>
      </template>
    </div>

    <span v-if="notification" class="notification"></span>
  </div>
</template>


<style lang="scss" scoped>
</style>
