import {Component, Prop, Watch} from 'vue-facing-decorator';
import {StyleSize} from '@/app/constants/Constants';
import {FormItem} from '@/app/views/components/form/FormItem';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    components: {},
})
export default class FormFieldTextAreaEditor extends FormItem<string> {

    @Prop({type: [String], default: null})
    declare public modelValue: string;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: Number, default: 5})
    public readonly rows: number;

    @Prop({type: String, default: StyleSize.MEDIUM})
    declare public size: StyleSize;

    public editor: ClassicEditor = ClassicEditor;
    public editorConfig: {} = {
        removePlugins: [
            'Heading',
            'Link',
            'CKFinder',
            'CKFinderUploadAdapter',
            'Autoformat',
            'BlockQuote',
            'CKBox',
            'CKFinder',
            'CloudServices',
            'EasyImage',
            'Heading',
            'Image',
            'ImageCaption',
            'ImageStyle',
            'ImageToolbar',
            'ImageUpload',
            'Indent',
            'Link',
            'MediaEmbed',
            'PasteFromOffice',
            'PictureEditing',
            'Table',
            'TableToolbar',
            'TextTransformation'
        ],
        toolbar: [
            'undo', 'redo', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList'
        ],
    };

    @Watch('value')
    public updateFromValue(): void {
        this.updateValue(this.value);
    }

}
