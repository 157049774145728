<script lang="ts" src="./GalleryModal.ts"></script>

<template>
  <div v-if="flags.show" :class="['modal viewport-scroll', { 'has-scrollbar': flags.hasScrollbar }]" :data-scrollbar-width="$store.app.browserScrollbarWidth" tabindex="0" @keydown.esc="hide()" @click.self="hide()">
    <div class="modal-dialog w-medium">
      <div class="dialog-header" @click.self="hide($event)">
        <m-button
          :appearance="$const.StyleAppearance.PRIMARY"
          :shape="$const.StyleShapes.CIRCLE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.OUTLINE"
          @click.prevent="hide()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon -mb-px" name="xmark"/>
        </m-button>
      </div>

      <div class="dialog-body" @click.self="hide($event)">

        <div class="current-image-wrapper grow" @click.self="hide($event)">
          <img :src="(current.originalSrc ?? current.originalUrl)" alt="" class="image">
        </div>

        <div v-if="fileList.length > 1" class="gallery-controls">
          <div class="prev">
            <m-button
              :appearance="$const.StyleAppearance.PRIMARY"
              :shape="$const.StyleShapes.CIRCLE"
              :size="$const.StyleSize.LARGE"
              :variant="$const.StyleVariants.OUTLINE"
              @click="goto(index - 1, true)"
            >
              <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="chevron-left"/>
            </m-button>
          </div>

          <div class="next">
            <m-button
              :appearance="$const.StyleAppearance.PRIMARY"
              :shape="$const.StyleShapes.CIRCLE"
              :size="$const.StyleSize.LARGE"
              :variant="$const.StyleVariants.OUTLINE"
              @click="goto(index + 1, true)"
            >
              <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="chevron-right"/>
            </m-button>
          </div>
        </div>

        <div class="inline-flex justify-center overflow-x-auto flex-none" @click.self="hide($event)">
          <ul ref="thumbsList" class="thumbs-list">
            <li
              v-for="(file, i) in fileList"
              :key="file"
              :class="['thumb', { current: index == i }]"
              @click="goto(i)"
            >
              <img :src="(file.thumbSrc ?? file.thumbUrl)" class="image"/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.modal {
  @apply px-0;

  &::before {
    @apply bg-opacity-90;
  }

  &.has-scrollbar[data-scrollbar-width="12"] {
    @apply pr-3;
  }

  &.has-scrollbar[data-scrollbar-width="15"] {
    padding-right: 15px;
  }

  &.has-scrollbar[data-scrollbar-width="17"] {
    padding-right: 17px;
  }
}

.modal-dialog {
  @apply flex flex-col w-full min-h-full bg-transparent shadow-none;
}

@media only screen and (max-device-width: theme('screens.md')) and (orientation: landscape) {
  .modal-dialog {
    min-height: initial;
  }
}

.dialog-header {
  @apply justify-end pt-0 pb-2 pr-6;
}

.dialog-body {
  @apply flex flex-col flex-1 px-0;
}

.current-image-wrapper {
  @apply max-w-full h-80 mx-auto mb-3 text-center sm:mb-6 sm:px-4 md:h-96 lg:h-128;

  &::before {
    @apply h-full inline-block align-middle;
    content: "";
  }

  .image {
    @apply inline-block max-w-full max-h-full align-middle rounded-none shadow-lg;
  }
}

.gallery-controls {
  @apply flex justify-between items-center px-2 space-x-2 absolute top-10 right-0 bottom-14 mb-1 left-0 pointer-events-none sm:px-6;

  .prev,
  .next {
    @apply pointer-events-auto;

    .btn {
      @apply shadow-lg border-0 text-neutral-0 bg-neutral-800 bg-opacity-50;
    }
  }
}

.thumbs-list {
  @apply max-w-full inline-flex flex-row flex-nowrap px-4 space-x-1.5 overflow-x-auto sm:mb-0 sm:space-x-2;

  .thumb {
    @apply w-12 h-12 flex-none relative rounded-xl border-2 border-neutral-10 border-opacity-25 bg-neutral-800 cursor-pointer transition-all overflow-hidden hover:border-green-75 hover:border-opacity-50;

    &::before {
      @apply absolute top-0 right-0 bottom-0 left-0 text-right bg-neutral-800 bg-opacity-25 transition-all;
      content: "";
    }

    &:hover::before,
    &.current::before {
      @apply bg-opacity-0;
    }

    &:hover .image {
      @apply opacity-50;
    }

    &.current {
      @apply border-green-400;

      .image {
        @apply opacity-100;
      }
    }

    .image {
      @apply w-full h-full object-cover rounded-none opacity-25 transition-all;
    }
  }
}

</style>
