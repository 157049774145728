import {Component, Prop} from "vue-facing-decorator";
import {FormElementWrapper} from "@/app/views/components/misc/form/FormElementWrapper";

@Component({})
export default class FormFieldNumberWrapper extends FormElementWrapper<number> {

    @Prop({type: Number, default: null})
    declare public modelValue: number;

    @Prop({type: Number, default: null})
    public readonly min: number = null;

    @Prop({type: Number, default: null})
    public readonly max: number = null;

    @Prop({type: Number, default: null})
    public readonly step: number = null;

    // Style properties
    @Prop({type: Boolean, default: false})
    declare public rtl: boolean;

}
