/**
 * Enumeration of possible company types.
 * Note that the string value is also used to compose api routes!
 */
export const enum CompanyTypes {

    BUYER = 'buyer',
    SELLER = 'seller',
    MANUFY = 'manufy',

}
