export const enum PurchaseOrderStatus {

    WAITING_FOR_COST_ESTIMATION = 'waiting_for_cost_estimation',
    PRICE_NEGOTIATION = 'price_negotiation',
    WAITING_FOR_PAYMENT = 'waiting_for_payment',
    WAITING_FOR_KYC = 'waiting_for_kyc',
    WAITING_FOR_SHIPMENT = 'waiting_for_shipment',
    COMPLETED = 'completed',
    BUYER_CANCELLED = 'buyer_cancelled',
    SELLER_CANCELLED = 'seller_cancelled',
    BUYER_DECLINED = 'buyer_declined',
    SELLER_DECLINED = 'seller_declined',

    // LEGACY
    PAYMENT_COMPLETED = 'payment_completed',

    // FRONT-END
    PENDING = 'pending'

}
