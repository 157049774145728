import {Component, Prop, Vue, Watch} from 'vue-facing-decorator';
import {StyleSize} from '@/app/constants/styles/StyleSize';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import * as HeroIcons from '@heroicons/vue/24/outline'
import * as HeroIconsSolid from '@heroicons/vue/24/solid'

import {IconTypes} from '@/app/constants/IconTypes';

export interface IIcon {

    name: string;
    solid: boolean;
    type: IconTypes;

}

const components = {};
components['font-awesome-icon'] = FontAwesomeIcon;
for (let iconName in HeroIcons) {
    components[iconName] = HeroIcons[iconName];
}

for (let iconName in HeroIconsSolid) {
    components[iconName + 'Solid'] = HeroIconsSolid[iconName];
}

@Component({
    components: components,
})
export default class Icon extends Vue {

    @Prop({type: String, default: null})    // In case of null the component is just omitted
    public readonly name: string;
    @Prop({type: String, default: null})
    public type: IconTypes;
    public id: string = null;
    // Make enums accessible in template:
    @Prop({type: Boolean, default: false})
    private readonly solid: boolean;
    @Prop({type: String, default: StyleSize.MEDIUM})
    private readonly size: StyleSize;

    public get clss(): string {
        if (this.type === IconTypes.MANUFY) {
            return `icon-${this.id}`;
        }

        switch (this.size) {
            case StyleSize.XSMALL:
                return 'w-3 h-3';
            case StyleSize.SMALL:
                return 'w-3.5 h-3.5';
            case StyleSize.MEDIUM:
                return 'w-4 h-4';
            case StyleSize.LARGE:
                return 'w-5 h-5';
            case StyleSize.XLARGE:
                return 'w-6 h-6';
            default:
                break;
        }
    }

    public get fontAwesomeType(): string {
        return this.type === IconTypes.FONT_AWESOME ?
            this.type + ((this.solid) ? 's' : 'r') :
            this.type;
    }

    public created(): void {
        this.parseName(this.name, null);
    }

    @Watch('name')
    private parseName(newValue: string, oldValue: string): void {
        if (newValue) {
            const name: string[] = newValue.split(' ');
            this.id = name.join(' ');

            let isIconFontFound: boolean = false;
            for (let key in Object.keys(IconTypes)) {
                const iconType: IconTypes = IconTypes[key];
                if (this.type === iconType) {
                    isIconFontFound = true;
                    break;
                }
            }

            if (isIconFontFound) {
                throw Error('Unknown icon type `' + this.type + '` and name ' + this.name + ' and this ID ' + this.id);
            }

            if (this.type === IconTypes.HERO) {
                this.id += '-icon' + ((this.solid) ? '-solid' : '');
            }

            if (this.type === IconTypes.MANUFY) {
                this.id = (this.solid ? 'solid-' : '') + this.id.replaceAll('_', '-');
            }
        } else {
            this.id = null;
        }
    }

}
