import Bluebird from "bluebird";
import {Component} from 'vue-facing-decorator';
import {Main} from "@/app/Main";
import {Modal} from "@/app/views/modals/Modal";
import {Lang} from "@/app/lang/Lang";
import {PurchaseOrderDTO} from "@/app/dto/PurchaseOrderDTO";
import {ReviewDTO} from "@/app/dto/ReviewDTO";
import {FormData} from "@/app/utils/FormData";
import {FormRules, FormRulesById} from "@/app/utils/FormRules";
import {RequestMethods} from "@/libs/core/constants/RequestMethods";
import {Uuid} from "@/types/Types";

type ReviewFormModel = {
    score?: number,
    body?: string,
    hasNameDisplayed?: boolean,
};

@Component({})
export default class ReviewModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };
    public reviewFormData: FormData<ReviewFormModel> = null;
    public purchaseOrder: PurchaseOrderDTO = null;
    public review: ReviewDTO = null;
    private collaborationUuid: Uuid = null

    public created(): void {
    }

    public save(): void {
        this.review
            ? this.updateReview()
            : this.submitReview()
    }

    public show(purchaseOrder, review): void {
        const reviewFormRules: FormRulesById = {};
        reviewFormRules.score = [FormRules.required(), FormRules.range(1, 5)];
        reviewFormRules.body = [FormRules.required(), FormRules.maxLength(255)];

        const reviewForm: ReviewFormModel = {
            score: null,
            body: null,
            hasNameDisplayed: null,
        };

        this.reviewFormData = new FormData(reviewForm, reviewFormRules);

        if (purchaseOrder) {
            this.collaborationUuid = purchaseOrder.collaborationUuid;
            this.purchaseOrder = purchaseOrder;
        }

        if (review) {
            this.review = review;
            this.reviewFormData.model.score = review.score
            this.reviewFormData.model.body = review.body
            this.reviewFormData.model.hasNameDisplayed = review.hasNameDisplayed;
        }

        this.flags.show = true;
    }

    private submitReview(): void {
        if (!this.reviewFormData.validate()) {
            return;
        }

        this.flags.loading = true;

        const payload: Record<string, any> = {};
        payload.uuid = this.collaborationUuid;
        payload.purchaseOrderUuid = this.purchaseOrder.uuid;
        payload.score = this.reviewFormData.model.score;
        payload.body = this.reviewFormData.model.body;
        payload.hasNameDisplayed = (this.reviewFormData.model.hasNameDisplayed);

        let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/review', RequestMethods.POST, payload);
        promise = promise.then(this.handleSubmitResult.bind(this, 'leaveReview'));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    private updateReview(): void {
        if (!this.reviewFormData.validate()) {
            return;
        }

        this.flags.loading = true;

        let promise = Main.app.alert.show(Main.trans.t(Lang.t.app.order.updateReview.alert.message), Main.trans.t(Lang.t.app.order.updateReview.alert.title), Main.trans.t(Lang.t.app.labels.yes), Main.trans.t(Lang.t.app.labels.no))

        promise = promise.then(() => {
            const payload: Record<string, any> = {};
            payload.uuid = this.review.uuid;
            payload.body = this.reviewFormData.model.body;
            payload.score = this.reviewFormData.model.score;
            payload.hasNameDisplayed = (this.reviewFormData.model.hasNameDisplayed);

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/review', RequestMethods.PATCH, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'updateReview'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }

        });
    }

    private handleSubmitResult(action: string): void {
        this.hide(true);
        Main.app.toast.success(Main.trans.t('app.order.' + action + '.success'), Main.trans.t(Lang.t.app.labels.success));

        setTimeout(() => {
            location.reload();
        }, 1000)
    }
}
