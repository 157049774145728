import {BaseDTO} from '@/app/dto/BaseDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {CompanyAvailabilityStatus} from '@/app/constants/CompanyAvailabilityStatus';

export class CompanyLayoutDTO extends BaseDTO {

    public slug: string = null;
    public name: string = null;
    public avatar: FileDTO = null;
    public credits: number = null;
    public registrationCountry: string = null;
    public unreadMessagesCount: number = null;
    public subscriptionType: string = null;
    public availabilityStatus: CompanyAvailabilityStatus = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            avatar: FileDTO,
        };
    }

}
