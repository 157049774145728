import {Component, Prop, Vue} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {FabricDTO} from '@/app/dto/FabricDTO';
import {Store} from '@/app/stores/Store';

@Component({})
export default class ProductFabrics extends Vue {

    @Prop({type: Array, default: null})
    public readonly fabrics: FabricDTO[];

    @Prop({type: String, default: null})
    public readonly buyerName: string;

    @Prop({type: Boolean, default: null})
    public readonly hasFabrics: boolean;

    @Prop({type: Boolean, default: true})
    public readonly showCaption: boolean;

    public get tooltipMessage(): string {
        const prepend = (this.hasFabrics) ?
            ('own') :
            ((this.fabrics?.length > 0) ?
                    ('requested') :
                    ('no')
            );

        if (Store.user.isBuyer) {
            return Main.trans.tc(`pages.sellerDashboard.project.${prepend}FabricsTooltip`, 0);
        } else {
            if (this.buyerName) {
                return Main.trans.tc(`pages.sellerDashboard.project.${prepend}FabricsTooltip`, 2, {name: this.buyerName});
            }

            if (this.hasFabrics) {
                return Main.trans.tc('pages.sellerDashboard.project.noFabricsTooltip', 1);
            }

            return Main.trans.tc(`pages.sellerDashboard.project.${prepend}FabricsTooltip`, 1);
        }

    }

}
