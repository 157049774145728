<script lang="ts" src="./FormFieldReviewWrapper.ts"></script>

<template>
  <div class="m-input-group">
    <label v-if="label" :class="['label', size]" :for="id">
      <span>{{ label }}</span>
      <span v-if="required" class="text-red-400">*</span>
    </label>

    <m-form-field-review
      :id="id"
      :clearable="true"
      :disabled="disabled"
      :dusk="dusk"
      :errors="errors"
      :max="max"
      :modelValue="modelValue"
      :oldRating="oldRating"
      :size="size"
      @update:modelValue="updateValue"
    />

    <p v-if="supportText" class="support-text">
      {{ supportText }}
    </p>
  </div>
</template>


<style lang="scss" scoped>
</style>
