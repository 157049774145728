export const enum SystemMessageTypes {

    CHAT_DISCLAIMER = 'chat_disclaimer',

    INQUIRY_FROM_BUYER = 'inquiry_from_buyer',

    VIDEO_CALL_JOINED = 'video_call_joined',
    VIDEO_CALL_LEFT = 'video_call_left',

    SAMPLE_ORDER_INITIATED = 'sample_order_initiated',
    PURCHASE_ORDER_INITIATED = 'purchase_order_initiated',

    PURCHASE_ORDER_STARTED = 'purchase_order_started',
    PURCHASE_ORDER_COSTS_UPDATED = 'purchase_order_costs_updated',

    PURCHASE_ORDER_WAITING_FOR_PAYMENT = 'purchase_order_waiting_for_payment',
    PURCHASE_ORDER_WAITING_FOR_SHIPMENT_SENT = 'purchase_order_waiting_for_shipment_sent',
    PURCHASE_ORDER_WAITING_FOR_SHIPMENT_RECEIVED = 'purchase_order_waiting_for_shipment_received',
    PURCHASE_ORDER_SELLER_WAITING_FOR_PAYMENT = 'purchase_order_seller_waiting_for_payment',
    PURCHASE_ORDER_WAITING_FOR_MONEY_RELEASE = 'purchase_order_waiting_for_money_release',
    PURCHASE_ORDER_WAITING_FOR_MONEY_RECEIVED = 'purchase_order_waiting_for_money_received',
    PURCHASE_ORDER_WAITING_FOR_INVOICE = 'purchase_order_waiting_for_invoice',
    PURCHASE_ORDER_DISPUTE_STARTED = 'purchase_order_dispute_started',
    PURCHASE_ORDER_WAITING_FOR_REVIEW = 'purchase_order_waiting_for_review',
    PURCHASE_ORDER_REVIEW_PLACED = 'purchase_order_review_placed',

    PURCHASE_ORDER_DOCUMENT_UPLOADED = 'purchase_order_document_uploaded',
}
