import Bluebird from 'bluebird';
import {Main} from '@/app/Main';
import {FileMimes} from '@/app/constants/FileMimes';
import {BaseDTO} from '@/app/dto/BaseDTO';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';

export class FileDTO extends BaseDTO {

    public createdAt: Date = null;

    public fileName: string = null;
    public originalUrl: string = null;
    public thumbUrl: string = null;
    public fileSize: string = null;  // Human-readable string
    public mimeType: string = null;
    public isTemp: boolean = false;

    // For client-side use only:
    public errors: string[] = null;  // Needed for the FormFileUpload
    public promise: Bluebird<unknown> = null;  // Needed for the FormFileUpload
    public isLoading: boolean = true;
    private originalBlobSrc: string = null;
    private useOriginalBlobSrc: boolean = false;
    private thumbBlobSrc: string = null;
    private useThumbBlobSrc: boolean = false;

    /**
     * A getter for the original's blob-src in case it's a private image.
     */
    public get originalSrc(): string {
        if (this.originalUrl?.includes('/api/private/')) {
            if (!this.useOriginalBlobSrc) { // Need to load the file first
                this.useOriginalBlobSrc = true;
                let promise: Bluebird<any> = Main.callApi(this.originalUrl, RequestMethods.GET);
                promise = promise.then((response: Response) => {
                    response.blob().then((blob: Blob) => {
                        this.originalBlobSrc = window.URL.createObjectURL(blob);
                    });
                });
            }
            return this.originalBlobSrc;
        }
        return this.originalUrl;
    }

    /**
     * A getter for the thumb's blob-src in case it's a private image.
     */
    public get thumbSrc(): string {
        if (this.thumbUrl?.includes('/api/private/')) {
            if (!this.useThumbBlobSrc) { // Need to load the file first
                this.useThumbBlobSrc = true;
                let promise: Bluebird<any> = Main.callApi(this.thumbUrl, RequestMethods.GET);
                promise = promise.then((response: Response) => {
                    response.blob().then((blob: Blob) => {
                        this.thumbBlobSrc = window.URL.createObjectURL(blob);
                    });
                });
            }
            return this.thumbBlobSrc;
        }
        return this.thumbUrl;
    }

    public getThumbUrl(): string {
        if (this.thumbUrl) {
            return this.thumbUrl;
        } else if (FileMimes.IMAGES.includes(this.mimeType)) {
            return this.originalUrl;
        }
        return null;
    }

}
