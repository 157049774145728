export const enum SampleOrderStatus {

    COST_ESTIMATION = 'cost_estimation',
    COMPLETED = 'completed',
    BUYER_CANCELLED = 'buyer_cancelled',
    SELLER_CANCELLED = 'seller_cancelled',
    BUYER_DECLINED = 'buyer_declined',
    SELLER_DECLINED = 'seller_declined',
    ACCEPTED = 'accepted',

}
