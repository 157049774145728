<script lang="ts" src="./ProjectReviewModal.ts"></script>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-small">
      <div class="dialog-header">

        <h2 class="title">
          {{ $trans.t('app.feedbackModal.label') }}
        </h2>

        <m-button
          :appearance="$const.StyleAppearance.NEUTRAL"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.GHOST"
          class="close"
          @click.prevent="hide()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="xmark"/>
        </m-button>
      </div>

      <form v-if="project" @submit.prevent="save()">
        <div ref="dialogBody" class="m-form dialog-body">
          <div class="-mt-3 text-neutral-80">
            {{ $trans.t('app.feedbackModal.supportText') }}
          </div>
          <div class="flex flex-col items-center space-y-3 text-center xs:grid xs:grid-cols-2 xs:gap-3 xs:space-y-0 xs:text-left">
            <m-form-field-review-wrapper
              id="scoreDescription"
              v-model="reviewFormData.model.scoreDescription"
              :clearable="true"
              :dusk="'scoreDescription'"
              :errors="reviewFormData.errors.scoreDescription"
              :label="$trans.t('app.form.scoreDescription.label')"
              :oldRating="reviewFormData.model.scoreDescription"
              :required="true"
              :size="$const.StyleSize.SMALL"
            />

            <m-form-field-review-wrapper
              id="scorePrice"
              v-model="reviewFormData.model.scorePrice"
              :clearable="true"
              :dusk="'scorePrice'"
              :errors="reviewFormData.errors.scorePrice"
              :label="$trans.t('app.form.scorePrice.label')"
              :oldRating="reviewFormData.model.scorePrice"
              :required="true"
              :size="$const.StyleSize.SMALL"
            />

            <m-form-field-review-wrapper
              id="scoreQuantity"
              v-model="reviewFormData.model.scoreQuantity"
              :clearable="true"
              :dusk="'scoreQuantity'"
              :errors="reviewFormData.errors.scoreQuantity"
              :label="$trans.t('app.form.scoreQuantity.label')"
              :oldRating="reviewFormData.model.scoreQuantity"
              :required="true"
              :size="$const.StyleSize.SMALL"
            />

            <m-form-field-review-wrapper
              id="scoreSustainability"
              v-model="reviewFormData.model.scoreSustainability"
              :clearable="true"
              :dusk="'scoreSustainability'"
              :errors="reviewFormData.errors.scoreSustainability"
              :label="$trans.t('app.form.scoreSustainability.label')"
              :oldRating="reviewFormData.model.scoreSustainability"
              :required="true"
              :size="$const.StyleSize.SMALL"
            />

            <m-form-field-review-wrapper
              id="scoreTechpack"
              v-model="reviewFormData.model.scoreTechpack"
              :clearable="true"
              :dusk="'scoreTechpack'"
              :errors="reviewFormData.errors.scoreTechpack"
              :label="$trans.t('app.form.scoreTechpack.label')"
              :oldRating="reviewFormData.model.scoreTechpack"
              :required="true"
              :size="$const.StyleSize.SMALL"
            />
          </div>

          <m-form-field-text-area-wrapper
            id="comment"
            v-model="reviewFormData.model.comment.originalValue"
            :errors="reviewFormData.errors.comment?.originalValue"
            :label="$trans.t('app.form.comment.label')"
            :maxCharCount="255"
            :placeholder="$trans.t('app.form.comment.placeholder')"
            :required="true"
          />
        </div>

        <div class="dialog-footer m-form__action-buttons">
          <m-cancel-button :size="$const.StyleSize.SMALL" @cancel="hide()"/>
          <m-submit-button :disabled="flags.loading" :label="$const.FormButtonLabels.SEND_FEEDBACK" :size="$const.StyleSize.SMALL"/>
        </div>
      </form>

      <div v-else>
        <div ref="dialogBody" class="dialog-body">
          <m-rfq-review v-if="review" :review="review"/>
        </div>

        <div class="dialog-footer"></div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.dialog-header {
  @apply py-2 xs:py-2 sm:py-4;

  .close .icon {
    @apply text-tiny;
  }
}

.dialog-footer {
  @apply flex-wrap justify-end;
}

</style>
