<script lang="ts" src="./PartnerBadge.ts"></script>


<template>
  <svg id="partner-badge" class="fill-curent" data-name="Layer 1" height="64" role="img" viewBox="0 0 64 64" width="64"
       xmlns="http://www.w3.org/2000/svg">
    <path class="outline"
          d="M17.068,5.82a12.246,12.246,0,0,0,6.98-2.892,12.262,12.262,0,0,1,15.9,0,12.246,12.246,0,0,0,6.98,2.892A12.261,12.261,0,0,1,58.18,17.068a12.246,12.246,0,0,0,2.892,6.98,12.262,12.262,0,0,1,0,15.9,12.3,12.3,0,0,0-2.892,6.98A12.261,12.261,0,0,1,46.932,58.18a12.3,12.3,0,0,0-6.98,2.892,12.262,12.262,0,0,1-15.9,0,12.3,12.3,0,0,0-6.98-2.892A12.261,12.261,0,0,1,5.82,46.932a12.246,12.246,0,0,0-2.892-6.98,12.262,12.262,0,0,1,0-15.9,12.246,12.246,0,0,0,2.892-6.98A12.261,12.261,0,0,1,17.068,5.82"
          data-name="Path 1022"
          fill="#fff" fill-rule="evenodd"/>
    <path class="shape"
          d="M15.255,6.255a9.8,9.8,0,0,0,5.584-2.312,9.8,9.8,0,0,1,12.72,0,9.813,9.813,0,0,0,5.584,2.312,9.808,9.808,0,0,1,9,9,9.786,9.786,0,0,0,2.312,5.584,9.809,9.809,0,0,1,0,12.724,9.817,9.817,0,0,0-2.312,5.584,9.807,9.807,0,0,1-9,9,9.817,9.817,0,0,0-5.584,2.316,9.813,9.813,0,0,1-12.72,0,9.806,9.806,0,0,0-5.584-2.316,9.814,9.814,0,0,1-9-9,9.79,9.79,0,0,0-2.312-5.584,9.809,9.809,0,0,1,0-12.724,9.813,9.813,0,0,0,2.312-5.584,9.814,9.814,0,0,1,9-9"
          data-name="Path 1023"
          fill="#573fc9" fill-rule="evenodd" transform="translate(4.4 4.4)"/>
    <path class="icon"
          d="M20.919,13.821a1.409,1.409,0,0,0,2.136.472l3.908-3.132a1.784,1.784,0,0,1-.348-1.052,1.756,1.756,0,1,1,1.756,1.756h-.032L26.331,22.9a2.809,2.809,0,0,1-2.764,2.308H11.4A2.817,2.817,0,0,1,8.631,22.9l-2-11.036H6.595a1.76,1.76,0,1,1,1.4-.7l3.912,3.132a1.405,1.405,0,0,0,2.132-.472l2.516-5.032a1.758,1.758,0,1,1,1.844,0Z"
          data-name="Path 1024"
          fill="#F9B300" transform="translate(14.161 16.459)"/>
    <rect id="Rectangle_473" data-name="Rectangle 473" fill="none" height="64" width="64"/>
  </svg>
</template>


<style lang="scss" scoped></style>
