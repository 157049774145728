import {BaseDTO} from '@/app/dto/BaseDTO';
import {ProductOfferDTO} from '@/app/dto/ProductOfferDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {Uuid} from '@/types/Types';
import {PurchaseOrderDTO} from "@/app/dto/PurchaseOrderDTO";
import {ServiceOfferDTO} from "@/app/dto/ServiceOfferDTO";

export class CollaborationDTO extends BaseDTO {

    public buyerName: string = null;
    public buyerSlug: string = null;
    public buyerUuid: Uuid = null;
    public status: string = null;
    public sellerName: string = null;
    public sellerSlug: string = null;
    public sellerUuid: Uuid = null;
    public sellerVerificationStatus: string = null;
    public sellerVerificationPurchaseOrderUuid = null;
    public projectName: string = null;
    public projectUuid: Uuid = null;
    public threadUuid: Uuid = null;
    public products: ProductOfferDTO[] = null;
    public services: ServiceOfferDTO[] = null;
    public purchaseOrders: PurchaseOrderDTO[] = null;
    public sampleOrders: PurchaseOrderDTO[] = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            products: [ProductOfferDTO],
            services: [ServiceOfferDTO],
            purchaseOrders: [PurchaseOrderDTO],
            sampleOrders: [PurchaseOrderDTO],
        };
    }

}
