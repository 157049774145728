import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {ProjectDTO} from '@/app/dto/ProjectDTO';
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {ListResult} from '@/app/utils/ListResult';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Uuid} from '@/types/Types';
import {Routes} from "@/app/Routes";

type ProjectSendFormModel = {
    sellerUuid: Uuid,
    projectUuids: string[],
};

@Component({})
export default class ProjectSendModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public projects: ProjectDTO[] = [];
    public selectedProjects: boolean[] = [];
    public selectAllToggle: boolean = false;
    public formData: FormData<ProjectSendFormModel> = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(sellerUuid: Uuid): void {
        this.projects = null;
        this.selectedProjects = null;
        this.selectAllToggle = false;

        this.flags.loading = true;

        // Get collaborations:
        let promise: Bluebird<any> = Main.callApi('buyer/collaborations-projects?sellerUuid=' + sellerUuid + '&isInquiry=false', RequestMethods.GET, null, [ProjectDTO]);
        promise = promise.then(this.handleCollaborationsResult.bind(this));
        promise = promise.catch(this.handleNotSubscribedError.bind(this));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });

        const formRules: FormRulesById = {};
        formRules.sellerUuid = [FormRules.required()];
        formRules.projectUuids = [FormRules.required()];

        const formData: ProjectSendFormModel = {
            sellerUuid: sellerUuid,
            projectUuids: [],
        };

        this.formData = new FormData(formData, formRules);
    }

    public toggleAllProjects(): void {
        this.selectAllToggle = !this.selectAllToggle;
        this.selectedProjects = this.selectedProjects.map(p => this.selectAllToggle);
        this.formData.model.projectUuids = (this.selectAllToggle) ? this.projects.map(p => p.uuid) : [];
    }

    public toggleProject(projectUuid: string, index: number): void {
        this.selectedProjects[index] = !this.selectedProjects[index];
        if (this.formData.model.projectUuids.indexOf(projectUuid) < 0) {
            this.addProject(projectUuid);
        } else {
            this.removeProject(projectUuid);
        }

        this.selectAllToggle = (this.selectedProjects.indexOf(false) < 0);
    }

    public removeProject(projectUuid: string): void {
        this.formData.model.projectUuids.remove(projectUuid);
    }

    public submit(): void {
        if (!this.formData.validate()) {
            return;
        }

        const payload: Record<string, any> = {};
        payload.uuid = Store.user.currentCompanyUuid;
        payload.sellerUuid = this.formData.model.sellerUuid;
        payload.projectUuids = this.formData.model.projectUuids;

        let promise: Bluebird<any> = Main.callApi('buyer/collaborations', RequestMethods.POST, payload);
        promise = promise.then(this.handleSubmitResult.bind(this));
        promise = promise.catch((err: Error) => {
            if (err) {
                Main.app.toast.danger(Main.trans.t(Lang.t.app.toast.danger.sendProject.message), Main.trans.t(Lang.t.app.toast.danger.sendProject.title));
            }
        });

        this.hide();
    }

    private addProject(projectUuid: string): void {
        this.formData.model.projectUuids.push(projectUuid);
    }

    private handleCollaborationsResult(result: ListResult<ProjectDTO>): void {
        this.projects = result.items;
        this.selectedProjects = this.projects.map(p => false);

        if (this.projects.length > 0) {
            this.flags.show = true;
        } else {
            Routes.resolveLink({name: 'rfqWizard', query: {suuid: this.formData.model.sellerUuid}});
        }
    }

    private handleSubmitResult(): void {
        this.hide(true);
        Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.invitationsSent.message), Main.trans.t(Lang.t.app.toast.success.invitationsSent.title));
    }

    private handleNotSubscribedError(): void {
        this.hide(true);
        Routes.resolveLink({name: 'buyerSubscriptionList'});
    }

}
