import {Component} from 'vue-facing-decorator';
import {PublicProductDTO} from '@/app/dto/PublicProductDTO';
import ProductInquiryVue from '@/app/views/components/misc/ProductInquiry.vue';
import {Modal} from '@/app/views/modals/Modal';

@Component({
    components: {
        'm-product-inquiry': ProductInquiryVue,
    },
})
export default class ProductInquiryModal extends Modal {

    public productDto: PublicProductDTO = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(productDto: PublicProductDTO): void {
        this.productDto = productDto;
        this.flags.show = true;
    }

}
