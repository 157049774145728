<script lang="ts" src="./Icon.ts"></script>


<template>

  <template v-if="type == $const.IconTypes.FONT_AWESOME || type == $const.IconTypes.FONT_AWESOME_BRANDS">
    <font-awesome-icon :icon="[fontAwesomeType, name]"/>
  </template>
  <template v-else-if="type == $const.IconTypes.MANUFY">
    <i :class="clss"/>
  </template>
  <template v-else-if="type == $const.IconTypes.HERO">
    <component :is="id" :class="clss"/>
  </template>

</template>


<style lang="scss" scoped>
</style>
