<script lang="ts" src="./CropModal.ts"></script>


<template>
  <div v-if="flags.show" class="modal viewport-scroll">
    <div class="modal-dialog w-large secondary">
      <div class="dialog-header">
        <h2 class="title">
          Crop
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <div class="dialog-body">

        <cropper
          ref="cropper"
          :default-size="defaultCropSize"
          :defaultBoundaries="settings.defaultBoundaries"
          :min-height="settings.minHeight"
          :min-width="settings.minWidth"
          :src="current.src"
          :stencil-props="{
              aspectRatio: settings.aspectRatio,
            }"
          class="cropper"
          @ready="flags.loading = false"
        />

        <div class="flex justify-center overflow-x-auto">
          <div class="thumbs-list">
            <div
              v-for="(file, i) in fileList"
              v-if="fileList.length > 1"
              :key="file"
              :class="['thumb', { current: current.name === file.name }]"
              @click="goto(i)"
            >
              <img :src="getThumb(file)" class="image"/>
              <div class="remove">
                <!--
                <m-button
                  :appearance="$const.StyleAppearance.PRIMARY"
                  :shape="$const.StyleShapes.SQUARE"
                  :size="$const.StyleSize.XSMALL"
                  :variant="$const.StyleVariants.GHOST"
                  @click.stop="removeFile(i)"
                >
                  <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark" />
                </m-button>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-footer">
        <div class="action-buttons">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.GHOST"
            @click="hide()"
          >
            {{ $trans.t('app.labels.cancel') }}
          </m-button>

          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :disabled="flags.loading"
            :size="$const.StyleSize.SMALL"
            @click="submit()"
          >
            {{ $trans.t('app.labels.done') }}
          </m-button>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.thumbs-list {
  @apply max-w-full inline-flex flex-row flex-nowrap mb-3 space-x-1.5 overflow-x-auto sm:mb-0 sm:space-x-2;

  .thumb {
    @apply w-12 h-12 flex-none relative rounded-xl border-2 border-neutral-10 cursor-pointer transition-all overflow-hidden hover:border-opacity-50;

    &:hover .remove {
      @apply opacity-100 visible;
    }

    &.current {
      @apply border-green-400;
    }

    .image {
      @apply w-full h-full object-cover rounded-none;
    }

    .remove {
      @apply pt-0.5 pr-0.5 absolute top-0 right-0 bottom-0 left-0 invisible opacity-0 text-right bg-neutral-800 bg-opacity-50 transition-all;

      .btn {
        @apply min-w-5 w-5 h-5 text-neutral-0 hover:text-neutral-10 hover:bg-neutral-0 hover:bg-opacity-5;
        min-height: theme('height.5');
      }
    }
  }
}

.cropper {
  @apply max-w-xs h-80 mx-auto mb-3 rounded-xl sm:mb-6 md:max-w-sm md:h-96 lg:max-w-lg lg:h-128;

  :deep(.vue-advanced-cropper__background),
  :deep(.vue-advanced-cropper__foreground) {
    @apply bg-neutral-10 rounded-xl;
  }

  :deep(.vue-simple-handler) {
    @apply bg-green-400;
  }

  :deep(.vue-simple-line) {
    @apply border-green-400;
  }

  :deep(img) {
    @apply rounded-none;
  }
}

.modal-dialog .dialog-footer {
  @apply flex flex-row flex-wrap items-center space-x-3 rounded-b-xl border-t bg-neutral-0 sm:border-0 sm:bg-transparent;

  .action-buttons {
    @apply w-full flex flex-1 flex-row flex-wrap items-center justify-end space-x-3 rounded-b-xl;
  }
}

</style>
