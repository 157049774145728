import Bluebird from 'bluebird';
import {Component, Vue} from 'vue-facing-decorator';
import {LocationDTO} from '@/app/dto/LocationDTO';
import {PublicCatalogueServiceDTO} from '@/app/dto/PublicCatalogueServiceDTO';
import {ThreadDTO} from '@/app/dto/ThreadDTO';
import Alert from '@/app/views/components/layout/Alert';
import AlertVue from '@/app/views/components/layout/Alert.vue';
import Toast from '@/app/views/components/misc/Toast';
import ToastVue from '@/app/views/components/misc/Toast.vue';
import OfferCreateModal from '@/app/views/modals/OfferCreateModal';
import OfferCreateModalVue from '@/app/views/modals/OfferCreateModal.vue';
import CropModal, {CropSettings} from '@/app/views/modals/CropModal';
import CropModalVue from '@/app/views/modals/CropModal.vue';
import CategoryListModal from '@/app/views/modals/CategoryListModal';
import CategoryListModalVue from '@/app/views/modals/CategoryListModal.vue';
import ProductInquiryModal from '@/app/views/modals/ProductInquiryModal';
import ProductInquiryModalVue from '@/app/views/modals/ProductInquiryModal.vue';
import CatalogueInquiryModal from '@/app/views/modals/CatalogueInquiryModal';
import CatalogueInquiryModalVue from '@/app/views/modals/CatalogueInquiryModal.vue';
import KYCModal from '@/app/views/modals/KYCModal';
import KYCModalVue from '@/app/views/modals/KYCModal.vue';
import LabelModal from '@/app/views/modals/LabelModal';
import LabelModalVue from '@/app/views/modals/LabelModal.vue';
import LocationModal from '@/app/views/modals/LocationModal';
import LocationModalVue from '@/app/views/modals/LocationModal.vue';
import ManufyValueListModal from '@/app/views/modals/ManufyValueListModal';
import ManufyValueListModalVue from '@/app/views/modals/ManufyValueListModal.vue';
import PurchaseOrderModal from '@/app/views/modals/PurchaseOrderModal';
import PurchaseOrderModalVue from '@/app/views/modals/PurchaseOrderModal.vue';
import ReviewModal from '@/app/views/modals/ReviewModal';
import ReviewModalVue from '@/app/views/modals/ReviewModal.vue';
import GalleryModal from '@/app/views/modals/GalleryModal';
import GalleryModalVue from '@/app/views/modals/GalleryModal.vue';
import VatValidationModal from "@/app/views/modals/VatValidationModal";
import VatValidationModalVue from "@/app/views/modals/VatValidationModal.vue";
import ProjectReviewModal from '@/app/views/modals/ProjectReviewModal';
import ProjectReviewModalVue from '@/app/views/modals/ProjectReviewModal.vue';
import ProjectSendModal from "@/app/views/modals/ProjectSendModal";
import ProjectSendModalVue from "@/app/views/modals/ProjectSendModal.vue";
import ReportCompanyModal from "@/app/views/modals/ReportCompanyModal";
import ReportCompanyModalVue from "@/app/views/modals/ReportCompanyModal.vue";
import AvailabilityStatusModal from "@/app/views/modals/AvailabilityStatusModal";
import AvailabilityStatusModalVue from "@/app/views/modals/AvailabilityStatusModal.vue";
import {FileDTO} from '@/app/dto/FileDTO';
import {PublicProductDTO} from "@/app/dto/PublicProductDTO";
import {Uuid} from '@/types/Types';
import {Routes} from "@/app/Routes";
import {FormSelectItem} from '@/app/views/components/form/FormSelect';
import {PublicCatalogueFabricDTO} from "@/app/dto/PublicCatalogueFabricDTO";
import {SellerProjectDTO} from "@/app/dto/SellerProjectDTO";

@Component({
    components: {
        AlertVue,
        // AuthModalVue,
        OfferCreateModalVue,
        ProductInquiryModalVue,
        CatalogueInquiryModalVue,
        VatValidationModalVue,
        LabelModalVue,
        LocationModalVue,
        ManufyValueListModalVue,
        CategoryListModalVue,
        CropModalVue,
        GalleryModalVue,
        PurchaseOrderModalVue,
        ReviewModalVue,
        KYCModalVue,
        ProjectSendModalVue,
        ReportCompanyModalVue,
        ProjectReviewModalVue,
        AvailabilityStatusModalVue,
        ToastVue,
    },
})
export default class App extends Vue {

    declare public $refs: {
        alert: Alert;
        // authModal:AuthModal;
        offerCreateModal: OfferCreateModal;
        productInquiryModal: ProductInquiryModal;
        catalogueInquiryModal: CatalogueInquiryModal;
        vatValidationModal: VatValidationModal;
        labelModal: LabelModal;
        locationModal: LocationModal;
        cropModal: CropModal;
        galleryModal: GalleryModal;
        kycModal: KYCModal;
        purchaseOrderModal: PurchaseOrderModal;
        manufyValueListModal: ManufyValueListModal;
        reviewModal: ReviewModal;
        projectSendModal: ProjectSendModal;
        reportCompanyModal: ReportCompanyModal;
        categoryListModal: CategoryListModal;
        projectReviewModal: ProjectReviewModal;
        availabilityStatusModal: AvailabilityStatusModal;
        toast: Toast;
    };

    public get alert(): Alert {
        return this.$refs.alert;
    }

    public get toast(): Toast {
        return this.$refs.toast;
    }

    // public closeAuthModal():void {
    //     this.$refs.authModal.hide();
    // }

    public created(): void {
    }

    public mounted(): void {
    }

    public openLoginModal(email: string = null, password: string = null): void {
        const data: { email?: string, password?: string } = {};
        if (email || password) {
            data.email = email;
            data.password = password;
        }

        Routes.resolveLink({path: '/login', query: data});
    }

    public openRegisterModal(): void {
        Routes.resolveLink({path: '/register'});
    }

    public openPasswordRequestModal(email: string = null): void {
        const data: { email?: string } = {};

        (email)
            ? data.email = email
            : data.email = '';

        Routes.resolveLink({path: '/password-request', query: data})
    }

    public openPasswordResetModal(token: string, email: string): void {
        const data: { email?: string, token?: string } = {};
        if (email || token) {
            data.email = email;
            data.token = token;
        }

        Routes.resolveLink({path: '/password-reset', query: data});
    }

    public openProjectSendModal(sellerUuid: Uuid): void {
        this.$refs.projectSendModal.show(sellerUuid);
    }

    public openReportCompanyModal(reportedCompanyUuid: Uuid): void {
        this.$refs.reportCompanyModal.show(reportedCompanyUuid);
    }

    /**
     * Open the offer create modal either by supplying a projectUuid OR a collaborationUuid.
     */
    public openOfferCreateModal(projectUuid: Uuid, collaborationUuid: Uuid, productUuid: Uuid = null, serviceUuid: Uuid = null): void {
        this.$refs.offerCreateModal.show(projectUuid, collaborationUuid, productUuid, serviceUuid);
    }

    /**
     * Open the product inquiry modal by supplying a PublicProductDTO.
     */
    public openProductInquiryModal(productDto: PublicProductDTO): void {
        this.$refs.productInquiryModal.show(productDto);
    }

    /**
     * Open the service inquiry modal by supplying a PublicCatalogueServiceDTO.
     */
    public openCatalogueInquiryModal(item: PublicCatalogueServiceDTO | PublicCatalogueFabricDTO): void {
        this.$refs.catalogueInquiryModal.show(item);
    }

    /**
     * Open the sample modal either by supplying a projectUuid OR a collaborationUuid.
     */
    public openSampleOrderModal(collaborationUuid: Uuid, sampleOrderUuid: Uuid = null, systemMessage: string = null, buttonOption: number = null): void {
        this.$refs.purchaseOrderModal.show(collaborationUuid, 'sample_order', sampleOrderUuid, systemMessage, buttonOption);
    }

    /**
     * Open the purchase order modal list for a collaborationUuid.
     */
    public openPurchaseOrderListModal(collaborationUuid: Uuid): void {
        this.$refs.purchaseOrderModal.showList(collaborationUuid);
    }

    /**
     * Open purchase order modal on the collaboration list page
     */
    public openPurchaseOrderCollaborationListModal(companyUuid: Uuid): void {
        this.$refs.purchaseOrderModal.showCollaborationList(companyUuid);
    }

    /**
     * Open the purchase order modal either by supplying a projectUuid OR a collaborationUuid.
     */
    public openPurchaseOrderModal(collaborationUuid: Uuid, purchaseOrderUuid: Uuid = null, systemMessage: string = null, buttonOption: number = null): void {
        this.$refs.purchaseOrderModal.show(collaborationUuid, 'purchase_order', purchaseOrderUuid, systemMessage, buttonOption);
    }

    /**
     * Open the review modal by supplying a purchase order.
     */
    public openReviewModal(purchaseOrder, review = null): void {
        this.$refs.reviewModal.show(purchaseOrder, review);
    }

    /**
     * Open the label-modal.
     */
    public openLabelModal(thread: ThreadDTO): Bluebird<ThreadDTO> {
        return this.$refs.labelModal.show(thread);
    }

    /**
     * Open the location-modal by supplying a list of locations.
     * NOTE: The given locations will be altered in case a new address is added!
     */
    public openLocationModal(companyUuid: Uuid, selectedUuid: Uuid = null): Bluebird<LocationDTO> {
        return this.$refs.locationModal.show(companyUuid, selectedUuid);
    }

    /**
     * Open the crop modal.
     */
    // public openCropModal(fileList:File[], aspectRatio:number = null):Bluebird<File[]> {
    public openCropModal(fileList: File[], settings: CropSettings = null): Bluebird<File[]> {
        return this.$refs.cropModal.show(fileList, settings);
    }

    /**
     * Open the gallery modal.
     */
    public openGalleryModal(fileList: FileDTO[], currentIndex: number = 0): boolean {
        return this.$refs.galleryModal.show(fileList, currentIndex);
    }

    /**
     * Open VAT validation modal.
     */
    public openVatValidationModal(): void {
        return this.$refs.vatValidationModal.show();
    }

    /**
     * Open Manufy Value list modal.
     */
    public openManufyValueListModal(values: Array<string>): void {
        return this.$refs.manufyValueListModal.show(values);
    }

    /**
     * Open Category list modal.
     */
    public openCategoryListModal(categories: Array<string>): void {
        return this.$refs.categoryListModal.show(categories);
    }

    /**
     * Open the KYC-modal
     */
    public openKYCModal(): void {
        this.$refs.kycModal.show();
    }

    /**
     * Open Project review modal.
     */
    public openProjectReviewModal(project: SellerProjectDTO, review = null): void {
        return this.$refs.projectReviewModal.show(project, review);
    }

    /**
     * Open Company availability status modal.
     */
    public openAvailabilityStatusModal(availabilityStatuses: FormSelectItem<string>[]): void {
        return this.$refs.availabilityStatusModal.show(availabilityStatuses);
    }

}
