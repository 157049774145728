import {Component} from 'vue-facing-decorator';
import {FileMimes} from '@/app/constants/FileMimes';
import {FileDTO} from '@/app/dto/FileDTO';
import {Modal} from '@/app/views/modals/Modal';

@Component({})
export default class GalleryModal extends Modal {

    declare public $refs: {
        thumbsList: HTMLDivElement;
    };

    public readonly flags = {
        ...super.flags,
        hasScrollbar: false,
    };

    public fileList: FileDTO[] = null;
    public index: number = null;

    public get current(): FileDTO {
        return this.fileList[this.index];
    }

    public created(): void {
    }

    public mounted(): void {
        // Listen for window resize:
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    public show(fileList: FileDTO[], selectedIndex: number = 0): boolean {
        // Filer out all non-image files:
        const selectedFile: FileDTO = fileList[selectedIndex];
        this.fileList = fileList.filter((file: FileDTO) => FileMimes.IMAGES.includes(file.mimeType));

        if (this.fileList.length == 0) {  // Nothing to show
            return false;
        }

        // Find the proper selected index:
        if (selectedFile) {
            selectedIndex = this.fileList.indexOf(selectedFile);
        }
        this.index = (selectedIndex >= 0) ? selectedIndex : 0;

        this.flags.show = true;
        this.resizeHandler();

        return true;
    }

    public hide(force: boolean = false): boolean {
        this.fileList = null;
        return super.hide(force);
    }

    public goto(index: number, loop: boolean = false): void {
        if (index == this.index) {
            return null;
        }

        // Get the direction based on the difference between the current and new indexes
        let direction = this.index - index <= -1 ? 1 : -1;

        // Whether pressing next/ prev would loop to the first/ last image
        if (loop) {
            // When the user is on the first image and clicks prev
            if (index < 0) {
                this.index = this.fileList.length - 1;
                direction = 1; // This way the spacing after the thumb would be added propperly
            }
            // When the user is on the last image and clicks next
            else if (index >= this.fileList.length) {
                this.index = 0;
                direction = -1; // This way the spacing before the thumb would be added propperly
            } else {
                this.index = index;
            }
        } else {
            this.index = Math.min(Math.max(index, 0), this.fileList.length - 1);
        }

        this.keepThumbsInView(direction);
    }

    private keepThumbsInView(direction: number = 1): void {
        if (direction > 1 || direction < -1) return;

        this.$nextTick(() => {
            let currentThumb: HTMLDivElement = this.$refs.thumbsList.querySelector('.current');
            if (!this.isInViewport(currentThumb)) {
                currentThumb.scrollIntoView();

                // Include space before/ after the thumb so they do not stick to the edges of the viewport
                this.$refs.thumbsList.scrollLeft += direction * 16;
            }
        });
    }

    private isInViewport(element: HTMLDivElement): boolean {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    private resizeHandler(): void {
        if (!this.flags.show) {
            return;
        }

        let siteHolder: HTMLDivElement = document.querySelector('.site-holder');
        this.flags.hasScrollbar = (siteHolder.clientHeight > window.innerHeight);
    }
}
