import Bluebird from 'bluebird';
import {Main} from '@/app/Main';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';

export class DownloadHelper {

    /**
     * Download the content form the given url as a file.
     * @param url The url where the file should be downloaded.
     * @param fileName (optional) If set, this name will be used. If left empty it is assumed that the server has a response header `content-file-name` (make sure the header is set AND exposed!)
     */
    public static downloadUrl(url: string, fileName: string = null): void {
        let promise: Bluebird<any> = Main.callApi(url, RequestMethods.GET);
        promise = promise.then((response: Response) => {
            fileName = fileName ?? response.headers.get('content-file-name');
            response.blob().then((blob: Blob) => {
                url = window.URL.createObjectURL(blob);

                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            });
        });
    }

}
