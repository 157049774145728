<script lang="ts" src="./FormFileUploadWrapper.ts"></script>

<template>
  <div class="m-input-group">
    <m-form-label
      v-if="label"
      :id="id"
      :required="required"
      :tooltipLabel="tooltipLabel"
      :tooltipLink="tooltipLink"
    >
      {{ label }}
    </m-form-label>

    <m-form-file-upload
      :id="id"
      :accept="accept"
      :aspectRatio="aspectRatio"
      :disabled="disabled"
      :dusk="dusk"
      :errors="errors"
      :modelValue="modelValue"
      :multiple="multiple"
      :placeholder="placeholder"
      :required="required"
      :size="size"
      @update:modelValue="updateValue"
    />

    <p v-if="supportText" class="support-text">
      {{ supportText }}
    </p>
  </div>
</template>


<style lang="scss" scoped>
</style>
