import {Component, Prop, Vue} from 'vue-facing-decorator';
import {PublicProductDTO} from "@/app/dto/PublicProductDTO";

@Component({})
export default class ProductCard extends Vue {

    @Prop({type: Boolean, default: true})
    public readonly hasShadow: boolean;

    @Prop({type: PublicProductDTO, default: null})
    public readonly product: PublicProductDTO;

    public created(): void {
    }

    public mounted(): void {
    }

}

