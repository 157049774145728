<script lang="ts" src="./ReportCompanyModal.ts"/>

<template>
  <div v-if="flags.show" class="modal top viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-medium secondary">

      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.reportCompanyModal.title') }}
        </h2>

        <m-button
          :appearance="$const.StyleAppearance.DISABLED"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.TEXT"
          @click.prevent="hide()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </m-button>
      </div>

      <div ref="dialogBody" class="dialog-body">
        <p class="mb-3 text-neutral-80">{{ $trans.t('app.reportCompanyModal.body') }}</p>
        <m-form-field-text-area v-model="formData.model.message" :errors="formData.errors.message"></m-form-field-text-area>
      </div>

      <div class="dialog-footer">
        <div class="action-buttons justify-end">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.GHOST"
            @click="hide()"
          >
            {{ $trans.t('app.labels.cancel') }}
          </m-button>

          <m-button
            :appearance="$const.StyleAppearance.SECONDARY"
            :disabled="flags.loading"
            :icon-before="{ name: 'paper-plane', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
            :size="$const.StyleSize.SMALL"
            @click="submit()"
          >
            {{ $trans.t('app.reportCompanyModal.btnLabel') }}
          </m-button>
        </div>
      </div>

    </div>
  </div>

</template>

<style lang="scss" scoped>

.modal-dialog {
  .dialog-header {
    @apply pb-2;
  }

  .dialog-footer {
    @apply p-6 flex flex-row flex-wrap items-center space-x-3 rounded-b-xl border-t sm:border-0 sm:bg-transparent;

    .action-buttons {
      @apply w-full flex flex-1 flex-row items-center space-x-3 rounded-b-xl;
    }
  }
}
</style>
