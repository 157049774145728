<script lang="ts" src="./FormLocationPicker.ts"></script>


<template>

  <div>
    <template v-if="locationDto">
      <div class="h-full flex flex-row items-start mt-3 p-3 border border-green-75 rounded-xl xs:p-4" @click.prevent="showLocations()">
        <div class="m-circle secondary xs:large">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="building"/>
        </div>
        <div class="flex flex-1 flex-col ml-4">
          <h6 class="mb-1 text-tiny">{{ locationDto.name }}</h6>

          <p class="text-tiny">{{ locationDto.address1 }}</p>
          <p v-if="locationDto.address2" class="text-tiny">{{ locationDto.address2 }}</p>

          <p class="text-tiny">{{ locationDto.zipcode }}, {{ locationDto.city }}</p>
          <p v-if="locationDto.country" class="text-tiny">{{ $trans.t('meta.countries.' + locationDto.country) }}</p>
        </div>
        <div class="self-center pl-4 text-green-400 xs:pl-6">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="check"/>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="mt-2 text-neutral-80">
        <em class="text-italic">{{ $trans.t('components.locationPicker.noSelection') }}</em>
      </p>
    </template>

    <m-button
      :appearance="$const.StyleAppearance.PRIMARY"
      :size="$const.StyleSize.SMALL"
      :variant="$const.StyleVariants.OUTLINE"
      class="mt-4"
      @click.prevent="showLocations()"
    >
      {{ $trans.t((locationDto) ? 'components.locationPicker.selectOtherBtnLabel' : 'components.locationPicker.selectBtnLabel') }}
    </m-button>

    <m-form-error :errors="errors"/>
  </div>

</template>


<style lang="scss" scoped>

</style>
