import {Component, Prop} from 'vue-facing-decorator';
import {StyleSize} from '@/app/constants/Constants';
import {FormItem} from '@/app/views/components/form/FormItem';

@Component({})
export default class FormFieldStepper extends FormItem<number> {

    @Prop({type: Number, default: null})
    declare public modelValue: number;

    @Prop({type: Number, default: null})
    public readonly min: number = null;

    @Prop({type: Number, default: null})
    public readonly max: number = null;

    @Prop({type: Number, default: null})
    public readonly step: number = null;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: StyleSize;

    public stepDown(): void {
        this.updateValue(this.value - this.step);
    }

    public stepUp(): void {
        this.updateValue(this.value + this.step);
    }

    public handleInput(event: Event): void {
        const target: HTMLInputElement = (event.target as HTMLInputElement);
        if (!target.validity.badInput) {
            this.updateValue(target.valueAsNumber);
        }
    }

    public updateValue(value: number): void {
        if (isNaN(value)) {
            value = null;
        } else {
            // UX-wise clipping won't work for min!
            if (this.max != null && value > this.max) {
                value = this.max;
            }
            if (this.step) {
                // TODO: Clip value to actual step
                // For now just use the simple clipping of decimal places:
                value = Number(value.toFixed(this.step.decimalPlaces()));
            }
        }
        super.updateValue(value);
    }

}
