/**
 * Style: Shapes.
 */
export const enum StyleShapes {

    DEFAULT = '',
    SQUARE = 'square',
    CIRCLE = 'circle',

}
