<script lang="ts" src="./LocationModal.ts"></script>


<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-small">
      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.locationModal.title') }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <div class="dialog-body">
        <div>
          <ul>
            <template v-for="location in locations">
              <!-- Location Modal -->
              <li
                :class="['p-3 mt-3 border rounded-xl text-center cursor-pointer transition-colors hover:border-green-75 xs:flex xs:flex-row xs:items-start xs:p-4 xs:text-left ', { 'border-green-75': selectedLocationUuid == location.uuid }]"
                @click="setLocation(location.uuid)">

                <div :class="['m-circle mx-auto transition-colors xs:mx-0 xs:large', { 'secondary': selectedLocationUuid == location.uuid }]">
                  <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="building"/>
                </div>

                <div class="flex flex-1 flex-col mt-3 xs:ml-4 xs:mt-0">
                  <h6 class="mb-1 text-tiny">{{ location.name }}</h6>

                  <input v-model="selectedLocationUuid" :value="location.uuid" class="hidden" name="deliveryLocation" type="radio">

                  <p class="text-tiny">{{ location.address1 }}</p>
                  <p v-if="location.address2" class="text-tiny">{{ location.address2 }}</p>

                  <p class="text-tiny">{{ location.zipcode }}, {{ location.city }}</p>
                  <p class="text-tiny">{{ $trans.t('meta.countries.' + location.country) }}</p>
                </div>

                <div v-if="selectedLocationUuid == location.uuid" class="self-center pt-2 pl-0 text-green-400 xs:pt-0 xs:pl-6">
                  <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="check"/>
                </div>
              </li>
            </template>

            <li v-if="flags.showNewAddressForm" :class="['mt-5 opacity-0 transition-all', { 'opacity-100': flags.showNewAddressForm }]">
              <h6>{{ $trans.t('app.locationModal.addNewAddress.title') }}</h6>
              <div class="m-input-group mt-3">
                <label class="label">{{ $trans.t('app.locationModal.addNewAddress.name') }}<span class="text-red-400">*</span></label>
                <m-form-field v-model="formData.model.name" :errors="formData.errors.name" type="text"/>
              </div>
              <div class="m-input-group mt-3">
                <label class="label">{{ $trans.t('app.locationModal.addNewAddress.address1') }}<span class="text-red-400">*</span></label>
                <m-form-field v-model="formData.model.address1" :errors="formData.errors.address1" type="text"/>
              </div>
              <div class="m-input-group mt-3">
                <label class="label">{{ $trans.t('app.locationModal.addNewAddress.address2') }}</label>
                <m-form-field v-model="formData.model.address2" :errors="formData.errors.address2" type="text"/>
              </div>
              <div class="m-input-group mt-3">
                <label class="label">{{ $trans.t('app.locationModal.addNewAddress.zipCode') }}<span class="text-red-400">*</span></label>
                <m-form-field v-model="formData.model.zipcode" :errors="formData.errors.zipcode" type="text"/>
              </div>
              <div class="m-input-group mt-3">
                <label class="label">{{ $trans.t('app.locationModal.addNewAddress.city') }}<span class="text-red-400">*</span></label>
                <m-form-field v-model="formData.model.city" :errors="formData.errors.city" type="text"/>
              </div>
              <div class="m-input-group mt-3">
                <label class="label">{{ $trans.t('app.locationModal.addNewAddress.region') }}</label>
                <m-form-field v-model="formData.model.region" :errors="formData.errors.region" type="text"/>
              </div>
              <div class="m-input-group mt-3">
                <label class="label">{{ $trans.t('app.locationModal.addNewAddress.country') }}<span class="text-red-400">*</span></label>
                <m-form-select-picker v-model="formData.model.country" :errors="formData.errors.country" :items="$store.meta.countries"/>
              </div>
            </li>
          </ul>

          <div class="mt-3">
            <m-button
              v-if="!flags.showNewAddressForm"
              :appearance="$const.StyleAppearance.PRIMARY"
              :icon-before="{ name: 'plus', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
              :size="$const.StyleSize.SMALL"
              :variant="$const.StyleVariants.GHOST"
              @click.prevent="showNewAddressForm()"
            >
              {{ $trans.t('app.locationModal.addNewAddress.addBtn') }}
            </m-button>
          </div>
        </div>
      </div>

      <div class="dialog-footer justify-end">
        <div class="item">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.GHOST"
            @click="hide()"
          >
            {{ $trans.t('app.labels.cancel') }}
          </m-button>
        </div>

        <div class="item">
          <m-button
            v-if="!flags.showNewAddressForm"
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            @click="close()"
          >
            {{ $trans.t('app.locationModal.selectLocationBtn') }}
          </m-button>

          <m-button
            v-else
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            @click="submit()"
          >
            {{ $trans.t('app.locationModal.addAddressBtn') }}
          </m-button>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.dialog-footer {
  @apply flex flex-wrap -mb-3 -ml-3 justify-end rounded-b-xl;

  .item {
    @apply pb-3 pl-3;
  }
}

</style>
