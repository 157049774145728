import {CastableProperties} from '@/app/utils/Castable';
import {RfqProductDTO} from "@/app/dto/RfqProductDTO";
import {BuyerProductOfferDTO} from "@/app/dto/BuyerProductOfferDTO";

export class BuyerRfqProductDTO extends RfqProductDTO {

    public offers: BuyerProductOfferDTO[] = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            offers: [BuyerProductOfferDTO],
        };
    }

}
