import {Component, Prop, Vue} from 'vue-facing-decorator';

@Component({
    emits: [
        'selectPage',
    ],
})
export default class AuthCard extends Vue {

    @Prop({type: String, default: null})
    public imageName: string;

    @Prop({type: String, default: null})
    public question: string;

    @Prop({type: String, default: null})
    public cta: string;

}
