<script lang="ts" src="./AuthCard.ts"></script>

<template>
  <div class="modal-dialog w-medium secondary border mt-4">
    <div
      class="m-image-card xs:horizontal secondary relative border rounded-xl p-4 pb-0 shadow-sm bg-neutral-0 hover:shadow-lg transition-all xs:border-0 xs:p-0"
      @click="$emit('selectPage');"
    >
      <div class="image-card__image-wrapper xs:flex-none xs:w-24 xs:min-h-24">
        <div class="image-gallery">
          <figure class="image-gallery__figure p-4 xs:p-0">
            <img :src="`${$settings.appPath}/images/website/${imageName}`" alt="Register illustration" class="h-40 xs:max-h-20">
          </figure>
        </div>
      </div>
      <div class="image-card__body xs:flex-1 xs:border xs:rounded-r-xl xs:px-4 xs:py-2">
        <div class="hidden xs:flex xs:flex-1 xs:pl-2 xs:pr-6">
          <div class="title-wrapper">
            <h2 class="title">{{ question }}</h2>
          </div>
        </div>

        <div>
          <div class="hidden xs:flex">
            <div class="image-card__footer">
              <m-button
                :appearance="$const.StyleAppearance.PRIMARY"
                :icon-after="{ name: 'chevron-right', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                :size="$const.StyleSize.XSMALL"
                :variant="$const.StyleVariants.TEXT"
                class="m-stretched-link"
              >
                {{ cta }}
              </m-button>
            </div>
          </div>

          <div class="flex-1 xs:hidden">
            <div class="image-card__footer">
              <m-button
                :appearance="$const.StyleAppearance.PRIMARY"
                :size="$const.StyleSize.MEDIUM"
                class="m-stretched-link mt-1 xs:mt-3"
              >
                {{ cta }}
              </m-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>

</style>
