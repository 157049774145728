<script lang="ts" src="./App.ts"></script>


<template>

  <router-view></router-view>

  <PurchaseOrderModalVue ref="purchaseOrderModal"/>
  <ReviewModalVue ref="reviewModal"/>
  <LabelModalVue ref="labelModal"/>
  <LocationModalVue ref="locationModal"/>
  <OfferCreateModalVue ref="offerCreateModal"/>
  <ProductInquiryModalVue ref="productInquiryModal"/>
  <CatalogueInquiryModalVue ref="catalogueInquiryModal"/>
  <VatValidationModalVue ref="vatValidationModal"/>
  <!-- <AuthModalVue ref="authModal" /> -->
  <KYCModalVue ref="kycModal"/>
  <ProjectSendModalVue ref="projectSendModal"/>
  <ReportCompanyModalVue ref="reportCompanyModal"/>
  <CropModalVue ref="cropModal"/>
  <GalleryModalVue ref="galleryModal"/>
  <ManufyValueListModalVue ref="manufyValueListModal"/>
  <CategoryListModalVue ref="categoryListModal"/>
  <ProjectReviewModalVue ref="projectReviewModal"/>
  <AvailabilityStatusModalVue ref="availabilityStatusModal"/>
  <AlertVue ref="alert"/>
  <ToastVue ref="toast"/>

</template>


<style lang="scss" scoped>
</style>
