<script lang="ts" src="./Pagination.ts"></script>


<template>

  <div v-if="paginator" class="pagination">
    <div :class="'flex flex-row ' + ((showItemsPerPage && perPageOptions.length) ? 'justify-between' : '')">
      <div v-if="showItemsPerPage && perPageOptions.length" class="flex flex-row">
        <span class="label my-auto pr-1">{{ $trans.t('components.pagination.itemsPerPage', {count: paginator.perPage}) }}</span>
        <m-form-select-picker v-model="paginator.perPage" :dropdownMaxCols="1" :items="perPageOptions" :size="$const.StyleSize.XSMALL"/>
      </div>

      <div class="flex flex-row">
        <m-button
          v-if="paginator.index > 1"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.OUTLINE"
          @click="paginator.first()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="angles-left"/>
        </m-button>

        <m-button
          v-if="paginator.index > 1"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.OUTLINE"
          class="mx-2"
          @click="paginator.prev()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="angle-left"/>
        </m-button>

        <div class="my-auto">
          {{ label }}
        </div>

        <m-button
          v-if="paginator.index < paginator.lastPage"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.OUTLINE"
          class="mx-2"
          @click="paginator.next()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="angle-right"/>
        </m-button>

        <m-button
          v-if="paginator.index < paginator.lastPage"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.OUTLINE"
          @click="paginator.last()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="angles-right"/>
        </m-button>
      </div>

    </div>
  </div>

</template>


<style lang="scss" scoped>

.pagination {
  // Make sure the text isn't selected on multiple clicks:
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

</style>
