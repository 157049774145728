import {Component, Prop, Vue} from 'vue-facing-decorator';
import {PublicCatalogueServiceDTO} from '@/app/dto/PublicCatalogueServiceDTO';

@Component({})
export default class ProductCard extends Vue {

    @Prop({type: Boolean, default: true})
    public readonly hasShadow: boolean;

    @Prop({type: Object, default: null})
    public readonly service: PublicCatalogueServiceDTO;

    public created(): void {
    }

    public mounted(): void {
    }

}

