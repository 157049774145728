import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Settings} from '@/app/Settings';
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';

type KYCFormModel = {
    cocNumber: string,
    companyLegalName: string,
    agreeOppTerms: boolean,
};

@Component({})
export default class KYCModal extends Modal {

    public readonly flags = {
        ...super.flags,
    };

    public formData: FormData<KYCFormModel> = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(): void {
        const formRules: FormRulesById = {};
        formRules.cocNumber = [FormRules.required(), FormRules.maxLength(45)];
        formRules.companyLegalName = [FormRules.required(), FormRules.maxLength(45)];
        formRules.agreeOppTerms = [FormRules.required(Main.trans.t(Lang.t.app.order.errors.oppTerms, {varNameOpp: Settings.nameOpp}))];

        const formData: KYCFormModel = {
            cocNumber: null,
            companyLegalName: Store.company.name,
            agreeOppTerms: null,
        };

        this.formData = new FormData(formData, formRules);

        const payload: Record<string, any> = {};
        payload.uuid = Store.user.currentCompanyUuid;

        let promise: Bluebird<any> = Main.callApi('company/opp', RequestMethods.GET, payload);
        promise = promise.then(this.handleCheckResult.bind(this));
        promise = promise.catch(this.handleError.bind(this));
    }

    public hide(force: boolean = false): boolean {
        const success: boolean = super.hide(force);
        Routes.addQueryArguments({startVerification: undefined}, true);
        return success;
    }

    public submit(): void {
        if (!this.formData.validate()) {
            return;
        }

        const payload: Record<string, any> = {};
        payload.uuid = Store.user.currentCompanyUuid;
        payload.cocNumber = this.formData.model.cocNumber;
        payload.companyLegalName = this.formData.model.companyLegalName;
        payload.oppTermsAccepted = this.formData.model.agreeOppTerms;

        let promise: Bluebird<any> = Main.callApi('company/opp', RequestMethods.POST, payload);
        promise = promise.then(this.handleSubmitResult.bind(this));
        promise = promise.catch(this.handleError.bind(this));
        promise = promise.finally(() => {
            this.hide()
        });
    }

    private handleCheckResult(result): void {
        if (result.canStartVerification) {
            this.flags.show = true;

            this.formData.model.companyLegalName = Store.company.name;
        } else if (result.oppOverviewUrl) {
            window.open(result.oppOverviewUrl, '_self');
        } else {
            this.hide();
        }
    }

    private handleSubmitResult(result: any): void {
        window.open(result.oppOverviewUrl, '_self');
    }

    private handleError(): void {
        Main.app.toast.danger(
            Main.trans.t(Lang.t.app.toast.danger.generalError.message),
            Main.trans.t(Lang.t.app.toast.danger.generalError.title)
        );
    }
}
