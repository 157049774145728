import {Settings} from '@/app/Settings';

export class StorageHelper {

    public static setSessionVar<T>(key: string, value: T): void {
        if (value) {
            window.sessionStorage.setItem(Settings.clientStoragePrefix + key, JSON.stringify(value));
        } else {
            window.sessionStorage.removeItem(Settings.clientStoragePrefix + key);
        }
    }

    public static getSessionVar<T>(key: string, defaultValue: T = null): any {
        let value: string = window.sessionStorage.getItem(Settings.clientStoragePrefix + key);
        let response: unknown;
        try {   // Try to parse as json:
            response = JSON.parse(value);
        } catch (err: unknown) {
            response = value;
        }

        return <T>response ?? defaultValue;
    }

    public static setLocalVar<T>(key: string, value: T): void {
        if (value) {
            window.localStorage.setItem(Settings.clientStoragePrefix + key, JSON.stringify(value));
        } else {
            window.localStorage.removeItem(Settings.clientStoragePrefix + key);
        }
    }

    public static getLocalVar<T>(key: string, defaultValue: T = null): T {
        let value: string = window.localStorage.getItem(Settings.clientStoragePrefix + key);
        let response: unknown;
        try {   // Try to parse as json:
            response = JSON.parse(value);
        } catch (err: unknown) {
            response = value;
        }

        return <T>response ?? defaultValue;
    }

}
