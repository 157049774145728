import Bluebird from 'bluebird';
import {Component, Prop, Vue} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Roles} from '@/app/constants/Roles';
import {CollaborationDTO} from "@/app/dto/CollaborationDTO";
import {PublicProductDTO} from "@/app/dto/PublicProductDTO";
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import CatalogueProductPricingLinesTableVue from "@/app/views/components/tables/CatalogueProductPricingTable.vue";

type ProductFormModel = {
    targetPrice?: number,
    targetQuantity?: number,
    message?: string,
};

enum States {
    GUEST = 'guest',
    SELLER = 'seller',
    UNSUBSCRIBED = 'unsubscribed',
    SUBSCRIBED = 'subscribed',
}

@Component({
    components: {
        'm-pricing-lines-table': CatalogueProductPricingLinesTableVue,
    },
})
export default class ProductInquiry extends Vue {

    public readonly flags = {
        loading: false,
    };

    public readonly States = States;

    @Prop({type: Object, required: true})
    public readonly productDto: PublicProductDTO;

    @Prop({type: Boolean, default: false})
    public readonly isInModal: boolean;

    public productFormData: FormData<ProductFormModel> = null;

    public get state(): States {
        if (Store.user.isBuyer && Store.user.hasRoles(Roles.BUYER_TIER_0)) {
            return States.UNSUBSCRIBED;
        } else if (Store.user.isSeller) {
            return States.SELLER;
        } else if (Store.user.isBuyer && Store.user.hasRoles(Roles.BUYER_TIER_1)) {
            return States.SUBSCRIBED;
        } else {
            return States.GUEST;
        }
    }

    public get unsubscribedDisclaimer(): string {
        switch (this.state) {
            case States.GUEST:
                return Main.trans.t('pages.productIndex.inquiry.register');
            case States.SELLER:
                return Main.trans.t('pages.productIndex.inquiry.registerAsBuyer');
            case States.UNSUBSCRIBED:
                return Main.trans.t('pages.productIndex.inquiry.noSubscription');
        }
    }

    public get totalPrice(): number {
        if (this.productFormData.model.targetPrice && this.productFormData.model.targetQuantity) {
            return this.productFormData.model.targetPrice * this.productFormData.model.targetQuantity;
        }

        return 0;
    }

    public get calculatedPricePerUnit(): number {
        let pricePerUnit: number = 0;
        this.productDto.pricingLines?.forEach((p) => {
            pricePerUnit = this.productFormData.model.targetQuantity >= p.moq ? p.price : pricePerUnit;
        });

        this.productFormData.model.targetPrice = pricePerUnit;

        return pricePerUnit;
    }

    public handleInquiryButtonClick(): void {
        if (this.state === States.SUBSCRIBED) {
            return Main.app.openProductInquiryModal(this.productDto);
        } else if (this.state === States.UNSUBSCRIBED) {
            Routes.resolveLink({name: 'buyerSubscriptionList'});
        } else if (this.state === States.GUEST) {
            return Main.app.openRegisterModal();
        } else if (this.state === States.SELLER) {
            Store.user.logout();
            return this.register();
        }
    }

    public created(): void {
        const productFormRules: FormRulesById = {};
        productFormRules.targetQuantity = [FormRules.required(), FormRules.min(this.productDto.moq), FormRules.max(1000000)];
        productFormRules.targetPrice = [FormRules.required(), FormRules.min(0.01)];

        this.productFormData = new FormData({}, productFormRules);
    }

    public register(): void {
        Main.app.openRegisterModal();
    }

    public startOrder(): void {
        if (!this.productFormData.model.targetPrice) {
            this.productFormData.model.targetPrice = this.calculatedPricePerUnit;
        }

        const formIsValid: boolean = this.productFormData.validate();

        if (!formIsValid) {
            return;
        }

        const payload: Record<string, any> = {};
        payload.uuid = this.productDto.uuid;
        payload.targetQuantity = this.productFormData.model.targetQuantity;
        payload.targetPrice = this.productFormData.model.targetPrice;

        let promise: Bluebird<any> = Main.callApi('buyer/inquiry', RequestMethods.POST, payload, CollaborationDTO);
        promise = promise.then(this.handleSubmitResult.bind(this));
        promise = promise.catch(this.handleSubmitError.bind(this));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    private handleSubmitResult(result: CollaborationDTO): void {
        Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.offerPlaced.message), Main.trans.t(Lang.t.app.toast.success.offerPlaced.title));

        Routes.resolveLink({name: 'chat', query: {threadUuid: result.threadUuid}});

        this.$emit('success');
    }

    private handleSubmitError(result: any): void {
        Routes.resolveLink({name: 'subscriptionRead'}, false, true);
    }

}
