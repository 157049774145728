<script lang="ts" src="./FormFieldRadioTab.ts"></script>

<template>
  <label
    :class="[
      'form-field-group',
      {
        disabled: disabled,
      },
    ]"
    :for="id"
  >

    <div
      :class="[
        'radio-tab-container',
        {
          checked: modelValue === val,
          disabled: disabled,
        }
      ]"
      @click="updateValue($refs.radio.value)"
    >
      <div
        :class="[
          'inline-flex',
          {
            checked: modelValue === val,
          },
        ]"
      >
        <input
          :id="id"
          ref="radio"
          :checked="modelValue === val"
          :disabled="disabled"
          :name="group"
          :value="val"
          class="hidden"
          type="radio"
          @input="updateValue()"
        />

        <div v-if="icon" class="icon-wrapper">
          <m-icon :name="icon.name" :solid="icon.solid" :type="icon.type" class="icon"/>
        </div>
      </div>
      <span class="label">
        <slot></slot>
      </span>
    </div>
  </label>
</template>


<style lang="scss" scoped src="./FormFieldRadio.scss"></style>
<style lang="scss" scoped src="./FormFieldRadioTab.scss"></style>
<style lang="scss" scoped>
</style>
