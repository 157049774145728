import {RouteLocationRaw} from "vue-router";
import {ComponentBase, Prop, Vue} from 'vue-facing-decorator';
import {StyleSize} from '@/app/constants/Constants';
import {IIcon} from '@/app/views/components/misc/Icon';

@ComponentBase({
    emits: [
        'update:modelValue',
    ],
})
export abstract class FormElementWrapper<T> extends Vue {

    @Prop({type: String, default: null})
    public modelValue: T;

    @Prop({type: Array, default: null})
    public readonly errors: string[];

    @Prop({type: Boolean, default: false})
    public readonly disabled: boolean;

    @Prop({type: Boolean, default: null})
    public readonly required: boolean;

    @Prop({type: String, default: null})
    public readonly dusk: string;

    @Prop({type: String, default: null})
    public readonly id: string;

    @Prop({type: String, default: null})
    public readonly label: string;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: String, default: null})
    public readonly supportText: string;

    @Prop({type: String, default: null})
    public readonly tooltipLabel: string;

    @Prop({type: [String, Object], default: null})
    public readonly tooltipLink: RouteLocationRaw;

    @Prop({type: Object, default: null})
    public readonly iconAfter: IIcon;

    @Prop({type: Object, default: null})
    public readonly iconBefore: IIcon;

    // Style properties
    @Prop({type: String, default: StyleSize.MEDIUM})
    declare public size: StyleSize;

    public value: T = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public updateValue(value: T): void {
        if (this.value === value) {
            // Set value to `undefined` first to trigger responsiveness
            this.value = undefined;
        }

        const oldValue: T = this.value;
        this.value = value;
        this.$emit('update:modelValue', value, oldValue);
    }

}
