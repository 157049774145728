<script lang="ts" src="./ProjectSendModal.ts"/>

<template>
  <div v-if="flags.show" class="modal top viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-medium secondary">

      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.projectSendModal.title') }}
        </h2>

        <m-button
          :appearance="$const.StyleAppearance.DISABLED"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.SMALL"
          :variant="$const.StyleVariants.TEXT"
          @click.prevent="hide()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </m-button>
      </div>

      <div ref="dialogBody" class="dialog-body">
        <p class="mb-3 text-neutral-80">{{ $trans.t('app.projectSendModal.subtitle') }}</p>

        <div class="flex flex-col items-stretch space-y-3 xs:flex-row-reverse xs:justify-between xs:items-center xs:space-y-0">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :icon-before="{ name: 'circle-plus', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
            :size="$const.StyleSize.SMALL"
            :to="{ name: 'rfqWizard', query: { suuid: formData.model.sellerUuid } }"
            @click.prevent="hide()"
          >
            {{ $trans.t('app.labels.newProject') }}
          </m-button>

          <m-form-field-checkbox
            v-model="selectAllToggle"
            :disabled="projects?.length == 0"
            class="ml-4"
            @click.prevent="(projects?.length > 0) ? toggleAllProjects() : null"
          >
            <span class="lg:ml-1">
              <template v-if="formData?.model?.projectUuids.length == 1">
                {{ formData.model.projectUuids.length }} {{ $trans.t('app.projectSendModal.selectedProject') }}
              </template>
              <template v-else-if="formData?.model?.projectUuids.length > 1">
                {{ $trans.t('app.projectSendModal.selectedProjects', {number: formData.model.projectUuids.length}) }}
              </template>
              <template v-else>
                {{ $trans.t('components.selectPicker.selectAll') }}
              </template>
            </span>
          </m-form-field-checkbox>
        </div>

        <ul v-if="projects?.length" class="project-list">
          <li v-for="(project, index) in projects" :class="['project', {  selected: selectedProjects[index] }]">
            <m-form-field-checkbox
              v-model="selectedProjects[index]"
              @click.prevent="toggleProject(project.uuid, index)"
            >
              <div class="flex flex-row items-center space-x-3 lg:ml-1 lg:space-x-4">
                <m-button
                  :appearance="$const.StyleAppearance.PRIMARY"
                  :is-light="true"
                  :shape="$const.StyleShapes.SQUARE"
                  :size="$const.StyleSize.SMALL"
                  :variant="$const.StyleVariants.OUTLINE"
                >
                  <m-icon :name="project.products[0].category" :solid="true" :type="$const.IconTypes.MANUFY"/>
                </m-button>

                <strong class="flex-1 line-clamp-1">{{ project.name }}</strong>
                <span class="font-normal text-neutral-400">{{ $trans.t('app.projectSendModal.productsInProject', ({number: project.products.length})) }}</span>
              </div>
            </m-form-field-checkbox>
          </li>
        </ul>
        <div v-else class="p-4 mt-4 flex justify-center relative rounded-xl text-center bg-blue-0 text-neutral-80">{{ $trans.t('app.labels.noResults') }}</div>

        <transition mode="out-in" name="fade">
          <m-section-message
            v-if="formData?.model?.projectUuids.length > 1"
            :appearance="$const.StyleAppearance.WARNING"
            :message="$trans.t('app.projectSendModal.multipleProjectsWarning.msg')"
            :title="$trans.t('app.projectSendModal.multipleProjectsWarning.title')"
          />
        </transition>
      </div>

      <div class="dialog-footer">
        <div class="action-buttons justify-end">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.GHOST"
            @click="hide()"
          >
            {{ $trans.t('app.labels.cancel') }}
          </m-button>

          <m-button
            :appearance="$const.StyleAppearance.SECONDARY"
            :disabled="flags.loading || projects?.length === 0 || formData?.model?.projectUuids.length === 0"
            :icon-before="{ name: 'paper-plane', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
            :size="$const.StyleSize.SMALL"
            @click="submit()"
          >
            <template v-if="formData?.model?.projectUuids.length <= 1">
              {{ $trans.t('app.labels.sendInvite') }}
            </template>
            <template v-else>
              {{ $trans.t('app.labels.sendInvites') }}
            </template>
          </m-button>
        </div>
      </div>

    </div>
  </div>

</template>

<style lang="scss" scoped>

.modal-dialog {
  .dialog-header {
    @apply pb-2;
  }

  .dialog-footer {
    @apply p-6 flex flex-row flex-wrap items-center space-x-3 rounded-b-xl border-t sm:border-0 sm:bg-transparent;

    .action-buttons {
      @apply w-full flex flex-1 flex-row items-center space-x-3 rounded-b-xl;
    }
  }
}

.project-list {
  @apply flex flex-col mt-4 border rounded-xl;

  .project {
    @apply p-4 border-t transition-colors;

    &:hover {
      @apply bg-blue-0;
    }

    &:first-child {
      @apply rounded-t-xl border-0;
    }

    &:last-child {
      @apply rounded-b-xl;
    }

    &.selected {
      @apply bg-blue-25;
    }

    :deep(.form-field-group .form-field) {
      @apply w-full;
    }
  }
}

</style>
