<script lang="ts" src="./ManufyValuePill.ts"></script>


<template>
  <div :class="class">
    <span class="circle">
      <m-icon :name="icon" :solid="true" :type="$const.IconTypes.MANUFY" class="icon"/>
    </span>
    <span class="label">
      <slot></slot>
    </span>
  </div>
</template>


<style lang="scss" scoped>
</style>
