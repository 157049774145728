/**
 * Style: Possible positions.
 */
export const enum StylePosition {

    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    LEFT = 'left',
    CENTER = 'center',

}
