export class TemplateHelper {

    /**
     * Escape the given string and replace 'new lines' with html equivalent.
     */
    public static parseToHtml(str: string): string {
        if (!str) {
            return str;
        }
        str = str.escapeHTML();
        // str = str.replaceAll(' ', '&nbsp;');
        str = str.replaceAll('\n', '<br />');
        return str;
    }

}
