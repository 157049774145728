import {Component} from 'vue-facing-decorator';
import {Modal} from '@/app/views/modals/Modal';

@Component({})
export default class ManufyValueListModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public values: Array<string>;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(values: Array<string>): void {
        this.values = values;

        this.flags.show = true;
    }

}
