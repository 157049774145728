<script lang="ts" src="./FormFieldPhoneNumber.ts"></script>


<template>
  <div
    :class="[
      'form-field-group',
      disabled ? 'disabled' : '',
      errors?.length > 0 ? 'has-error' : '',
      size,
    ]"
  >
    <div class="flex relative">
      <m-form-select-country-picker
        :id="id"
        ref="phoneCountryCode"
        :direction="direction"
        :disabled="disabled"
        :dusk="'country-code'"
        :items="$store.meta.phoneCountryCodes"
        :modelValue="phoneCountryCode"
        :size="size"
        class="mr-3"
        @updatePhoneCountryCode="(countryCode: string) => updateCountryCode(countryCode)"
      />

      <m-form-field-number
        ref="phoneNumber"
        :autocomplete="AutocompleteStandard.PHONE_NUMBER"
        :disabled="disabled"
        :dusk="'phone-number'"
        :modelValue="phoneNumber"
        :placeholder="placeholder"
        :size="size"
        class="flex-1"
        @handleInput="(phoneNumber: number) => updatePhoneNumber(phoneNumber)"
      >
        <template #prepend>
          <span class="prepend"><span class="text-neutral-60">{{ phoneCountryCode }}</span></span>
        </template>
      </m-form-field-number>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.form-field-group .form-field .prepend {
  @apply mr-0;
}

</style>
