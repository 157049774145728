<script lang="ts" src="./VerifiedBadge.ts"></script>


<template>
  <svg id="verified-badge" class="fill-curent" data-name="Layer 1" height="64" role="img" viewBox="0 0 64 64" width="64"
       xmlns="http://www.w3.org/2000/svg">
    <path class="shape"
          d="M19.068,7.82a12.264,12.264,0,0,0,6.98-2.892,12.264,12.264,0,0,1,15.9,0,12.264,12.264,0,0,0,6.98,2.892A12.264,12.264,0,0,1,60.181,19.068a12.247,12.247,0,0,0,2.892,6.98,12.264,12.264,0,0,1,0,15.9,12.264,12.264,0,0,0-2.892,6.98A12.264,12.264,0,0,1,48.932,60.181a12.264,12.264,0,0,0-6.98,2.892,12.264,12.264,0,0,1-15.9,0,12.264,12.264,0,0,0-6.98-2.892A12.264,12.264,0,0,1,7.82,48.932a12.264,12.264,0,0,0-2.892-6.98,12.264,12.264,0,0,1,0-15.9,12.264,12.264,0,0,0,2.892-6.98A12.264,12.264,0,0,1,19.068,7.82Z"
          fill="#fff" fill-rule="evenodd" transform="translate(-2 -2)"/>
    <path class="icon"
          d="M15.654,6.656a9.811,9.811,0,0,0,5.584-2.314,9.811,9.811,0,0,1,12.723,0,9.811,9.811,0,0,0,5.584,2.314,9.811,9.811,0,0,1,9,9,9.8,9.8,0,0,0,2.314,5.584,9.811,9.811,0,0,1,0,12.723,9.811,9.811,0,0,0-2.314,5.584,9.811,9.811,0,0,1-9,9,9.811,9.811,0,0,0-5.584,2.314,9.811,9.811,0,0,1-12.723,0,9.811,9.811,0,0,0-5.584-2.314,9.811,9.811,0,0,1-9-9,9.811,9.811,0,0,0-2.314-5.584,9.811,9.811,0,0,1,0-12.723,9.811,9.811,0,0,0,2.314-5.584,9.811,9.811,0,0,1,9-9ZM39.462,23.462a3.2,3.2,0,1,0-4.525-4.525L24.4,29.475l-4.138-4.138a3.2,3.2,0,1,0-4.525,4.525l6.4,6.4a3.2,3.2,0,0,0,4.525,0l12.8-12.8Z"
          fill="#75bd8f" fill-rule="evenodd" transform="translate(4.4 4.4)"/>
  </svg>
</template>


<style lang="scss" scoped></style>
