import {reactive} from 'vue';
import {Main} from '@/app/Main';
import {FormRule, FormRuleResult, FormRulesById} from '@/app/utils/FormRules';
import {Lang} from "@/app/lang/Lang";

type Errors = string[] & { _?: ErrorsById[] };
type ErrorsById = { [fieldId: string]: Errors | ErrorsById };
type FormModel = Record<string, any>;

export class FormData<T = FormModel> {

    private _rules: FormRulesById = null;
    private _errorsById: ErrorsById = null;
    private _errorCountBySub: Record<string, number> = {};

    constructor(model: T, rules: FormRulesById = null) {
        this._model = <T>reactive(<Object>model);
        this._rules = rules;
        this._errorsById = reactive({});

        // Watch the model for changes:
        Main.app.$watch(() => this._model, (newValue: T, oldValue: T) => {
            // Revalidate (part of) the form in case it contained any previous errors:
            if (this._errorCount) {
                this.validateFields();
            }

            for (let sub in this._errorCountBySub) {
                if (!this._errorCountBySub.hasOwnProperty(sub)) {
                    continue;
                }
                if (this._errorCountBySub[sub] > 0) {
                    this.validateFields(sub);
                }
            }
        }, {deep: true});
    }

    private _model: T = null;

    public get model(): T {
        return this._model;
    }

    private _errorCount: number = 0;

    /**
     *  The total number of errors in the ErrorsById object.
     */
    public get errorCount(): number {
        return this._errorCount;
    }

    private _errorMsg: string = null;

    public get errorMsg(): string {
        return this._errorMsg;
    }

    public set errorMsg(msg: string) {
        this._errorMsg = msg;
    }

    public get errors(): ErrorsById {
        return this._errorsById;
    }

    /**
     * Validate values in the model by executing all form rules.
     * @param sub A string representing the sub-rules that need to be validated. Nested rules can be validated by using a dot-notation e.g. 'sub.subSub'.
     *      This only works for rule-branches, not for rule-leafs!
     * @return boolean A boolean indicating whether the form is valid or not.
     */
    public validate(sub: string = null): boolean {
        const isValid: boolean = this.validateFields(sub);

        if (!isValid) {
            Main.app.toast.danger(Main.trans.t(Lang.t.app.toast.error.form.message), Main.trans.t(Lang.t.app.toast.error.form.title));
        }

        return isValid;
    }

    /**
     * Set errors for the form fields in this form.
     * @param errors An object containing the errors per field.
     */
    public setErrors(errors: ErrorsById): void {
        const rec = (errors: ErrorsById, errorsTarget: ErrorsById): void => {
            for (let fieldId in errors) {
                if (!errors.hasOwnProperty(fieldId)) {
                    continue;
                }

                // Make sure the errorsTarget is an array
                if (!errorsTarget[fieldId]) {
                    errorsTarget[fieldId] = [];
                }

                if (!errors[fieldId]) {
                    (<Errors>errorsTarget[fieldId]).length = 0;    // Clear possible old errors
                } else if (errors[fieldId] instanceof Array) { // Leaf (array)
                    (<Errors>errorsTarget[fieldId]).length = 0;    // Clear possible old errors
                    (<Errors>errorsTarget[fieldId]).push(...<Errors>errors[fieldId]);
                } else if (errors[fieldId] instanceof Object) { // Branch
                    rec(<ErrorsById>errors[fieldId], <ErrorsById>errorsTarget[fieldId]);
                } else {
                    console.warn('Error(s) in `' + fieldId + '` should be in an Array!');
                }
            }
        };

        if (!errors) {
            throw new Error('At the moment it\'s not possible to clear all errors at once! Please clear each specific error like so: `{ someField: null }`');
            // TODO: clear all errors in the this._errorsById when `errors` is empty.
        }
        rec(errors, this._errorsById);
    }

    /**
     * Create a payload based on the form's model. It's basically a deep-copy of the model but it also parses some values:
     * - Date gets stringified.
     * - Empty array-elements (null or undefined) are removed.
     * @param removeEmptyArrayElements If set to true any empty array-elements (null or undefined) will not be removed.
     * @return The payload.
     */
    public createPayload(removeEmptyArrayElements: boolean = true): Record<string, any> {
        const parse = (src: unknown): unknown => {
            let payload: any = null;
            if (src instanceof Array) {
                payload = [];
                for (let i: number = src.length; i--;) {
                    if (removeEmptyArrayElements && (src[i] === null || src[i] === undefined)) {
                        continue;
                    }
                    payload.unshift(parse(src[i]));
                }
            } else if (src instanceof Date) {
                payload = src.toISOString();
            } else if (src instanceof Object) {
                payload = {};
                for (let key in src) {
                    if (!src.hasOwnProperty(key)) {
                        continue;
                    }
                    payload[key] = parse(src[key]);
                }
            } else {
                payload = src ?? null;  // Make sure undefined propertys will be set to null!
            }

            return payload;
        };

        const payload: Record<string, any> = parse(this._model);
        return payload;
    }

    public toJSON(): any {
        return this._model;
    }

    /**
     * Validate a single value agains the given rules.
     * @param value The value to validate
     * @param fieldRules The rules to use for the validation
     * @param errors The error array to store the errors in
     * @return The number of errors for the given value
     */
    private validateValue(value: any, fieldRules: FormRule[], errors: Errors): number {
        // Make sure to clear the old errors first:
        errors.length = 0;
        if (!fieldRules) {
            return 0;
        }    // No rules to check
        for (let i: number = 0; i < fieldRules.length; i++) {
            const validationHandler: FormRule = fieldRules[i];
            const ruleIsValid: FormRuleResult = validationHandler(value, this._model);
            if (ruleIsValid === -1) {
                break;  // skip further rules for this property
            } else if (ruleIsValid !== true) {
                errors.push(ruleIsValid);

                break;  // skip further rules for this property
            }
        }
        return errors.length;
    }

    /**
     * Validate all fields.
     * @return A boolean indicating whether all fields are valid or not.
     */
    private validateFields(sub: string = null): boolean {

        if (!sub) {
            this._errorMsg = null;
        }

        let errorCount: number = 0;

        if (!this._rules) { // Nothing to check anyway
            return true;
        }

        const rec = (rules: FormRulesById, model: FormModel, errors: ErrorsById): void => {
            for (let fieldId in rules) {
                if (!rules.hasOwnProperty(fieldId)) {
                    continue;
                }

                if (!rules[fieldId]) {  // Leaf: Empty leaf, nothing to validate here

                } else if (rules[fieldId] instanceof Array) {  // Leaf: This is an array with actual rules
                    if (!errors[fieldId]) {
                        errors[fieldId] = [];
                    }

                    const fieldRules: FormRule[] = <FormRule[]>rules[fieldId];
                    const errCount: number = this.validateValue(model[fieldId], fieldRules, <Errors>errors[fieldId]);

                    errorCount += errCount;

                    // if (errCount) { continue; }  // Skip checking of possible array-children

                    // Check for potential array-children to check:
                    if (rules[fieldId]._) { // Model should be an array
                        errors[fieldId]._ = [];
                        for (let i = model[fieldId].length; i--;) {
                            if (!model[fieldId][i]) {
                                continue;
                            }   // Skip if the value is non-existent. The existence of the value should be checked by the rules for the containing array

                            errors[fieldId]._[i] = {};
                            rec(rules[fieldId]._, model[fieldId][i], errors[fieldId]._[i]);
                        }
                    }
                } else {  // Branch: More rules are nested
                    if (!errors[fieldId]) {
                        errors[fieldId] = {};
                    }

                    const subRules: FormRulesById = <FormRulesById>rules[fieldId];
                    const subModel: FormModel = <FormModel>model[fieldId];
                    const subErrors: ErrorsById = <ErrorsById>errors[fieldId];
                    rec(subRules, subModel, subErrors);
                }
            }
        };

        let subRules: FormRulesById = this._rules;
        let subModel: FormModel = this._model;
        let subErrors: ErrorsById = this._errorsById;
        if (sub) {
            // Find correct sub:
            const subArr: string[] = sub.split('.');
            for (let i: number = 0; i < subArr.length; i++) {
                const leaf: string = subArr[i];
                if (!subErrors[leaf]) {
                    subErrors[leaf] = {};
                }

                subRules = subRules[leaf];
                subModel = subModel[leaf];
                subErrors = <ErrorsById>subErrors[leaf];
            }
            if (!subModel) {
                throw new Error('Sub `' + sub + '` can\'t target an empty value!');
            }
        }
        rec(subRules, subModel, subErrors);

        if (sub) {
            // In case a sub was supplied, only set the error count for this specific sub.
            // This way the form can know what to re-validate in case the data changes.
            this._errorCountBySub[sub] = errorCount;
        } else {
            this._errorCount = errorCount;
        }

        if (errorCount) {
            console.warn('Validation errors:', subErrors);
        }

        return (errorCount == 0);
    }
}
