import {BaseDTO} from '@/app/dto/BaseDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";

export class BaseProjectDTO extends BaseDTO {

    public createdAt: Date = null;

    public name: string = null;
    public type: string = null;

    public targetDeliveryDate: Date = null;

    public buyer: CompanyShortDTO = null;

    public countries: string[] = [];

    public manufyValues: string[] = [];

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            createdAt: Date,
            targetDeliveryDate: Date,
            buyer: CompanyShortDTO,
        };
    }

}
