import {Component, Prop} from 'vue-facing-decorator';
import {StylePosition} from '@/app/constants/styles/StylePosition';
import {FormItem} from '@/app/views/components/form/FormItem';
import {FormSelectPickerItem} from '@/app/views/components/form/FormSelectPicker';

@Component({})
export default class FormLanguagePicker extends FormItem<string | string[]> {

    @Prop({type: Array, required: true})
    declare public readonly items: FormSelectPickerItem<string>[];

    @Prop({type: String, default: null})
    declare public readonly modelValue: string;

    @Prop({type: String, default: StylePosition.BOTTOM})
    public readonly direction: StylePosition;

    public readonly flags = {
        opened: false,
    };

    public selectedItem: FormSelectPickerItem<string> = null;

    /**
     * The label to be displayed in the field.
     */
    public get selectedLabel(): string {
        return this.selectedItem?.id;
    }

    public created(): void {
        // Select initial item based on the value:
        for (let i: number = this.items.length; i--;) {
            if (this.items[i].id == this.value) {
                this.selectedItem = this.items[i];
                return;
            }
        }
    }

    public handleFieldClick($event: MouseEvent): void {
        this.open();
    }

    public close(): void {
        this.flags.opened = false;
    }

    public clickItem(item: FormSelectPickerItem<string> = null): void {
        this.selectedItem = item;
        this.flags.opened = false;
        this.updateValue(item.id);
    }

    public checkItemSelected(item: FormSelectPickerItem<string> = null): boolean {
        return !!(this.value && (this.value === item.id));   // Use !! to make sure it doesn't return null
    }

    private open(): void {
        this.flags.opened = true;
    }

}
