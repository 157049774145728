import {Component, Prop, Vue} from 'vue-facing-decorator';
import {PublicSellerShortDTO} from "@/app/dto/PublicSellerShortDTO";

@Component({})
export default class PublicSellerCard extends Vue {

    @Prop({type: Boolean, default: true})
    public readonly hasShadow: boolean;

    @Prop({type: PublicSellerShortDTO, default: null})
    public readonly seller: PublicSellerShortDTO;

    public created(): void {
        this.seller.profileImages?.forEach(i => i.isLoading = false);
    }

    public mounted(): void {
    }
}
