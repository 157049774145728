/**
 * Extend the Array class with some handy functions.
 * Use: `import 'PATH_TO_LIBRARY/core/extensions/Array';`.
 * @author Arno van Oordt
 * @version 1.0.7
 */
interface Array<T> {
    first(): T;

    last(): T;

    random(): T;

    sum(): number;

    mean(): number;

    shuffle(clone?: boolean): T[];

    move(fromIndex: number, toIndex: number): T[];

    haveCommon(array2: T[]): boolean;

    remove(needle: T): boolean;

    getAssociativeKeys(): string[];

    getAssociativeValues(): T[];
}

/**
 * Get the first element in the array.
 * @returns The last element in the array.
 * @example
 *   const a:number[] = [1,2,3,4,5];
 *   a.first()    //output: 1
 */
Array.prototype.first = function <T>(): T {
    return this[0];
}

/**
 * Get the last element in the array.
 * @returns The last element in the array.
 * @example
 *   const a:number[] = [1,2,3,4,5];
 *   a.last()    // Output: 5
 */
Array.prototype.last = function <T>(): T {
    return (this.length > 0) ? this[this.length - 1] : undefined;
}

/**
 * Get a random value from the array.
 * @returns A random value from the given array.
 * @example
 *   const a:number[] = [1,2,3,4,5];
 *   a.random(a)    // Output: 4
 * @see #mean
 */
Array.prototype.random = function <T>(): T {
    return this[Math.floor(Math.random() * this.length)];
}

/**
 * Get the sum of all values in the array.
 * @returns The sum of all values in the array. Incase not all values ar numerical NaN is returned.
 * @example
 *   const a:number[] = [1,2,3,4,5];
 *   a.sum()    // Output: 15
 * @see #mean
 */
Array.prototype.sum = function <_T>(): number {
    let sum: number = 0;
    for (let i: number = 0; i < this.length; i++) {
        sum += Number(this[i]);
    }
    return sum;
}

/**
 * Get the mean value of all values in the array.
 * @returns The mean of all values in the array. Incase not all values ar numerical NaN is returned.
 * @example
 *   const a:number[] = [1,2,3,4,5];
 *   a.mean()    // Output: 3
 * @see #sum
 */
Array.prototype.mean = function <_T>(): number {
    let sum: number = 0;
    for (let i: number = 0; i < this.length; i++) {
        sum += Number(this[i]);
    }
    return sum / this.length;
}

/**
 * Shuffles the value in the array.
 * @param clone Whether to return a cloned version of the array (true) or shuffle the array itself (false).
 * @returns Either a the shuffled input or a shuffled clone of the array itself.
 * @example
 *   const a:number[] = [1,2,3,4,5];
 *   a.shuffle();
 *   a    // Output: [3,4,1,2,5]
 */
Array.prototype.shuffle = function <T>(clone: boolean = false): T[] {
    const array: T[] = (clone) ? this.slice() : this;
    const a2: T[] = array.splice(0, array.length);
    while (a2.length > 0) {
        array.push(a2.splice((Math.random() * a2.length) >> 0, 1)[0]);
    }
    return array;
}

/**
 * Move the value from one index to another index.
 * @param fromIndex The index of the item to move.
 * @param toIndex The index to which the item should move.
 * @returns The changed array.
 * @example
 *   const a:number[] = [1,2,3,4,5];
 *   a.move(1,3);
 *   a    // Output: [1,3,4,2,5]
 */
Array.prototype.move = function <T>(fromIndex: number, toIndex: number): T[] {
    this.splice(toIndex, 0, this.splice(fromIndex, 1)[0]);
    return this;
}

/**
 * Check if array has at least one element in common whith the given array.
 * @param array2 The second array.
 * @returns True if both arrays contain at least one common element.
 */
Array.prototype.haveCommon = function <T>(array2: T[]): boolean {
    return this.some((a1: T) => array2.some((a2: T) => (a1 === a2)));
}

/**
 * Remove item from array. All instances of the needle are removed in the it's in the array multiple times.
 * @param value the value to look for.
 * @returns True if the array contained the element.
 */
Array.prototype.remove = function <T>(needle: T): boolean {
    let found = false;
    let index: number = this.indexOf(needle);
    while (index !== -1) {
        found = true;
        this.splice(index, 1);
        index = this.indexOf(needle);
    }
    return found;
}

/**
 * Get a list of associative keys.
 * @returns Array with associative keys.
 * @example
 *  const a:string[] & { someProp?:string, someOtherProp?:string } = ['Aa','Bb'];
 *  a.someProp = 'Cc';
 *  a.someOtherProp = 'Dd';
 *  a.getAssociativeKeys()    // Output: ['someProp', 'someOtherProp']
 */
Array.prototype.getAssociativeKeys = function <T>(): string[] {
    return Object.keys(this).filter((value, index, array) => {
        return (isNaN(parseInt(value)));
    });
}

/**
 * Get a list of values from the associative keys only.
 * @returns Array with values from the associative keys.
 * @example
 *  const a:string[] & { someProp?:string, someOtherProp?:string } = ['Aa','Bb'];
 *  a.someProp = 'Cc';
 *  a.someOtherProp = 'Dd';
 *  a.getAssociativeValues()    // Output: ['Cc', 'Dd']
 */
Array.prototype.getAssociativeValues = function <T>(): unknown[] {
    const values: unknown[] = [];
    for (let key in this) {
        if (!this.hasOwnProperty(key) || !isNaN(parseInt(key))) {
            continue;
        }
        values.push(this[key]);
    }
    return values;
}
