import {BaseDTO} from '@/app/dto/BaseDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {CastableProperties} from '@/app/utils/Castable';

export class InquiryDTO extends BaseDTO {

    public name: string = null;
    public targetPrice: number = null;
    public targetQuantity: number = null;
    public coverImage: FileDTO = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            coverImage: FileDTO,
        };
    }
}
