<script lang="ts" src="./FormLanguagePicker.ts"></script>


<template>
  <div
    v-click-outside="close"
    :class="[
      'form-field-group',
      {
        disabled: disabled,
        opened: flags.opened,
      },
    ]"
  >
    <!-- Input field -->
    <div class="form-field" @click="handleFieldClick($event)">
      <div class="prepend">
        <img v-if="selectedItem" :src="`${$settings.appPath}/images/country-flags/${selectedItem.id.toLowerCase()}.svg`" alt=""/>
      </div>

      <input
        :id="id"
        :dusk="dusk"
        :value="selectedItem?.id.toUpperCase()"
        class="input"
        readonly
        type="text"
      />
    </div>

    <!-- Dropwdown -->
    <transition mode="out-in" name="slide-from-top-fade">
      <div v-if="flags.opened" :class="['dropdown', direction]">
        <ol class="flex flex-col py-2 max-h-40 overflow-auto">
          <!-- List items: -->
          <li
            v-for="item in items"
            :key="item.id"
            :class="['item', {
              checked: checkItemSelected(item),
            }]"
            :dusk="'language-' + item.id"
            @click="clickItem(item)"
          >
            <div class="hidden">
              <input
                :checked="checkItemSelected(item)"
                :disabled="disabled"
                type="radio"
              />
            </div>

            <img :src="`${$settings.appPath}/images/country-flags/${item.id}.svg`" class="inline-flex self-center max-w-7 w-7 h-4 object-cover rounded-md mr-2"/>
            {{ item.id.toUpperCase() }}
          </li>
        </ol>
      </div>
    </transition>
  </div>
</template>


<style lang="scss" scoped src="./FormFieldCheckbox.scss"></style>
<style lang="scss" scoped src="./FormFieldRadio.scss"></style>
<style lang="scss" scoped>
.dropdown {
  @apply flex flex-col min-w-max absolute -right-3 top-full rounded-xl border bg-neutral-0 transition-all duration-300 ease-in-out shadow-xl;
  z-index: 1;

  .item {
    @apply flex flex-1 px-4 py-1 leading-snug hover:bg-blue-25 hover:text-blue-400 cursor-pointer transition-colors duration-200 ease-in-out;

    &.checked {
      @apply bg-blue-25 text-blue-400;
    }
  }

  &.top {
    @apply bottom-10 top-auto;
  }
}

.form-field {
  @apply h-10 w-16 border-transparent bg-transparent cursor-pointer;

  .prepend {
    @apply pl-0 py-0;

    img {
      @apply max-w-none w-7 rounded-sm;
    }
  }

  .input {
    @apply pl-2 pr-0 py-1.5 font-medium leading-tight bg-transparent cursor-pointer;
  }
}

</style>
