<script lang="ts" src="./FormFieldWrapper.ts"></script>

<template>
  <div class="m-input-group">
    <m-form-label
      v-if="label"
      :id="id"
      :required="required"
      :tooltipLabel="tooltipLabel"
      :tooltipLink="tooltipLink"
    >
      {{ label }}
    </m-form-label>

    <m-form-field
      :id="id"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :dusk="dusk"
      :errors="errors"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :required="required"
      :rtl="rtl"
      :size="size"
      :type="type"
      @update:modelValue="updateValue"
    >
      <template #prepend>
        <div v-if="iconBefore" class="prepend">
          <m-icon :name="iconBefore.name" :solid="iconBefore.solid" :type="iconBefore.type"/>
        </div>
      </template>

      <template #append>
        <div v-if="iconAfter" class="append">
          <m-icon :name="iconAfter.name" :solid="iconAfter.solid" :type="iconAfter.type"/>
        </div>
      </template>
    </m-form-field>

    <p v-if="supportText" class="support-text">
      {{ supportText }}
    </p>
  </div>
</template>


<style lang="scss" scoped>
</style>
