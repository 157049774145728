import {RouteLocationRaw} from 'vue-router';
import {Roles} from '@/app/constants/Roles';
import {Store} from '@/app/stores/Store';

export type GateResult = true | RouteLocationRaw;

/**
 * Static gate functions that can check specific situations, where for example a user can only pass if he doesn't have a certain role.
 */
export class Gate {

    /**
     * Check if the user still needs to register a company.
     */
    public static needsCompanyRegistration(): GateResult {
        // User already registered a company or is Manufy admin
        if (Store.user.hasRoles(Roles.MANUFY_USER) || Store.user.hasRoles(Roles.BUYER_USER) || Store.user.hasRoles(Roles.SELLER_USER)) {
            return {name: 'dashboard'};
        }
        return true;
    }

    /**
     * Check if the user is still needs to verify his email.
     */
    public static checkUnverified(): GateResult {
        if (Store.user.hasRoles(Roles.VERIFIED_USER)) {    // User is already verified
            return {name: 'dashboard'};
        }
        return true;
    }

    public static userTermsAccepted(): GateResult {
        if (Store.user.user.termsAccepted) {
            return {name: 'dashboard'};
        }
        return true;
    }

}
