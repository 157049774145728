import {Component} from 'vue-facing-decorator';
import {PublicCatalogueServiceDTO} from '@/app/dto/PublicCatalogueServiceDTO';
import {Modal} from '@/app/views/modals/Modal';
import CatalogueInquiryVue from '@/app/views/components/misc/CatalogueInquiry.vue';
import {PublicCatalogueFabricDTO} from "@/app/dto/PublicCatalogueFabricDTO";

@Component({
    components: {
        'm-catalogue-inquiry': CatalogueInquiryVue,
    },
})
export default class CatalogueInquiryModal extends Modal {

    public item: PublicCatalogueServiceDTO | PublicCatalogueFabricDTO = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(item: PublicCatalogueServiceDTO | PublicCatalogueFabricDTO): void {
        this.item = item;
        this.flags.show = true;
    }

}
