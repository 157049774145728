import {BaseDTO} from '@/app/dto/BaseDTO';
import {CollaborationDTO} from '@/app/dto/CollaborationDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {LabelDTO} from '@/app/dto/LabelDTO';
import {MessageDTO} from '@/app/dto/MessageDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";

export class ThreadDTO extends BaseDTO {

    public subject: string = null;
    public latestMessage: MessageDTO = null;
    public collaboration: CollaborationDTO = null;
    public unreadMessagesCount: number = 0;
    public isArchived: boolean = null;
    public company: CompanyShortDTO = null;
    public isMuted: boolean = null;
    public media: FileDTO[] = null;
    public labels: LabelDTO[] = null;
    public cachedMessage: string = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            latestMessage: MessageDTO,
            collaboration: CollaborationDTO,
            company: CompanyShortDTO,
            media: [FileDTO],
            labels: [LabelDTO],
        };
    }

}
