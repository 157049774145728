import {Component, Prop, Vue} from 'vue-facing-decorator';
import {RouteLocationRaw} from 'vue-router';
import {Routes} from '@/app/Routes';
import {Store} from '@/app/stores/Store';
import {IIcon} from '@/app/views/components/misc/Icon';

export interface IMenuItem {

    label: string;
    icon?: IIcon;
    link?: RouteLocationRaw;
    notifications?: number;
    children?: IMenuItem[];

}

@Component({
    emits: [
        'click',    // Prevents `[Vue warn]: Extraneous non-emits event listeners (click) were passed to component ...`
        'menuItemCallback', // Allows parent elements to pass an on-click event to the root DOM element of this component
    ],
})
export default class MenuItem extends Vue {

    @Prop({type: Object, required: true})
    public readonly item: IMenuItem;

    public readonly flags = {
        open: false,
    };

    /**
     * Check if user has access to this item or at least one of its children.
     */
    public get hasAccess(): boolean {
        return Store.user.hasAccess(this.item);
    }

    /**
     * Check if the target route's name matches the name of the current route.
     */
    public get isSelected(): boolean {
        const checkItemSelected = function (item: IMenuItem): boolean {
            if (!item) {
                return false;
            }
            let isSelected: boolean = false;
            if (item.children) {
                for (let i: number = item.children.length; i--;) {
                    isSelected = checkItemSelected(item.children[i]);
                    if (isSelected) {
                        break;
                    }
                }
            } else {
                isSelected = (Routes.resolve(item.link)?.name == Routes.currentRoute.name);
            }
            return isSelected;
        }

        return checkItemSelected(this.item);
    }

    public created(): void {
        this.$watch(() => this.isSelected, (newValue: boolean, _oldValue: boolean) => {
            this.flags.open = newValue;
        },);
    }

    public mounted(): void {
        this.flags.open = this.isSelected;
    }

}
