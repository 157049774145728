<script lang="ts" src="./OfferCreateModal.ts"></script>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-x-large">
      <div class="dialog-header flex-wrap">
        <h2 class="title">
          {{ $trans.t('app.bidModal.title') }}
        </h2>

        <m-button
          :appearance="$const.StyleAppearance.WARNING"
          :iconBefore="{ name: 'star', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
          :size="$const.StyleSize.XSMALL"
          class="hidden mr-2 xs:inline-table"
          @click="openProjectReviewModal()"
        >
          {{ $trans.t('app.buttonLabels.sendFeedback') }}
        </m-button>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <form class="m-form" @submit.prevent="submit()">
        <div class="dialog-body space-y-4 sm:space-x-12 sm:space-y-0">
          <div class="flex-1">
            <m-button
              :appearance="$const.StyleAppearance.WARNING"
              :iconBefore="{ name: 'star', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
              :size="$const.StyleSize.XSMALL"
              class="mr-2 xs:hidden"
              @click="openProjectReviewModal()"
            >
              {{ $trans.t('app.buttonLabels.sendFeedback') }}
            </m-button>

            <p class="mb-4 text-neutral-80">{{ $trans.t('app.bidModal.chooseProducts') }}</p>

            <div v-if="project.manufyValues.length > 0" class="mb-3">
              <m-value-pill
                v-for="value in project.manufyValues"
                :appearance="$const.StyleAppearance.SECONDARY"
                :icon="value"
                :size="$const.StyleSize.XSMALL"
                class="mr-2 mb-2"
              >
                {{ $trans.t('meta.manufyValues.' + value) }}
              </m-value-pill>
            </div>

            <ul class="product-list">
              <li
                v-for="(product, index) in project.products"
                :key="product.uuid"
                :class="['product', {'order-last': productIsLast,'edit': formData.model.products[index],'offer-placed': product.quantity > 0 && !formData.model.products[index]}]"
              >
                <div class="header">
                  <div class="category-circle">
                    <div :class="[
                        'm-circle dark large transition-colors',
                        formData.model.products[index] ? $const.StyleAppearance.SECONDARY : $const.StyleAppearance.NEUTRAL_NEW,
                      ]">
                      <m-icon
                        :name="product.category"
                        :solid="true"
                        :type="$const.IconTypes.MANUFY"
                      />
                    </div>
                  </div>

                  <div class="text">
                    <strong class="title">{{ product.name }}</strong>
                    <p class="category">
                      {{ $trans.t('meta.categories.' + product.category) }}
                    </p>
                  </div>

                  <div>
                    <m-form-field-checkbox-button
                      v-if="product.quantity"
                      :disabled="true"
                      class="h-10"
                    >
                      {{ $trans.t('app.buttonLabels.offerSent') }}
                    </m-form-field-checkbox-button>

                    <m-form-field-checkbox-button
                      v-else
                      :model-value="!!formData.model.products[index]"
                      class="h-10"
                      @click.prevent="toggleProductOffer(index)"
                    >
                      {{ $trans.t('app.buttonLabels.offerMake') }}
                    </m-form-field-checkbox-button>
                  </div>
                </div>

                <div class="body">
                  <div v-if="product.fabrics?.length > 0" class="mb-2">
                    <m-product-fabrics
                      :buyer-name="project.buyer.name"
                      :fabrics="product.fabrics"
                    />
                  </div>

                  <m-text-clamp :max-lines="2" class="text-tiny">{{ product.description.value }}</m-text-clamp>

                  <div class="flex mt-2 mb-3 sm:hidden">
                    <m-button
                      :appearance="product.hasTechpack ? $const.StyleAppearance.PRIMARY : $const.StyleAppearance.DISABLED"
                      :disabled="!product.hasTechpack"
                      :icon-before="{ name: 'download', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :size="$const.StyleSize.XSMALL"
                      :to="'download::' + product.techpackUrl"
                    >
                      <span v-if="product.hasTechpack">{{ $trans.t('pages.sellerDashboard.project.techpack') }}</span>
                      <span v-else>{{ $trans.t('app.bidModal.techpackUnavailable') }}</span>
                    </m-button>
                  </div>

                  <div v-if="!formData.model.products[index]" class="flex justify-start my-3 sm:mt-2">
                    <ul class="flex flex-row flex-wrap -mb-3 -ml-3">
                      <li class="pb-3 pl-3">
                        <m-badge :appearance="$const.StyleAppearance.NEUTRAL">
                          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="mr-1.5 text-sm" name="boxes"/>

                          <template v-if="product.quantity > 0">
                            <span class="text-green-400">{{ product.quantity }}</span>
                          </template>
                          <span :class="{ 'ml-1 text-neutral-60 line-through': product.quantity > 0 }">
                              {{ product.targetQuantity }}
                            </span>
                        </m-badge>
                      </li>

                      <li class="pb-3 pl-3">
                        <m-badge :appearance="$const.StyleAppearance.NEUTRAL">
                          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="mr-1.5 text-sm" name="shirt"/>

                          <template v-if="product.price > 0">
                            <span class="text-green-400">&euro;{{ product.price }}</span>
                          </template>
                          <span :class="{ 'ml-1 text-neutral-60 line-through': product.price > 0 }">
                              &euro;{{ product.targetPrice }}
                            </span>
                        </m-badge>
                      </li>

                      <li class="pb-3 pl-3 hidden sm:inline-flex sm:flex-none">
                        <m-badge
                          :appearance="$const.StyleAppearance.PRIMARY"
                          :disabled="!product.hasTechpack"
                          :interactive="product.hasTechpack"
                        >
                          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="mr-1.5 text-sm" name="download"/>

                          <m-link
                            v-if="product.hasTechpack"
                            :size="$const.StyleSize.XSMALL"
                            :to="'download::' + product.techpackUrl"
                          >
                            {{ $trans.t('pages.sellerDashboard.project.techpack') }}
                          </m-link>
                          <span v-else>{{ $trans.t('app.bidModal.techpackUnavailable') }}</span>
                        </m-badge>
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-if="formData.model.products[index]" class="offer-form">
                  <m-form-field-number-wrapper
                    id="quantity"
                    v-model="formData.model.products[index].quantity"
                    :dusk="'quantity'"
                    :errors="formData.errors.products?._[index]?.quantity"
                    :iconBefore="{ name: 'boxes', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                    :label="$trans.t('app.form.quantity.label')"
                    :max="100000000"
                    :min="1"
                    :placeholder="(product.quantity ?? product.targetQuantity).toString()"
                    :required="true"
                    :size="formFieldSize"
                    :step="1"
                    class="flex-1"
                  />

                  <!-- We use :step=".05" but the form doesn't consider it when validating.What's up with that? -->
                  <m-form-field-number-wrapper
                    id="price"
                    v-model="formData.model.products[index].price"
                    :dusk="'price'"
                    :errors="formData.errors.products?._[index]?.price"
                    :iconBefore="{ name: 'euro-sign', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                    :label="$trans.t('app.form.pricePerUnit.label')"
                    :max="1000000"
                    :min="0.05"
                    :placeholder="(product.price ?? product.targetPrice).toString()"
                    :required="true"
                    :size="formFieldSize"
                    :step=".05"
                    class="flex-1"
                  />
                </div>

                <div class="footer">
                  <div
                    :class="['total-price', !formData.model.products[index] ?(!product.quantity ? '' : 'offer-placed') : 'edit']"
                  >
                    <span class="label">{{ $trans.t('app.bidModal.totalPrice') }}</span>
                    <strong class="price">{{ $trans.n(totalPrice(product, index), 'eur') }}</strong>
                  </div>
                </div>
              </li>

              <li
                v-for="(service, index) in project.services"
                :key="service.uuid"
                :class="['product', {
                  'edit': formData.model.services[index],
                  'offer-placed': formData.model.services[index]
                }]"
              >
                <div class="header">
                  <div class="category-circle">
                    <div :class="[
                        'm-circle dark large transition-colors',
                        formData.model.services[index] ? $const.StyleAppearance.SECONDARY : $const.StyleAppearance.NEUTRAL_NEW,
                      ]">
                      <m-icon :name="'other'" :solid="true" :type="$const.IconTypes.MANUFY"/>
                    </div>
                  </div>

                  <div class="text">
                    <strong class="title">{{ service.name }}</strong>
                    <p class="category">
                      {{ $trans.t('meta.serviceCategoryTypes.' + service.serviceCategory) }}
                    </p>
                  </div>

                  <div>
                    <m-form-field-checkbox-button
                      v-if="service.offerUuid"
                      :disabled="true"
                      class="h-10"
                    >
                      {{ $trans.t('app.buttonLabels.offerSent') }}
                    </m-form-field-checkbox-button>

                    <m-form-field-checkbox-button
                      v-else
                      :model-value="!!formData.model.services[index]"
                      class="h-10"
                      @click.prevent="toggleServiceOffer(index)"
                    >
                      {{ $trans.t('app.buttonLabels.offerSend') }}
                    </m-form-field-checkbox-button>
                  </div>
                </div>

                <div class="body">
                  <m-text-clamp :max-lines="2" class="text-tiny">{{ service.description.value }}</m-text-clamp>
                </div>

                <div class="flex mx-3">
                  <div v-if="service.attachmentsUrl" class="flex mt-2">
                    <m-button
                      :appearance="$const.StyleAppearance.PRIMARY"
                      :icon-before="{ name: 'download', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :size="$const.StyleSize.XSMALL"
                      :to="'download::' + service.attachmentsUrl"
                    >
                      <span v-if="service.attachmentsUrl">{{ $trans.t('app.order.attachments.title') }}</span>
                    </m-button>
                  </div>
                </div>

                <div class="footer mb-4">
                </div>
              </li>
            </ul>
          </div>

          <div class="side-form">
            <div class="relative mt-1 sm:mt-10 z-10">
              <div class="mb-4 text-center sm:-mt-8 md:mb-6">
                <m-company-short-card :company="project.buyer" :hasShadow="false" class="product"/>
              </div>

              <m-form-field-wrapper
                id="deliveryDate"
                v-model="formData.model.deliveryDate"
                :dusk="'deliveryDate'"
                :errors="formData.errors.deliveryDate"
                :label="$trans.t('app.form.deliveryDate.label')"
                :min="Date.tomorrow()"
                :required="true"
                :size="formFieldSize"
                class="mb-3 sm:mb-4"
                type="date"
              />

              <m-form-field-text-area-wrapper
                id="message"
                v-model="formData.model.message"
                :auto-grow="isMobile"
                :dusk="'message'"
                :errors="formData.errors.message"
                :label="$trans.t('app.form.message.label')"
                :maxHeight="isMobile ? '64' : '128'"
                :placeholder="$trans.t('app.form.message.placeholder')"
                :rows="isMobile ? 8 : 10"
                :size="formFieldSize"
              />
            </div>
          </div>
        </div>

        <div class="dialog-footer m-form__action-buttons">
          <m-cancel-button :size="$const.StyleSize.SMALL" @cancel="hide()"/>
          <m-submit-button
            :disabled="flags.loading || numNewOffers == 0"
            :label="'app.buttonLabels.offerSend'"
            :size="$const.StyleSize.SMALL"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./OfferCreateModal.scss"></style>

<style lang="scss" scoped>
</style>
