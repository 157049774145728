import {Component} from 'vue-facing-decorator';
import {Store} from '@/app/stores/Store';
import {CompanyAvailabilityStatus} from '@/app/constants/CompanyAvailabilityStatus';
import {Modal} from '@/app/views/modals/Modal';
import {FormData} from '@/app/utils/FormData';
import {FormRulesById} from '@/app/utils/FormRules';
import {FormSelectItem} from '@/app/views/components/form/FormSelect';

type CompanyAvailabilityStatisFormModel = {
    availabilityStatus?: CompanyAvailabilityStatus,
};

@Component({
    emits: [
        'handleOnSubmit',
    ]
})
export default class AvailabilityStatusModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public formData: FormData<CompanyAvailabilityStatisFormModel> = null;
    public currentAvailabilityStatus: CompanyAvailabilityStatus;
    public availabilityStatuses: FormSelectItem<string>[];

    public created(): void {
    }

    public mounted(): void {
    }

    public show(availabilityStatuses: FormSelectItem<string>[]): void {
        this.availabilityStatuses = availabilityStatuses;

        // define rules:
        const formRules: FormRulesById = {};

        // Reset form:
        this.formData = new FormData({}, formRules);
        this.formData.model.availabilityStatus = Store.company.availabilityStatus;

        this.flags.show = true;
    }

    public submit(): void {
        Store.company.availabilityStatus = this.formData.model.availabilityStatus;
        this.hide();

        this.$emit('handleOnSubmit');
    }

    public iconColor(availabilityStatus: FormSelectItem<string>): string {
        switch (availabilityStatus?.id) {
            case CompanyAvailabilityStatus.FULL_CAPACITY:
            case CompanyAvailabilityStatus.NOT_ACTIVELY_SEARCHING:
                return 'text-red-400';
            case CompanyAvailabilityStatus.ON_HOLIDAY:
            case CompanyAvailabilityStatus.PROJECT_IN_PROCESS:
                return 'text-yellow-300';
            case CompanyAvailabilityStatus.LOOKING_FOR_PARTNERS:
            case CompanyAvailabilityStatus.OPEN_CAPACITY:
                return 'text-green-400';
            default:
                return 'text-neutral-80';
        }
    }
}
