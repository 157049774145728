import {Component, Prop, Vue} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Lang} from '@/app/lang/Lang';
import {Paginator} from '@/app/utils/Paginator';
import {FormSelectPickerItem} from '@/app/views/components/form/FormSelectPicker';

@Component({})
export default class Pagination extends Vue {

    /**
     * The options with an id of `-1` will be get a label defined in the `components.pagination.itemsPerPageAll` lang property.
     * NOTE: This will not work if perPageOptions is defined inline since in that case the array won't be reactive!
     */
    @Prop({
        type: Array, default: [
            {id: 10,},
            {id: 50,},
            {id: 100,},
            {id: -1},
        ]
    })
    public readonly perPageOptions: FormSelectPickerItem<number>[];

    @Prop({type: Boolean, default: false})
    public readonly showItemsPerPage: boolean;

    @Prop({type: Object, default: null})
    public readonly paginator: Paginator;

    public get label(): string {
        const start: number = this.paginator.perPage * (this.paginator.currentPage - 1) + 1;
        const end: number = (this.paginator.perPage == -1) ? this.paginator.totalItems : Math.min(start + this.paginator.perPage - 1, this.paginator.totalItems);

        return Main.trans.t(Lang.t.components.pagination.label, {
            varCurrentPage: this.paginator.currentPage,
            varTotalPages: this.paginator.lastPage,
            varStartItem: start,
            varEndItem: end,
            varTotalItems: this.paginator.totalItems,
        });
    }

    public created(): void {
        if (this.showItemsPerPage) {
            for (let i: number = this.perPageOptions.length; i--;) {
                if (this.perPageOptions[i].id == -1) {
                    this.perPageOptions[i].label = Main.trans.t(Lang.t.components.pagination.itemsPerPageAll);
                }
            }
        }
    }

}
