import Bluebird from 'bluebird';
import InstantSearch from 'vue-instantsearch/vue3/es';
import FloatingVue from 'floating-vue'
import Echo from 'laravel-echo';
import {PusherPrivateChannel} from 'laravel-echo/src/channel';
import NProgress from 'nprogress';
import Pusher from 'pusher-js';
import {App as VueApp, createApp} from 'vue';
import {LocationQuery} from 'vue-router';
import {Lang} from '@/app/lang/Lang';
import {Routes} from '@/app/Routes';
import {Settings} from '@/app/Settings';
import {ApiResponseCodes} from '@/app/constants/ApiResponseCodes';
import * as constants from '@/app/constants/Constants';
import {PusherChannels} from '@/app/constants/PusherChannels';
import {PusherEvents} from '@/app/constants/PusherEvents';
import {PaginationDTO} from '@/app/dto/PaginationDTO';
import {Translations} from '@/app/lang/Translations';
import {Store} from '@/app/stores/Store';
import {Castable} from '@/app/utils/Castable';
import {ListResult} from '@/app/utils/ListResult';
import {PusherHelper} from '@/app/utils/PusherHelper';
import {StripeHelper} from '@/app/utils/StripeHelper';
import {TemplateHelper} from '@/app/utils/TemplateHelper';
import App from '@/app/views/App';
import App2 from '@/app/views/App';
import AppVue from '@/app/views/App.vue';
import FormErrorVue from '@/app/views/components/form/FormError.vue';
import FormFieldVue from '@/app/views/components/form/FormField.vue';
import FormFieldCheckboxVue from '@/app/views/components/form/FormFieldCheckbox.vue';
import FormFieldCheckboxButtonVue from '@/app/views/components/form/FormFieldCheckboxButton.vue';
import FormFieldNumberVue from '@/app/views/components/form/FormFieldNumber.vue';
import FormFieldPhoneNumberVue from '@/app/views/components/form/FormFieldPhoneNumber.vue';
import FormFieldRadioButtonVue from '@/app/views/components/form/FormFieldRadioButton.vue';
import FormFieldRadioCardVue from '@/app/views/components/form/FormFieldRadioCard.vue';
import FormFieldRadioTabVue from '@/app/views/components/form/FormFieldRadioTab.vue';
import FormFieldReviewVue from '@/app/views/components/form/FormFieldReview.vue';
import FormFieldStepperVue from '@/app/views/components/form/FormFieldStepper.vue';
import FormFieldSwitchVue from '@/app/views/components/form/FormFieldSwitch.vue';
import FormFieldTextAreaVue from '@/app/views/components/form/FormFieldTextArea.vue';
import FormFieldTextAreaEditorVue from '@/app/views/components/form/FormFieldTextAreaEditor.vue';
import FormFileUploadVue from '@/app/views/components/form/FormFileUpload.vue';
import FormLocationPicker from '@/app/views/components/form/FormLocationPicker.vue';
import FormSelectCountryCodePickerVue from '@/app/views/components/form/FormSelectCountryCodePicker.vue';
import FormSelectPickerVue from '@/app/views/components/form/FormSelectPicker.vue';
import FormSelectCardPickerVue from '@/app/views/components/form/FormSelectCardPicker.vue';
import BreadcrumbsVue from '@/app/views/components/layout/Breadcrumbs.vue';
import LinkVue from '@/app/views/components/layout/Link.vue';
import MediaVue from '@/app/views/components/layout/Media.vue';
import MenuItemVue from '@/app/views/components/layout/MenuItem.vue';
import SectionMessageVue from '@/app/views/components/layout/SectionMessage.vue';
import TextClampVue from '@/app/views/components/layout/TextClamp.vue';
import AvatarVue from '@/app/views/components/misc/Avatar.vue';
import BadgeVue from '@/app/views/components/misc/Badge.vue';
import ButtonVue from '@/app/views/components/misc/Button.vue';
import IconVue from '@/app/views/components/misc/Icon.vue';
import IndicatorVue from '@/app/views/components/misc/Indicator.vue';
import LoadingSpinnerVue from '@/app/views/components/misc/LoadingSpinner.vue';
import ManufyValuePillVue from '@/app/views/components/misc/ManufyValuePill.vue';
import ManufyValueIconsVue from "@/app/views/components/misc/ManufyValueIcons.vue";
import CategoryIconsVue from "@/app/views/components/misc/CategoryIcons.vue"
import PaginationVue from '@/app/views/components/misc/Pagination.vue';
import TooltipVue from '@/app/views/components/misc/Tooltip.vue';
import AuthCardVue from "@/app/views/components/misc/AuthCard.vue";
import ProductCardVue from "@/app/views/components/misc/ProductCard.vue";
import ServiceCardVue from "@/app/views/components/misc/ServiceCard.vue";
import FabricCardVue from "@/app/views/components/misc/FabricCard.vue";
import PublicSellerCardVue from "@/app/views/components/misc/PublicSellerCard.vue";
import CancelButtonVue from '@/app/views/components/misc/form/CancelButton.vue';
import FormFieldNumberWrapperVue from '@/app/views/components/misc/form/FormFieldNumberWrapper.vue';
import FormFieldPhoneNumberWrapperVue from '@/app/views/components/misc/form/FormFieldPhoneNumberWrapper.vue';
import FormFieldReviewWrapperVue from '@/app/views/components/misc/form/FormFieldReviewWrapper.vue';
import FormFieldTextAreaWrapperVue from '@/app/views/components/misc/form/FormFieldTextAreaWrapper.vue';
import FormFieldWrapperVue from '@/app/views/components/misc/form/FormFieldWrapper.vue';
import FormFileUploadWrapperVue from '@/app/views/components/misc/form/FormFileUploadWrapper.vue';
import FormLabelVue from '@/app/views/components/misc/form/FormLabel.vue';
import FormSelectPickerWrapperVue from '@/app/views/components/misc/form/FormSelectPickerWrapper.vue';
import SubmitButtonVue from '@/app/views/components/misc/form/SubmitButton.vue';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {WebServiceError} from '@/libs/core/errors/WebServiceError';
import {UnreadMessagePayload} from '@/types/PusherEventPayloadTypes';
import {Uuid} from '@/types/Types';
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {faFacebookF, faInstagram, faLinkedinIn, faPinterestP, faStripe, faTiktok, faYoutube,} from '@fortawesome/free-brands-svg-icons';
import BuyerSearchBarVue from "@/app/views/components/misc/BuyerSearchBar.vue";
import ProductFabricsVue from "@/app/views/components/misc/ProductFabrics.vue";
import AdminCompanyFiltersVue from "@/app/views/components/misc/AdminCompanyFilters.vue";
import CompanyShortCardVue from "@/app/views/components/misc/CompanyShortCard.vue";
import manufyLogo from "@/assets/logo_desktop_notification.svg";
import {MessageDTO} from "@/app/dto/MessageDTO";
import CKEditor from "@ckeditor/ckeditor5-vue";
import * as Sentry from "@sentry/vue";

library.add(far);
library.add(fas);
library.add(faFacebookF, faInstagram, faLinkedinIn, faPinterestP, faStripe, faTiktok, faYoutube);

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        tr: typeof tr;
        $store: typeof Store;
        $trans: any;
        $const: typeof constants;
        $app: App2; // Using `App` here will reference to the App interface from Vue itself
        $settings: typeof Settings;
        $baseUrl: (url: string) => string;
        $helper: typeof TemplateHelper;
        $routes: typeof Routes;
    }
}

export class Main {

    public static vueApp: VueApp<Element> = null;

    private static _app: App = null;

    public static get app(): App {
        return Main._app;
    }

    public static get trans(): any {
        return Translations.i18n.global;
    }

    public static init(): void {
        window.addEventListener('unhandledrejection', Main.handleUnhandledRejection);

        Bluebird.config({
            warnings: false,    // Disable warnings to ignore undefined rejection calls and nested unreturned promise warnings (esp. annoying in case of Alert modals inside api results)
            // warnings: {
            //     wForgottenReturn: false,    // Prevent nested unreturned promise warnings (esp. annoying in case of Alert modals inside api results)
            // },
        });

        NProgress.configure({
            showSpinner: true,
        });

        Translations.init();    // Make sure at least the default translations are available

        const handleTranslationsLoaded = () => {
            const vueApp: VueApp<Element> = createApp(AppVue);

            this.bootSentry(vueApp);

            vueApp.use(Translations.i18n);
            vueApp.use(Routes.router);
            vueApp.use(FloatingVue);
            vueApp.use(InstantSearch);
            vueApp.use(CKEditor);

            // Register global components:
            vueApp.component('m-avatar', AvatarVue);
            vueApp.component('m-badge', BadgeVue);
            vueApp.component('m-button', ButtonVue);
            vueApp.component('m-icon', IconVue);
            vueApp.component('m-indicator', IndicatorVue);
            vueApp.component('m-link', LinkVue);
            vueApp.component('m-loading-spinner', LoadingSpinnerVue);
            vueApp.component('m-value-pill', ManufyValuePillVue);
            vueApp.component('m-value-icons', ManufyValueIconsVue);
            vueApp.component('m-category-icons', CategoryIconsVue);
            vueApp.component('m-breadcrumbs', BreadcrumbsVue);
            vueApp.component('m-pagination', PaginationVue);
            vueApp.component('m-media', MediaVue);
            vueApp.component('m-menu-item', MenuItemVue);
            vueApp.component('m-section-message', SectionMessageVue);
            vueApp.component('m-text-clamp', TextClampVue);
            vueApp.component('m-form-error', FormErrorVue);
            vueApp.component('m-form-field', FormFieldVue);
            vueApp.component('m-form-field-checkbox', FormFieldCheckboxVue);
            vueApp.component('m-form-field-checkbox-button', FormFieldCheckboxButtonVue);
            vueApp.component('m-form-field-number', FormFieldNumberVue);
            vueApp.component('m-form-field-phone-number', FormFieldPhoneNumberVue);
            vueApp.component('m-form-field-radio-button', FormFieldRadioButtonVue);
            vueApp.component('m-form-field-radio-card', FormFieldRadioCardVue);
            vueApp.component('m-form-field-radio-tab', FormFieldRadioTabVue);
            vueApp.component('m-form-field-review', FormFieldReviewVue);
            vueApp.component('m-form-field-stepper', FormFieldStepperVue);
            vueApp.component('m-form-field-switch', FormFieldSwitchVue);
            vueApp.component('m-form-field-text-area', FormFieldTextAreaVue);
            vueApp.component('m-form-field-text-area-editor', FormFieldTextAreaEditorVue);
            vueApp.component('m-form-file-upload', FormFileUploadVue);
            vueApp.component('m-form-location-picker', FormLocationPicker);
            vueApp.component('m-form-select-country-picker', FormSelectCountryCodePickerVue);
            vueApp.component('m-form-select-picker', FormSelectPickerVue);
            vueApp.component('m-form-select-card-picker', FormSelectCardPickerVue);
            vueApp.component('m-tooltip', TooltipVue);
            vueApp.component('m-auth-card', AuthCardVue);
            vueApp.component('m-product-card', ProductCardVue);
            vueApp.component('m-service-card', ServiceCardVue);
            vueApp.component('m-fabric-card', FabricCardVue);
            vueApp.component('m-public-seller-card', PublicSellerCardVue);
            vueApp.component('m-cancel-button', CancelButtonVue);
            vueApp.component('m-submit-button', SubmitButtonVue);
            vueApp.component('m-form-field-number-wrapper', FormFieldNumberWrapperVue);
            vueApp.component('m-form-field-phone-number-wrapper', FormFieldPhoneNumberWrapperVue);
            vueApp.component('m-form-field-review-wrapper', FormFieldReviewWrapperVue);
            vueApp.component('m-form-field-text-area-wrapper', FormFieldTextAreaWrapperVue);
            vueApp.component('m-form-field-wrapper', FormFieldWrapperVue);
            vueApp.component('m-form-file-upload-wrapper', FormFileUploadWrapperVue);
            vueApp.component('m-form-label', FormLabelVue);
            vueApp.component('m-form-select-picker-wrapper', FormSelectPickerWrapperVue);
            vueApp.component('m-company-short-card', CompanyShortCardVue);
            vueApp.component('m-buyer-search-bar', BuyerSearchBarVue);
            vueApp.component('m-product-fabrics', ProductFabricsVue);
            vueApp.component('m-admin-company-filters', AdminCompanyFiltersVue);

            // Add a v-click-outside directive:
            vueApp.directive('click-outside', {
                beforeMount(el, binding, _vnode) {
                    el.clickOutsideEvent = (evt: PointerEvent) => {
                        evt.stopPropagation();
                        if (!(el === evt.target || el.contains(evt.target))) {
                            binding.value(evt, el);
                        }
                    }
                    // Wait 1 frame otherwise a potential click that mounted the element will immediately trigger a click-outside event:
                    window.requestAnimationFrame(() => {
                        document.addEventListener('click', el.clickOutsideEvent)
                    });
                },
                unmounted(el) {
                    document.removeEventListener('click', el.clickOutsideEvent);
                },
            });

            Date.prototype.timeSinceHandler = (index: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, type: 'future' | 'past', count: number, date: Date, payload: any = null): string => {
                // Note: index <= 2 means the difference is less than 1 hour!
                // return (index <= 2) ? Main._app.$tc(`dateTimeSince[${index}].${type}`, count) : Main._app.$d(date, (date.isSameDay(new Date())) ? 'time' : 'dateTime');
                return (index <= 2) ? Main.trans.tc(`dateTimeSince[${index}].${type}`, count) : Main._app.$d(date, 'time');
            }

            Main.vueApp = vueApp;
            Main._app = <App>vueApp.mount('#app');

            // Make stores globally available in html:
            vueApp.config.globalProperties.tr = tr;
            vueApp.config.globalProperties.$store = Store;
            vueApp.config.globalProperties.$const = constants;
            vueApp.config.globalProperties.$app = Main._app;
            vueApp.config.globalProperties.$settings = Settings;
            vueApp.config.globalProperties.$baseUrl = (url: string = ''): string => {
                return (Settings.appBaseUrl + url).replace(/[\/]{2,}/, '\/');
            };   // Add base path and remove any double /;
            vueApp.config.globalProperties.$helper = TemplateHelper;
            vueApp.config.globalProperties.$routes = Routes;
            vueApp.config.globalProperties.$trans = Translations.i18n.global;

            this.bootIntercom();

            // Init Pusher via Laravel Echo:
            if (Store.user.bearerToken) {  // No need to init if the user is not logged in since the page will be refreshed anyway once he does
                window.Pusher = Pusher;
                window.Echo = new Echo({
                    broadcaster: 'pusher',
                    key: import.meta.env.VITE_PUSHER_KEY,
                    cluster: import.meta.env.VITE_PUSHER_CLUSTER,
                    forceTLS: true,
                    encrypted: true,
                    authEndpoint: Settings.apiUrl + 'broadcasting/auth',
                    auth: {
                        headers: {
                            authorization: 'Bearer ' + Store.user.bearerToken,
                        }
                    }
                });
                window.Echo.connector.pusher.connection.bind('connected', function () {
                    // Enable for eg `dontBroadcastToCurrentUser`:
                    Store.app.apiService.defaultHeaders['X-Socket-ID'] = window.Echo.socketId();
                });

                const companyUuid: Uuid = Store.user.currentCompanyUuid;
                if (companyUuid) {
                    // Start listening for new messages:
                    const companyPrivateChannel: PusherPrivateChannel = PusherHelper.enterChannel(PusherChannels.COMPANY_PRIVATE, companyUuid);
                    companyPrivateChannel.listen(PusherEvents.UNREAD_MESSAGE, (result: UnreadMessagePayload): void => {
                        Store.company.unreadMessagesCount = result.companyUnreadMessagesCount;

                        this.initiateFavicon(Store.company.unreadMessagesCount);

                        if (result.messageUuid) {
                            const payload: Record<string, any> = {};
                            payload.uuid = result.messageUuid;

                            let promise: Bluebird<any> = Main.callApi('thread/message', RequestMethods.GET, payload, MessageDTO, false);
                            promise = promise.then((result: MessageDTO) => {
                                const body = (result.body) ?
                                    Main.trans.t('app.desktopNotifications.body.new_message', {varCompany: result.sender.name, varMessage: result.body.value}) :
                                    Main.trans.t('app.desktopNotifications.body.attachment', {varCompany: result.sender.name});

                                const notification: Notification = new Notification(
                                    Main.trans.t('app.desktopNotifications.title.new_message'), {
                                        body: body,
                                        tag: result.threadUuid,
                                        icon: manufyLogo,
                                    }
                                );

                                notification.onclick = function (): void {
                                    window.focus();
                                    Routes.resolveLink({name: 'chat', query: {threadUuid: result.threadUuid}}, null, true);
                                    notification.close();
                                };
                            });
                        }
                    });

                    // Start pinging others that this company is online:
                    const companyPublicChannel: PusherPrivateChannel = PusherHelper.enterChannel(PusherChannels.COMPANY_PUBLIC, companyUuid);
                    window.setInterval(() => {
                        companyPublicChannel.whisper(PusherEvents.ONLINE, {});
                    }, Settings.companyPingInterval);
                }
            }

            // Check url query to see if any modals need to be opened:
            let promise: Promise<void> = Routes.router.isReady();
            promise = promise.then(() => {
                const query: LocationQuery = Main.app.$route.query;

                if (query.token && query.email) {
                    Main._app.openPasswordResetModal(<string>query.token, <string>query.email);
                }

                if (query.startVerification) {
                    Main._app.openKYCModal();
                }
            });
        };

        const locale: string = Store.app.locale;
        Store.app.apiService.defaultHeaders['man-locale'] = locale;
        const promise = Translations.loadLocale(locale);
        if (promise) {
            promise.then(handleTranslationsLoaded);
        } else {
            handleTranslationsLoaded();
        }
    }

    /**
     * Make an api call.
     */
    public static showError(message: string, title: string = null, settings: any = null): void {
        console.error('Error: ' + message);
    }

    /**
     * Make an api call.
     * @param route The route to call
     * @param method Request method
     * @param payload Optional payload
     * @param castType Either a Castable class or a Castable class wraped in a 1-item array.
     * @param showLoading Determine whether the NProgress progress bar shows on top of the screen.
     *  In case of Castable class the result will be cast to this type.
     *  In case of Castable class inside an array the result will be cast to the class defined in the first array item.
     *  Leave this property empty in case the result should'n be parsed.
     */
    public static callApi<T>(route: string, method: RequestMethods = RequestMethods.GET, payload: any = null, castType: typeof Castable | typeof Castable[] = null, showLoading: boolean = true): Bluebird<T> {
        if (showLoading) {
            NProgress.start();
        }
        let promise: Bluebird<unknown> = Store.app.apiService.request<T>(route, method, payload, {});
        if (castType) { // Cast the result:
            promise = promise.then((result: unknown): Castable | ListResult<Castable> => {
                if (castType instanceof Array) {
                    const cast: ListResult<Castable> = Object.assign(new ListResult(), result);
                    cast.pagination = PaginationDTO.cast(cast.pagination);
                    cast.items = cast.items.map((item: any) => {
                        return castType[0].cast(item);
                    });
                    return cast;
                } else {
                    return castType.cast(result);
                }
            });
        }
        if (showLoading) {
            promise = promise.finally(NProgress.done);
        }
        return <Bluebird<T>>promise;
    }

    /**
     * Boot Intercom with at least the base settings set.
     */
    public static bootIntercom(hideDefaultLauncher: boolean = false): void {
        const intercomSettings = {
            app_id: Settings.intercomKey,
            email: Store.user.user?.email,
            user_id: Store.user.user?.uuid,
            custom_launcher_selector: '.m-intercom-launcher',
            action_color: '#75BD8F',
            hide_default_launcher: hideDefaultLauncher,
        };

        window.Intercom('boot', intercomSettings);
    }

    public static bootSentry(app: any): void {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }

    /**
     * Manually open the Intercom console.
     */
    public static openIntercom(): void {
        window.Intercom('show');
    }

    /**
     * Open the VAT validation Modal.
     */
    public static openVatValidationModal(): void {
        Main.app.openVatValidationModal();
    }

    /**
     *
     */
    public static openStripeCustomerPortal(): void {
        StripeHelper.openCustomerPortal();
    }

    /**
     * Copy the given text to the clipboard.
     */
    public static copyToClipboard(text: string): void {
        const textarea: HTMLTextAreaElement = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        textarea.focus();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }

    public static requestNotificationPermission(): boolean {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission().then(function (permission: NotificationPermission): void {
                if (permission === 'granted') {
                    Routes.refreshPage();
                }
            });
        } else {
            return true;
        }
    }

    public static initiateFavicon(unreadMessageCount: number): void {
        const link = document.querySelector("link[rel*='shortcut icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = (unreadMessageCount > 0) ? '/frontend/favicon_notification_indicator.ico' : '/frontend/favicon.ico';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    public static async getRecaptchaToken(): Promise<string> {
        return await window.grecaptcha?.execute(Settings.recaptchaSiteKey, {action: 'submit'}) ?? '__someDummyToken__';
    }

    /**
     * API Callback function to handle general errors.
     */
    private static handleUnhandledRejection(event: PromiseRejectionEvent): void {
        if (event.reason instanceof WebServiceError) {
            event.preventDefault(); // Prevent uncaught promises from writing errors in the console
            const error: WebServiceError = event.reason;
            switch (error.code) {
                case ApiResponseCodes.TERMS_NOT_ACCEPTED:
                case ApiResponseCodes.TERMS_NOT_ACCEPTED_BUYER:
                case ApiResponseCodes.TERMS_NOT_ACCEPTED_SELLER:
                    Routes.resolveLink({name: 'userTermsEdit'});
                    return;
                case ApiResponseCodes.BUYER_NOT_SUBSCRIBED:
                    Routes.resolveLink({name: 'buyerSubscriptionList'});
                    return;
                case ApiResponseCodes.SELLER_NOT_SUBSCRIBED:
                    Routes.resolveLink({name: 'sellerSubscriptionList'});
                    return;
                case ApiResponseCodes.INTERNAL_SERVER_ERROR:
                    Main.showError(Main.trans.t(Lang.t.errors.unknown, {message: error.message}), Main.trans.t(Lang.t.errors.title));
                    return;
                case ApiResponseCodes.UNAUTHORIZED:
                    Store.user.refreshUser();
                    return;
                case ApiResponseCodes.GOOGLE_LOGIN_FAILED:
                case ApiResponseCodes.FORBIDDEN:
                    if (Routes.currentRoute.name == 'dashboard') {
                        Main.showError(Main.trans.t(Lang.t.errors.callForbidden), Main.trans.t(Lang.t.errors.title));
                    } else if (Routes.currentRoute.name == 'companySubscriptionPending') {
                        Routes.resolveLink({name: 'companySubscriptionPending'});
                    } else {
                        Routes.resolveLink({name: 'dashboard'});
                    }
                    return;
                case ApiResponseCodes.INVALID_ARGUMENTS:
                    let msg: string = '';
                    for (let field in error.data.errors) {
                        msg += '- ' + error.data.errors[field].join('<br />') + '<br />';
                    }
                    Main.showError(Main.trans.t(Lang.t.errors.invalidArguments, {message: msg}), Main.trans.t(Lang.t.errors.title), {timeOut: 0, extendedTimeOut: 0, closeButton: true});
                    return;
                case ApiResponseCodes.USER_DEACTIVATED:
                    Main.app.toast.danger(Main.trans.t(Lang.t.app.toast.error.userDeactivated.message), Main.trans.t(Lang.t.app.toast.error.userDeactivated.title));
                    Routes.resolveLink({name: 'dashboard'});
                    return;
                default:
                    Main.showError(Main.trans.t(Lang.t.errors.unknown, {message: error.message}), Main.trans.t(Lang.t.errors.title), (error.message) ? {timeOut: 0, extendedTimeOut: 0, closeButton: true} : undefined);
                    return;
            }
            // throw error;
        }
    }

}
