import {Component, Prop} from 'vue-facing-decorator';
import {StyleSize} from '@/app/constants/Constants';
import {FormItem} from '@/app/views/components/form/FormItem';

@Component({
    emits: [
        'handleInput',
    ],
})
export default class FormFieldNumber extends FormItem<number> {

    @Prop({type: Number, default: null})
    declare public modelValue: number;

    @Prop({type: Number, default: null})
    public readonly min: number = null;

    @Prop({type: Number, default: null})
    public readonly max: number = null;

    @Prop({type: Number, default: null})
    public readonly step: number = null;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: Boolean, default: false})
    declare public rtl: boolean;

    @Prop({type: String, default: StyleSize.MEDIUM})
    declare public size: StyleSize;

    public handleInput(evt: Event): void {
        const target: HTMLInputElement = evt.target as HTMLInputElement;

        // Don't update if the current component value equals the new input!
        // If a `.` is typed at the end of the field it won't be in either target.value or target.valueAsNumber so in this case we don't want to trigger the updateValue method (which would undo the typed `.` )
        // For a possible hacky solution see: https://stackoverflow.com/questions/43346284/cannot-get-raw-value-from-including-dot-from-html-input-type-number
        if (!target.validity.badInput && this.value != target.valueAsNumber) {
            this.updateValue(target.valueAsNumber);
        }

        this.$emit('handleInput', target.valueAsNumber);
    }

    public updateValue(value: number): void {
        if (isNaN(value)) {
            value = null;
        } else {
            // UX-wise clipping won't work for min!
            if (this.max != null && value > this.max) {
                value = this.max;
            }
            if (this.min != null && value < this.min) {
                value = this.min;
            }
            if (this.step) {
                // TODO: Clip value to actual step
                // For now just use the simple clipping of decimal places:
                value = Number(value.toFixed(this.step.decimalPlaces()));
            }
        }
        super.updateValue(value);
    }

}
