import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from "@/app/constants/styles/StyleAppearance";
import {StyleShapes} from "@/app/constants/styles/StyleShapes";
import {StyleSize} from "@/app/constants/styles/StyleSize";
import {StyleVariants} from "@/app/constants/styles/StyleVariants";
import {Main} from "@/app/Main";

@Component({})
export default class ManufyValueIcons extends Vue {

    @Prop({type: Array, default: []})
    public readonly values: string[];

    @Prop({type: Number, default: 3})
    public readonly maxIcons: number;

    @Prop({type: String, default: StyleAppearance.PRIMARY})
    public readonly appearance: StyleAppearance;

    @Prop({type: Boolean, default: true})
    public readonly isLight: boolean;

    @Prop({type: String, default: StyleShapes.SQUARE})
    public readonly shape: StyleShapes;

    @Prop({type: String, default: StyleVariants.OUTLINE})
    public readonly variant: StyleVariants;

    @Prop({type: [String, Object], default: StyleSize.MEDIUM})
    public readonly size: StyleSize | Object;

    public openList(): void {
        Main.app.openManufyValueListModal(this.values);
    }

}
