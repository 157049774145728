<script lang="ts" src="./CancelButton.ts"></script>


<template>
  <m-button
    :appearance="$const.StyleAppearance.NEUTRAL"
    :size="size"
    :to="to"
    :variant="$const.StyleVariants.GHOST"
    @click="cancel()"
  >
    <!-- This is a list of all cancel labels used:
         - Cancel
         - Back
         - [...]
    -->
    {{ $trans.t(label) }}
  </m-button>
</template>


<style lang="scss" scoped>
</style>
