<script lang="ts" src="./Link.ts"></script>


<template>

  <a :class="class" :href="href" @click.prevent="handleClick($event)">
    <slot></slot>
  </a>

</template>


<style lang="scss" scoped>

</style>
