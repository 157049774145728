import {Component, Prop} from 'vue-facing-decorator';
import {FormItem} from '@/app/views/components/form/FormItem';
import {StylePosition, StyleSize} from '@/app/constants/Constants';
import FormSelectCountryCodePicker from '@/app/views/components/form/FormSelectCountryCodePicker';
import {AutocompleteStandard} from "@/app/constants/AutocompleteStandard";

type CompanyPhoneFormData = {
    phoneNumber: number,
    phoneCountryCode: string,
}

@Component({
    emits: [
        'update:phoneNumber',
        'update:phoneCountryCode',
    ],
})
export default class FormFieldPhoneNumber extends FormItem<CompanyPhoneFormData> {

    public readonly AutocompleteStandard = AutocompleteStandard;

    @Prop({type: Number, default: null})
    public readonly phoneNumber: number = null;

    @Prop({type: String, default: null})
    public readonly phoneCountryCode: string = null;

    @Prop({type: String, default: null})
    public readonly dusk: string = null;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    // appearance settings:
    @Prop({type: Boolean, default: false})
    public readonly rtl: boolean;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: string;

    @Prop({type: String, default: StylePosition.BOTTOM})
    public readonly direction: StylePosition;

    declare public $refs: {
        phoneCountryCode: FormSelectCountryCodePicker;
        phoneNumber: FormSelectCountryCodePicker;
    };

    public get valueStr(): number {
        return this.value.phoneNumber ? this.value.phoneNumber : null;
    }

    public created(): void {
    }

    public focus(event: MouseEvent): void {
        this.$refs.phoneCountryCode.handleFieldClick(event);
    }

    public updateCountryCode(countryCode: string): void {
        this.$emit('update:phoneCountryCode', countryCode)
    }

    public updatePhoneNumber(phoneNumber: number): void {
        this.$emit('update:phoneNumber', phoneNumber)
    }
}
