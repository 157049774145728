import Bluebird from 'bluebird';
import {Component, Vue} from 'vue-facing-decorator';
import {KeyboardCode} from '@/app/constants/KeyboardCode';
import {StyleSize} from '@/app/constants/styles/StyleSize';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';

@Component({})
export default class Alert extends Vue {

    public readonly flags = {
        show: false,
    };

    public width: StyleSize;
    public appearance: StyleAppearance;
    public message: string = null;
    public title: string = null;
    public btnALabel: string = null;
    public btnBLabel: string = null;
    private resolveHandler: (thenableOrResult?: any) => void = null;
    private rejectHandler: (error?: any) => void = null;
    private keyUpHandler: (event: KeyboardEvent) => void = null;

    public created(): void {
        this.keyUpHandler = this.handleKeyUp.bind(this);    // Needs to be set once to add and remove the same function instance as event listener!
    }

    public mounted(): void {
    }

    public show(message: string, title: string = null, btnALabel: string = null, btnBLabel: string = null, appearance: StyleAppearance = null, width: StyleSize = null): Bluebird<void> {
        this.flags.show = true;
        this.message = message;
        this.title = title;
        this.btnALabel = btnALabel;
        this.btnBLabel = btnBLabel;

        this.appearance = (appearance) ? appearance : StyleAppearance.PRIMARY;
        this.width = (width) ? width : StyleSize.SMALL;

        document.addEventListener('keyup', this.keyUpHandler);

        const promiseCallback = (resolve: (thenableOrResult?: any) => void, reject: (error?: any) => void, _onCancel?: (callback: () => void) => void): void => {
            this.resolveHandler = resolve;
            this.rejectHandler = reject;
        };
        return new Bluebird(promiseCallback);
    }

    public hide(): void {
        document.removeEventListener('keyup', this.keyUpHandler);
        this.flags.show = false;
    }

    public handleBtnAClick(): void {
        this.resolveHandler();
        this.hide();
    }

    /**
     * Even if the B button doesn't need a handler it's best to always add a catch to prevent errors in the console
     *  let promise = Main.app.alert.show('Yes or No', null, 'Yes', 'No');
     *  promise = promise.catch((err:Error) => { if (err) { throw err; }; });   // The error is empty in case the B-button was pressed, but it could contain an actual error thrown in the `then` resolveHandler.
     */
    public handleBtnBClick(): void {
        this.rejectHandler();
        this.hide();
    }

    private handleKeyUp(event: KeyboardEvent): void {
        switch (event.code) {
            case KeyboardCode.ENTER:
                this.handleBtnAClick();
                break;
            case KeyboardCode.ESCAPE:
                if (this.btnBLabel) {
                    this.handleBtnBClick();
                } else {
                    this.handleBtnAClick();
                }
                break;
        }
    }
}
