/**
 * Style: Variant types.
 */
export const enum StyleVariants {

    DEFAULT = '',
    TEXT = 'text',
    OUTLINE = 'outline-variant',
    GHOST = 'ghost',

}
