import Bluebird from 'bluebird';
import {RequestMethods} from '../constants/RequestMethods';
import {WebServiceError} from '../errors/WebServiceError';

// Enable cancellation
Bluebird.config({
    cancellation: true,
});

/**
 *
 * @author Arno van Oordt
 * @version 4.1.2
 */
export class WebService {

    private _baseURL: string = null;
    private _defaultTimeout: number = null;
    private _forceMethod: RequestMethods = null;

    /**
     * Create a new WebService instance.
     * @param baseUrl The base url that will be used for each request.
     * @param defaultHeaders The headers that will be send with each request.
     * @param forceMethod Force every request to be the given method. This can be handy for testing purposes.
     */
    constructor(baseUrl: string = '', defaultHeaders: { [name: string]: string } = null, forceMethod: RequestMethods = null, defaultTimeout: number = null) {
        this._baseURL = baseUrl;
        this._defaultHeaders = (defaultHeaders) ? defaultHeaders : {};
        this._forceMethod = forceMethod;
        this._defaultTimeout = defaultTimeout;
    }

    private _defaultHeaders: { [name: string]: string } = null;

    /**
     * The default headers that will be used for each request.
     */
    public get defaultHeaders(): { [name: string]: string } {
        return this._defaultHeaders;
    }

    public set defaultHeaders(defaultHeaders: { [name: string]: string }) {
        this._defaultHeaders = defaultHeaders;
    }

    /**
     * Make a fech request.
     * @param method RequestMethods enum.
     * @param url The url to use.
     * @param payload The optional payload.
     * @param requestHeaders Optional additional headers.
     * @returns Bluebird.
     */
    public static request<T>(url: string, method: RequestMethods = RequestMethods.GET, payload: any = null, requestHeaders: { [name: string]: string } = {}, timeout: number = null): Bluebird<T> {
        if (url.indexOf('./') == 0) {    // Use the website root in case the url starts with ./
            url = url.replace('.', document.location.origin);
        }

        if (!(payload instanceof FormData)) {
            // Parse payload:
            const query: string[] = WebService._parsePayload(payload);

            if (method == RequestMethods.POST || method == RequestMethods.PATCH || method == RequestMethods.DELETE) {
                // requestHeaders['Content-Type'] = 'application/json'; // Causes CORS errors
                // payload = JSON.stringify(payload);
                requestHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
                payload = query.join('&');
            } else if (method == RequestMethods.GET) {
                if (query.length > 0) {
                    url += (url.includes('?')) ? '&' : '?';
                    url += query.join('&');
                }
                payload = null;
            }
        }

        const abortController = new AbortController();
        let promise: Promise<Response | Error> = window.fetch(url, {
            method: method,
            body: payload,
            signal: abortController.signal,
            headers: requestHeaders,
            mode: 'cors',
        });
        promise = promise.then((response: Response) => {
            let resp = null;
            if (response.status == 204) {   // Empty response
                resp = null;
            } else if (response.status >= 400) {
                resp = response.json().then((errorData: any) => {
                    throw new WebServiceError(response, errorData);
                });
            } else {
                const contentType: string = response.headers.get('content-type');
                if (contentType == 'application/json') {
                    resp = response.json();
                } else {  // Any other type will be handled as blob
                    resp = response;
                }
            }
            return resp;
        });
        // Stop propagating in case the promise was canceled/aborted:
        promise = promise.catch((err: Error | WebServiceError) => {
            if (abortController.signal.aborted) {
                return null;
            } else if (err instanceof WebServiceError) {   // Some WebServiceErrorjust created by thee `.then` handler
                throw err;
            } else { // Some unknown error
                throw new WebServiceError(null, err);   // Can't create Response manually :(
            }
        });

        const promiseCallback = (resolve: (thenableOrResult?: any) => void, reject: (error?: any) => void, onCancel?: (callback: () => void) => void): void => {
            promise.then(resolve, reject);
            onCancel((): void => {
                abortController.abort();
            });
        };

        return new Bluebird<T>(promiseCallback);
    }

    /**
     * Make an ajax GET request.
     * @param url The url to use.
     * @param payload The optional payload.
     * @param requestHeaders Optional additional headers.
     * @returns Bluebird.
     */
    public static get<T>(url: string, payload: any = null, requestHeaders: { [name: string]: string } = null, timeout: number = null): Bluebird<T> {
        return WebService.request(url, RequestMethods.GET, payload, requestHeaders, timeout);
    }

    /**
     * Make an ajax POST request.
     * @param url The url to use.
     * @param payload The optional payload.
     * @param requestHeaders Optional additional headers.
     * @returns Bluebird.
     */
    public static post<T>(url: string, payload: any = null, requestHeaders: { [name: string]: string } = null, timeout: number = null): Bluebird<T> {
        return WebService.request(url, RequestMethods.POST, payload, requestHeaders, timeout);
    }

    /**
     * Recursively parse the given payload and return the query.
     */
    private static _parsePayload(payload: any, path: string = null): string[] {
        const query: string[] = [];
        for (const key in payload) {
            if (!payload.hasOwnProperty(key)) {
                continue;
            }
            const value: any = payload[key];
            if (value === null || value === undefined) {
                continue;
            }

            const keyPath: string = (!path) ? key : (path + '[' + key + ']');

            if (value instanceof Date) {
                query.push(encodeURIComponent(keyPath) + '=' + encodeURIComponent(value.toISOString()));
            } else if (typeof value === 'object') { // Object or Array
                query.push(...WebService._parsePayload(value, keyPath));
            } else {
                query.push(encodeURIComponent(keyPath) + '=' + encodeURIComponent(value));
            }
        }
        return query;
    }

    public request<T>(url: string, method: RequestMethods = RequestMethods.GET, payload: any = null, requestHeaders: { [name: string]: string } = null, timeout: number = null): Bluebird<T> {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = this._baseURL + url;
        }

        // Add default headers to request headers:
        if (!requestHeaders) {
            requestHeaders = {};
        }
        for (const key in this._defaultHeaders) {
            if (!this._defaultHeaders.hasOwnProperty(key)) {
                continue;
            }
            requestHeaders[key] = this._defaultHeaders[key];
        }

        if (this._forceMethod !== null) {
            method = this._forceMethod;
        }

        timeout = (timeout != null) ? timeout : this._defaultTimeout;
        return WebService.request(url, method, payload, requestHeaders, timeout);
    }

    /**
     * Make an ajax GET request.
     * @param url The url to use.
     * @param payload The optional payload.
     * @param requestHeaders Optional additional headers.
     * @returns Bluebird.
     */
    public get<T>(url: string, payload: any = null, requestHeaders: { [name: string]: string } = {}, timeout: number = null): Bluebird<T> {
        return this.request(url, RequestMethods.GET, payload, requestHeaders, timeout);
    }

    /**
     * Make an ajax POST request.
     * @param url The url to use.
     * @param payload The optional payload.
     * @param requestHeaders Optional additional headers.
     * @returns Bluebird.
     */
    public post<T>(url: string, payload: any = null, requestHeaders: { [name: string]: string } = {}, timeout: number = null): Bluebird<T> {
        return this.request(url, RequestMethods.POST, payload, requestHeaders, timeout);
    }
}
