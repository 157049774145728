<script lang="ts" src="./LoadingSpinner.ts"></script>


<template>

  <div v-show="loading" class="p-8 relative xl:p-12">
    <div :class="clss"></div>
  </div>

</template>


<style lang="scss" scoped>

.loading-spinner {
  @apply animate-spin absolute top-1/2 left-1/2 z-10 rounded-full origin-center box-border;

  &.x-small {
    @apply w-4 h-4 -mt-2 -ml-2 border-3;
  }

  &.small {
    @apply w-6 h-6 -mt-3 -ml-3 border-3;
  }

  &.medium {
    @apply w-8 h-8 -mt-4 -ml-4 border-4;
  }

  &.large {
    @apply w-10 h-10 -mt-5 -ml-5 border-4;
  }

  &.x-large {
    @apply w-1/2 h-12 -mt-6 -ml-6 border-4;
  }

  &.neutral {
    @apply border-neutral-60;
    border-top-color: transparent;
  }

  &.primary {
    @apply border-blue-100;
    border-top-color: transparent;
  }

  &.secondary {
    @apply border-green-100;
    border-top-color: transparent;
  }

  &.warning {
    @apply border-yellow-75;
    border-top-color: transparent;
  }

  &.danger {
    @apply border-red-100;
    border-top-color: transparent;
  }

  &.info {
    @apply border-teal-75;
    border-top-color: transparent;
  }

  &.help {
    @apply border-purple-100;
    border-top-color: transparent;
  }
}

</style>
