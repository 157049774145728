<script lang="ts" src="./ProductListSupportBlock.ts"></script>

<template>
  <li class="flex flex-col flex-1 space-y-4 auto-rows-max col-span-full lg:grid lg:grid-cols-3 lg:space-y-0 lg:gap-6 sm:col-span-1 lg:col-span-full 2xl:grid-cols-4">

    <div
      class="h-full flex flex-col col-span-full border rounded-xl overflow-hidden bg-green-25 border-green-75 sm:bg-neutral-0 sm:border-neutral-20 lg:grid lg:grid-cols-3 lg:gap-6 lg:col-span-full lg:bg-neutral-0 lg:border-neutral-20 xl:col-span-2 2xl:grid-cols-3 2xl:col-span-3">

      <div class="flex flex-col flex-1 items-center self-center p-4 col-span-full sm:items-start sm:col-span-1 lg:items-start lg:col-span-2 lg:p-6">

        <div class="flex-1">

          <h6 class="mb-2 text-lg text-center leading-tight sm:text-xl sm:text-left">
            {{ $trans.t('components.createProjectSupportBlock.title') }}
          </h6>
          <p class="mb-6 text-base text-center leading-tight sm:text-lg sm:text-left">
            {{ $trans.t('components.createProjectSupportBlock.description') }}
          </p>
        </div>

        <div class="w-full flex flex-wrap justify-center -mt-3 -ml-3 text-center sm:flex-col sm:flex-nowrap sm:justify-normal sm:m-0 sm:space-y-3 lg:flex-row lg:flex-wrap lg:-mt-3 lg:-ml-3">
          <m-button
            :appearance="$const.StyleAppearance.SECONDARY"
            :iconBefore="{ name: 'circle-plus', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
            :to="{ name: 'rfqWizard' }"
            class="mt-3 ml-3 sm:ml-0 lg:ml-3"
          >
            {{ $trans.t('app.buttonLabels.projectCreate') }}
          </m-button>
        </div>
      </div>

      <ul class="hidden sm:flex sm:flex-col sm:justify-center sm:p-4 sm:space-y-4 sm:order-first sm:bg-green-50 lg:flex lg:flex-col lg:col-span-1 lg:order-last">
        <li class="flex flex-col space-y-3">
          <span class="w-14 h-1.5 rounded-xl bg-green-75"></span>
          <span class="w-full h-10 border-4 rounded-xl border-green-75 bg-neutral-0"></span>
        </li>
        <li class="flex flex-col space-y-3">
          <span class="w-14 h-1.5 rounded-xl bg-green-400"></span>
          <span class="w-full h-10 border-4 rounded-xl border-green-400 bg-neutral-0"></span>
        </li>
        <li class="flex flex-col space-y-3">
          <span class="w-14 h-1.5 rounded-xl bg-green-75"></span>
          <span class="w-full h-10 border-4 rounded-xl border-green-75 bg-neutral-0"></span>
        </li>
      </ul>

    </div>

    <div class="hidden group hover:bg-smoke-0 transition-colors xl:flex xl:flex-col xl:items-center xl:justify-center xl:p-4 xl:relative xl:text-center xl:leading-tight xl:border xl:rounded-xl xl:bg-neutral-0 xl:col-span-1"
         @click="openIntercom()">
      <m-icon
        :solid="true"
        :type="$const.IconTypes.FONT_AWESOME"
        class="mb-4 text-4xl leading-none text-neutral-60 group-hover:text-neutral-80 transition-colors"
        name="headset"
      />

      <h6 class="mb-1 text-lg">{{ $trans.t('components.customerSupportBlock.title') }}</h6>
      <p class="mb-4 text-base">{{ $trans.t('components.customerSupportBlock.description') }}</p>

      <div class="flex">
        <m-button :appearance="$const.StyleAppearance.NEUTRAL_NEW" class="m-stretched-link">
          {{ $trans.t('app.buttonLabels.customerSupport') }}
        </m-button>
      </div>
    </div>

  </li>
</template>

<style lang="scss" scoped>

</style>
