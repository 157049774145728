import {Component, Watch} from 'vue-facing-decorator';
import {Settings} from '@/app/Settings';
import {AutocompleteStandard} from '@/app/constants/AutocompleteStandard';
import {IIcon} from '@/app/views/components/misc/Icon';
import {FormSelect} from '@/app/views/components/form/FormSelect';

export type FormSelectCountryCodePickerItem<T extends string | number | boolean> = { [key: string]: any; } & { id?: T, label?: string, countryCode?: string, icon?: IIcon, searchable?: boolean };

@Component({
    emits: [
        'open',
        'close',
        'updatePhoneCountryCode',
    ],
})
export default class FormSelectCountryCodePicker extends FormSelect {

    public readonly AutocompleteStandard = AutocompleteStandard;

    public override get filteredItems(): FormSelectCountryCodePickerItem<string>[] {
        if (this.filterStr) {
            const filterStr: string = this.filterStr.toLowerCase();
            return this.items.filter((value: FormSelectCountryCodePickerItem<string>): boolean => {
                if (!value.id || value.searchable === false) {    // Is group or non-searchable
                    return false;
                }

                const label: string = `${value.id} ${value.label} ${value.countryCode}`;
                return label.toLowerCase().includes(filterStr);
            });
        }

        return Array.from(this.items);  // Always make a copy to make sure the original array isn't changed
    }

    public get selectedFlagSrc(): string {
        return `${Settings.appPath}/images/country-flags/${this.selectedItems[0]?.countryCode?.toLowerCase()}.svg`;
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public override handleFieldClick(event: MouseEvent): void {
        super.handleFieldClick(event);

        this.filterStr = null;
    }

    @Watch('value')
    public updatePhoneCountryCode(_newValue: string, _oldValue: string): void {
        if (this.selectedItems[0]) {
            this.filterStr = this.selectedItems[0].id;
            this.$emit('updatePhoneCountryCode', this.selectedItems[0].id);
        }
    }

}
