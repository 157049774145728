import {Component, Prop, Vue} from 'vue-facing-decorator';
import {PublicCatalogueFabricDTO} from "@/app/dto/PublicCatalogueFabricDTO";

@Component({})
export default class FabricCard extends Vue {

    @Prop({type: Boolean, default: true})
    public readonly hasShadow: boolean;

    @Prop({type: Object, default: null})
    public readonly fabric: PublicCatalogueFabricDTO;

    public created(): void {
    }

    public mounted(): void {
    }

}

