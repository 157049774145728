/**
 * Enumeration of possible chat message types.
 */
export const enum ChatMessageTypes {

    INTERACTIVE = 'interactive',
    SYSTEM = 'system',
    TEXT = 'text',

}
