import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Settings} from '@/app/Settings';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {CollaborationDTO} from '@/app/dto/CollaborationDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {LocationDTO} from '@/app/dto/LocationDTO';
import {PurchaseOrderDTO} from '@/app/dto/PurchaseOrderDTO';
import {PurchaseOrderProductDTO} from '@/app/dto/PurchaseOrderProductDTO';
import {LegalDetailsDTO} from '@/app/dto/LegalDetailsDTO';
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {ListResult} from '@/app/utils/ListResult';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Uuid} from '@/types/Types';
import {PurchaseOrderStatus} from "@/app/constants/PurchaseOrderStatus";
import {PurchaseOrderShipmentStatus} from "@/app/constants/PurchaseOrderShipmentStatus";
import {PurchaseOrderPaymentStatus} from "@/app/constants/PurchaseOrderPaymentStatus";
import {Routes} from "@/app/Routes";
import {PurchaseOrderPaymentMethodTypes} from "@/app/constants/PurchaseOrderPaymentMethodTypes";
import {SystemMessageTypes} from "@/app/constants/SystemMessageTypes";
import {ProjectDTO} from "@/app/dto/ProjectDTO";

enum Pages {
    LIST = 'list',
    COLLABORATION_LIST = 'collaborationList',
    READ = 'read',
    OVERVIEW = 'overview',
    PRODUCTS = 'products',
    DELIVERY = 'delivery',
    PAYMENT = 'payment',
    SHIPMENT = 'shipment',
    DISPUTE = 'dispute',
    REVIEW = 'review',
    REVIEW_SENT = 'reviewSent',
    ATTACHMENTS = 'attachments',
}

enum States {
    LIST = 'list',
    COLLABORATION_LIST = 'collaborationList',
    READ = 'read',
    CREATING = 'creating',
    UPDATING_COSTS = 'updatingCosts',
    PAYMENT = 'payment',
    SHIPMENT = 'shipment',
    DISPUTE = 'dispute',
    REVIEW = 'review',
    REVIEW_SENT = 'reviewSent',
    ATTACHMENTS = 'attachments',
}

type AttachmentsFormModel = {
    collaborationUuid: Uuid,
    products: BuyerProductFormModel[],
    remark?: string,
    attachments?: FileDTO[],
};

type BuyerProductsFormModel = {
    collaborationUuid: Uuid,
    products: BuyerProductFormModel[],
    remark?: string,
    attachments?: FileDTO[],
};

type BuyerProductFormModel = {
    productUuid: Uuid,
    quantity: number,
    size: string,
};

type BuyerDeliveryFormModel = {
    locationUuid?: Uuid,
    _hasSameBillingAddress: boolean,
    billingLocationUuid?: Uuid,
    useOwnCourier?: boolean,
    buyerCourierName?: string,
    buyerCourierNumber?: string,
    preferredDeliveryDate?: Date,
};

type SellerCostsFormModel = {
    collaborationUuid: Uuid,
    extraProductionCosts: number,
    shippingCosts: number,
    vatPercentage: number,
    products: SellerProductCostsFormModel[],
};

type SellerProductCostsFormModel = {
    purchaseOrderProductUuid: Uuid,
    productName: string,
    unitPrice: number,
    quantity: number,
    size: string,
};

type SellerDeliveryFormModel = {
    locationUuid?: Uuid,
    _hasSameBillingAddress: boolean,
    billingLocationUuid?: Uuid,
    estimatedShippingDate?: Date,
    cocNumber?: string,
    companyLegalName?: string,
    agreeOppTerms: boolean,
};

type SellerShipmentFormModel = {
    shipmentDate?: Date,
    shipmentCourierName?: string,
    shipmentTrackAndTrace?: string,
};

type ReviewFormModel = {
    score?: number,
    body?: string,
    hasNameDisplayed?: boolean,
};

type BuyerDisputeFormModel = {
    message?: string,
};

type BuyerPaymentFormModel = {
    paymentMethod?: string,
};

@Component({})
export default class PurchaseOrderModal extends Modal {

    // Make enums accessible in template:
    public readonly Pages = Pages;
    public readonly States = States;
    public readonly PurchaseOrderPaymentTypes = PurchaseOrderPaymentMethodTypes;

    declare public readonly $refs: {
        dialogBody: HTMLDivElement;
    };

    public readonly flags = {
        ...super.flags,
        loading: false,
        hasScrollbar: false,
        alreadyAgreedToOppTerms: false,
    };

    public page: Pages = null;
    public state: States = null;
    public buttonOption: number = 0;

    public scrollbarWidth: number = null;
    public mainContentScrollHeight: number = 0;

    // Variables for entire modal
    public collaborationUuid: Uuid = null
    public collaboration: CollaborationDTO = null;
    public type: string = null

    // Variable for read & list page
    public purchaseOrder: PurchaseOrderDTO = null;
    public purchaseOrders: PurchaseOrderDTO[] = [];

    // Variable for collaboration list page
    public projects: ProjectDTO[] = [];
    public companyUuid: Uuid = null;

    public buyerProductsFormData: FormData<BuyerProductsFormModel> = null;
    public buyerDeliveryFormData: FormData<BuyerDeliveryFormModel> = null;

    // Variables for update costs flow
    public sellerCostsFormData: FormData<SellerCostsFormModel> = null;
    public sellerDeliveryFormData: FormData<SellerDeliveryFormModel> = null;

    public legalDetails: LegalDetailsDTO = null;

    // Variables for dispute & shipment flow
    public buyerDisputeFormData: FormData<BuyerDisputeFormModel> = null;
    public sellerShipmentFormData: FormData<SellerShipmentFormModel> = null;

    // Variables for review flow
    public reviewFormData: FormData<ReviewFormModel> = null;

    // Variables for payment flow
    public buyerPaymentFormData: FormData<BuyerPaymentFormModel> = null;

    public attachmentsFormData: FormData<AttachmentsFormModel> = null;

    public selectedLocationStr: string = '';
    public selectedBillingLocationStr: string = '';

    public readonly fixedFees: Array<string, number> = {
        'bank_transfer': 0,
        'ideal': 0.28,
        'giropay': 0.40,
        'bcmc': 0.35,
        'sofort': 0.25,
        'paypal': 0.15,
        'creditcard': 0.25,
    };

    public readonly percentageFees: Array<string, number> = {
        'bank_transfer': 0,
        'ideal': 0,
        'giropay': 1.5,
        'bcmc': 0,
        'sofort': 1.4,
        'creditcard': 2,
        'paypal': 3.5,
    };

    public get statusIndicatorShow(): boolean {
        if (!this.purchaseOrder) {
            return false;
        }

        switch (this.purchaseOrder.status) {
            case PurchaseOrderStatus.WAITING_FOR_COST_ESTIMATION:
            case PurchaseOrderStatus.WAITING_FOR_KYC:
                return Store.user.isBuyer;
            case PurchaseOrderStatus.WAITING_FOR_PAYMENT:
                return (Store.user.isBuyer && this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.PENDING) ||
                    Store.user.isSeller;
            case PurchaseOrderStatus.WAITING_FOR_SHIPMENT:
                return (Store.user.isBuyer && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_SENT) ||
                    (Store.user.isSeller && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_RECEIVED);
            case PurchaseOrderStatus.COMPLETED:
                return this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.COMPLETED;
            case PurchaseOrderStatus.BUYER_CANCELLED:
            case PurchaseOrderStatus.BUYER_DECLINED:
            case PurchaseOrderStatus.SELLER_CANCELLED:
            case PurchaseOrderStatus.SELLER_DECLINED:
                return true;
        }

        return false;
    }

    public get statusIndicatorIcon(): string {
        if (this.purchaseOrder.status === PurchaseOrderStatus.BUYER_DECLINED || this.purchaseOrder.status === PurchaseOrderStatus.SELLER_DECLINED) {
            return 'circle-minus';
        }

        if (this.purchaseOrder.status === PurchaseOrderStatus.COMPLETED) {
            return 'circle-check'
        }

        return 'circle-exclamation';
    }

    public get buttonBShow(): boolean {
        switch (this.state) {
            case States.CREATING:
            case States.UPDATING_COSTS:
            case States.SHIPMENT:
            case States.DISPUTE:
            case States.REVIEW:
            case States.REVIEW_SENT:
            case States.ATTACHMENTS:
                switch (this.page) {
                    case Pages.PRODUCTS:
                    case Pages.DELIVERY:
                    case Pages.OVERVIEW:
                    case Pages.SHIPMENT:
                    case Pages.DISPUTE:
                    case Pages.REVIEW:
                    case Pages.REVIEW_SENT:
                    case Pages.ATTACHMENTS:
                        return true;
                }
                break;
            case States.READ:
                if (!this.purchaseOrder || !Store.user.isBuyer) {
                    break;
                }

                switch (this.purchaseOrder.status) {
                    case PurchaseOrderStatus.PRICE_NEGOTIATION:
                        return true;
                    case PurchaseOrderStatus.WAITING_FOR_PAYMENT:
                        return (this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.OPEN);
                    case PurchaseOrderStatus.WAITING_FOR_SHIPMENT:
                        return (this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_RECEIVED && !this.purchaseOrder.ticketUuid);
                }
        }

        return false;
    }

    public get buttonBLabel(): string {
        switch (this.state) {
            case States.CREATING:
            case States.UPDATING_COSTS:
            case States.SHIPMENT:
            case States.DISPUTE:
            case States.REVIEW:
            case States.REVIEW_SENT:
            case States.ATTACHMENTS:
                switch (this.page) {
                    case Pages.PRODUCTS:
                    case Pages.SHIPMENT:
                    case Pages.DISPUTE:
                    case Pages.REVIEW:
                        return Main.trans.t(Lang.t.app.labels.cancel);
                    case Pages.DELIVERY:
                    case Pages.OVERVIEW:
                    case Pages.REVIEW_SENT:
                    case Pages.ATTACHMENTS:
                        return Main.trans.t(Lang.t.app.labels.back);
                }
                break;
            case States.READ:
                if (!this.purchaseOrder || !Store.user.isBuyer) {
                    break;
                }

                switch (this.purchaseOrder.status) {
                    case PurchaseOrderStatus.PRICE_NEGOTIATION:
                        return Main.trans.t(Lang.t.app.labels.decline);
                    case PurchaseOrderStatus.WAITING_FOR_PAYMENT:
                        if (this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.OPEN) {
                            return Main.trans.t(Lang.t.app.order.paymentDetails.instructions);
                        }
                        break;
                    case PurchaseOrderStatus.WAITING_FOR_SHIPMENT:
                        if (this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_RECEIVED && !this.purchaseOrder.ticketUuid) {
                            return Main.trans.t(Lang.t.app.order.startDispute.label);
                        }
                        break;
                }
        }

        return;
    }

    public get buttonAShow(): boolean {
        switch (this.state) {
            case States.CREATING:
            case States.UPDATING_COSTS:
            case States.SHIPMENT:
            case States.DISPUTE:
            case States.REVIEW:
            case States.PAYMENT:
            case States.ATTACHMENTS:
                switch (this.page) {
                    case Pages.PRODUCTS:
                    case Pages.DELIVERY:
                    case Pages.OVERVIEW:
                    case Pages.SHIPMENT:
                    case Pages.DISPUTE:
                    case Pages.REVIEW:
                    case Pages.PAYMENT:
                    case Pages.ATTACHMENTS:
                        return true;
                }
                break;
            case States.READ:
                if (!this.purchaseOrder) {
                    break;
                }

                if (Store.user.isBuyer) {
                    if (this.purchaseOrder.status === PurchaseOrderStatus.PRICE_NEGOTIATION) {
                        return true;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_PAYMENT && this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.OPEN) {
                        return true;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_SHIPMENT && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_RECEIVED) {
                        return true;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.COMPLETED && !this.purchaseOrder.review) {
                        return true;
                    }
                }

                if (Store.user.isSeller) {
                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_COST_ESTIMATION || this.purchaseOrder.status === PurchaseOrderStatus.PRICE_NEGOTIATION) {
                        return true;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_SHIPMENT && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_SENT) {
                        return true;
                    }

                    if (this.purchaseOrder.review) {
                        return true;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_KYC) {
                        return true;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_PAYMENT && this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.WAITING_FOR_MONEY_RECEIVED) {
                        return true;
                    }
                }
                break;
        }

        return false;
    }

    public get buttonALabel(): string {
        switch (this.state) {
            case States.CREATING:
            case States.UPDATING_COSTS:
            case States.SHIPMENT:
            case States.DISPUTE:
            case States.REVIEW:
            case States.PAYMENT:
            case States.ATTACHMENTS:
                switch (this.page) {
                    case Pages.PRODUCTS:
                    case Pages.DELIVERY:
                        return Main.trans.t(Lang.t.app.labels.next);
                    case Pages.OVERVIEW:
                    case Pages.SHIPMENT:
                    case Pages.DISPUTE:
                    case Pages.REVIEW:
                    case Pages.ATTACHMENTS:
                        return Main.trans.t(Lang.t.app.labels.send);
                    case Pages.PAYMENT:
                        return Main.trans.t(Lang.t.app.buttonLabels.startPayment);
                }
                break;
            case States.READ:
                if (!this.purchaseOrder) {
                    break;
                }

                if (Store.user.isBuyer) {
                    if (this.purchaseOrder.status === PurchaseOrderStatus.PRICE_NEGOTIATION) {
                        return Main.trans.t(Lang.t.app.labels.accept);
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_PAYMENT && this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.OPEN) {
                        return Main.trans.t(Lang.t.app.labels.makePayment);
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_SHIPMENT && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_RECEIVED) {
                        if (!this.purchaseOrder.ticketUuid) {
                            return Main.trans.t(Lang.t.app.order.shipmentReceived.label);
                        }
                        return Main.trans.t(Lang.t.app.order.endDispute.label);
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.COMPLETED && !this.purchaseOrder.review) {
                        return Main.trans.t(Lang.t.app.order.leaveReview.label);
                    }
                }

                if (Store.user.isSeller) {
                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_COST_ESTIMATION || this.purchaseOrder.status === PurchaseOrderStatus.PRICE_NEGOTIATION) {
                        return Main.trans.t(Lang.t.app.order.costs.update);
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_SHIPMENT && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_SENT) {
                        return Main.trans.t(Lang.t.app.order.shipmentSent.label);
                    }

                    if (this.purchaseOrder.review) {
                        return Main.trans.t(Lang.t.pages.chat.purchaseOrder.showReview);
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_KYC) {
                        return Main.trans.t(Lang.t.app.kycModal.title);
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_PAYMENT && this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.WAITING_FOR_MONEY_RECEIVED) {
                        return Main.trans.t(Lang.t.app.buttonLabels.confirmTransaction);
                    }
                }
                break;
        }

        return;
    }

    public get costsTransaction(): number {
        if (!Store.user.isBuyer) {
            return null;
        }

        if (this.state !== States.PAYMENT || !this.buyerPaymentFormData.model.paymentMethod) {
            return null;
        }

        return this.fixedFees[this.buyerPaymentFormData.model.paymentMethod] + +this.calculatePercentage(this.purchaseOrder.totalCosts, this.percentageFees[this.buyerPaymentFormData.model.paymentMethod]);
    }

    public get costsProduction(): number {
        if (this.state === States.UPDATING_COSTS) {
            let total: number = 0;

            this.sellerCostsFormData.model.products.forEach((product: SellerProductCostsFormModel) => {
                total += this.productSubtotal(product);
            });

            return total;
        }

        if (this.purchaseOrder?.productionCosts) {
            return this.purchaseOrder.productionCosts;
        }

        return 0;
    }

    public get costsOther(): number {
        if (this.state === States.UPDATING_COSTS) {
            return this.sellerCostsFormData.model.extraProductionCosts ?? 0;
        }

        if (this.purchaseOrder?.extraProductionCosts || this.purchaseOrder?.extraProductionCosts == 0) {
            return this.purchaseOrder.extraProductionCosts;
        }

        return 0;
    }

    public get costsShipping(): number {
        if (this.state === States.UPDATING_COSTS) {
            return this.sellerCostsFormData.model.shippingCosts ?? 0;
        }

        if (this.purchaseOrder?.shippingCosts || this.purchaseOrder?.shippingCosts === 0) {
            return this.purchaseOrder.shippingCosts;
        }

        return 0;
    }

    public get costsSubtotal(): number {
        if (this.state === States.UPDATING_COSTS) {
            let total: number = 0;

            if (this.sellerCostsFormData.model.shippingCosts) {
                total += +this.sellerCostsFormData.model.shippingCosts;
            }

            if (this.sellerCostsFormData.model.extraProductionCosts) {
                total += +this.sellerCostsFormData.model.extraProductionCosts;
            }

            total += this.costsProduction;

            return total;
        }

        if (this.purchaseOrder?.subtotalCosts) {
            return this.purchaseOrder.subtotalCosts;
        }

        return 0;
    }

    public get costsTotal(): number {
        if (this.state === States.UPDATING_COSTS) {
            let total: number = 0;

            total += this.costsSubtotal;
            total += this.costsVAT;

            return total;
        }

        if (this.purchaseOrder?.totalCosts) {
            if (Store.user.isBuyer) {
                return (this.purchaseOrder.totalCosts + this.costsTransaction);
            }

            if (Store.user.isSeller) {
                return this.purchaseOrder.sellerTotalCostsGross;
            }
        }

        return 0;
    }

    public get costsSellerTotal(): number {
        if (this.state === States.UPDATING_COSTS) {
            let total: number = 0;

            total += this.costsTotal;
            total -= this.costsSellerFee;
            total -= this.costsSellerFeeVAT;

            return total;
        }

        if (this.purchaseOrder?.sellerTotalCostsNet) {
            return this.purchaseOrder.sellerTotalCostsNet;
        }

        return 0;
    }

    public get costsVAT(): number {
        if (this.state === States.UPDATING_COSTS) {
            return this.calculatePercentage(this.costsSubtotal, this.sellerCostsFormData.model.vatPercentage);
        }

        if (this.purchaseOrder?.vatCosts || this.purchaseOrder?.vatCosts == 0) {
            return this.purchaseOrder.vatCosts;
        }

        return 0;
    }

    public get costsSellerFee(): number {
        if (this.state === States.UPDATING_COSTS) {
            return this.calculatePercentage(this.costsSubtotal, this.purchaseOrder?.sellerFeePercentage);
        }

        if (this.purchaseOrder?.sellerFeeSubtotalCosts || this.purchaseOrder?.sellerFeeSubtotalCosts == 0) {
            return this.purchaseOrder.sellerFeeSubtotalCosts;
        }

        return 0;
    }

    public get costsSellerFeeVAT(): number {
        if (this.state === States.UPDATING_COSTS) {
            return this.calculatePercentage(this.costsSellerFee, this.legalDetails?.vatPercentage);
        }

        if (this.purchaseOrder?.sellerFeeVatCosts || this.purchaseOrder?.sellerFeeVatCosts == 0) {
            return this.purchaseOrder.sellerFeeVatCosts;
        }

        return 0;
    }

    public get sellerKycVerified(): boolean {
        return this?.purchaseOrder?.sellerKycStatus === 'active';
    }

    public buttonBClick(): void {
        switch (this.state) {
            case States.CREATING:
            case States.UPDATING_COSTS:
            case States.SHIPMENT:
            case States.DISPUTE:
            case States.REVIEW:
            case States.REVIEW_SENT:
            case States.ATTACHMENTS:
                switch (this.page) {
                    case Pages.PRODUCTS:
                        this.hide();
                        return;
                    case Pages.SHIPMENT:
                    case Pages.DISPUTE:
                    case Pages.DELIVERY:
                    case Pages.OVERVIEW:
                    case Pages.REVIEW:
                    case Pages.REVIEW_SENT:
                    case Pages.ATTACHMENTS:
                        this.previousPage();
                        return
                }
                break;
            case States.READ:
                if (!this.purchaseOrder || !Store.user.isBuyer) {
                    break;
                }

                switch (this.purchaseOrder.status) {
                    case PurchaseOrderStatus.PRICE_NEGOTIATION:
                        this.confirmDeclinePurchaseOrder();
                        return;
                    case PurchaseOrderStatus.WAITING_FOR_PAYMENT:
                        if (this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.OPEN) {
                            Routes.resolveLink(Main.app.$settings.urlPaymentInstructions, true)
                        }
                        return;
                    case PurchaseOrderStatus.WAITING_FOR_SHIPMENT:
                        if (this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_RECEIVED && !this.purchaseOrder.ticketUuid) {
                            this.showStartDisputePage();
                        }
                        return;
                }
        }
    }

    public buttonAClick(): void {
        switch (this.state) {
            case States.CREATING:
            case States.UPDATING_COSTS:
            case States.SHIPMENT:
            case States.DISPUTE:
            case States.REVIEW:
            case States.PAYMENT:
            case States.ATTACHMENTS:
                switch (this.page) {
                    case Pages.PRODUCTS:
                        const productsFormData = (this.state == States.CREATING) ? this.buyerProductsFormData : this.sellerCostsFormData;
                        if (!productsFormData.validate()) {
                            return;
                        }

                        this.showPage(Pages.DELIVERY);
                        break;
                    case Pages.DELIVERY:
                        const deliveryFormData = (this.state == States.CREATING) ? this.buyerDeliveryFormData : this.sellerDeliveryFormData;
                        if (!deliveryFormData.validate()) {
                            return;
                        }

                        this.showPage(Pages.OVERVIEW);
                        break;
                    case Pages.OVERVIEW:
                        if (this.state == States.CREATING) {
                            this.submitCreateOrderFlow();
                        } else {
                            this.submitUpdateCostsFlow();
                        }
                        break;
                    case Pages.SHIPMENT:
                        this.submitSentShipment();
                        break;
                    case Pages.DISPUTE:
                        this.submitStartDispute();
                        break;
                    case Pages.REVIEW:
                        this.submitReview();
                        break;
                    case Pages.PAYMENT:
                        this.submitPayPurchaseOrder();
                        break;
                    case Pages.ATTACHMENTS:
                        this.submitAttachments();
                        break;
                }
                break;
            case States.READ:
                if (!this.purchaseOrder) {
                    break;
                }

                if (Store.user.isBuyer) {
                    if (this.purchaseOrder.status === PurchaseOrderStatus.PRICE_NEGOTIATION) {
                        this.confirmAcceptPurchaseOrder();
                        return;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_PAYMENT && this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.OPEN) {
                        // this.submitPayPurchaseOrder();
                        this.showPaymentPage();
                        return;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_SHIPMENT && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_RECEIVED) {
                        if (!this.purchaseOrder.ticketUuid) {
                            this.confirmShipmentReceived();
                            return;
                        }
                        this.confirmDisputeClosed();
                        return;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.COMPLETED && !this.purchaseOrder.review) {
                        this.showReviewPage();
                        return;
                    }
                }

                if (Store.user.isSeller) {
                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_COST_ESTIMATION || this.purchaseOrder.status === PurchaseOrderStatus.PRICE_NEGOTIATION) {
                        this.startUpdateCostsState();
                        return;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_SHIPMENT && this.purchaseOrder.shipmentStatus === PurchaseOrderShipmentStatus.WAITING_FOR_SHIPMENT_SENT) {
                        this.showShipmentSentPage();
                        return;
                    }

                    if (this.purchaseOrder.review) {
                        this.showReviewPreviewPage();
                        return;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_KYC) {
                        Routes.resolveLink(this.purchaseOrder.sellerKycUrl, false)
                        return;
                    }

                    if (this.purchaseOrder.status === PurchaseOrderStatus.WAITING_FOR_PAYMENT && this.purchaseOrder.paymentStatus === PurchaseOrderPaymentStatus.WAITING_FOR_MONEY_RECEIVED) {
                        this.confirmPaymentReceived();
                        return;
                    }
                }
                break;
        }

        return;
    }

    public created(): void {
    }

    public mounted(): void {
        // Listen for window resize:
        window.addEventListener('resize', this.resizeHandler.bind(this));
        this.resizeHandler();
    }

    public updated(): void {
    }

    public show(collaborationUuid: Uuid, type: string, purchaseOrderUuid: Uuid, systemMessage: string = null, buttonOption: number = null): void {
        this.collaborationUuid = collaborationUuid;
        this.flags.formChanged = false;

        if (purchaseOrderUuid) {
            if (systemMessage) {
                this.showCurrentPage(purchaseOrderUuid, systemMessage, buttonOption);

                if (systemMessage === SystemMessageTypes.PURCHASE_ORDER_DOCUMENT_UPLOADED || systemMessage === SystemMessageTypes.PURCHASE_ORDER_WAITING_FOR_INVOICE) {
                    this.showAttachmentsPage();
                }
                return;
            }

            this.showPurchaseOrderReadPage(purchaseOrderUuid);
            return;
        }

        this.type = type;
        this.showPurchaseOrderCreatePage();
    }

    public showList(collaborationUuid: Uuid): void {
        this.collaborationUuid = collaborationUuid;

        this.showPurchaseOrderListPage();
    }

    public showCollaborationList(companyUuid: Uuid): void {
        this.companyUuid = companyUuid;

        this.showCollaborationListPage();
    }

    // SHOW PAGE FUNCTIONS
    public startCreateOrderState(type: string = 'purchase_order'): void {
        this.flags.formChanged = true;
        this.purchaseOrder = null;
        this.type = type;

        const productsFormRules: FormRulesById = {};
        productsFormRules.products = [FormRules.required(), FormRules.minLength(1)];
        productsFormRules.remark = [FormRules.maxLength(2000)];
        productsFormRules.attachments = [];
        productsFormRules.products._ = {};
        productsFormRules.products._.productUuid = [FormRules.required()];
        productsFormRules.products._.quantity = [FormRules.required(), FormRules.range(1, 100000000)];
        productsFormRules.products._.size = [FormRules.required(), FormRules.maxLength(255)];

        this.buyerProductsFormData = new FormData({collaborationUuid: this.collaborationUuid, products: [], attachments: []}, productsFormRules);

        const buyerDeliveryFormRules: FormRulesById = {};
        buyerDeliveryFormRules.locationUuid = [FormRules.required()];
        buyerDeliveryFormRules._hasSameBillingAddress = [FormRules.required(null, true)];
        buyerDeliveryFormRules.billingLocationUuid = [FormRules.omitFurtherRules(() => {
            return this.buyerDeliveryFormData.model._hasSameBillingAddress;
        }), FormRules.required()];
        buyerDeliveryFormRules.useOwnCourier = [FormRules.required(null, true)];
        buyerDeliveryFormRules.buyerCourierName = [FormRules.maxLength(200), FormRules.omitFurtherRules(() => {
            return !this.buyerDeliveryFormData.model.useOwnCourier;
        }), FormRules.required()];
        buyerDeliveryFormRules.buyerCourierNumber = [FormRules.maxLength(200), FormRules.omitFurtherRules(() => {
            return !this.buyerDeliveryFormData.model.useOwnCourier;
        }), FormRules.required()];
        buyerDeliveryFormRules.preferredDeliveryDate = [FormRules.required(), FormRules.minDate(Date.tomorrow())];
        buyerDeliveryFormRules.products = [FormRules.minLength(1)];

        this.buyerDeliveryFormData = new FormData({_hasSameBillingAddress: true}, buyerDeliveryFormRules);

        // For test only:
        if (Settings.debugMode) {
            this.buyerDeliveryFormData.model.useOwnCourier = false;
            this.buyerDeliveryFormData.model.preferredDeliveryDate = new Date();
        }

        this.setState(States.CREATING);
        this.showPage(Pages.PRODUCTS);

        this.addProduct();

        // For test only:
        if (Settings.debugMode) {
            this.buyerProductsFormData.model.products.first().productUuid = this.collaboration.products.first().product.uuid;
            this.buyerProductsFormData.model.products.first().quantity = 5;
            this.buyerProductsFormData.model.products.first().size = 'M'

            this.addProduct();

            this.buyerProductsFormData.model.products.last().productUuid = this.collaboration.products.first().product.uuid;
            this.buyerProductsFormData.model.products.last().quantity = 10;
            this.buyerProductsFormData.model.products.last().size = 'L'
        }
    }

    public startUpdateCostsState(): void {
        this.flags.formChanged = true;
        const products = [];
        this.purchaseOrder.products.forEach((product: PurchaseOrderProductDTO) => {
            const productModel: SellerProductCostsFormModel = {
                purchaseOrderProductUuid: product.uuid,
                productName: product.productName,
                quantity: product.quantity,
                size: product.size,
                unitPrice: product?.unitPrice,
            };

            products.push(productModel);
        });

        const costsFormRules: FormRulesById = {};
        costsFormRules.products = [FormRules.required(), FormRules.minLength(1)];
        costsFormRules.extraProductionCosts = [FormRules.required(), FormRules.range(0, 1000000)];
        costsFormRules.shippingCosts = [FormRules.required(), FormRules.range(0, 100000)];
        costsFormRules.vatPercentage = [FormRules.required(), FormRules.range(0, 100)];
        costsFormRules.remark = [];
        costsFormRules.products._ = {};
        costsFormRules.products._.purchaseOrderProductUuid = [FormRules.required()];
        costsFormRules.products._.unitPrice = [FormRules.required(), FormRules.range(0.01, 100000)];
        costsFormRules.products._.quantity = [FormRules.required(), FormRules.range(1, 1000000)];
        costsFormRules.products._.size = [FormRules.required()];

        const costsForm: SellerCostsFormModel = {
            collaborationUuid: this.collaborationUuid,
            products: products,
            extraProductionCosts: this.purchaseOrder?.extraProductionCosts,
            shippingCosts: this.purchaseOrder?.shippingCosts,
            vatPercentage: this.purchaseOrder?.vatPercentage,
        };

        this.sellerCostsFormData = new FormData(costsForm, costsFormRules);

        const sellerDeliveryFormRules: FormRulesById = {};
        sellerDeliveryFormRules.locationUuid = [FormRules.required()];
        sellerDeliveryFormRules._hasSameBillingAddress = [FormRules.required(null, true)];
        sellerDeliveryFormRules.billingLocationUuid = [FormRules.omitFurtherRules(() => {
            return this.sellerDeliveryFormData.model._hasSameBillingAddress;
        }), FormRules.required()];
        sellerDeliveryFormRules.estimatedShippingDate = [FormRules.required(), FormRules.minDate(Date.tomorrow())];
        sellerDeliveryFormRules.cocNumber = [FormRules.omitFurtherRules(() => {
            return this.flags.alreadyAgreedToOppTerms;
        }), FormRules.required(), FormRules.maxLength(45)];
        sellerDeliveryFormRules.companyLegalName = [FormRules.omitFurtherRules(() => {
            return this.flags.alreadyAgreedToOppTerms;
        }), FormRules.required(), FormRules.maxLength(45)];
        sellerDeliveryFormRules.agreeOppTerms = [FormRules.omitFurtherRules(() => {
            return this.flags.alreadyAgreedToOppTerms;
        }), FormRules.required(Main.trans.t(Lang.t.app.order.errors.oppTerms, {varNameOpp: Settings.nameOpp}))];

        const deliveryForm: SellerDeliveryFormModel = {
            locationUuid: this.purchaseOrder?.sellerLocation?.uuid,
            _hasSameBillingAddress: true,
            estimatedShippingDate: this.purchaseOrder?.estimatedShippingDate,
            agreeOppTerms: false,
        };

        this.sellerDeliveryFormData = new FormData(deliveryForm, sellerDeliveryFormRules);

        // For test only:
        if (Settings.debugMode) {
            this.sellerCostsFormData.model.products.first().unitPrice = 10;
            this.sellerCostsFormData.model.products.last().unitPrice = 15;
            this.sellerCostsFormData.model.extraProductionCosts = 100;
            this.sellerCostsFormData.model.shippingCosts = 20;
            this.sellerCostsFormData.model.vatPercentage = 21;
            this.sellerDeliveryFormData.model.cocNumber = 'KvK1234';
            this.sellerDeliveryFormData.model.estimatedShippingDate = new Date();
        }

        // Check if the seller has correct legal details:
        const payload: Record<string, any> = {};
        payload.uuid = Store.user.currentCompanyUuid;

        let promise: Bluebird<any> = Main.callApi('company/legal-details', RequestMethods.GET, payload, LegalDetailsDTO);
        promise = promise.then((result: LegalDetailsDTO) => {
            this.legalDetails = result;

            this.flags.alreadyAgreedToOppTerms = (result.oppOverviewUrl != null);
            this.sellerDeliveryFormData.model.cocNumber = result.cocNumber;
            this.sellerDeliveryFormData.model.companyLegalName = Store.company.name;
            this.sellerDeliveryFormData.model.agreeOppTerms = this.flags.alreadyAgreedToOppTerms;
        });

        this.setState(States.UPDATING_COSTS);
        this.showPage(Pages.PRODUCTS);
    }

    public showPurchaseOrderListPage(): void {
        this.getCollaboration(this.collaborationUuid, States.LIST);

        this.setState(States.LIST);
        this.showPage(Pages.LIST);

        this.flags.show = true;
    }

    public showCollaborationListPage(): void {
        this.getCollaborationList();

        this.setState(States.COLLABORATION_LIST);
        this.showPage(Pages.COLLABORATION_LIST);

        this.flags.show = true;
    }

    public showCurrentPage(purchaseOrderUuid: Uuid, systemMessage: string, buttonOption: number = null): void {
        this.resetModal();

        this.setState(States.READ);
        this.showPage(Pages.READ);
        this.buttonOption = buttonOption;

        this.getPurchaseOrder(purchaseOrderUuid, systemMessage);
    }

    public showShipmentSentPage(): void {
        this.flags.formChanged = true;

        const sellerShipmentFormRules: FormRulesById = {};
        sellerShipmentFormRules.shipmentDate = [FormRules.required()];
        sellerShipmentFormRules.shipmentCourierName = [FormRules.required(), FormRules.maxLength(200)];
        sellerShipmentFormRules.shipmentTrackAndTrace = [FormRules.required(), FormRules.maxLength(200)];

        const shipmentForm: SellerShipmentFormModel = {
            shipmentDate: null,
            shipmentCourierName: null,
            shipmentTrackAndTrace: null,
        };

        this.sellerShipmentFormData = new FormData(shipmentForm, sellerShipmentFormRules);

        this.setState(States.SHIPMENT);
        this.showPage(Pages.SHIPMENT);

        this.flags.show = true;
    }

    public showStartDisputePage(): void {
        this.flags.formChanged = true;

        const buyerDisputeFormRules: FormRulesById = {};
        buyerDisputeFormRules.message = [FormRules.required()];

        const disputeForm: BuyerDisputeFormModel = {
            message: null,
        };

        this.buyerDisputeFormData = new FormData(disputeForm, buyerDisputeFormRules);

        this.setState(States.DISPUTE);
        this.showPage(Pages.DISPUTE);

        this.flags.show = true;
    }

    public showReviewPage(): void {
        this.flags.formChanged = true;

        const reviewFormRules: FormRulesById = {};
        reviewFormRules.score = [FormRules.required(), FormRules.range(1, 5)];
        reviewFormRules.body = [FormRules.required(), FormRules.maxLength(255)];

        const reviewForm: ReviewFormModel = {
            score: null,
            body: null,
            hasNameDisplayed: null,
        };

        this.reviewFormData = new FormData(reviewForm, reviewFormRules);

        this.setState(States.REVIEW);
        this.showPage(Pages.REVIEW);

        this.flags.show = true;
    }

    public showReviewPreviewPage(): void {
        this.setState(States.REVIEW_SENT);
        this.showPage(Pages.REVIEW_SENT);

        this.flags.show = true;
    }

    // CONFIRM FUNCTIONS
    public confirmAcceptPurchaseOrder(): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.requestOrder.accept.msg),
            Main.trans.t(Lang.t.app.order.requestOrder.accept.title),
            Main.trans.t(Lang.t.app.order.requestOrder.accept.yes),
            Main.trans.t(Lang.t.app.sampleModal.requestSample.confirm.no)
        );
        promise = promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.uuid = this.purchaseOrder.uuid;

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/accept', RequestMethods.PATCH, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'accept'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    // CONFIRM FUNCTIONS
    public confirmPaymentReceived(): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.paymentReceivedOrder.accept.msg),
            Main.trans.t(Lang.t.app.order.paymentReceivedOrder.accept.title),
            Main.trans.t(Lang.t.app.order.paymentReceivedOrder.accept.yes),
            Main.trans.t(Lang.t.app.order.paymentReceivedOrder.accept.no)
        );
        promise = promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.uuid = this.purchaseOrder.uuid;

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/payment/received', RequestMethods.POST, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'accept'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    public confirmDeclinePurchaseOrder(): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.requestOrder.decline.msg),
            Main.trans.t(Lang.t.app.order.requestOrder.decline.title),
            Main.trans.t(Lang.t.app.order.requestOrder.decline.yes),
            Main.trans.t(Lang.t.app.sampleModal.requestSample.confirm.no),
            StyleAppearance.DANGER
        );
        promise = promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.uuid = this.purchaseOrder.uuid;

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/decline', RequestMethods.PATCH, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'decline'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    public confirmCancelPurchaseOrder(purchaseOrderUuid: Uuid): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.requestOrder.cancel.msg),
            Main.trans.t(Lang.t.app.order.requestOrder.cancel.title),
            Main.trans.t(Lang.t.app.order.requestOrder.cancel.yes),
            Main.trans.t(Lang.t.app.sampleModal.requestSample.confirm.no)
        );
        promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.uuid = purchaseOrderUuid;

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/cancel', RequestMethods.PATCH, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'cancel'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    public confirmShipmentReceived(): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.shipmentReceived.confirm.msg, {orderNumber: this.purchaseOrder.orderNumber}),
            Main.trans.t(Lang.t.app.order.shipmentReceived.confirm.title),
            Main.trans.t(Lang.t.app.order.shipmentReceived.confirm.yes),
            Main.trans.t(Lang.t.app.order.shipmentReceived.confirm.no)
        );
        promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.uuid = this.purchaseOrder.uuid;

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/shipment/received', RequestMethods.POST, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'shipmentReceived'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    public confirmDisputeClosed(): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.endDispute.confirm.msg),
            Main.trans.t(Lang.t.app.order.endDispute.confirm.title),
            Main.trans.t(Lang.t.app.order.endDispute.confirm.yes),
            Main.trans.t(Lang.t.app.order.endDispute.confirm.no)
        );
        promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.uuid = this.purchaseOrder.ticketUuid;

            let promise: Bluebird<any> = Main.callApi('collaboration/ticket/close', RequestMethods.PATCH, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'endDispute'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    public submitSentShipment(): void {
        if (!this.sellerShipmentFormData.validate()) {
            return;
        }

        this.flags.loading = true;

        const payload: Record<string, any> = {};
        payload.uuid = this.purchaseOrder.uuid;
        payload.shipmentDate = this.sellerShipmentFormData.model.shipmentDate;
        payload.shipmentCourierName = this.sellerShipmentFormData.model.shipmentCourierName;
        payload.shipmentTrackAndTrace = this.sellerShipmentFormData.model.shipmentTrackAndTrace;

        let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/shipment/sent', RequestMethods.POST, payload);
        promise = promise.then(this.handleSubmitResult.bind(this, 'shipmentSent'));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    public submitStartDispute(): void {
        if (!this.buyerDisputeFormData.validate()) {
            return;
        }

        this.flags.loading = true;

        const payload: Record<string, any> = {};
        payload.uuid = this.collaborationUuid;
        payload.message = this.buyerDisputeFormData.model.message;
        payload.purchaseOrderUuid = this.purchaseOrder.uuid;

        let promise: Bluebird<any> = Main.callApi('collaboration/ticket', RequestMethods.POST, payload);
        promise = promise.then(this.handleSubmitResult.bind(this, 'startDispute'));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    public submitReview(): void {
        if (!this.reviewFormData.validate()) {
            return;
        }

        this.flags.loading = true;

        const payload: Record<string, any> = {};
        payload.uuid = this.collaborationUuid ?? this.purchaseOrder.collaborationUuid;
        payload.purchaseOrderUuid = this.purchaseOrder.uuid;
        payload.score = this.reviewFormData.model.score;
        payload.body = this.reviewFormData.model.body;
        payload.hasNameDisplayed = (this.reviewFormData.model.hasNameDisplayed);

        let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/review', RequestMethods.POST, payload);
        promise = promise.then(this.handleSubmitResult.bind(this, 'leaveReview'));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    public showPaymentPage(): void {
        const paymentFormRules: FormRulesById = {};
        paymentFormRules.paymentMethod = [FormRules.required()];

        const paymentForm: BuyerPaymentFormModel = {
            paymentMethod: null,
        };

        this.buyerPaymentFormData = new FormData(paymentForm, paymentFormRules);

        this.setState(States.PAYMENT);
        this.showPage(Pages.PAYMENT);
    }

    public submitPayPurchaseOrder(): void {
        if (!this.buyerPaymentFormData.validate()) {
            return;
        }

        this.purchaseOrder.status = PurchaseOrderStatus.PENDING;

        this.flags.loading = true;

        const payload: Record<string, any> = {};
        payload.uuid = this.purchaseOrder.uuid;
        payload.paymentMethod = this.buyerPaymentFormData.model.paymentMethod;

        let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/payment/started', RequestMethods.POST, payload);
        promise = promise.then(this.handlePayResult.bind(this));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    public createAddressString(location: LocationDTO, alt: string = null): string {
        return (location)
            ? location.name + '<br>' + location.address1 + '<br>' + location.zipcode + ', ' + location.city + '<br>' + Main.trans.t('meta.countries.' + location.country)
            : (alt ?? '<span class="font-medium text-yellow-300">' + Main.trans.t('app.labels.toBeDetermined') + '</span>');
    }

    public previousPage(): void {
        switch (this.page) {
            case Pages.READ:
                this.showPurchaseOrderListPage();
                break;

            case Pages.PRODUCTS:
                if (this.state == States.UPDATING_COSTS) {
                    this.showPurchaseOrderReadPage(this.purchaseOrder.uuid)
                } else {
                    this.showPurchaseOrderListPage();
                }
                break;

            case Pages.DELIVERY:
                this.showPage(Pages.PRODUCTS);
                break;

            case Pages.OVERVIEW:
                this.showPage(Pages.DELIVERY);
                break;

            case Pages.DISPUTE:
            case Pages.REVIEW_SENT:
            case Pages.SHIPMENT:
            case Pages.REVIEW:
            case Pages.ATTACHMENTS:
                this.showPurchaseOrderReadPage(this.purchaseOrder.uuid)
                break;
        }
    }

    /**
     * The product name for the given product uuid
     */
    public getProductName(productUuid: Uuid): string {
        for (let i = this.collaboration.products.length; i--;) {
            const productOffer = this.collaboration.products[i];
            if (productOffer.product.uuid == productUuid) {
                return productOffer.product.name;
            }
        }
        return null;
    }

    public showPurchaseOrderReadPage(purchaseOrderUuid: Uuid): void {
        this.resetModal();

        this.setState(States.READ);
        this.showPage(Pages.READ);

        this.getPurchaseOrder(purchaseOrderUuid);

        this.flags.show = true;
    }

    public addProduct(): void {
        const product: BuyerProductFormModel = {
            productUuid: null,
            quantity: null,
            size: null,
        };

        this.buyerProductsFormData.model.products.push(product);
        this.mainContentScrollHeight = 0;
    }

    public removeProduct(index: number): void {
        this.buyerProductsFormData.model.products.splice(index, 1);

        this.mainContentScrollHeight = 0;
    }

    public productSubtotal(product: SellerProductCostsFormModel): number {
        let total: number = 0;

        if (product.unitPrice && product.quantity) {
            total += +(product.unitPrice * product.quantity);
        }

        return total;
    }

    public createDateString(date: Date): string {
        return (date)
            ? Main.trans.d(date, 'date')
            : '<span class="font-medium text-yellow-300">' + Main.trans.t('app.labels.toBeDetermined') + '</span>';
    }

    public showCostEstimation(): boolean {
        return !(this.page === Pages.LIST ||
            this.page === Pages.SHIPMENT ||
            this.page === Pages.DISPUTE ||
            this.page === Pages.REVIEW ||
            this.page === Pages.REVIEW_SENT ||
            this.page === Pages.ATTACHMENTS ||
            this.page === Pages.COLLABORATION_LIST);
    }

    public showAttachmentsPage(): void {
        const productsFormRules: FormRulesById = {};
        productsFormRules.attachments = [FormRules.required(), FormRules.minLength(1), FormRules.maxLength(10)];

        this.attachmentsFormData = new FormData({attachments: []}, productsFormRules);

        this.setState(States.ATTACHMENTS);
        this.showPage(Pages.ATTACHMENTS);
    }

    public submitAttachments(): void {
        if (!this.attachmentsFormData.validate()) {
            return;
        }

        const payload: Record<string, any> = {};
        payload.uuid = this.purchaseOrder.uuid;
        payload.companyUuid = Store.user.currentCompanyUuid;

        payload.attachments = [];
        for (let i: number = this.attachmentsFormData.model.attachments.length; i--;) {
            payload.attachments.unshift(this.attachmentsFormData.model.attachments[i].uuid);
        }

        let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/attachments', RequestMethods.POST, payload, PurchaseOrderDTO);
        promise = promise.then(this.handleFileUploadedResult.bind(this));
    }

    public handleFileUploadedResult(): void {
        Main.app.toast.success(
            Main.trans.t(Lang.t.app.toast.success.fileUploaded.message),
            Main.trans.t(Lang.t.app.toast.success.fileUploaded.title)
        );

        setTimeout(() => {
            this.buttonBClick();
        }, 200)
    }

    private resetModal(): void {
        this.flags.formChanged = false;
        this.purchaseOrder = null;
        this.type = null;
        this.buttonOption = null;
    }

    private showPurchaseOrderCreatePage(): void {
        this.getCollaboration(this.collaborationUuid, States.CREATING);
    }

    private submitCreateOrderFlow(): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.requestOrder.confirm.msg),
            Main.trans.t(Lang.t.app.order.requestOrder.confirm.title),
            Main.trans.t(Lang.t.app.sampleModal.requestSample.confirm.yes),
            Main.trans.t(Lang.t.app.sampleModal.requestSample.confirm.no)
        );
        promise = promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.buyerUuid = Store.user.currentCompanyUuid;
            payload.collaborationUuid = this.buyerProductsFormData.model.collaborationUuid;
            payload.type = this.type;

            payload.remark = this.buyerProductsFormData.model.remark;

            payload.products = [];
            this.buyerProductsFormData.model.products.forEach((product: BuyerProductFormModel) => {
                payload.products.push(Object.assign({}, product));
            });

            payload.attachments = [];
            for (let i: number = this.buyerProductsFormData.model.attachments.length; i--;) {
                payload.attachments.unshift(this.buyerProductsFormData.model.attachments[i].uuid);
            }

            payload.buyerLocationUuid = this.buyerDeliveryFormData.model.locationUuid;
            payload.buyerBillingLocationUuid = (!this.buyerDeliveryFormData.model._hasSameBillingAddress) ? this.buyerDeliveryFormData.model.billingLocationUuid : null;
            payload.preferredDeliveryDate = this.buyerDeliveryFormData.model.preferredDeliveryDate;

            payload.buyerCourierName = this.buyerDeliveryFormData.model.buyerCourierName;
            payload.buyerCourierNumber = this.buyerDeliveryFormData.model.buyerCourierNumber;

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order', RequestMethods.POST, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'create'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    private submitUpdateCostsFlow(): void {
        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.order.requestOrder.update.msg),
            Main.trans.t(Lang.t.app.order.requestOrder.update.title),
            Main.trans.t(Lang.t.app.order.requestOrder.update.yes),
            Main.trans.t(Lang.t.app.sampleModal.requestSample.confirm.no)
        );
        promise = promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.uuid = this.purchaseOrder.uuid;

            payload.cocNumber = this.sellerDeliveryFormData.model.cocNumber;
            payload.companyLegalName = this.sellerDeliveryFormData.model.companyLegalName;
            payload.oppTermsAccepted = this.sellerDeliveryFormData.model.agreeOppTerms;

            payload.sellerLocationUuid = this.sellerDeliveryFormData.model.locationUuid;
            payload.sellerBillingLocationUuid = (!this.sellerDeliveryFormData.model._hasSameBillingAddress) ? this.sellerDeliveryFormData.model.billingLocationUuid : null;
            payload.estimatedShippingDate = this.sellerDeliveryFormData.model.estimatedShippingDate;

            payload.extraProductionCosts = this.sellerCostsFormData.model.extraProductionCosts;
            payload.shippingCosts = this.sellerCostsFormData.model.shippingCosts;
            payload.vatPercentage = this.sellerCostsFormData.model.vatPercentage;

            payload.products = [];
            this.sellerCostsFormData.model.products.forEach((product: SellerProductCostsFormModel) => {
                payload.products.push(Object.assign({}, product));
            });

            let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/costs', RequestMethods.PATCH, payload);
            promise = promise.then(this.handleSubmitResult.bind(this, 'costs'));
            promise = promise.finally(() => {
                this.flags.loading = false;
            });
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }
        });
    }

    private handleSubmitResult(action: string, result: any): void {
        this.hide(true);
        Main.app.toast.success(
            Main.trans.t('app.order.' + action + '.success'),
            Main.trans.t(Lang.t.app.labels.success)
        );

        if (Routes.currentRoute.name == 'dashboard' || Routes.currentRoute.name == 'projectList') {
            Routes.resolveLink({name: 'chat', query: {threadUuid: result.threadUuid}});
        }
    }

    private handlePayResult(result: any): void {
        if (result.url) {
            window.location.href = result.url;
            return;
        }

        this.hide();
        Main.app.toast.success(
            Main.trans.t(Lang.t.app.toast.success.paymentMethodChosen.message),
            Main.trans.t(Lang.t.app.toast.success.paymentMethodChosen.title)
        );
    }

    private calculatePercentage(input: number, percentage: number): number {
        let total: number = 0;

        if (percentage != null && percentage != 0) {
            total = (input * (percentage / 100));
        }

        return total;
    }

    private showPage(page: Pages): void {
        this.selectedLocationStr = '';
        this.selectedBillingLocationStr = '';

        if (page == Pages.OVERVIEW) {
            // Get addresses (since they are async):
            const locationUuid: Uuid = (this.state == States.CREATING) ? this.buyerDeliveryFormData?.model.locationUuid : this.sellerDeliveryFormData?.model.locationUuid;

            Store.locations.getLocationByUuid(locationUuid, Store.user.currentCompanyUuid).then((location: LocationDTO) => {
                this.selectedLocationStr = this.createAddressString(location);
            });

            let billingLocationUuid: Uuid = null;

            if (this.state == States.CREATING && !this.buyerDeliveryFormData.model._hasSameBillingAddress) {
                billingLocationUuid = this.buyerDeliveryFormData.model.billingLocationUuid
            }
            if (this.state == States.UPDATING_COSTS && !this.sellerDeliveryFormData.model._hasSameBillingAddress) {
                billingLocationUuid = this.sellerDeliveryFormData.model.billingLocationUuid
            }

            Store.locations.getLocationByUuid(billingLocationUuid, Store.user.currentCompanyUuid).then((location: LocationDTO) => {
                this.selectedBillingLocationStr = this.createAddressString(location, Main.trans.t('app.labels.sameAddressAsDelivery'));
            });
        }

        this.mainContentScrollHeight = 0;
        this.page = page;
    }

    private setState(state: States): void {
        this.state = state;
    }

    // API Getters
    private getCollaboration(collaborationUuid: Uuid, state: States): void {
        const payload: Record<string, any> = {};
        payload.uuid = collaborationUuid;

        let promise: Bluebird<any> = Main.callApi('collaboration', RequestMethods.GET, payload, CollaborationDTO);

        if (state == States.LIST) {
            promise = promise.then(this.handleCollaborationResultForList.bind(this));
        } else if (state === States.CREATING) {
            promise = promise.then(this.handleCollaborationResultForCreating.bind(this));
        }
    }

    private getCollaborationList(companyUuid: Uuid): void {
        const payload: Record<string, any> = {};
        payload.uuid = companyUuid;

        let promise: Bluebird<any> = Main.callApi('buyer/collaborations', RequestMethods.GET, payload, [ProjectDTO]);
        promise = promise.then(this.handleCollaborationListResult.bind(this));
    }

    private handleCollaborationResultForList(result: CollaborationDTO): void {
        this.collaboration = result;
        this.flags.show = true;

        this.getPurchaseOrderList(this.collaboration.uuid);
    }

    private handleCollaborationListResult(result: ListResult<ProjectDTO>): void {
        this.projects = result.items;
    }

    private handleCollaborationResultForCreating(result: CollaborationDTO): void {
        this.collaboration = result;

        if (Store.user.isBuyer) {
            this.flags.show = true;
            this.startCreateOrderState(this.type);
        } else if (Store.user.isSeller) {
            let promise = Main.app.alert.show(
                Main.trans.t('app.purchaseOrder.sellerInitConfirm.msg.' + this.type, {brandName: this.collaboration.buyerName}),
                Main.trans.t('app.purchaseOrder.sellerInitConfirm.title.' + this.type),
                Main.trans.t(Lang.t.app.purchaseOrder.sellerInitConfirm.sendButton),
                Main.trans.t(Lang.t.app.labels.cancel)
            );
            promise = promise.then(() => {
                const payload: Record<string, any> = {};
                payload.collaborationUuid = this.collaboration.uuid;
                payload.type = this.type;

                let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order/initiate', RequestMethods.POST, payload);
                promise = promise.then(() => {
                    Main.app.toast.success(Main.trans.t(Lang.t.app.purchaseOrder.sellerInitConfirm.success), Main.trans.t(Lang.t.app.labels.success));
                });
            });
            promise = promise.catch((err: Error) => {
                if (err) {
                    throw err;
                }
            });
        }
    }

    private getPurchaseOrder(purchaseOrderUuid: Uuid, systemMessage: string = null): void {
        const payload: Record<string, any> = {};
        payload.uuid = purchaseOrderUuid;
        payload.systemMessage = systemMessage;

        let promise: Bluebird<any> = Main.callApi('collaboration/purchase-order', RequestMethods.GET, payload, PurchaseOrderDTO);
        promise = promise.then(this.handlePurchaseOrderResult.bind(this));
    }

    private handlePurchaseOrderResult(result: PurchaseOrderDTO): void {
        this.purchaseOrder = result;
        this.type = this.purchaseOrder.type;

        if (this.purchaseOrder.isCurrentStep) {
            if (this.buttonOption === 0) {
                this.buttonAClick();
            }

            if (this.buttonOption === 1) {
                this.buttonBClick();
            }
        }

        this.flags.show = true;
    }

    private getPurchaseOrderList(collaborationUuid: Uuid): void {
        const payload: Record<string, any> = {};
        payload.uuid = this.collaborationUuid;

        let promise: Bluebird<any> = Main.callApi('collaboration/purchase-orders', RequestMethods.GET, payload, [PurchaseOrderDTO]);
        promise = promise.then(this.handlePurchaseOrderListResult.bind(this));
    }

    private handlePurchaseOrderListResult(result: ListResult<PurchaseOrderDTO>): void {
        this.purchaseOrders = result.items;
    }

    private resizeHandler(): void {
        if (!this.showCostEstimation()) {
            return;
        }

        if (this.$refs.dialogBody) {
            this.flags.hasScrollbar = (this.$refs.dialogBody.scrollHeight > this.$refs.dialogBody.clientHeight);
            this.mainContentScrollHeight = (this.$refs.dialogBody.scrollHeight > this.$refs.dialogBody.clientHeight) ? this.$refs.dialogBody.scrollHeight : 0;
        }
    }
}
