<script lang="ts" src="./FormField.ts"></script>


<template>
  <div
    :class="[
      'form-field-group',
      size,
      borderAppearance,
      {
        'has-error': (errors?.length > 0),
        'has-data': (value?.length > 0 || value > 0),
        disabled: disabled,
      },
    ]"
  >
    <div class="form-field">
      <slot name="prepend"></slot>

      <input
        :id="id"
        :autocomplete="autocomplete"
        :class="[
          'input',
          {
            'text-right': rtl,
          }
        ]"
        :disabled="disabled"
        :dusk="dusk"
        :max="(max instanceof Date) ? max.toISODateString() : max"
        :min="(min instanceof Date) ? min.toISODateString() : min"
        :placeholder="placeholder"
        :type="type"
        :value="valueStr"
        @input="updateFromValue($event)"
      />
      <slot name="append"></slot>
    </div>

    <slot></slot> <!-- ? -->

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </div>
</template>
