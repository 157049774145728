import {Component, Prop, Vue, Watch} from 'vue-facing-decorator';
import {RouteParams, RouteRecord} from 'vue-router';
import {Routes} from '@/app/Routes';

interface IBreadcrumb {
    title: string;
    link?: any;
    params?: RouteParams;
}

@Component({})
export default class Breadcrumbs extends Vue {

    public levelList: IBreadcrumb[] = [];
    /**
     * These params will be used to fill breadcrumbs with a *-segment
     */
    @Prop({type: Object, default: {}})
    private readonly labelParams: Object;
    /**
     * This whole param object will be passed on to every breadcrumb link
     */
    @Prop({type: Object, default: {}})
    private readonly urlParams: RouteParams;

    public created(): void {
    }

    public mounted(): void {
        this._updateList();
    }

    @Watch('labelParams', {deep: true})
    private handleLabelParamsChange(_newValue: Object, _oldValue: Object): void {
        this._updateList();
    }

    @Watch('urlParams', {deep: true})
    private handleUrlParamsChange(_newValue: Object, _oldValue: Object): void {
        this._updateList();
    }

    private _updateList(): void {
        let matched: RouteRecord[] = Routes.currentRoute.matched;
        let current: RouteRecord = matched[matched.length - 1];

        let list: IBreadcrumb[] = [];
        this._parseCrumb(current, list);

        if (list.last()) {
            // Remove link from last crumb:
            delete list.last().link;
            delete list.last().params;
        }

        this.levelList = list;
    }

    /**
     * Parse breadcrumb path.
     * Segments starting with a $ will use the name of the breadcrumb from the path with that name.
     * Segments starting with a ~ won't be clickable.
     * Segments starting with a # (possibly after ~) will indicate it's a reference to another meta property.
     * Segments starting with a * (possibly after ~) will indicate it's a reference to a property in the labelParams supplied to the component.
     */
    private _parseCrumb(r: RouteRecord, list: IBreadcrumb[]): void {
        if (!r?.meta?.breadcrumb) {
            warn('Route has no breadcrumbs defined!');
            return null;
        }
        const split: string[] = (r.meta.breadcrumb as string).split('/');
        while (split.length) {
            let c: string = split.shift();
            if (c.indexOf('$') === 0) { // Reference to other breadcrumb
                const parentRroute = Routes.findRouteByName(c.substring(1));
                if (parentRroute && parentRroute.meta && parentRroute.meta.breadcrumb) {
                    this._parseCrumb(parentRroute, list);    // TODO: Prevent circular breadcrumb
                }
            } else {
                const item: IBreadcrumb = {title: null};
                let clickable: boolean = true;
                if (c.indexOf('~') === 0) { // Non-clickable
                    c = c.substring(1);
                    clickable = false;
                }

                if (c.indexOf('*') === 0) { // Reference to some other meta-property
                    c = this.labelParams[c.substring(1)] ?? c;
                }

                if (c.indexOf('#') === 0) { // Reference to some other meta-property
                    c = c.substring(1);
                    c = r.meta[c] as string;
                }

                item.title = c;
                if (clickable) {
                    item.link = r.name;
                    item.params = this.urlParams;
                }

                list.push(item);
            }
        }
    }
}
