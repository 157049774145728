import {Component, Prop} from 'vue-facing-decorator';
import {FormItem} from '@/app/views/components/form/FormItem';
import {StyleSize} from '@/app/constants/Constants';

@Component({})
export default class FormField extends FormItem<string | Date> {

    @Prop({type: [String, Date], default: null})
    declare public modelValue: string | Date;

    @Prop({type: String, default: null})
    public readonly dusk: string = null;

    @Prop({type: String, default: null})
    public readonly type: string = null;

    // normal html attributes:
    @Prop({type: [Number, Date, String], default: null})
    public readonly min: number | Date | string;

    @Prop({type: [Number, Date, String], default: null})
    public readonly max: number | Date | string = null;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: String, default: null})
    public readonly autocomplete: string;

    // appearance settings:
    @Prop({type: Boolean, default: false})
    public readonly rtl: boolean;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: string;

    @Prop({type: String, default: null})
    public readonly borderAppearance: string;

    public get valueStr(): string {
        return (this.value instanceof Date) ? this.value.toISOString().substr(0, 10) : this.value;
    }

    public updateFromValue(evt: Event): void {
        const target: HTMLInputElement = (evt.target as HTMLInputElement);
        this.updateValue((this.value instanceof Date) ? target.valueAsDate : target.value);
    }
}
