import {Castable} from '@/app/utils/Castable';

export class TranslationDTO extends Castable {

    public value: string = null;
    public lang: string = null;
    public originalValue: string = null;
    public originalLang: string = null;

}
