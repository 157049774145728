import Bluebird from 'bluebird';
import toastr from 'toastr';
import {Component, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {StylePosition} from '@/app/constants/styles/StylePosition';
import {StyleSize} from '@/app/constants/styles/StyleSize';

@Component({})
export default class Toast extends Vue {

    public flags = {
        show: false,
    };

    public title: string = null;
    public message: string = null;
    public options: object = null;
    public appearance: StyleAppearance;
    public x: StylePosition;
    public y: StylePosition;
    public size: StyleSize;
    public resolveHandler: (thenableOrResult?: any) => void = null;
    public rejectHandler: (error?: any) => void = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public info(message: string, title: string = null, options: object = null, size: StyleSize = null, x: StylePosition = null, y: StylePosition = null): Bluebird<void> {
        return this.show(message, title, StyleAppearance.INFO, options, size, x, y);
    }

    public success(message: string, title: string = null, options: object = null, size: StyleSize = null, x: StylePosition = null, y: StylePosition = null): Bluebird<void> {
        return this.show(message, title, StyleAppearance.SECONDARY, options, size, x, y);
    }

    public danger(message: string, title: string = null, options: object = null, size: StyleSize = null, x: StylePosition = null, y: StylePosition = null): Bluebird<void> {
        return this.show(message, title, StyleAppearance.DANGER, options, size, x, y);
    }

    public warning(message: string, title: string = null, options: object = null, size: StyleSize = null, x: StylePosition = null, y: StylePosition = null): Bluebird<void> {
        return this.show(message, title, StyleAppearance.WARNING, options, size, x, y);
    }

    public hide(): void {
        toastr.remove();

        // Clear settings:
        this.flags.show = false;
        this.message = null;
        this.title = null;
        this.options = null;

        this.appearance = null;
        this.size = null;
        this.x = null;
        this.y = null;
    }

    private show(message: string, title: string = null, appearance: StyleAppearance = null, options: object = null, size: StyleSize = null, x: StylePosition = null, y: StylePosition = null): Bluebird<void> {
        this.flags.show = true;
        this.message = message;
        this.title = title;

        this.appearance = appearance ?? StyleAppearance.WARNING;
        this.options = options ?? {};
        this.size = size ?? StyleSize.SMALL;
        this.x = x ?? StylePosition.CENTER;
        this.y = y ?? StylePosition.TOP;

        toastr.options = {
            closeButton: this.options['closeButton'] ?? true,
            debug: this.options['debug'] ?? false,
            newestOnTop: this.options['newestOnTop'] ?? true,
            preventDuplicates: this.options['preventDuplicates'] ?? true,
            showDuration: this.options['showDuration'] ?? 200,
            hideDuration: this.options['hideDuration'] ?? 1200,
            timeOut: this.options['timeout'] ?? 5000,
            extendedTimeOut: this.options['extendedTimeOut'] ?? 1500,
            positionClass: `toast-${this.y}-${this.x}`,
            showEasing: this.options['showEasing'] ?? 'swing',
            hideEasing: this.options['hideEasing'] ?? 'linear',
            showMethod: this.options['showMethod'] ?? 'fadeIn',
            hideMethod: this.options['hideMethod'] ?? 'fadeOut',
        };

        let toast = null;
        switch (this.appearance) {
            case StyleAppearance.DANGER:
                toast = toastr.error(this.message, this.title);
                break;
            case StyleAppearance.SECONDARY:
                toast = toastr.success(this.message, this.title);
                break;
            case StyleAppearance.INFO:
                toast = toastr.info(this.message, this.title);
                break;
            case StyleAppearance.WARNING:
                toast = toastr.warning(this.message, this.title);
                break;
        }

        if (toast) {
            toast[0].classList.add(this.size);
        }

        const promiseCallback = (resolve: (thenableOrResult?: any) => void, reject: (error?: any) => void, onCancel?: (callback: () => void) => void): void => {
            this.resolveHandler = resolve;
            this.rejectHandler = reject;
        };
        return new Bluebird(promiseCallback);
    }

}
