import {Component} from 'vue-facing-decorator';
import {FormSelect} from '@/app/views/components/form/FormSelect';

@Component({
    emits: [
        'open',
        'close',
    ],
})
export default class FormSelectPicker<U extends string | number | boolean = string> extends FormSelect {

    public created(): void {
    }

    public mounted(): void {
    }

}
