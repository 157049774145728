export const enum PurchaseOrderPaymentMethodTypes {

    BANK_TRANSFER = 'bank_transfer',
    CREDIT_CARD = 'creditcard',
    IDEAL = 'ideal',
    PAYPAL = 'paypal',
    GIROPAY = 'giropay',
    SOFORT = 'sofort',
    BANCONTACT = 'bcmc',

}
