<script lang="ts" src="./ProductFabrics.ts"></script>


<template>
  <div v-if="showCaption" class="flex items-center relative">
    <small class="m-caption mb-1">{{ $trans.t('pages.sellerDashboard.project.fabrics') }}</small>

    <m-tooltip :label="tooltipMessage">
      <m-icon
        :solid="true"
        :type="$const.IconTypes.FONT_AWESOME"
        class="mb-1 px-1 text-sm text-teal-300 cursor-pointer"
        name="circle-info"
      />
    </m-tooltip>
  </div>

  <ul v-if="fabrics?.length > 0" class="flex flex-wrap -mt-2 -ml-2">
    <li v-for="fabric in fabrics" class="mt-2 ml-2">
      <m-badge :appearance="$const.StyleAppearance.NEUTRAL">
        <strong>{{ fabric.percentage }}&percnt;&nbsp;</strong>
        <span class="font-normal">{{ $trans.t('meta.fabrics.' + fabric.name) }}</span>
      </m-badge>
    </li>
  </ul>
  <h5 v-else class="flex flex-row items-center mt-1 font-body font-medium text-tiny leading-tight text-neutral-400">
    <m-icon
      :solid="true"
      :type="$const.IconTypes.MANUFY"
      class="icon mr-1"
      name="denim"
    />

    <p v-if="hasFabrics">{{ $trans.t('pages.sellerDashboard.products.fabrics.haveOwn') }}</p>
    <em v-else class="text-neutral-60">{{ $trans.t('app.labels.notAvailable') }}</em>
  </h5>
</template>


<style lang="scss" scoped>
</style>
