import {createI18n, type I18n, type I18nOptions} from 'vue-i18n';
import {Lang} from '@/app/lang/Lang';

export class Translations {

    private static i18nInst: I18n = null;

    /**
     * Get the global i18n instance.
     */
    public static get instance(): any {  // For some reason the types of i18n.global don't represent what is actually available in the object
        return Translations.i18n.global;
    }

    /**
     * Get the i18n instance.
     */
    public static get i18n(): I18n {
        return Translations.i18nInst;
    }

    /**
     * The current locale.
     */
    public static get locale(): string {
        return <string>Translations.i18nInst.global.locale;
    }

    public static init(): void {
        // Loop over the entire lang structure and fill the `Lang.t` value with paths to the corresponding translation.
        const parse = (node: any, path: string = null) => {
            if (node instanceof Object) {
                path = (path) ? path + '.' : '';
                const copy = (node instanceof Array) ? [] : {};
                for (let key in node) {
                    if (!node.hasOwnProperty(key)) {
                        continue;
                    }
                    copy[key] = parse(node[key], path + key);
                }
                return copy;
            } else {
                return path;
            }
        }

        Lang.t = <typeof Lang.translations>parse(Lang.translations);

        const i18nOptions: I18nOptions = {
            missing: (locale: string, id: string): string => {
                const msg: string = 'Translation for [' + locale + ' > ' + id + '] is missing!';
                if (locale == Lang.defaultLocale) {
                    throw new Error(msg);
                } else {
                    console.warn(msg);
                }
                return null;
            },
            silentFallbackWarn: true,
            warnHtmlInMessage: 'off',    // 'off' | 'warn' | 'error'
            fallbackLocale: Lang.defaultLocale,
        };
        Translations.i18nInst = createI18n(i18nOptions);
        Translations.i18nInst.global.setLocaleMessage(Lang.defaultLocale, Lang.translations);
        Translations.i18nInst.global.setNumberFormat(Lang.defaultLocale, Lang.numberFormats);
        Translations.i18nInst.global.setDateTimeFormat(Lang.defaultLocale, Lang.dateFormats);
        Translations.i18nInst.global.locale = Lang.defaultLocale;
    }

    public static loadLocale(locale: string): Promise<any> {
        if (locale == Lang.defaultLocale) {
            return null;
        }

        // let promise:Promise<any> = import('@/assets/lang/' + locale + '.json');
        let promise: Promise<any> = fetch('/lang/' + locale + '.json').then((response) => {
            return response.json();
        });
        promise = promise.then((result) => {
            Translations.i18nInst.global.setLocaleMessage(locale, result.translations);
            Translations.i18nInst.global.setNumberFormat(locale, result.numberFormats ?? Lang.numberFormats);
            Translations.i18nInst.global.setDateTimeFormat(locale, result.dateFormats ?? Lang.dateFormats);
        });
        promise = promise.catch(() => {
            console.warn('Translations for [' + locale + '] couldn\'t be loaded!');
        });
        promise = promise.finally(() => {
            Translations.i18nInst.global.locale = locale;   // Will force fallback to default language
        });

        return promise;
    }

}
