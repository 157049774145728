export const enum AutocompleteStandard {

    // Values based on: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill

    USERNAME = 'username',
    FIRST_NAME = 'given-name',
    LAST_NAME = 'family-name',
    NEW_PASSWORD = 'new-password',
    EMAIL = 'email',
    CURRENT_PASSWORD = 'current-password',
    ORGANIZATION = 'organization"',
    PHONE_NUMBER = 'tel-local',
    PHONE_COUNTRY_CODE = 'tel-country-code',
    ADDRESS_LINE_1 = 'address-line1',
    ADDRESS_LINE_2 = 'address-line2',
    CITY = 'address-level2',
    ZIP_CODE = 'postal-code',
    COUNTRY = 'country',

}
