import {Component, Prop} from 'vue-facing-decorator';
import {FormItem} from '@/app/views/components/form/FormItem';

@Component({})
export default class FormFieldCheckbox extends FormItem<boolean> {

    @Prop({type: [Boolean], default: null})
    declare public modelValue: boolean;

    declare public $refs: {
        checkbox: HTMLInputElement;
    };

    public handleInput(evt: Event): void {
        this.updateValue((evt.target as HTMLInputElement).checked);
    }

}
