import {Component, Prop, Vue} from 'vue-facing-decorator';
import {PricingLineDTO} from "@/app/dto/PricingLineDTO";

type MoqRangePriceModel = {
    moqRange: string,
    moqRangePrice: number,
};

@Component({})
export default class CatalogueProductPricingTable extends Vue {

    @Prop({type: Object, default: null})
    public readonly pricingLines: PricingLineDTO[] = null;

    @Prop({type: Boolean, default: false})
    public readonly singleLine: boolean;

    public get formattedPricingLines(): MoqRangePriceModel[] {
        const moqRangePrices: MoqRangePriceModel[] = [];

        this.pricingLines.forEach((line, index) => {
            const currentMoq: number = line.moq;
            const nextMoq: number = (index < this.pricingLines.length - 1) ? this.pricingLines[index + 1].moq : null;
            const moqRange: string = nextMoq ? `${currentMoq} - ${nextMoq - 1}` : `${currentMoq}+`;
            const moqRangePrice: number = line.price;

            moqRangePrices.push({moqRange, moqRangePrice});
        });

        return moqRangePrices;
    }

    public created(): void {
    }

    public shouldShowRow(index): boolean {
        const range: string = this.formattedPricingLines[index].moqRange;

        return !range.includes('null -') && !range.includes('null+');
    }
}
