/**
 * Style: Alignment.
 */
export const enum Align {

    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',

}
