export const enum FormButtonLabels {

    BACK = 'app.buttonLabels.back',
    CANCEL = 'app.buttonLabels.cancel',
    CLEAR = 'app.buttonLabels.clear',
    CREATE_ACCOUNT = 'app.buttonLabels.createAccount',
    NEXT = 'app.buttonLabels.next',
    RESET = 'app.buttonLabels.reset',
    RESET_PASSWORD = 'app.buttonLabels.resetPassword',
    SAVE = 'app.buttonLabels.save',
    SEND = 'app.labels.send',
    SEND_FEEDBACK = 'app.buttonLabels.sendFeedback',
    SEND_LINK = 'app.buttonLabels.sendLink',
    SIGN_IN = 'app.buttonLabels.signIn',
    SKIP = 'app.buttonLabels.skip',
    START_TRIAL = 'app.buttonLabels.startTrial',
    SUBMIT = 'app.buttonLabels.submit',
    UPDATE_AVAILABILITY_STATUS = 'app.buttonLabels.updateAvailabilityStatus',

}
