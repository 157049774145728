<script lang="ts" src="./FormFieldPhoneNumberWrapper.ts"></script>

<template>
  <div class="m-input-group">
    <m-form-label
      :id="id"
      :required="required"
      :tooltipLabel="tooltipLabel"
      :tooltipLink="tooltipLink"
      @click="focus($event)"
    >
      {{ label ?? $trans.t('app.form.phone.label') }}
    </m-form-label>

    <div class="flex relative">
      <m-form-select-country-picker
        :id="id"
        ref="phoneCountryCode"
        :autocomplete="AutocompleteStandard.PHONE_COUNTRY_CODE"
        :direction="direction"
        :disabled="disabled"
        :dusk="'country-code'"
        :items="$store.meta.phoneCountryCodes"
        :modelValue="phoneCountryCode"
        :required="required"
        :size="size"
        class="mr-3"
        @updatePhoneCountryCode="(countryCode: string) => updateCountryCode(countryCode)"
      />

      <m-form-field-number
        ref="phoneNumber"
        :autocomplete="AutocompleteStandard.PHONE_NUMBER"
        :disabled="disabled"
        :dusk="'phone-number'"
        :modelValue="phoneNumber"
        :placeholder="placeholder ?? $trans.t('app.form.phone.placeholder')"
        :required="required"
        :size="size"
        class="flex-1"
        @handleInput="(phoneNumber: number) => updatePhoneNumber(phoneNumber)"
      >
        <template #prepend>
          <span class="prepend"><span class="text-neutral-60">{{ phoneCountryCode }}</span></span>
        </template>
      </m-form-field-number>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>

    <p v-if="supportText" class="support-text">
      {{ supportText }}
    </p>
  </div>
</template>


<style lang="scss" scoped>
</style>
