import {ComponentBase, Prop, Watch} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {StyleSize} from '@/app/constants/Constants';
import {DeviceTypes} from '@/app/constants/DeviceTypes';
import {StylePosition} from '@/app/constants/styles/StylePosition';
import {Store} from '@/app/stores/Store';
import {FormItem} from '@/app/views/components/form/FormItem';
import {IIcon} from '@/app/views/components/misc/Icon';

/**
 * A definition of a select picker item.
 * The item needs to have at least an id property.
 * If the `label` property isn't set, the `id` will be used as label.
 */
export type FormSelectItem<T extends string | number | boolean> = { [key: string]: any; } & { id?: T, label?: string, append?: string, icon?: IIcon, searchable?: boolean };

@ComponentBase({
    emits: [
        'open',
        'close',
    ],
})
export abstract class FormSelect<U extends string | number | boolean = string> extends FormItem<U | U[]> {

    @Prop({type: [String, Number, Boolean, Array], default: null})
    declare public readonly modelValue: U | U[];

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: String, default: null})
    public readonly allLabel: string;

    @Prop({type: Object, default: null})
    public readonly prependIcon: IIcon;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: StyleSize;

    @Prop({type: String, default: StylePosition.BOTTOM})
    public readonly direction: StylePosition;

    @Prop({type: Number, default: 2})
    public readonly dropdownMaxCols: number;

    @Prop({type: Boolean, default: false})
    public readonly multiselect: boolean;

    @Prop({type: Boolean, default: false})
    public readonly handleResize: boolean;

    @Prop({type: Boolean, default: false})
    public readonly omitItemIcons: boolean;

    @Prop({type: Number, default: -1})
    public readonly maxVisibleStep: number;

    declare public readonly $refs: {
        dropdown: HTMLDivElement,
        formField: HTMLDivElement,
        filterInput: HTMLInputElement,
    };

    public readonly flags = {
        opened: false,
        initialTap: true,
    };

    public filterStr: string = null;
    public maxVisible: number = -1;
    @Prop({type: Array, required: true})
    protected readonly items: FormSelectItem<U>[];
    private zeroTimePoint: number = null;
    private resizeHandler: FormSelect['handleWindowResize'] = null;

    public get selectedItems(): FormSelectItem<U>[] {
        const values: U[] = (this.value instanceof Array) ? this.value : [this.value];
        return this.items.filter((item: FormSelectItem<U>) => {
            return values.includes(item.id);
        });
    }

    /**
     * Returns an array with all items based on the search value.
     */
    public get filteredItems(): FormSelectItem<U>[] {
        if (this.filterStr) {
            const filterStr: string = this.filterStr.toLowerCase();
            return this.items.filter((value: FormSelectItem<U>): boolean => {
                if (!value.id || value.searchable === false) {    // Is group or non-searchable
                    return false;
                }
                const label: string = value.label ?? '' + value.id;
                return label.toLowerCase().includes(filterStr);
            });
        }
        return Array.from(this.items);  // Always make a copy to make sure the original array isn't changed
    }

    /**
     * A shortened list based on the maximum number of items visibile.
     */
    public get visibleItems(): FormSelectItem<U>[] {
        return (this.maxVisible > 0) ? this.filteredItems.slice(0, this.maxVisible) : Array.from(this.filteredItems);
    }

    /**
     * The label to be displayed in the field when it's not in search mode.
     * In case null is returned the placeholder value will be used.
     */
    public get selectedLabel(): string {
        let label: string = null;
        if (this.selectedItems.length > 0) {
            if (this.multiselect) {
                label = Main.trans.tc('components.selectPicker.multiselectLabel', this.selectedItems.length, {count: this.selectedItems.length});
            } else {
                label = this.selectedItems[0].label ?? '' + this.selectedItems[0].id;
            }
        }
        return label;
    }

    public get allSelected(): boolean {
        return (this.selectedItems.length == this.items.length);
    }

    public created(): void {
        if (this.handleResize) {
            this.resizeHandler = this.handleWindowResize.bind(this);
        }

        if (this.maxVisibleStep > 0) {
            this.maxVisible = this.maxVisibleStep;
        }
    }

    public mounted(): void {
        if (this.handleResize) {
            window.addEventListener('resize', this.resizeHandler);
            this.handleWindowResize();
        }
    }

    public handleChevronClick(event: MouseEvent): void {
        if (this.flags.opened) {
            event.stopPropagation();    // Prevent from opening-up again
            this.close();
        }
    }

    public handleFieldClick(event: MouseEvent): void {
        if (this.handleResize) {
            this.handleWindowResize();
        }

        this.open();

        // Wait a tick so the element can receive focus
        requestAnimationFrame((timeStamp) => {
            this.zeroTimePoint = timeStamp;
            this.focusOnFilterInput(timeStamp);
        });
    }

    public open(): void {
        this.flags.opened = true;
        this.$emit('open');
    }

    public close(): void {
        this.flags.opened = false;
        this.flags.initialTap = true;
        this.$emit('close');
    }

    public clickItem(item: FormSelectItem<U> = null): void {
        if (this.multiselect && this.checkItemSelected(item)) {
            this.deselectItem(item);
        } else {
            this.selectItem(item);
        }
    }

    public checkItemSelected(item: FormSelectItem<U> = null): boolean {
        return (this.value === item.id || (this.value instanceof Array && this.value.includes(item.id)));
    }

    public selectItem(item: any): void {
        let v: U | U[] = this.value;
        if (v instanceof Array) {
            v.push(item.id);
        } else {
            v = item.id;
        }

        if (!this.multiselect) {
            this.flags.opened = false;
        }
        this.updateValue(v);
    }

    public deselectItem(item: any): void {
        let v: U | U[] = this.value;
        if (v instanceof Array) {
            v.remove(item.id);
        } else if (v === item.id) {
            v = null;
        }
        this.updateValue(v);
    }

    public selectAllItems(): void {
        let v: U[] = this.items.map((item) => {
            return item.id;
        });
        this.updateValue(v);
    }

    public deselectAllItems(): void {
        this.updateValue((this.value instanceof Array) ? [] : null);
    }

    // This only pushes elements to the array. Even if the UI is a radio option, still this array would become bigger when you click another radio option. It should instead filter and replace the selected element if not multiselect.
    public showMore(): void {
        this.maxVisible += this.maxVisibleStep;
    }

    public showLess(): void {
        this.maxVisible = this.maxVisibleStep;
    }

    private focusOnFilterInput(timeStamp) {
        const value = (timeStamp - this.zeroTimePoint) / 150;
        if (value < 1) {
            requestAnimationFrame((t) => this.focusOnFilterInput(t));
        } else {
            if ((Store.app.device != DeviceTypes.MOBILE) || (Store.app.device == DeviceTypes.MOBILE && !this.flags.initialTap)) {
                this.$refs.filterInput.focus();
            }
            this.flags.initialTap = false;
        }
    }

    private handleWindowResize(): void {
        const formFieldRect = this.$refs.formField?.getBoundingClientRect();

        if (this.$refs.dropdown) {
            this.$refs.dropdown.style.position = 'fixed';
            this.$refs.dropdown.style.width = formFieldRect.width + 'px';
            this.$refs.dropdown.style.top = (formFieldRect.top + formFieldRect.height + 8) + 'px';
            this.$refs.dropdown.style.right = formFieldRect.right + 'px';
            this.$refs.dropdown.style.left = formFieldRect.left + 'px';
        }
    }

    @Watch('flags', {deep: true})
    private updateInputFocusRing(_newValue: object, _oldValue: object): void {
        if (_newValue['opened'] === true) {
            this.$refs.formField.classList.add('ring-1', 'ring-neutral-400');
        } else if (_newValue['opened'] === false) {
            this.$refs.formField.classList.remove('ring-1', 'ring-neutral-400');
        }
    }

    // TODO: @Krasi - Implement a function that scrolls to the selected item(s) in the dropdown once the user opens it
    // TODO: @Krasi - Close the dropdown when switching between input fields and other elements on the DOM using the keyboard. For example, when switching between input fields with the Tab key.

}
