<script lang="ts" src="./FormFieldTextAreaWrapper.ts"></script>

<template>
  <div class="m-input-group">
    <m-form-label
      v-if="label"
      :id="id"
      :disabled="disabled"
      :required="required"
      :tooltipLabel="tooltipLabel"
      :tooltipLink="tooltipLink"
    >
      {{ label }}
    </m-form-label>

    <m-form-field-text-area
      v-if="!editor"
      :id="id"
      ref="textArea"
      :autoGrow="autoGrow"
      :disabled="disabled"
      :dusk="dusk"
      :errors="errors"
      :fullHeight="fullHeight"
      :manualResize="manualResize"
      :maxHeight="maxHeight"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :required="required"
      :rows="rows"
      :size="size"
      @update:modelValue="updateValue"
    />

    <m-form-field-text-area-editor
      v-else
      :id="id"
      ref="textAreaEditor"
      :disabled="disabled"
      :dusk="dusk"
      :errors="errors"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :required="required"
      :rows="rows"
      :size="size"
      @update:modelValue="updateValue"
    />

    <div class="support-text space-y-1">
      <p :class="!isCharCountValid ? ((disabled) ? ('text-neutral-60') : ('text-red-400')) : ''">
        <span>&lbrace;{{ charCount ?? 0 }}</span>
        <span v-if="maxCharCount">&sol;{{ maxCharCount }}</span>
        <span>&rbrace;</span>
      </p>
      <p v-if="supportText">{{ supportText }}</p>
    </div>
  </div>
</template>


<style lang="scss" scoped>
</style>
