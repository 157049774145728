<script lang="ts" src="./VatValidationModal.ts"/>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-medium secondary">

      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.vatModal.title') }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <div class="dialog-body">
        <div class="mb-5" v-html="$trans.t('app.vatModal.body')"></div>
        <m-form-field v-model="formData.model.vatNumber" :errors="formData.errors.vatNumber" :placeholder="$trans.t('app.vatModal.placeholder')" class="mb-5"/>
      </div>

      <div class="dialog-footer justify-end">
        <div class="pb-3 pl-3">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.GHOST"
            @click="hide()"
          >
            {{ $trans.t('app.labels.cancel') }}
          </m-button>
        </div>

        <div class="pb-3 pl-3">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :disabled="flags.loading"
            :size="$const.StyleSize.SMALL"
            @click="submit()"
          >
            {{ $trans.t('app.vatModal.btnLabel') }}
          </m-button>
        </div>
      </div>

    </div>
  </div>

</template>

<style lang="scss" scoped>
.dialog-footer {
  @apply flex flex-wrap -mb-3 -ml-3 justify-end rounded-b-xl;
}

</style>
