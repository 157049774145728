import {Component, Prop} from 'vue-facing-decorator';
import {FormFieldRadio} from '@/app/views/components/form/FormFieldRadio';
import {IIcon} from '@/app/views/components/misc/Icon';

@Component({})
export default class FormFieldRadioTab extends FormFieldRadio {

    @Prop({type: Object, default: null})
    public icon: IIcon;

}
