import {Component, Prop, Vue} from 'vue-facing-decorator';
import {FormData} from "@/app/utils/FormData";
import {IconTypes} from "@/app/constants/IconTypes";
import {CompanyTurnover} from "@/app/constants/CompanyTurnover";
import {CompanyTotalEmployees} from "@/app/constants/CompanyTotalEmployees";
import {CompanyAvailabilityStatus} from "@/app/constants/CompanyAvailabilityStatus";
import {CompanySubscriptionTypes} from "@/app/constants/CompanySubscriptionTypes";

type BaseFilterModel = {
    name?: string;
    email?: string;
    city?: string;
    countries?: string[],
    timeframe?: string;
    createdAt?: string;
    categories?: string[],
    annualTurnover?: CompanyTurnover[];
    totalEmployees?: CompanyTotalEmployees[];
    sortColumn?: string;
    sortDirection?: string;
    isVerified?: boolean,
    isOpenToFeedback?: boolean,
    isDeleted?: boolean,
    availabilityStatus?: CompanyAvailabilityStatus[],
    subscriptionStatus?: CompanySubscriptionTypes[],
    perPage?: string;
    page?: number;
};

@Component({})
export default class AdminCompanyFilters extends Vue {

    @Prop({type: Object, required: true,})
    public filterData: FormData<any> = null;

    @Prop({type: Object, required: true,})
    public sortByColumn: Array<any> = null;

    @Prop({type: Boolean, required: true,})
    public isOnboarding: Boolean = false;

    @Prop({type: Object, required: false,})
    public availabilityStatus: Array<any> = null;

    public createdAtTimeframe = [
        {id: null, label: ''},
        {id: '<', label: 'Before'},
        {id: '>', label: 'After'}
    ];

    public sortDirection = [
        {id: null, label: ''},
        {id: 'desc', label: 'High to Low', icon: {name: 'arrow-down', solid: true, type: IconTypes.FONT_AWESOME}},
        {id: 'asc', label: 'Low to High', icon: {name: 'arrow-up', solid: true, type: IconTypes.FONT_AWESOME}},
    ];

    public subscriptionStatus = [
        {id: 'unpaid', label: 'Unpaid'},
        {id: 'manual', label: 'Manual'},
        {id: 'paid', label: 'Paid'}
    ];

    public created(): void {
    }

    public mounted(): void {
    }

}

