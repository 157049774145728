import {Castable} from '@/app/utils/Castable';
import {Uuid} from '@/types/Types';

export class PurchaseOrderProductDTO extends Castable {

    public uuid: Uuid = null;
    public productUuid: Uuid = null;
    public productName: string = null;
    public size: string = null;
    public quantity: number = null;
    public unitPrice: number = null;
    public totalPrice: number = null;

}
