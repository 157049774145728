import {Component, Vue} from 'vue-facing-decorator';
import {Main} from '@/app/Main';

@Component({})
export default class ProductListSupportBlock extends Vue {

    public openIntercom(): void {
        Main.openIntercom();
    }

}
