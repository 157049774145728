import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {StyleSize} from '@/app/constants/Constants';

@Component({
    emits: [
        'buttonAOnClick',
        'buttonBOnClick',
    ],
})
export default class SectionMessage extends Vue {

    @Prop({type: String, required: false, default: StyleAppearance.PRIMARY})
    public readonly appearance: StyleAppearance;

    @Prop({type: String, required: false, default: StyleSize.MEDIUM})
    public readonly size: StyleSize;

    @Prop({type: String, required: false, default: null})
    public readonly iconName: string;

    @Prop({type: Boolean, required: false, default: true})
    public readonly closable: boolean;

    @Prop({type: Boolean, required: false, default: false})
    public readonly solid: boolean;

    @Prop({type: String, required: false, default: null})
    public readonly title: string;

    @Prop({type: String, required: false, default: null})
    public readonly message: string;

    @Prop({type: String, required: false, default: null})
    public readonly buttonA: string;

    @Prop({type: [String, Object], required: false, default: null})
    public readonly buttonATo: string;

    @Prop({type: Boolean, required: false, default: false})
    public readonly buttonANewWindow: boolean;

    @Prop({type: String, required: false, default: null})
    public readonly buttonB: string;

    @Prop({type: [String, Object], required: false, default: null})
    public readonly buttonBTo: string;

    @Prop({type: Boolean, required: false, default: false})
    public readonly buttonBNewWindow: boolean;

    public readonly flags = {
        show: true,
    };

    public get icon(): string {
        if (this.iconName) return this.iconName;

        switch (this.appearance) {
            case StyleAppearance.WARNING:
                return 'triangle-exclamation';
            case StyleAppearance.DANGER:
                return 'circle-exclamation';
            case StyleAppearance.SECONDARY:
                return 'thumbs-up';
            case StyleAppearance.PRIMARY:
            case StyleAppearance.INFO:
                return 'circle-info';
            case StyleAppearance.HELP:
                return 'star';
        }
        return null;
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public close(): void {
        this.flags.show = false;
    }

    public buttonAOnClick(): void {
        this.$emit('buttonAOnClick');
    }

    public buttonBOnClick(): void {
        this.$emit('buttonBOnClick');
    }

}
