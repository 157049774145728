import Bluebird from 'bluebird';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Store} from '@/app/stores/Store';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';

export class StripeHelper {

    /**
     * Load and open a link to the Stripe Customer Portal.
     */
    public static openCustomerPortal(): void {
        const payload: Record<string, any> = {};
        payload.uuid = Store.user.user.companyUuid;

        let promise: Bluebird<any> = Main.callApi('company/subscription/portal', RequestMethods.GET, payload);
        promise = promise.then((result: { url?: string }) => {
            if (!result.url) {
                Routes.resolveLink({name: 'dashboard'}, false, false);
            }
            Routes.resolveLink(result.url, false, false);
        });
    }

}
