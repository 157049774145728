import Bluebird from 'bluebird';
import {Main} from '@/app/Main';
import {LocationDTO} from '@/app/dto/LocationDTO';
import {StoreBase} from '@/app/stores/StoreBase';
import {ListResult} from '@/app/utils/ListResult';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Uuid} from '@/types/Types';

interface ILocationsState {
}

/**
 *
 */
export class LocationsStore extends StoreBase<ILocationsState> {

    constructor() {
        super();

    }

    /**
     * Get all current locations for the given company.
     */
    public async getLocations(companyUuid: Uuid): Promise<LocationDTO[]> {
        const resolveHandler = (resolve: Function) => {
            const payload: Record<string, any> = {};
            payload.uuid = companyUuid;

            let promise: Bluebird<any> = Main.callApi('company/locations', RequestMethods.GET, payload, [LocationDTO]);
            promise = promise.then((result: ListResult<LocationDTO>) => {
                resolve(result.items);
            });
        };

        return new Promise(resolveHandler);
    }

    /**
     * Add a location to the given company.
     * Once stored the new location entity will be returned.
     */
    public async addLocation(companyUuid: Uuid, location: LocationDTO): Promise<LocationDTO> {
        const resolveHandler = (resolve: Function) => {
            const payload: Record<string, any> = {};
            payload.companyUuid = companyUuid;
            payload.name = location.name;
            payload.address1 = location.address1;
            payload.address2 = location.address2;
            payload.zipcode = location.zipcode;
            payload.city = location.city;
            payload.region = location.region;
            payload.country = location.country;

            let promise: Bluebird<any> = Main.callApi('company/location', RequestMethods.POST, payload, LocationDTO);
            promise = promise.then((result: LocationDTO) => {
                resolve(result);
            });
        };

        return new Promise(resolveHandler);
    }

    public async getLocationByUuid(locationUuid: Uuid, companyUuid: Uuid): Promise<LocationDTO> {
        const resolveHandler = (resolve: Function) => {
            if (!locationUuid) {
                resolve(null);
                return;
            }

            const payload: Record<string, any> = {};
            payload.uuid = locationUuid;
            payload.companyUuid = companyUuid;

            let promise: Bluebird<any> = Main.callApi('company/location', RequestMethods.GET, payload, LocationDTO);
            promise = promise.then((result: LocationDTO) => {
                resolve(result);
            });
            promise = promise.catch(() => {
                resolve(null);
            });
        };

        return new Promise(resolveHandler);
    }

}
