<script lang="ts" src="./SectionMessage.ts"></script>


<template>
  <div v-if="flags.show" :class="['section-message', size, appearance, solid ? 'solid' : '', title ? 'has-title' : '']">
    <div class="section-message__header">
      <m-icon :name="icon" :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon"/>

      <p v-if="!title" class="message" v-html="message"></p>
      <h6 v-else class="title">{{ title }}</h6>

      <button v-if="closable" class="p-0.5 close" type="button" @click="close()">
        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
      </button>
    </div>
    <div class="section-message__body">
      <p v-if="message && title" class="message" v-html="message"></p>
      <div v-if="buttonA" class="section-message__footer">
        <m-button
          :appearance="$const.StyleAppearance.DARK"
          :newWindow="buttonANewWindow"
          :size="$const.StyleSize.SMALL"
          :to="buttonATo"
          class="button-a"
          @click="buttonAOnClick"
        >
          <span class="label">{{ buttonA }}</span>
        </m-button>

        <m-button
          v-if="buttonB"
          :appearance="$const.StyleAppearance.DARK"
          :newWindow="buttonBNewWindow"
          :size="$const.StyleSize.SMALL"
          :to="buttonBTo"
          :variant="$const.StyleVariants.OUTLINE"
          class="button-b"
          @click="buttonBOnClick"
        >
          <span class="label">{{ buttonB }}</span>
        </m-button>
      </div>
      <slot>

      </slot>
    </div>
  </div>
</template>


<style lang="scss" scoped>
</style>
