import {CastableProperties} from '@/app/utils/Castable';
import {BaseProjectDTO} from "@/app/dto/BaseProjectDTO";
import {RfqServiceDTO} from "@/app/dto/RfqServiceDTO";
import {SellerRfqProductDTO} from "@/app/dto/SellerRfqProductDTO";
import {Uuid} from "@/types/Types";
import {SellerRfqServiceDTO} from "@/app/dto/SellerRfqServiceDTO";

export class SellerProjectDTO extends BaseProjectDTO {

    public updatedAt: Date = null;
    public deliveryDate: Date = null;

    public collaborationUuid: Uuid = null;

    public products: SellerRfqProductDTO[] = [];
    public services: SellerRfqServiceDTO[] = [];

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            updatedAt: Date,
            deliveryDate: Date,
            products: [SellerRfqProductDTO],
            services: [RfqServiceDTO],
        };
    }

}
