import {Component, Prop, Vue} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";
import {CompanyTypes} from "@/app/constants/CompanyTypes";
import {IconTypes} from '@/app/constants/IconTypes';
import {Store} from '@/app/stores/Store';
import {IIcon} from '@/app/views/components/misc/Icon';
import {CompanySubscriptionTierTypes} from '@/app/constants/CompanySubscriptionTierTypes';
import {CompanyAvailabilityStatus} from '@/app/constants/CompanyAvailabilityStatus';
import {Lang} from '@/app/lang/Lang';

@Component({})
export default class CompanyShortCard extends Vue {

    @Prop({type: Boolean, default: true})
    public readonly hasShadow: boolean;

    @Prop({type: Object, default: null})
    public readonly company: CompanyShortDTO;

    public readonly CompanyTypes = CompanyTypes;

    public readonly CompanyAvailabilityStatus = CompanyAvailabilityStatus;

    public get availabilityStatusColor(): string {
        switch (this.company.availabilityStatus) {
            case CompanyAvailabilityStatus.FULL_CAPACITY:
            case CompanyAvailabilityStatus.NOT_ACTIVELY_SEARCHING:
                return 'bg-red-400 text-neutral-800';
            case CompanyAvailabilityStatus.ON_HOLIDAY:
            case CompanyAvailabilityStatus.PROJECT_IN_PROCESS:
                return 'bg-yellow-300 text-neutral-800';
            case CompanyAvailabilityStatus.OPEN_CAPACITY:
            case CompanyAvailabilityStatus.LOOKING_FOR_PARTNERS:
                return 'bg-green-400 text-neutral-0';
        }

        return 'bg-smoke-0 text-neutral-400';
    }

    public get availabilityStatusLabel(): string {
        if (!this.company.availabilityStatus) return;

        switch (this.company.companyType) {
            case CompanyTypes.BUYER:
                return Main.trans.t(Lang.t.meta.buyerAvailabilityStatuses[this.company.availabilityStatus]);
            case CompanyTypes.SELLER:
                return Main.trans.t(Lang.t.meta.sellerAvailabilityStatuses[this.company.availabilityStatus]);
            default:
                null;
        }
    }

    public get companyPublicPage(): object {
        if (this.company.companyType === CompanyTypes.BUYER) {
            return {name: 'publicBuyerRead', params: {uuid: this.company.uuid}};
        }
        if (this.company.companyType === CompanyTypes.SELLER) {
            return {name: 'publicSellerRead', params: {uuid: this.company.uuid}};
        }
    }

    public get isSubscribed(): boolean {
        return this.company.subscriptionTier &&
            this.company.subscriptionTier !== CompanySubscriptionTierTypes.BUYER_TIER_0 &&
            this.company.subscriptionTier !== CompanySubscriptionTierTypes.SELLER_TIER_0;
    }

    public get subscriptionIcon(): IIcon {
        return {
            name: (this.isSubscribed) ? ('check') : ('times'),
            solid: true,
            type: IconTypes.FONT_AWESOME,
        };
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public sendProjectToSeller(): void {
        if (Store.user.isBuyer) {
            Main.app.openProjectSendModal(this.company.uuid);
        } else if (Store.user.user === null) {
            Routes.resolveLink({name: 'rfqWizard', query: {suuid: this.company.uuid}});
        }
    }
}
