import Bluebird from 'bluebird';
import draggable from 'vuedraggable';
import {Component, Prop} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {FileDTO} from '@/app/dto/FileDTO';
import {ApiResponseCodes} from '@/app/constants/ApiResponseCodes';
import {FileMimes} from '@/app/constants/FileMimes';
import {Lang} from '@/app/lang/Lang';
import {FormItem} from '@/app/views/components/form/FormItem';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {WebServiceError} from '@/libs/core/errors/WebServiceError';

@Component({
    components: {
        draggable,
    },
})
export default class FormFileUpload extends FormItem<FileDTO[]> {

    @Prop({type: Array, default: []}) // Only valid files are placed in this list
    declare public modelValue: [];

    @Prop({type: Boolean, default: false}) // In case of single, the FileDTO is still stored in the modelValue array; the array will then always have at most 1 item!
    declare public multiple: boolean;

    @Prop({type: String, default: null})
    public readonly accept: string;

    @Prop({type: Number, default: null})
    public readonly aspectRatio: number;

    @Prop({type: Number, default: null})
    public readonly minWidth: number;

    @Prop({type: Number, default: null})
    public readonly minHeight: number;

    declare public readonly $refs: {
        field: HTMLInputElement;
    };
    public erroneousFiles: FileDTO[] = [];
    public entering: boolean = false;
    public readonly flags = {
        entering: false,
        notAllowed: false,
        fileDragDisabled: false,
    };
    private numLoading: number = 0;
    private readonly events: string[] = ['dragenter', 'dragleave', 'dragover', 'drop'];

    /**
     * The current number of files being uploaded to the server.
     */
    public get numFilesLoading(): number {
        return this.numLoading;
    }

    public get canSubmitFiles(): boolean {
        return this.erroneousFiles?.length === 0 && this.numFilesLoading === 0;
    }

    public get fileDragDisabled(): boolean {
        return this.flags.fileDragDisabled;
    }

    public get acceptedFileTypes(): string {
        let result: string = this.accept
        if (this.accept === FileMimes.FILES) {
            result = result.replace(',' + FileMimes.DESIGN_ALIASES, '');
        }

        result = result.replaceAll('image/', '').replaceAll('application/', '').replaceAll('text/', '').replaceAll('.', '');

        return result.split(',').join(', ');
    }

    public get filesStillUploading(): number {
        const files: FileDTO[] = this.modelValue;
        let result: number = 0;
        files?.forEach(f => (!f.fileSize) ? result++ : null)

        return result;
    }

    public mounted(): void {
        this.events.forEach(event => document.body.addEventListener(event, (e) => e.preventDefault()));
    }

    public beforeUnmounted(): void {
        this.events.forEach(event => document.body.removeEventListener(event, (e) => e.preventDefault()));
    }

    public acceptedFiles(files: File[]): File[] {
        return files.filter(f => this.accept.includes(f.type));
    }

    /**
     * Handle new files being selected by the file input element.
     */
    public async handleFilesSelected(evt: Event): Promise<void> {
        const fileList: FileList = (evt.target as HTMLInputElement).files;

        this.handleFileList(fileList);
    }

    public deleteFile(dto: FileDTO): void {
        dto.promise?.cancel();

        this.value.remove(dto);
        this.erroneousFiles.remove(dto);

        if (dto.isTemp) {
            const payload: Record<string, any> = {};
            payload.uuid = dto.uuid;

            let promise: Bluebird<any> = Main.callApi('file', RequestMethods.DELETE, payload);
            // nothing to wait for..
        }
    }

    /**
     * Remove all files.
     */
    public removeAllFiles(): void {
        while (this.value?.length > 0) {
            this.deleteFile(this.value[0]);
        }

        while (this.erroneousFiles?.length > 0) {
            this.deleteFile(this.erroneousFiles[0]);
        }
    }

    /**
     * Remove all erroneus files.
     */
    public removeErroneusFiles(): void {
        while (this.erroneousFiles?.length > 0) {
            this.deleteFile(this.erroneousFiles[0]);
        }
    }

    public handleFilesDropped(e: DragEvent): void {
        const fileList: FileList = e.dataTransfer?.files;

        if (fileList?.length > 0) {
            if (!this.multiple && fileList.length > 1) {
                this.flags.notAllowed = true;
            }

            if (!this.flags.notAllowed) {
                this.handleFileList(fileList);
            }
        }

        this.flags.entering = false;
    }

    public handleDrag(e: DragEvent): void {
        if (this.flags.fileDragDisabled) return;

        this.flags.entering = true;
        this.flags.notAllowed = false;
    }

    public handleDragLeave(e: DragEvent): void {
        this.flags.entering = false;
        this.flags.notAllowed = false;
    }

    private async handleFileList(fileList: FileList): Promise<void> {
        let files: File[] = [...fileList];
        const acceptedFiles = this.acceptedFiles(files);

        if (this.accept == FileMimes.IMAGES && acceptedFiles?.length > 0) {
            files = await Main.app.openCropModal(acceptedFiles, {aspectRatio: this.aspectRatio, minWidth: this.minWidth, minHeight: this.minHeight, defaultBoundaries: 'fit'});
        }

        if (!this.value || !this.multiple) {    // If single, just reset the array
            this.value = [];
        }

        this.numLoading = files.length;
        for (let i: number = files.length; i--;) {
            const file: File = files[i];

            const dto: FileDTO = new FileDTO();
            dto.fileName = file.name;
            if ((file.type?.length > 0) && (FileMimes.IMAGES.includes(file.type))) {
                dto.thumbUrl = URL.createObjectURL(file);

                const img = new Image();
                img.src = dto.thumbUrl;
                await img.decode();
                if (img.width < this.minWidth || img.height < this.minHeight) {
                    dto.errors = [Main.trans.t(Lang.t.errors.minImageDimensions, {varMinWidth: this.minWidth, varMinHeight: this.minHeight})];
                }
            }
            this.value.push(dto);

            if (dto.errors?.length) {   // Client side error
                this.handleFileUploadError(dto, null);
                this.numLoading--;
                // No need to uplaod to the server:
                if (this.multiple) {
                    this.numLoading = 0;
                    continue;
                } else {
                    break;
                }
            }

            const payload: FormData = new FormData();
            payload.append('file', file);

            let route: string = 'file';
            switch (this.accept) {
                case FileMimes.IMAGES:
                    route = 'image';
                    break;
                case FileMimes.DOCUMENTS:
                    route = 'document';
                    break;
            }

            let promise: Bluebird<any> = Main.callApi(route, RequestMethods.POST, payload, FileDTO);
            dto.promise = promise;  // store the promise on the dto so it can be canceled on delete of this dto
            promise = promise.then(this.handleFileUploadResult.bind(this, dto));
            promise = promise.catch(this.handleFileUploadError.bind(this, dto));
            promise = promise.finally(() => {
                dto.promise = null;
                this.numLoading = this.numLoading - 1 < 0 ? 0 : this.numLoading - 1;
            });

            if (!this.multiple) {
                this.numLoading = 0;
                break;
            }  // Just in case the user manipulated the dom to select multiple anyway
        }

        // Reset the input field:
        if (this.$refs.field) {
            this.$refs.field.value = null;
        }
    }

    private handleFileUploadResult(dto: FileDTO, result: FileDTO): void {
        delete result.thumbUrl; // As to not overwrite the local set url with the one from the server

        Object.assign(dto, result); // Assign the result to the already exsisting dto

        this.$forceUpdate();    // Trigger the reactivity since `Object.assign` won't do that by itself

        this.$emit('update:modelValue', this.value.concat());
    }

    private handleFileUploadError(dto: FileDTO, error: WebServiceError = null): void {
        this.modelValue?.remove(dto);
        this.value?.remove(dto);
        this.erroneousFiles.push(dto);

        if (error) {
            switch (error.code) {
                case null:  // In case of a 413 since `.fetch` can't handle this error for some reason
                case ApiResponseCodes.INTERNAL_SERVER_ERROR:
                    dto.errors = [Main.trans.t(Lang.t.errors.unknown)];
                    break;
                case ApiResponseCodes.FILE_SIZE_EXCEEDED:
                    dto.errors = [Main.trans.t(Lang.t.errors.fileSizeExceded)];
                    break;
                case ApiResponseCodes.INVALID_ARGUMENTS:
                    dto.errors = error.data.errors.file;
                    break;
                default:
                    throw error;
            }
        }
        this.$forceUpdate();

        this.$emit('update:modelValue', this.value.concat());
    }

}
