import {Component, Prop} from "vue-facing-decorator";
import {FormElementWrapper} from "@/app/views/components/misc/form/FormElementWrapper";

@Component({})
export default class FormFieldTextAreaWrapper extends FormElementWrapper<string> {

    @Prop({type: Number, default: 5})
    public readonly rows: number;

    @Prop({type: Number, default: null})
    public readonly minCharCount: number;

    @Prop({type: Number, default: null})
    public readonly maxCharCount: number;

    @Prop({type: Boolean, default: false})
    declare public autoGrow: boolean;

    @Prop({type: Boolean, default: false})
    declare public editor: boolean;

    @Prop({type: Boolean, default: true})
    declare public manualResize: boolean;

    @Prop({type: Boolean, default: false})
    declare public fullHeight: boolean;

    @Prop({type: String, default: "64"})
    public readonly maxHeight: [string | number];

    public get charCount(): number {
        return this.modelValue?.length;
    }

    // TODO: @Krasi - Improve this, so that on init it shows grey zero and only after the user has started using the component, then show the red zero.
    public get isCharCountValid(): boolean {
        const isAboveMin: boolean = this.charCount >= this.minCharCount;
        const isBelowMax: boolean = this.charCount <= this.maxCharCount;
        const isAboveZero: boolean = this.charCount > 0;

        return (this.minCharCount ?
            ((this.maxCharCount) ?
                (isAboveMin && isBelowMax) :
                (isAboveMin)) :
            ((this.maxCharCount) ?
                    (isAboveZero && isBelowMax) :
                    (isAboveZero)
            ));
    }

}
