/**
 * Enumeration of all company subscription tier types.
 */
export const enum CompanySubscriptionTierTypes {

    BUYER_TIER_0 = 'buyer_tier_0',
    BUYER_TIER_1 = 'buyer_tier_1',
    BUYER_TIER_2 = 'buyer_tier_2',
    BUYER_TIER_3 = 'buyer_tier_3',

    SELLER_TIER_0 = 'seller_tier_0',
    SELLER_TIER_1 = 'seller_tier_1',
    SELLER_TIER_2 = 'seller_tier_2',
    SELLER_TIER_3 = 'seller_tier_3',

}
