export const enum ApiResponseCodes {

    SUCCESS = 200,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    FILE_SIZE_EXCEEDED = 413,
    INVALID_ARGUMENTS = 422,
    TERMS_NOT_ACCEPTED = 430,
    TERMS_NOT_ACCEPTED_BUYER = 432,
    TERMS_NOT_ACCEPTED_SELLER = 433,
    SELLER_NOT_VERIFIED = 434,
    TRANSACTION_FAILED = 435,
    INVITATION_INVALID = 436,
    BUYER_NOT_SUBSCRIBED = 437,
    SELLER_NOT_SUBSCRIBED = 438,
    REFUND_FAILED = 439,
    GOOGLE_LOGIN_FAILED = 440,
    USER_DELETE_FAILED = 442,
    USER_DEACTIVATED = 443,
    INTERNAL_SERVER_ERROR = 500,

}
