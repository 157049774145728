<script lang="ts" src="./Badge.ts"></script>


<template>
  <span
    :class="clss"
    :disabled="(disabled) ? 'disabled' : null"
  >
    <slot></slot>
  </span>
</template>


<style lang="scss" scoped>
</style>
