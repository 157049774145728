import {Vue} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Lang} from '@/app/lang/Lang';

export class Modal extends Vue {

    public readonly flags = {
        show: false,
        formChanged: false,
    };

    public created(): void {
    }

    public mounted(): void {
    }

    public hide(force: boolean = false): boolean {
        if (!force && this.flags.formChanged) {
            let promise = Main.app.alert.show(Main.trans.t(Lang.t.app.modal.close.message), Main.trans.t(Lang.t.app.modal.close.title), Main.trans.t(Lang.t.app.labels.yes), Main.trans.t(Lang.t.app.labels.no));
            promise = promise.then(() => {
                this.flags.formChanged = false;
                this.hide();
            });
            promise = promise.catch((err: Error) => {
                if (err) {
                    throw err;
                }

            });
            return false;
        }

        this.flags.show = false;
        this.flags.formChanged = false;

        return true;
    }

}
