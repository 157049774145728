<script lang="ts" src="./CatalogueProductPricingTable.ts"></script>

<template v-if="pricingLines">
  <table v-if="pricingLines" class="w-full leading-tightest">

    <tbody v-if="(pricingLines.length < 1) || (pricingLines[0].moq == null) || (pricingLines[0].price == null)">
    <tr>
      <td>
        <p class="text-green-400">{{ $trans.t('pages.sellerDashboard.products.pricingLines.placeholder') }}</p>
      </td>
    </tr>
    </tbody>

    <tbody v-else :class="singleLine ? '' : 'divide-y'">
    <tr
      v-for="(line, index) in formattedPricingLines"
      v-if="!singleLine"
      class="divide-x"
    >
      <template v-if="shouldShowRow(index)">
        <td
          :class="[
              'py-3 pr-4',
              (index === 0) ?
                'pt-0' :
                (index === formattedPricingLines.length - 1) ?
                  'pb-0' :
                  ''
            ]"
        >
          <p class="text-tiny text-left text-neutral-80">{{ line.moqRange }}</p>
        </td>
        <td
          :class="[
              'py-3 pl-4',
              (index === 0) ?
                'pt-0' :
                (index === formattedPricingLines.length - 1) ?
                  'pb-0' :
                  ''
            ]"
        >
          <h5 :class="['text-lg text-right md:text-left', index === 0 ? 'text-green-400' : '']">
            {{ line.moqRangePrice ? $trans.n(line.moqRangePrice, 'eur') : '' }}
          </h5>
        </td>
      </template>
    </tr>

    <tr v-else>
      <td
        v-for="(line, index) in formattedPricingLines"
        :class="[
            'px-4',
            (index === 0) ?
              'pl-0' :
              (index === formattedPricingLines.length - 1) ?
                'pr-0' :
                ''
          ]"
      >
        <p class="mb-1 text-neutral-80">{{ line.moqRange }}</p>
        <h5 class="text-lg 2xl:text-xl">
          {{ line.moqRangePrice ? $trans.n(line.moqRangePrice, 'eur') : '' }}
        </h5>
      </td>
    </tr>
    </tbody>

  </table>
</template>

<style lang="scss" scoped>

</style>
