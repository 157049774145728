import {RfqProductDTO} from '@/app/dto/RfqProductDTO';
import {RfqReviewDTO} from '@/app/dto/RfqReviewDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {CollaborationDTO} from "@/app/dto/CollaborationDTO";
import {BaseProjectDTO} from "@/app/dto/BaseProjectDTO";
import {RfqServiceDTO} from "@/app/dto/RfqServiceDTO";

export class ProjectDTO extends BaseProjectDTO {

    public products: RfqProductDTO[] = [];
    public services: RfqServiceDTO[] = [];

    public collaborations: CollaborationDTO[] = [];
    public reviews: RfqReviewDTO[] = []

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            products: [RfqProductDTO],
            services: [RfqServiceDTO],
            collaborations: [CollaborationDTO],
            reviews: [RfqReviewDTO],
        };
    }

    public get needsTPAssistence(): boolean {
        for (let i: number = this.products.length; i--;) {
            if (this.products[i].techpackAssistance) {
                return true;
            }
        }

        return false;
    }

}
