<script lang="ts" src="./ProductCard.ts"></script>

<template>
  <div :class="['flex flex-1 flex-col p-4 relative border rounded-xl bg-neutral-0', hasShadow ? 'shadow-md hover:shadow-lg transition-shadow' : '']">

    <div v-if="product.profileImages?.length > 0" class="h-48 flex space-x-3">
      <div class="h-full flex-1 relative">
        <figure class="figure">
          <template v-if="product.profileImages[0].thumbUrl">
            <img
              :alt="product.profileImages[0].fileName"
              :src="product.profileImages[0].thumbUrl"
              class="image"
            />
          </template>
          <template v-else>
            <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
          </template>
        </figure>
      </div>

      <ul v-if="product.profileImages?.length > 1" class="flex flex-col space-y-3">
        <li v-for="(image, index) in product.profileImages.slice(1, 3)" :key="image.uuid" class="w-20 h-20 flex-1 relative">
          <figure class="figure">
            <template v-if="image.thumbUrl">
              <img
                :alt="image.fileName"
                :src="image.thumbUrl"
                class="image"
              />
            </template>
            <template v-else>
              <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
            </template>
          </figure>
        </li>
      </ul>
    </div>

    <div v-else class="h-48 relative">
      <figure class="figure">
        <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
      </figure>
    </div>

    <div class="flex flex-col flex-1 my-4 space-y-4">
      <div class="space-y-1">
        <h3 class="font-body line-clamp-1">
          <m-link
            :new-window="true"
            :to="{ name: 'seoProductRead', params: { slug: product.slug } }"
            v-html="product.name.value"
          />
        </h3>
        <p class="text-tiny leading-tight line-clamp-2" v-html="product.description.value"></p>
      </div>

      <div :class="[product.manufyValues?.length > 0 ? 'flex flex-col space-y-2' : 'hidden xs:flex xs:flex-col xs:space-y-2']">
        <small class="m-caption">{{ $trans.t('pages.productIndex.manufyValues') }}</small>
        <m-value-icons
          v-if="product.manufyValues?.length > 0"
          :maxIcons="4"
          :size="$const.StyleSize.SMALL"
          :values="product.manufyValues"
        />
        <p v-else class="h-8 flex items-center text-neutral-60">-</p>
      </div>

      <div class="flex flex-col space-y-2">
        <small class="m-caption">{{ $trans.t('pages.productIndex.category') }}</small>
        <p class="flex space-x-2 items-center text-tiny leading-tightest">
          <m-icon :name="product.category" :solid="true" :type="$const.IconTypes.MANUFY" class="text-blue-400"/>
          <span>{{ $trans.t('meta.categories.' + product.category) }}</span>
        </p>
      </div>

      <div class="flex flex-col space-y-2">
        <div class="flex items-center space-x-1">
          <small class="m-caption">{{ $trans.t('pages.productIndex.moq') }}</small>
          <m-tooltip :label="$trans.t('pages.productIndex.moqTooltip')">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="text-tiny text-teal-300 cursor-pointer" name="circle-info"/>
          </m-tooltip>
        </div>
        <p class="flex space-x-2 items-center text-tiny leading-tightest">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="text-blue-400" name="boxes"/>
          <span>{{ product.moq }}</span>
        </p>
      </div>

      <div class="flex flex-col space-y-2">
        <small class="m-caption">{{ $trans.t('pages.productIndex.madeFor') }}</small>
        <p class="flex space-x-2 items-center text-tiny leading-tightest">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="text-blue-400" name="genderless"/>
          <span>{{ $trans.t('meta.madeFor.' + product.madeFor) }}</span>
        </p>
      </div>
    </div>

    <m-button
      :appearance="$const.StyleAppearance.NEUTRAL_NEW"
      :icon-before="{ name: 'eye', solid: true, type: $const.IconTypes.HERO, }"
      :new-window="true"
      :size="$const.StyleSize.SMALL"
      :to="{ name: 'seoProductRead', params: { slug: product.slug } }"
    >
      {{ $trans.t('app.buttonLabels.viewProduct') }}
    </m-button>

  </div>
</template>

<style lang="scss" scoped>

.figure {
  @apply h-full flex items-center justify-center overflow-hidden relative rounded-xl border bg-neutral-10 bg-opacity-40;

  .image {
    @apply min-w-full h-full object-cover;
  }
}

.missing-image-icon {
  @apply w-14 h-14 text-neutral-30;
}

</style>
