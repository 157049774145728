export const enum Roles {

    SYSTEM_USER = 'system_user',
    VERIFIED_USER = 'verified_user',

    MANUFY_USER = 'manufy_user',
    MANUFY_SUPER = 'manufy_super',

    COMPANY_USER = 'company_user',
    COMPANY_SUPER = 'company_super',

    BUYER_USER = 'buyer_super',    // TODO: change to buyer_user once backend is ready!
    BUYER_SUPER = 'buyer_super',

    SELLER_USER = 'seller_super',    // TODO: change to buyer_user once backend is ready!
    SELLER_SUPER = 'seller_super',

    BUYER_TIER_0 = 'buyer_tier_0',
    BUYER_TIER_1 = 'buyer_tier_1',
    BUYER_TIER_2 = 'buyer_tier_2',
    BUYER_TIER_3 = 'buyer_tier_3',

    SELLER_TIER_0 = 'seller_tier_0',
    SELLER_TIER_1 = 'seller_tier_1',
    SELLER_TIER_2 = 'seller_tier_2',
    SELLER_TIER_3 = 'seller_tier_3',

}
