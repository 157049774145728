import {BaseDTO} from '@/app/dto/BaseDTO';
import {FabricDTO} from '@/app/dto/FabricDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {PricingLineDTO} from '@/app/dto/PricingLineDTO';
import {TranslationDTO} from '@/app/dto/TranslationDTO';
import {PublicSellerShortDTO} from "@/app/dto/PublicSellerShortDTO";
import {CastableProperties} from '@/app/utils/Castable';

export class PublicProductDTO extends BaseDTO {

    public slug: string = null;
    public name: TranslationDTO = new TranslationDTO();
    public description: TranslationDTO = new TranslationDTO();
    public seller: PublicSellerShortDTO = null;
    public madeFor: string = null;
    public category: string = null;
    public moq: number = null;
    public profileImages: FileDTO[] = null;
    public fabrics: FabricDTO[] = null;
    public manufyValues: string[] = [];
    public grammage: number = null;
    public grammageType: string = null;
    public objectID: string = null;
    public pricingLines: PricingLineDTO[] = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            name: TranslationDTO,
            description: TranslationDTO,
            profileImages: [FileDTO],
            fabrics: [FabricDTO],
            pricingLines: [PricingLineDTO],
            seller: PublicSellerShortDTO,
        };
    }

}
