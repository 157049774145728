import Bluebird from 'bluebird';
import {Component, Prop, Vue, Watch} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {KeyboardCode} from '@/app/constants/KeyboardCode';
import {LabelDTO} from '@/app/dto/LabelDTO';
import {Lang} from '@/app/lang/Lang';

@Component({
    emits: [
        'select',
        'save',
        'delete',
    ],
})
export default class LabelEdit extends Vue {

    public editLabel: LabelDTO = null;
    declare public $refs: {
        editField: HTMLInputElement;
    };
    public flags = {
        editMode: false,
        selected: false,
    };
    @Prop({type: Object, required: true,})
    private readonly label: LabelDTO;
    @Prop({type: Boolean, default: false})
    private readonly selected: boolean;
    private keyUpHandler: (event: KeyboardEvent) => void = null;

    public created(): void {
        this.flags.selected = this.selected;

        this.keyUpHandler = this.handleKeyUp.bind(this);
        this.handleLabelChange(this.label, null);
    }

    public mounted(): void {
        if (!this.editLabel.uuid) {
            this.edit();
        }
    }

    public handleSelectedChange(newValue: boolean, _oldValue: boolean): void {
        if (!this.label.uuid) {
            return;
        }
        this.$emit('select', newValue, this.label.uuid);
    }

    public edit(): void {
        this.flags.editMode = true;
        document.addEventListener('keyup', this.keyUpHandler);

        window.requestAnimationFrame(() => {
            this.$refs.editField.focus();
        });
    }

    public save(): void {
        if (!this.flags.editMode) {
            return;
        } // Nothing to save
        this.flags.editMode = false;
        document.removeEventListener('keyup', this.keyUpHandler);

        this.editLabel.name = this.editLabel.name?.trim();
        if (!this.editLabel.name || this.editLabel.name == '' || this.editLabel.name == this.label.name) {  // Empty name or hasn't changed
            this.cancel();
            return;
        }

        this.$emit('save', this.editLabel, this.flags.selected);
    }

    public cancel(): void {
        this.flags.editMode = false;
        document.removeEventListener('keyup', this.keyUpHandler);

        if (this.editLabel.uuid) {
            // Revert to the original name:
            this.editLabel.name = this.label.name;
        } else {
            // delete this (new) label:
            this.$emit('delete', null);
        }
    }

    public deleteLabel(): void {
        this.flags.editMode = false;
        document.removeEventListener('keyup', this.keyUpHandler);

        if (this.editLabel.uuid) {
            const alertPromise: Bluebird<void> = Main.app.alert.show(
                Main.trans.t(Lang.t.app.labelModal.alert.deleteLabel.message),
                Main.trans.t(Lang.t.app.labelModal.alert.deleteLabel.title),
                Main.trans.t(Lang.t.app.labels.yes),
                Main.trans.t(Lang.t.app.labels.no)
            );
            alertPromise.then(() => {
                this.$emit('delete', this.editLabel.uuid);
            });
        } else {
            this.$emit('delete', null);
        }
    }

    @Watch('editLabel.name')
    protected handleEditLabelChange(newValue: string, _oldValue: string): void {
        if (this.label.uuid) {
            return;
        }    // This is only applicable to new labes
        this.flags.selected = (newValue != null && newValue.trim() != '');
    }

    @Watch('label')
    protected handleLabelChange(newValue: LabelDTO, _oldValue: LabelDTO): void {
        this.editLabel = Object.assign({}, newValue);
    }

    private handleKeyUp(evt: KeyboardEvent): void {
        switch (evt.code) {
            case KeyboardCode.ENTER:
                this.save();
                break;
            case KeyboardCode.ESCAPE:
                this.cancel();
                break;
        }
    }

}
