<script lang="ts" src="./Media.ts"></script>


<template>
  <div :class="['media', {'inverted': inverted}]">
    <div class="m-circle">
      <m-icon :name="icon.name" :solid="icon.solid" :type="icon.type"/>
    </div>
    <div class="media__body">
      <small :class="[inverted ? 'media__caption' : 'm-caption']">{{ caption }}</small>

      <template v-if="isArray">
        <ul class="media__list">
          <li v-for="(item, index) in value" :key="index" class="item">
            <m-badge>{{ item }}</m-badge>
          </li>
        </ul>
      </template>
      <template v-else-if="value">
        <p class="media__value" v-html="value"></p>
      </template>
      <template v-else>
        <p class="media__value">-</p>
      </template>
    </div>
  </div>
</template>


<style lang="scss" scoped>
</style>
