import {Settings} from '@/app/Settings';
import {Store} from '@/app/stores/Store';

export class SegmentHelper {

    public static trackPageView(): void {
        if (Settings.isProduction) {
            let payload = {};

            if (Store.user.user) {
                payload = {
                    email: Store.user.user.email,
                    firstName: Store.user.user.firstName,
                    lastName: Store.user.user.lastName,
                    uuid: Store.user.user.uuid,
                }
            }

            window.analytics.identify(Store.user.user?.uuid, payload);
            window.analytics.page();
        }
    }

}
