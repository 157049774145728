export const enum PusherEvents {

    // private company channel:
    CHANGED_LABELS = '.changedLabels',
    UNREAD_MESSAGE = '.unreadMessage',
    ARCHIVE_THREAD = '.archiveThread',
    MUTE_THREAD = '.muteThread',

    // public company channel whispers:
    ONLINE = 'online',

    // private thread channel:
    NEW_MESSAGE = '.newMessage',

    // private thread channel whispers:
    TYPING = 'typing',
}
