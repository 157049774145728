<script lang="ts" src="./ReviewModal.ts"></script>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-large">
      <div class="dialog-header">
        <h2 v-if="purchaseOrder" class="title">
          {{ $trans.t('app.order.leaveReview.body.title') }} {{ $trans.t('app.purchaseOrder.type.' + purchaseOrder.type) }} #{{ purchaseOrder.getFormattedOrderNumber() }}
        </h2>

        <h2 v-else class="title">
          {{ $trans.t('app.order.updateReview.body.title') }} #{{ review.getFormattedOrderNumber() }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <div ref="dialogBody" class="dialog-body">
        <div class="m-gutter-wrapper vertical flex-col flex-1 sm:w-0">
          <div class="text-center">
            <div v-if="purchaseOrder">
              <m-avatar
                :appearance="$const.StyleAppearance.PRIMARY"
                :partner="purchaseOrder.seller.isPartner"
                :shape="$const.StyleShapes.DEFAULT"
                :size="$const.StyleSize.XLARGE"
                :url="purchaseOrder.seller.avatar?.getThumbUrl()"
                :verified="purchaseOrder.seller.isVerified"
                class="mx-auto"
              />

              <h3 class="mt-4 text-base sm:text-lg">{{ purchaseOrder.seller.name }}</h3>
              <p v-if="purchaseOrder.seller.primaryLocation" class="mt-1.5 text-lg text-center leading-tight mb-3">
                <m-icon :solid="true" :type="$const.IconTypes.HERO" class="inline-block mb-1 text-green-400" name="map-pin"/>
                {{ purchaseOrder.seller.primaryLocation?.formattedString }}
              </p>
            </div>

            <template class="flex justify-center">
              <m-form-field-review
                v-model="reviewFormData.model.score"
                :clearable="true"
                :errors="reviewFormData.errors.score"
                :max="5"
                :old-rating="reviewFormData.model.score"
                class="mt-4"
              />
            </template>
          </div>

          <div class="m-input-group">
            <div class="mt-3">
              <m-form-field-text-area
                id="body"
                v-model="reviewFormData.model.body"
                :errors="reviewFormData.errors.body"
                :placeholder="$trans.t('app.order.leaveReview.body.placeholder')"
                name="body"
              />
            </div>
          </div>

          <div class="m-input-group">
            <m-form-field-checkbox v-model="reviewFormData.model.hasNameDisplayed">{{ $trans.t('app.order.leaveReview.body.nameDisplayToggle') }}</m-form-field-checkbox>
          </div>
        </div>
      </div>

      <div class="dialog-footer justify-end">
        <div class="item">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.GHOST"
            @click="hide()"
          >
            {{ $trans.t('app.labels.cancel') }}
          </m-button>
        </div>

        <div class="item">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            @click="save()"
          >
            {{ $trans.t('app.labels.save') }}
          </m-button>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped src="./PurchaseOrderModal.scss"></style>
