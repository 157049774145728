import {Component, Prop} from "vue-facing-decorator";
import {FormElementWrapper} from "@/app/views/components/misc/form/FormElementWrapper";
import {FormSelectItem} from "@/app/views/components/form/FormSelect";
import {StylePosition} from '@/app/constants/styles/StylePosition';

@Component({})
export default class FormSelectPickerWrapper<U extends string | number | boolean = string> extends FormElementWrapper<U | U[]> {

    @Prop({type: [String, Number, Boolean, Array], default: null})
    declare public readonly modelValue: U | U[];

    @Prop({type: Boolean, default: null})
    public readonly multiselect: boolean = null;

    @Prop({type: Array, default: null, required: true})
    public readonly items: FormSelectItem<U>[] = null;

    @Prop({type: String, default: null})
    public readonly allLabel: string;

    @Prop({type: String, default: StylePosition.BOTTOM})
    public readonly dropdownDirection: StylePosition;

    @Prop({type: Number, default: 2})
    public readonly dropdownMaxCols: number;

    @Prop({type: Boolean, default: true})
    public readonly showDropdownIcons: boolean;

    @Prop({type: Number, default: -1})
    public readonly maxVisibleStep: number;

}
