import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Lang} from '@/app/lang/Lang';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Uuid} from '@/types/Types';

type ReportCompanyModel = {
    reportedCompanyUuid: Uuid
    message: string,
};

@Component({})
export default class ReportCompanyModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };
    public formData: FormData<ReportCompanyModel> = null;
    private message: string = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(reportedCompanyUuid: Uuid): void {
        this.message = null;

        const formRules: FormRulesById = {};
        formRules.reportedCompanyUuid = [FormRules.required()];
        formRules.message = [FormRules.required(), FormRules.maxLength(1000)];

        const formData: ReportCompanyModel = {
            reportedCompanyUuid: reportedCompanyUuid,
            message: this.message
        };

        this.formData = new FormData(formData, formRules);

        this.flags.show = true;
    }

    public submit(): void {
        if (!this.formData.validate()) {
            return;
        }

        let promise = Main.app.alert.show(Main.trans.t(Lang.t.app.reportCompanyModal.alert.message), Main.trans.t(Lang.t.app.reportCompanyModal.alert.title), Main.trans.t(Lang.t.app.labels.yes), Main.trans.t(Lang.t.app.labels.no));
        promise = promise.then(() => {
            this.flags.loading = true;

            const payload: Record<string, any> = {};
            payload.reportedCompanyUuid = this.formData.model.reportedCompanyUuid;
            payload.message = this.formData.model.message;

            let promise: Bluebird<any> = Main.callApi('company/report', RequestMethods.POST, payload);
            promise = promise.then(this.handleSubmitResult.bind(this));
            promise = promise.finally(() => {
                this.flags.loading = false;
                this.hide();
            });
        });

    }

    private handleSubmitResult(): void {
        this.hide(true);
        Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.companyReported.message), Main.trans.t(Lang.t.app.toast.success.companyReported.title))
    }

}
