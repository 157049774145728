<script lang="ts" src="./Alert.ts"></script>


<template>
  <div v-if="flags.show" class="alert" @click.self="(!btnBLabel) ? hide() : null">
    <div :class="`alert-dialog ${width != null ? 'w-' + width : ''} ${appearance}`">
      <div class="dialog-header">
        <h2 class="title">{{ title }}</h2>
      </div>

      <div class="dialog-body">{{ message }}</div>

      <div class="dialog-footer">
        <m-button
          v-if="btnBLabel"
          :appearance="$const.StyleAppearance.PRIMARY"
          :size="$const.StyleSize.MEDIUM"
          :variant="$const.StyleVariants.OUTLINE"
          class="mr-4"
          @click="handleBtnBClick()"
        >{{ btnBLabel }}
        </m-button>

        <m-button
          v-if="btnALabel"
          :appearance="appearance"
          :dusk="'confirm'"
          :size="$const.StyleSize.MEDIUM"
          @click="handleBtnAClick()"
        >{{ btnALabel }}
        </m-button>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
</style>
