/**
 * Common file mimes.
 */
export class FileMimes {

    public static JPEG: string = 'image/jpeg';
    public static JPG: string = 'image/jpg';
    public static PNG: string = 'image/png';
    public static GIF: string = 'image/gif';
    public static SVG: string = 'image/svg+xml';

    public static PDF: string = 'application/pdf';

    public static DOC: string = '.doc';
    public static DOCX: string = '.docx';
    public static ODT: string = '.odt';
    public static RTF: string = '.rtf';

    public static XLS: string = '.xls';
    public static XLSX: string = '.xlsx';
    public static ODS: string = '.ods';
    public static CSV: string = '.csv';

    public static PPT: string = '.ppt';
    public static PPTX: string = '.pptx';
    public static ODP: string = '.odp';

    public static MDL: string = '.mdl';
    public static DXF: string = '.dxf';

    public static AI: string = ['.ai', '.eps'].join(',');
    public static AI_ALIASES: string = ['.application/illustrator', 'application/postscript'].join(',');
    public static PSD: string = '.psd';
    public static PSD_ALIASES: string = ['application/photoshop', 'application/psd', 'application/x-photoshop', 'image/photoshop', 'image/psd', 'image/x-photoshop', 'image/x-psd', 'image/vnd.adobe.photoshop'].join(',');
    public static INDD: string = '.indd';
    public static INDD_ALIASES: string = ['application/x-indesign'].join(',');
    public static XD: string = '.xd';
    public static XD_ALIASES: string = ['application/vnd.adobe.xdp+xml'].join(',');

    public static TEXT: string = [FileMimes.DOC, FileMimes.DOCX, FileMimes.ODT, FileMimes.RTF].join(',');
    public static SPREADSHEET: string = [FileMimes.XLS, FileMimes.XLSX, FileMimes.ODS, FileMimes.CSV].join(',');
    public static PRESENTATION: string = [FileMimes.PPT, FileMimes.PPTX, FileMimes.ODP].join(',');
    public static DESIGN: string = [FileMimes.AI, FileMimes.PSD, FileMimes.INDD, FileMimes.XD].join(',');
    public static DESIGN_ALIASES: string = [FileMimes.AI_ALIASES, FileMimes.PSD_ALIASES, FileMimes.INDD_ALIASES, FileMimes.XD_ALIASES].join(',');
    public static TECHPACK: string = [FileMimes.MDL, FileMimes.DXF].join(',');

    public static IMAGES: string = [FileMimes.JPEG, FileMimes.JPG, FileMimes.PNG, FileMimes.GIF, FileMimes.SVG].join(',');
    public static DOCUMENTS: string = [FileMimes.PDF, FileMimes.TEXT, FileMimes.SPREADSHEET, FileMimes.PRESENTATION, FileMimes.TECHPACK, FileMimes.DESIGN, FileMimes.DESIGN_ALIASES].join(',');

    public static FILES: string = [FileMimes.IMAGES, FileMimes.DOCUMENTS].join(',');

}
