import {Component, Prop} from "vue-facing-decorator";
import {FormElementWrapper} from "@/app/views/components/misc/form/FormElementWrapper";

@Component({})
export default class FormFieldReviewWrapper extends FormElementWrapper<number> {

    @Prop({type: Number, default: null})
    declare public modelValue: number;

    @Prop({type: Number, default: 5})
    public readonly max: number = null;

    @Prop({type: Boolean, default: null})
    public readonly clearable: boolean;

    @Prop({type: Number, default: null})
    public readonly oldRating: number = null;

}
