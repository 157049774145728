import {Component, Prop} from "vue-facing-decorator";
import {FormElementWrapper} from "@/app/views/components/misc/form/FormElementWrapper";

@Component({})
export default class FormFieldWrapper extends FormElementWrapper<string | Date> {

    @Prop({type: [String, Date], default: null})
    declare public modelValue: string | Date;

    @Prop({type: String, default: 'text'})
    public readonly type: string;

    // normal html attributes:
    @Prop({type: [Number, Date, String], default: null})
    public readonly min: number | Date | string;

    @Prop({type: [Number, Date, String], default: null})
    public readonly max: number | Date | string = null;

    @Prop({type: String, default: null})
    public readonly autocomplete: string;

    // Style properties
    @Prop({type: Boolean, default: false})
    declare public rtl: boolean;

}
