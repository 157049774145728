import {Component, Prop, Vue} from 'vue-facing-decorator';
import {RfqReviewDTO} from '@/app/dto/RfqReviewDTO';

@Component({})
export default class RfqReview extends Vue {

    @Prop({type: Object, default: null})
    public readonly review: RfqReviewDTO;

    constructor(...args: any[]) {
        super(args);
    }

    public created(): void {
    }

    public mounted(): void {
    }
}
