/**
 * ...
 * @author Arno van Oordt
 * @version 1.0.0
 */
export class Cookies {

    public static setCookie(name: string, val: string, expiresSec: number = 7 * 24 * 60 * 60): void {
        const value: string = val;

        // Set it expire in 7 days:
        const date: Date = new Date();
        date.setTime(date.getTime() + (expiresSec * 1000));

        document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    }

    public static getCookie(name: string): string {
        const cookies: Record<string, string> = Cookies.getCookies();
        return cookies[name];
    }

    public static getCookies(): Record<string, string> {
        const result: Record<string, string> = {};
        let cookies: string[] = document.cookie.split('; ');
        for (let i: number = 0; i < cookies.length; i++) {
            const split: string[] = cookies[i].split('=');
            const key: string = split.shift();
            if (!key) {
                continue;
            }
            result[key] = split.join('=');
        }
        return result;
    }

    public static deleteCookie(name: string): void {
        // Set it expire in -1 days:
        const date: Date = new Date();
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

        document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
    }
}
