import {BaseDTO} from '@/app/dto/BaseDTO';

export class LegalDetailsDTO extends BaseDTO {

    public registrationCountry: string = null;
    public registrationYear: number = null;
    public legalOwner: string = null;

    public cocNumber: string = null;
    public ibanNumber: string = null;
    public bicNumber: string = null;

    public vatNumber: string = null;
    public vatPercentage: number = null;
    public hasVatValidated: boolean = null;

    public oppStatus: string = null;
    public oppOverviewUrl: string = null;
    public oppRequirements: string[] = null
}
