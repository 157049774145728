import {BaseDTO} from '@/app/dto/BaseDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {Uuid} from '@/types/Types';
import {RfqServiceDTO} from "@/app/dto/RfqServiceDTO";

export class ServiceOfferDTO extends BaseDTO {

    public createdAt: Date = null;

    public companyUuid: Uuid = null;
    public collaborationUuid: Uuid = null;

    public deliveryDate: Date = null

    public service: RfqServiceDTO = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            deliveryDate: Date,
            service: RfqServiceDTO,
        };
    }

}
