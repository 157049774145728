import {CastableProperties} from '@/app/utils/Castable';
import {Uuid} from '@/types/Types';
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";
import {BaseProductOfferDTO} from "@/app/dto/BaseProductOfferDTO";

export class BuyerProductOfferDTO extends BaseProductOfferDTO {

    public threadUuid: Uuid = null;
    public seller: CompanyShortDTO = null

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            seller: CompanyShortDTO,
        };
    }

}
