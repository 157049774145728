import {ComponentBase, Prop} from 'vue-facing-decorator';
import {FormItem} from '@/app/views/components/form/FormItem';

@ComponentBase({})
export abstract class FormFieldRadio extends FormItem<string | number | boolean> {

    @Prop({type: [String, Number, Boolean], default: null})
    declare public modelValue: string | number | boolean;

    @Prop({type: String, default: null})
    public readonly group: string;

    // TODO: Remove this property from this class if it's not used by all instances
    @Prop({type: String, default: null})
    public readonly discount: string;

    @Prop({type: [String, Number, Boolean], default: null})
    public readonly val: string | number | boolean;

    // TODO: Remove this property from this class if it's not used by all instances
    @Prop({type: Boolean, default: null})
    public readonly recommended: boolean;

    declare public readonly $refs: {
        radio: HTMLInputElement;
    };

    public get selected(): boolean {
        return (this.modelValue === this.val);
    }

    public updateValue(_value: string = null): void {
        if (this.disabled) return;
        super.updateValue(this.val);    // Pass on `val` since that is still in it's original type while value from the input event will always be a string
    }

}
