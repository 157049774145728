import '@/app/styles/index.scss';
import '@/global';
import '@/js/intercom.js';
import '@/js/googletag.js';
import '@/js/reditus.js';
import '@/js/segment.js';
import '@/libs/core/extensions/Array';
import '@/libs/core/extensions/Date';
import '@/libs/core/extensions/Number';
import '@/libs/core/extensions/String';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {Main} from '@/app/Main';
import {Settings} from '@/app/Settings';

declare global {
    interface Window {
        log: Console['log'];
        warn: Console['warn'];
        tr: (...args: any[]) => void;
        Echo: Echo;
        Pusher: typeof Pusher;
        grecaptcha: any;
        Intercom: any;
        dataLayer: any;
        analytics: any;
        gr: any; // reditus
    }
}

console.info('%c ' + Settings.appTitle + '%c v' + Settings.appVersion + ' ',
    'border-radius: 4px 0px 0px 4px; padding: 5px; background: #00a4a2; font-weight: bold; color: #fff;',
    'border-radius: 0px 4px 4px 0px; padding: 5px; background: #636b6f; font-weight: bold; color: #fff;'
);

let loadCount: number = 0;

function checkInit(): void {
    loadCount--;
    if (loadCount > 0) {
        return;
    }

    // Initialize the app:
    Main.init();
}

const fjs: HTMLScriptElement = document.getElementsByTagName('script')[0];

if (!document.getElementById('google-recaptcha')) {
    loadCount++;
    const js: HTMLScriptElement = document.createElement('script');
    js.id = 'google-recaptcha';
    js.src = 'https://www.google.com/recaptcha/api.js?render=' + Settings.recaptchaSiteKey;
    js.onload = js.onerror = (_evt: Event): void => {
        (window.grecaptcha) ? window.grecaptcha.ready(checkInit) : checkInit();
    };
    fjs.parentNode.insertBefore(js, fjs);
}
