import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {StylePosition} from '@/app/constants/styles/StylePosition';
import {StyleSize} from '@/app/constants/styles/StyleSize';

@Component({})
export default class Indicator extends Vue {

    @Prop({type: String, default: StyleAppearance.PRIMARY})
    private readonly appearance: StyleAppearance;

    @Prop({type: String, default: StyleSize.XSMALL})
    private readonly size: StyleSize;

    @Prop({type: String, default: StylePosition.RIGHT})
    private readonly x: StylePosition;

    @Prop({type: String, default: StylePosition.TOP})
    private readonly y: StylePosition;

    @Prop({type: Boolean, default: false})
    private readonly disabled: boolean;

    public get clss(): string {
        let clss: string = 'indicator';
        if (this.appearance) {
            clss += ' ' + this.appearance;
        }
        if (this.size) {
            clss += ' ' + this.size;
        }
        if (this.x) {
            clss += ' x-' + this.x;
        }
        if (this.y) {
            clss += ' y-' + this.y;
        }
        if (this.disabled) {
            clss += ' disabled';
        }

        return clss;
    }

}
