<script lang="ts" src="./PublicSellerCard.ts"></script>

<template>
  <div :class="['flex flex-1 flex-col relative border rounded-xl', hasShadow ? 'shadow-md hover:shadow-lg transition-shadow' : '']">

    <div class="flex flex-row px-4 py-3 text-left border-b">
      <div class="flex flex-none">
        <m-avatar
          :appearance="$const.StyleAppearance.PRIMARY"
          :partner="seller.isPartner"
          :size="$const.StyleSize.SMALL"
          :url="seller.avatar?.getThumbUrl() ?? seller.avatarUrl"
          :verified="seller.isVerified"
        />
      </div>

      <div class="self-center ml-3">
        <m-link
          :newWindow="true"
          :to="{ name: 'publicSellerRead', params: { uuid: seller.uuid } }"
          class="m-stretched-link"
        >
          <h2 class="mb-1 leading-tightest line-clamp-1">{{ seller.getName() }}</h2>
        </m-link>

        <p class="text-tiny text-left line-clamp-1 leading-tightest text-neutral-80">
          {{ seller.primaryLocation?.formattedString }}
        </p>
      </div>
    </div>

    <div class="flex flex-col flex-1 px-4 py-3">
      <p v-if="seller.introductionBrief?.value" class="mb-3 line-clamp-3">{{ seller.introductionBrief.value }}</p>
      <em v-else class="block flex-1 mb-3 leading-tightest text-neutral-60">{{ $trans.t('pages.sellerIndex.noDescription') }}</em>

      <ul v-if="seller.profileImages?.length > 0" class="h-20 flex flex-row items-center space-x-3 mb-3 md:h-16">
        <li v-for="image in seller.profileImages.slice(0, 3)" class="h-full flex-1">
          <img :alt="image.fileName" :src="image.thumbUrl" class="w-full h-full object-cover">
        </li>
      </ul>
      <ul v-else class="hidden xs:h-20 xs:flex xs:flex-row xs:items-center xs:space-x-3 xs:mb-3 md:h-16">
        <li class="hidden xs:block">
          <em class="leading-tightest text-neutral-60">{{ $trans.t('pages.sellerIndex.noImages') }}</em>
        </li>
      </ul>

      <div class="flex flex-col space-y-3">
        <div class="flex flex-col">
          <small class="mb-2 m-caption">{{ $trans.t('pages.sellerDashboard.filters.categories') }}</small>
          <m-category-icons
            v-if="seller.categories?.length > 0"
            :appearance="$const.StyleAppearance.PRIMARY"
            :categories="seller.categories"
            :maxIcons="4"
            :size="$const.StyleSize.SMALL"
          />
          <span v-else class="block flex-1 text-neutral-60">-</span>
        </div>

        <div :class="[seller.manufyValues?.length > 0 ? 'flex flex-col z-10' : 'hidden xs:flex xs:flex-col']">
          <small class="mb-2 m-caption">{{ $trans.t('app.labels.manufyValues') }}</small>
          <m-value-icons
            v-if="seller.manufyValues?.length > 0"
            :maxIcons="4"
            :size="$const.StyleSize.SMALL"
            :values="seller.manufyValues"
          />
          <p v-else class="flex-1 text-neutral-60">-</p>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>

</style>
