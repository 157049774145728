<script lang="ts" src="./FormFieldTextArea.ts"></script>


<template>
  <div
    :class="[
      'form-field-group',
      size,
      {
        'has-error': (errors?.length > 0),
        'has-data': (value?.length > 0),
        disabled: disabled,
      },
    ]"
  >
    <div ref="textareaWrapper" :class="['form-field flex-col', { 'auto-grow': autoGrow, 'h-full': fullHeight }]" :data-replicated-value="autoGrow">
      <textarea
        :id="id"
        ref="textarea"
        :class="['textarea', manualResize ? 'resize-y' : 'resize-none', maxHeightClass()]"
        :disabled="disabled"
        :dusk="dusk"
        :placeholder="placeholder"
        :rows="rows"
        :value="value"
        @input="updateFromValue($event)"
        @keyup="adjustTextareaHeight"
      />
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.form-field-group .form-field {
  @apply p-0;
}

.auto-grow {
  @apply grid flex-1 relative items-stretch;

  &::after {
    @apply h-auto min-w-0 px-5 py-3 rounded-xl text-base whitespace-pre-wrap invisible;
    content: attr(data-replicated-value);
  }

  .textarea {
    @apply h-auto resize-none overflow-auto;
  }

  &::after,
  .textarea {
    @apply max-h-64 transition-all;
    grid-area: 1 / 1 / 2 / 2;
  }
}

</style>
