import {Castable} from '@/app/utils/Castable';

export class PaginationDTO extends Castable {

    public currentItems: number = 0;
    public currentPage: number = 1;
    public lastPage: number = 1;
    public perPage: number = 10;
    public totalItems: number = 0;

}
