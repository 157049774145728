import {Component, Prop} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {FormFieldRadio} from '@/app/views/components/form/FormFieldRadio';
import {IIcon} from '@/app/views/components/misc/Icon';

@Component({})
export default class FormFieldRadioButton extends FormFieldRadio {

    @Prop({type: Object, default: null})
    public icon: IIcon;

    @Prop({type: String, default: 'blue'})
    public activeColor: string;

    @Prop({type: String, default: StyleAppearance.PRIMARY})
    public appearance: StyleAppearance;

    @Prop({type: Boolean, default: false})
    public isLight: boolean;

    public get formFieldClassList(): string {
        let clss: string = 'form-field';
        if (this.activeColor) {
            clss += ' ' + this.activeColor;
        }
        if (this.appearance) {
            clss += ' ' + this.appearance;
        }
        if (this.isLight) {
            clss += ' light';
        }

        return clss;
    }

}
