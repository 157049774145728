<script lang="ts" src="./FormFieldTextAreaEditor.ts"></script>


<template>
  <div>
    <ckeditor v-model="value" :config="editorConfig" :editor="editor"></ckeditor>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </div>
</template>


<style lang="scss" scoped>

</style>
