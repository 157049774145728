import {Main} from '@/app/Main';
import {BaseDTO} from '@/app/dto/BaseDTO';
import {Lang} from '@/app/lang/Lang';

export class LocationDTO extends BaseDTO {

    public name: string = null;
    public address1: string = null;
    public address2: string = null;
    public zipcode: string = null;
    public city: string = null;
    public region: string = null;
    public country: string = null;

    public get googleMapsAddress(): string {
        if (!this.city || !this.country) {
            return null;
        }

        return this.city + '+' + Main.trans.t('meta.countries.' + this.country);
    }

    public get formattedString(): string {
        const location: string[] = [];

        if (this.city) {
            location.push(this.city);
        }
        if (this.country) {
            location.push(Main.trans.t(Lang.t.meta.countries[this.country]));
        }

        return location.join(', ');
    }
}
