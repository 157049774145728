/**
 * Enumeration of possible company subscription types.
 * Note that the string value is also used to compose api routes!
 */
export const enum CompanySubscriptionTypes {

    PAID = 'paid',
    UNPAID = 'unpaid',
    MANUAL = 'manual',

}
