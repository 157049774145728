import {Signal} from 'typed-signals';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Settings} from '@/app/Settings';
import {CompanySubscriptionTypes} from "@/app/constants/CompanySubscriptionTypes";
import {FileDTO} from '@/app/dto/FileDTO';
import {Lang} from '@/app/lang/Lang';
import {DeviceTypes} from '@/app/constants/DeviceTypes';
import {IconTypes} from '@/app/constants/IconTypes';
import {Store} from '@/app/stores/Store';
import {StoreBase} from '@/app/stores/StoreBase';
import {StorageHelper} from '@/app/utils/StorageHelper';
import {IMenuItem} from '@/app/views/components/layout/MenuItem';
import RFQWizardPage, {RFQFormModel} from '@/app/views/pages/buyer/RFQCreatePage';
import {WebService} from '@/libs/core/services/WebService';
import {Cookies} from '@/libs/core/utils/Cookies';
import {Uuid} from '@/types/Types';

interface IAppState {
    local: {
        deviceId?: string;
        sideMenuOpened?: boolean;
        frontOfficeSideMenuOpened?: boolean,
        locale?: string;
        targetRoute?: string;
        showVerifiedAlert?: boolean;
    };
    device: DeviceTypes;
    width: number;
    height: number;
    tabletBreakPoint: number;
    mobileBreakPoint: number;
    sideMenuBreakPoint: number;
    browserScrollbarWidth: number;
    apiService: WebService;
}

/**
 *
 */
export class AppStore extends StoreBase<IAppState> {

    public deviceChangedSignal: Signal<(device: DeviceTypes) => void> = new Signal();

    constructor() {
        super();

        this.state.local = StorageHelper.getLocalVar<IAppState['local']>('settings', {});
        if (this.state.local instanceof String) {
            this.state.local = {};
        }

        // Set default values for local:
        if (!this.state.local.deviceId) {
            this.state.local.deviceId = (Math.random() + 1).toString(36).substring(2);   // Generate random id
        }
        if (!this.state.local.locale) {
            this.state.local.locale = Lang.defaultLocale;
        }
        if (this.state.local.sideMenuOpened === undefined) {
            this.state.local.sideMenuOpened = false;
        }
        if (this.state.local.frontOfficeSideMenuOpened === undefined) {
            this.state.local.frontOfficeSideMenuOpened = false;
        }

        this.state.device = DeviceTypes.DESKTOP;
        this.state.width = 0;
        this.state.height = 0;
        this.state.browserScrollbarWidth = this.getBrowserScrollbarWidth();
        this.state.tabletBreakPoint = 1280;
        this.state.mobileBreakPoint = 768;
        this.state.sideMenuBreakPoint = 1024;
        this.state.apiService = new WebService(Settings.apiUrl, {
            'accept': 'application/json',
            'x-xsrf-token': decodeURIComponent(Cookies.getCookie('XSRF-TOKEN')),
            'man-locale': this.state.local.locale,
        });

        // Listen for window resize:
        window.addEventListener('resize', this.resizeHandler.bind(this));
        this.resizeHandler();
    }

    public get deviceId(): string {
        return this.state.local.deviceId;
    }

    public get deviceWidth(): number {
        return this.state.width;
    }

    public get topMenuVisible(): boolean {
        // Hide top menu when mandated by the routes metadata
        return Main.app.$route.meta.hideTopMenu !== true;
    }

    public get sideMenuVisible(): boolean {
        // Hide side menu when mandated by the routes metadata or when the user doesn't have a company (or is not logged in)
        return (Store.user.currentCompanyUuid && Main.app.$route.meta.hideSideMenu !== true);
    }

    public get fullHeight(): boolean {
        return (Main.app.$route.meta.fullHeight === true);
    }

    public get sideMenuOpened(): boolean {
        return (this.state.width >= this.state.sideMenuBreakPoint || this.state.local.sideMenuOpened);
    }

    public set sideMenuOpened(value: boolean) {
        this.state.local.sideMenuOpened = value;
        this.saveSettings();
    }

    public get frontOfficeSideMenuOpened(): boolean {
        return (this.state.width >= this.state.sideMenuBreakPoint) ? false : this.state.local.frontOfficeSideMenuOpened;
    }

    public set frontOfficeSideMenuOpened(value: boolean) {
        this.state.local.frontOfficeSideMenuOpened = value;
        this.saveSettings();
    }

    /**
     * Route to go to after login.
     */
    public get targetRoute(): string {
        return this.state.local.targetRoute;
    }

    public set targetRoute(value: string) {
        this.state.local.targetRoute = value;
        this.saveSettings();
    }

    /**
     * Route to go to after login.
     */
    public get showVerifiedAlert(): boolean {
        return this.state.local.showVerifiedAlert;
    }

    public set showVerifiedAlert(value: boolean) {
        this.state.local.showVerifiedAlert = value;
        this.saveSettings();
    }

    public get device(): DeviceTypes {
        return this.state.device;
    }

    public set device(value: DeviceTypes) {
        this.state.device = value;
    }

    public get browserScrollbarWidth(): number {
        return this.state.browserScrollbarWidth;
    }

    public set browserScrollbarWidth(value: number) {
        this.state.browserScrollbarWidth = value;
    }

    public get apiService(): WebService {
        return this.state.apiService;
    }

    public get hasPendingRFQ(): boolean {
        if (!Store.user.isBuyer && !Store.user.isGuest) {
            // In case of a seller there can never be a pending RFQ
            // Having a pending RFQ in session can remain there for when the user potentially logs in as buyer
            return false;
        }

        try {
            const formData: RFQFormModel = StorageHelper.getLocalVar<RFQFormModel>('rfq');
            if (formData) {
                return true;
            }
        } catch (error) {
        }
        return false;
    }

    /**
     * The current locale. If a user is logged in the locale of the user is used. Otherwise, the local locale is used.
     */
    public get locale(): string {
        return Store.user.user?.locale ?? Routes.localeFromUrl ?? this.state.local.locale;
    }

    public set locale(locale: string) {
        if (!locale) {
            locale = Lang.defaultLocale;
        }

        if (Store.user.user) {  // User is logged in; store local and on server
            Store.user.updateLocale(locale);
        } else {  // No logged-in user; store local
            this.state.local.locale = locale;
            this.saveSettings();

            // Go to the current route but remove the possible locale prefix:
            const currentRoute = Routes.currentRoute;
            const targetRoute = {name: currentRoute.name, params: currentRoute.params, query: currentRoute.query};
            targetRoute.params.locale = undefined;
            Routes.resolveLink(targetRoute, false, true);   // Force the page to refresh
        }
    }

    /**
     * The menu structure for the back office
     *  example:
     *      menu.push({ link: { name: 'sellerProjectList' }, label: 'Projects', icon: { name: 'ViewBoardsIcon', solid: false, type: IconTypes.HERO }, notifications: 5 });
     */
    public get boStructure(): IMenuItem[] {
        const menu: IMenuItem[] = [];

        menu.push({link: {name: 'dashboard'}, label: Main.trans.t(Lang.t.app.menus.home), icon: {name: 'home', solid: false, type: IconTypes.HERO},});
        menu.push({link: {name: 'chat'}, label: Main.trans.t(Lang.t.pages.chat.title), icon: {name: 'chat-bubble-oval-left-ellipsis', solid: false, type: IconTypes.HERO}});

        // Buyer:
        if (Store.user.isBuyer) {
            menu.push({
                link: null, label: Main.trans.t(Lang.t.app.menus.myProduction), icon: {name: 'rectangle-stack', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'projectList'}, label: Main.trans.t(Lang.t.app.menus.projects), icon: {name: 'rectangle-stack', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'purchaseOrderList'}, label: Main.trans.t((Lang.t.pages.company.orders.title)), icon: {name: 'clipboard-document-check', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'buyerReviewList'}, label: Main.trans.t((Lang.t.app.menus.reviews)), icon: {name: 'star', solid: true, type: IconTypes.HERO},},
                ]
            });
            menu.push({
                link: null, label: Main.trans.t(Lang.t.app.menus.explore), icon: {name: 'globe-alt', solid: true, type: IconTypes.HERO}, children: [
                    {link: {name: 'sellerIndex'}, label: Main.trans.t(Lang.t.pages.sellerIndex.menuTitle), icon: {name: 'user-group', solid: true, type: IconTypes.HERO}},
                    {link: {name: 'publicServiceList'}, label: Main.trans.t(Lang.t.pages.serviceIndex.menuTitle), icon: {name: 'pen-ruler', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'publicFabricList'}, label: Main.trans.t(Lang.t.pages.fabricIndex.menuTitle), icon: {name: 'rug', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'publicProductList'}, label: Main.trans.t(Lang.t.pages.productIndex.menuTitle), icon: {name: 'shopping-bag', solid: true, type: IconTypes.HERO},},
                ]
            });
            menu.push({
                link: null, label: Main.trans.t(Lang.t.pages.company.home), icon: {name: 'building-office', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'companyProfileEdit'}, label: Main.trans.t(Lang.t.app.menus.publicProfile), icon: {name: 'user-circle', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'companyContactEdit'}, label: Main.trans.t(Lang.t.app.menus.contactInfo), icon: {name: 'phone', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'companyLegalDetailsEdit'}, label: Main.trans.t(Lang.t.app.menus.legalInfo), icon: {name: 'briefcase', solid: true, type: IconTypes.HERO}},
                    {link: {name: 'companyNotificationList'}, label: Main.trans.t(Lang.t.pages.company.notifications.title), icon: {name: 'envelope', solid: true, type: IconTypes.HERO}},
                    {link: {name: 'companyLocationList'}, label: Main.trans.t(Lang.t.pages.company.locations.title), icon: {name: 'map-pin', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'companyUserList'}, label: Main.trans.t(Lang.t.app.menus.team), icon: {name: 'user-group', solid: true, type: IconTypes.HERO},},
                    // { link: { name: 'companyInvoiceList' }, label: Main.trans.t(Lang.t.pages.buyerDashboard.quickLinks.company.invoices), icon: { name: 'document-text', solid: true, type: IconTypes.HERO }, },
                    (Store.company.subscriptionType === CompanySubscriptionTypes.PAID) ? {
                        link: 'call::openStripeCustomerPortal',
                        label: Main.trans.t(Lang.t.app.menus.subscription),
                        icon: {name: 'credit-card', solid: true, type: IconTypes.FONT_AWESOME}
                    } : null,
                ]
            });
        }

        // Seller:
        if (Store.user.isSeller) {
            menu.push({
                link: null, label: Main.trans.t(Lang.t.app.menus.myProduction), icon: {name: 'rectangle-stack', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'sellerProjectList'}, label: Main.trans.t(Lang.t.app.menus.projects), icon: {name: 'rectangle-stack', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'purchaseOrderList'}, label: Main.trans.t((Lang.t.pages.company.orders.title)), icon: {name: 'clipboard-document-check', solid: true, type: IconTypes.HERO},},
                ]
            });
            menu.push({
                link: null, label: Main.trans.t(Lang.t.pages.company.profile.label), icon: {name: 'user-circle', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'companyProfileEdit'}, label: Main.trans.t(Lang.t.app.menus.publicProfile), icon: {name: 'user-circle', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'companyMediaEdit'}, label: Main.trans.t(Lang.t.pages.company.media.title), icon: {name: 'photo', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'companyLocationList'}, label: Main.trans.t(Lang.t.pages.company.locations.title), icon: {name: 'map-pin', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'sellerProductList'}, label: Main.trans.t(Lang.t.app.menus.products), icon: {name: 'shopping-bag', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'sellerServiceList'}, label: Main.trans.t(Lang.t.app.menus.services), icon: {name: 'pen-ruler', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'catalogueFabricList'}, label: Main.trans.t(Lang.t.app.menus.fabrics), icon: {name: 'rug', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'sellerManufyValueList'}, label: Main.trans.t(Lang.t.app.labels.manufyValues), icon: {name: 'leaf', solid: true, type: IconTypes.FONT_AWESOME},},
                    // { link: { name: 'companyStatisticsRead' }, label: Main.trans.t(Lang.t.pages.company.statistics.title), icon: { name: 'chart-bar', solid: true, type: IconTypes.HERO }, },
                ]
            });
            menu.push({
                link: null, label: Main.trans.t(Lang.t.pages.company.title), icon: {name: 'building-office', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'companyContactEdit'}, label: Main.trans.t(Lang.t.app.menus.contactInfo), icon: {name: 'phone', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'companyLegalDetailsEdit'}, label: Main.trans.t(Lang.t.app.menus.legalInfo), icon: {name: 'briefcase', solid: true, type: IconTypes.HERO}},
                    {link: {name: 'companyNotificationList'}, label: Main.trans.t(Lang.t.pages.company.notifications.title), icon: {name: 'envelope', solid: true, type: IconTypes.HERO}},
                    {link: {name: 'companyUserList'}, label: Main.trans.t(Lang.t.app.menus.team), icon: {name: 'user-group', solid: true, type: IconTypes.HERO},},
                    // { link: { name: 'companyInvoiceList' }, label: Main.trans.t(Lang.t.pages.buyerDashboard.quickLinks.company.invoices), icon: { name: 'document-text', solid: true, type: IconTypes.HERO }, },
                    (Store.company.subscriptionType === CompanySubscriptionTypes.PAID) ? {
                        link: 'call::openStripeCustomerPortal',
                        label: Main.trans.t(Lang.t.app.menus.subscription),
                        icon: {name: 'credit-card', solid: true, type: IconTypes.HERO}
                    } : null,
                ]
            });
            menu.push({link: {name: 'sellerFiltersEdit'}, label: Main.trans.t(Lang.t.pages.sellerFilters.title), icon: {name: 'cog-6-tooth', solid: false, type: IconTypes.HERO}});
        }

        if (Store.user.isBuyer || Store.user.isSeller) {
            let helpList: IMenuItem[] = [];
            helpList.push({link: 'https://www.manufy.com/how-it-works-brands/', label: Main.trans.t(Lang.t.pages.buyerDashboard.quickLinks.company.howItWorks), icon: {name: 'link', solid: true, type: IconTypes.HERO},});

            if (Store.user.isBuyer) {
                helpList.push({link: Settings.urlManufyWebinar, label: Main.trans.t(Lang.t.app.profileMenu.dailyDemo), icon: {name: 'computer-desktop', solid: true, type: IconTypes.HERO},});
            }

            helpList.push({link: 'https://help.manufy.com/', label: Main.trans.t(Lang.t.app.profileMenu.faq), icon: {name: 'question-mark-circle', solid: true, type: IconTypes.HERO},});
            helpList.push({
                link: "call::openIntercom",
                label: Main.trans.t(Lang.t.pages.sellerDashboard.quotationWall.unverifiedWarning.btnBLabel),
                icon: {name: 'chat-bubble-left-ellipsis', solid: true, type: IconTypes.HERO},
            });

            menu.push({link: null, label: Main.trans.t(Lang.t.app.menus.help), icon: {name: 'information-circle', solid: false, type: IconTypes.HERO}, children: helpList});
        }

        // Admin:
        if (Store.user.isAdmin) {
            menu.push({
                link: null, label: 'Companies', icon: {name: 'building-office', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'adminOnboardingList'}, label: Main.trans.t(Lang.t.app.menus.onboarding), icon: {name: 'fa-tasks', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'adminBuyerList'}, label: Main.trans.t(Lang.t.app.menus.buyers), icon: {name: 'shirt', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'adminSellerList'}, label: Main.trans.t(Lang.t.app.menus.sellers), icon: {name: 'building-office', solid: true, type: IconTypes.HERO},},
                ]
            });


            menu.push({
                link: null, label: 'Catalogue', icon: {name: 'book-open', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'adminFabricList'}, label: Main.trans.t(Lang.t.app.menus.fabrics), icon: {name: 'rug', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'adminServiceList'}, label: Main.trans.t(Lang.t.app.menus.services), icon: {name: 'pen-ruler', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'adminCatalogueProductList'}, label: Main.trans.t(Lang.t.app.menus.products), icon: {name: 'shirt', solid: true, type: IconTypes.FONT_AWESOME},},
                ]
            });

            menu.push({
                link: null, label: Main.trans.t(Lang.t.app.menus.projects), icon: {name: 'rectangle-stack', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'adminProjectList'}, label: Main.trans.t(Lang.t.app.menus.projects), icon: {name: 'rectangle-stack', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'adminProductList'}, label: Main.trans.t(Lang.t.app.menus.products), icon: {name: 'shirt', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'adminRfqServiceList'}, label: Main.trans.t(Lang.t.app.menus.services), icon: {name: 'pen-ruler', solid: true, type: IconTypes.FONT_AWESOME},},
                    {link: {name: 'adminRfqReviewList'}, label: Main.trans.t(Lang.t.app.menus.reviews), icon: {name: 'star', solid: true, type: IconTypes.HERO},},
                ]
            });

            menu.push({
                link: null, label: Main.trans.t(Lang.t.app.menus.purchaseOrders), icon: {name: 'clipboard-document-check', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'adminPurchaseOrderList'}, label: Main.trans.t(Lang.t.app.menus.purchaseOrders), icon: {name: 'clipboard-document-check', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'adminReviewList'}, label: Main.trans.t(Lang.t.app.menus.reviews), icon: {name: 'star', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'adminTransactionList'}, label: Main.trans.t(Lang.t.app.menus.transactions), icon: {name: 'credit-card', solid: true, type: IconTypes.HERO},},
                ]
            });

            menu.push({
                link: null, label: Main.trans.t(Lang.t.app.menus.interactions), icon: {name: 'inbox-arrow-down', solid: false, type: IconTypes.HERO}, children: [
                    {link: {name: 'adminProductOfferList'}, label: Main.trans.t(Lang.t.app.menus.productOffers), icon: {name: 'inbox-arrow-down', solid: true, type: IconTypes.HERO},},
                    {link: {name: 'adminProductInquiryList'}, label: Main.trans.t(Lang.t.app.menus.inquiries), icon: {name: 'shopping-cart', solid: true, type: IconTypes.HERO},},
                ]
            });

            let administrationList = [];
            administrationList.push({link: {name: 'adminManufyValueDocumentList'}, label: Main.trans.t(Lang.t.app.menus.manufyValueApplications), icon: {name: 'leaf', solid: true, type: IconTypes.FONT_AWESOME},})
            administrationList.push({link: {name: 'adminInvoiceList'}, label: Main.trans.t(Lang.t.app.menus.invoices), icon: {name: 'document-text', solid: true, type: IconTypes.HERO},})
            administrationList.push({link: {name: 'adminUserList'}, label: Main.trans.t(Lang.t.app.menus.users), icon: {name: 'users', solid: true, type: IconTypes.HERO},})
            administrationList.push({link: {name: 'adminMediaList'}, label: Main.trans.t(Lang.t.app.menus.media), icon: {name: 'file', solid: false, type: IconTypes.FONT_AWESOME},})

            if (Store.user.isSuperAdmin) {
                administrationList.push({link: {name: 'adminAdminList'}, label: Main.trans.t(Lang.t.app.menus.admins), icon: {name: 'chevron-double-up', solid: true, type: IconTypes.HERO},});
                administrationList.push({link: {name: 'superAdminOperations'}, label: 'Operations', icon: {name: 'cog-6-tooth', solid: true, type: IconTypes.HERO},});
            }

            if (Settings.isLocal) {
                administrationList.push({link: {name: 'translations'}, label: Main.trans.t(Lang.t.app.menus.translations), icon: {name: 'language', solid: true, type: IconTypes.HERO},});
            }

            menu.push({link: null, label: Main.trans.t(Lang.t.app.menus.administration), icon: {name: 'briefcase', solid: false, type: IconTypes.HERO}, children: administrationList});

            if (Store.user.isSuperAdmin) {
                menu.push({
                    link: null, label: 'Stripe', icon: {name: 'currency-euro', solid: false, type: IconTypes.HERO}, children: [
                        {link: {name: 'stripeProductList'}, label: 'Products', icon: {name: 'circle-stack', solid: true, type: IconTypes.HERO},},
                        {link: {name: 'stripeConfigurationList'}, label: 'Configurations', icon: {name: 'circle-stack', solid: true, type: IconTypes.HERO},},
                        {link: {name: 'stripePricingTableRead'}, label: 'Pricing Tables', icon: {name: 'circle-stack', solid: true, type: IconTypes.HERO},},
                    ]
                });
            }
        }

        return menu;
    }

    public getBrowserScrollbarWidth(): number {

        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        // outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    }

    /**
     * Get all pending RFQ models from local storage.
     */
    public getPendingRFQs(): RFQFormModel[] {
        const rfqKey: string = Settings.clientStoragePrefix + 'rfq';
        const models: RFQFormModel[] = [];
        for (let i = window.localStorage.length; i--;) {
            const key: string = window.localStorage.key(i);
            if (key.startsWith(rfqKey)) {
                const sellerUuid: Uuid = <Uuid>key.substr(rfqKey.length + 1);
                models.push(this.getPendingRFQ((sellerUuid == '') ? null : sellerUuid));
            }
        }

        return models;
    }

    /**
     * Get a potential pending RFQ model from local storage.
     */
    public getPendingRFQ(sellerUuid: Uuid = null): RFQFormModel {
        if (!Store.user.isBuyer && !Store.user.isGuest) {
            return null;
        }

        try {
            const varName: string = (sellerUuid) ? 'rfq_' + sellerUuid : 'rfq';

            // Try to read previously inserted data from local storage:
            const formData: RFQFormModel = StorageHelper.getLocalVar<RFQFormModel>(varName);
            if (formData?.project) {
                // Cast dates:
            }
            if (formData?.products) {
                // Cast fileDtos:
                for (let i: number = formData.products.length; i--;) {
                    formData.products[i].targetDeliveryDate = (formData.products[i].targetDeliveryDate) ? new Date(formData.products[i].targetDeliveryDate) : null;

                    for (let j: number = formData.products[i]._tpFiles.length; j--;) {
                        formData.products[i]._tpFiles[j] = FileDTO.cast(formData.products[i]._tpFiles[j]);
                    }

                }
            }
            if (formData?.services) {
                // Cast fileDtos:
                for (let i: number = formData.services.length; i--;) {
                    formData.services[i].targetDeliveryDate = (formData.services[i].targetDeliveryDate) ? new Date(formData.services[i].targetDeliveryDate) : null;

                    for (let j: number = formData.services[i]._attachments.length; j--;) {
                        formData.services[i]._attachments[j] = FileDTO.cast(formData.services[i]._attachments[j]);
                    }
                }
            }
            return formData;
        } catch (error) {
        }
        return null;
    }

    /**
     * Locally store data for a pending RFQ.
     * Note that value has a type `RFQWizardPage['formData']` but because of json parsing it will actually be the models of the forms that will be stored!
     * So when we do a StorageHelper.getLocalVar we get a type `RFQFormModel` back!
     */
    public setPendingRFQ(value: RFQWizardPage['formData'], sellerUuid: Uuid = null): void {
        const varName: string = (sellerUuid) ? 'rfq_' + sellerUuid : 'rfq';
        StorageHelper.setLocalVar(varName, value);
    }

    private saveSettings(): void {
        StorageHelper.setLocalVar('settings', this.state.local);
    }

    private resizeHandler(): void {
        this.state.width = window.innerWidth;
        this.state.height = window.innerHeight;
        this.state.browserScrollbarWidth = this.getBrowserScrollbarWidth();

        if (this.state.width < this.state.mobileBreakPoint) {
            this.state.device = DeviceTypes.MOBILE;
        } else if (this.state.width < this.state.tabletBreakPoint) {
            this.state.device = DeviceTypes.TABLET;
        } else {
            this.state.device = DeviceTypes.DESKTOP;
        }
        this.deviceChangedSignal.emit(this.state.device);
    }
}
