import {BaseDTO} from '@/app/dto/BaseDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {TranslationDTO} from "@/app/dto/TranslationDTO";

export class RfqReviewDTO extends BaseDTO {

    public sellerName: string = null
    public projectName: string = null
    public comment: TranslationDTO = new TranslationDTO();
    public createdAt: Date = null;

    public scoreDescription: number = null;
    public scoreTechpack: number = null;
    public scorePrice: number = null;
    public scoreQuantity: number = null;
    public scoreSustainability: number = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            createdAt: Date,
            comment: TranslationDTO,
        };
    }
}
