import {CastableProperties} from '@/app/utils/Castable';
import {BaseProductOfferDTO} from "@/app/dto/BaseProductOfferDTO";
import {BuyerRfqProductDTO} from "@/app/dto/BuyerRfqProductDTO";

export class ProductOfferDTO extends BaseProductOfferDTO {

    public unreadMessagesCount: number = null;

    public status: string = null;
    public product: BuyerRfqProductDTO = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            product: BuyerRfqProductDTO,
        };
    }

}
