<script lang="ts" src="./Indicator.ts"></script>


<template>

  <div :class="clss">
    <slot></slot>
  </div>

</template>


<style lang="scss" scoped>

.indicator {
  @apply flex items-center justify-center absolute rounded-full border-3 border-neutral-0 bg-neutral-10;

  &.x-small {
    @apply w-4 h-4;

    &.x-right {
      @apply -right-1;
    }

    &.x-center {
      @apply right-0 left-0 mx-auto;
    }

    &.x-left {
      @apply -left-1;
    }

    &.y-top {
      @apply -top-1;
    }

    &.y-bottom {
      @apply -bottom-1;
    }
  }

  &.small {
    @apply w-5 h-5;

    &.x-right {
      @apply -right-1.5;
    }

    &.x-center {
      @apply right-0 left-0 mx-auto;
    }

    &.x-left {
      @apply -left-1.5;
    }

    &.y-top {
      @apply -top-1.5;
    }

    &.y-bottom {
      @apply -bottom-1.5;
    }
  }

  &.medium {
    @apply w-6 h-6;

    &.x-right {
      @apply -right-2;
    }

    &.x-center {
      @apply right-0 left-0 mx-auto;
    }

    &.x-left {
      @apply -left-2;
    }

    &.y-top {
      @apply -top-2;
    }

    &.y-bottom {
      @apply -bottom-2;
    }
  }

  &.large {
    @apply w-7 h-7;

    &.x-right {
      @apply -right-2.5;
    }

    &.x-center {
      @apply right-0 left-0 mx-auto;
    }

    &.x-left {
      @apply -left-2.5;
    }

    &.y-top {
      @apply -top-2.5;
    }

    &.y-bottom {
      @apply -bottom-2.5;
    }
  }

  &.x-large {
    @apply w-8 h-8;

    &.x-right {
      @apply -right-3;
    }

    &.x-center {
      @apply right-0 left-0 mx-auto;
    }

    &.x-left {
      @apply -left-3;
    }

    &.y-top {
      @apply -top-3;
    }

    &.y-bottom {
      @apply -bottom-3;
    }
  }

  &.dark {
    @apply bg-neutral-800;
  }

  &.neutral {
    @apply bg-neutral-400;
  }

  &.primary {
    @apply bg-blue-400;
  }

  &.secondary {
    @apply bg-green-400;
  }

  &.warning {
    @apply bg-yellow-300;
  }

  &.danger {
    @apply bg-red-400;
  }

  &.help {
    @apply bg-purple-300;
  }
}

</style>
