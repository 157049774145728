import Bluebird from 'bluebird';
import {Cropper, CropperResult} from 'vue-advanced-cropper';
import {Component} from 'vue-facing-decorator';
import {Modal} from '@/app/views/modals/Modal';

import 'vue-advanced-cropper/dist/style.css';

export type CropSettings = {
    minWidth?: number,
    minHeight?: number,
    aspectRatio?: number,
    defaultBoundaries?: 'fit' | 'fill',
};

@Component({
    components: {
        Cropper,
    },
})
export default class CropModal extends Modal {

    declare public readonly $refs: {
        cropper: InstanceType<typeof Cropper>;
    };

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public fileList: File[] = null;
    public croppedFiles: File[] = null;
    public results: CropperResult[] = null;
    public index: number = null;
    public resolveHandler: (thenableOrResult?: File[]) => void = null;
    public settings: CropSettings = {};

    public get current(): { name: string, src: string } {
        return {
            name: this.fileList[this.index].name,
            src: URL.createObjectURL(this.fileList[this.index]),
        }
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public show(fileList: File[], settings: CropSettings = null): Bluebird<File[]> {
        this.index = 0;
        this.results = [];
        this.croppedFiles = [];

        this.fileList = fileList;
        this.settings = settings ?? {};

        this.flags.loading = true;
        this.flags.show = true;

        const promiseCallback = (resolve: (thenableOrResult?: File[]) => void, reject: (error?: any) => void, onCancel?: (callback: () => void) => void): void => {
            this.resolveHandler = resolve;
        };

        return new Bluebird<File[]>(promiseCallback);
    }

    public hide(force: boolean = false): boolean {
        this.fileList = [];
        this.results = [];
        this.croppedFiles = [];
        return super.hide(force);
    }

    public async goto(index: number): Promise<void> {
        if (this.flags.loading || index == this.index) {
            return null;
        }

        await this.storeCurrentRect();

        this.index = Math.min(Math.max(index, 0), this.fileList.length - 1);

        // Restore the crop-rect:
        this.$refs.cropper.setCoordinates(this.results[this.index]?.coordinates);
    }

    public async submit(): Promise<void> {
        await this.storeCurrentRect();

        // Fill the non-cropped slots with the original:
        for (let i: number = this.fileList.length; i--;) {
            if (!this.croppedFiles[i]) {
                this.croppedFiles[i] = this.fileList[i];
            }
        }

        this.resolveHandler(this.croppedFiles);
        this.hide();
    }

    public async storeCurrentRect(): Promise<void> {
        const index: number = this.index;
        // Store the crop meta results:
        const result: CropperResult = this.$refs.cropper.getResult();

        const newCoordinates = result.coordinates;
        const prevCoordinates = this.results[index]?.coordinates;
        // Check if needs cropping:
        if (result.image.height == newCoordinates.height && result.image.width == newCoordinates.width) {
            return;
        }
        // Check if cropping changed:
        if (newCoordinates.height === prevCoordinates?.height && newCoordinates.width === prevCoordinates?.width && newCoordinates.top === prevCoordinates?.top && newCoordinates.left === prevCoordinates?.left) {
            return;
        }

        this.results[index] = result;   // Store the result to reset when coming back to this image
        this.flags.loading = true;

        // Store the result as File:
        // NOTE: Since the blob is generated from the visibile canvas we have to do it now and can't wait for all the files to have a result!
        const blob: Blob = await new Promise((resolve) => {
            return result.canvas.toBlob(resolve);
        });
        const file: File = new File([blob], this.fileList[index].name);
        this.croppedFiles[index] = file;
    }

    // private removeFile(index:number):void {
    //     this.fileList.splice(index, 1);
    //     this.results.splice(index, 1);
    //     this.croppedFiles.splice(index, 1);
    //     if (index < this.index) {
    //         this.index = index;
    //     }
    //     else if (index == this.index) {
    //         this.goto(this.index - 1);
    //     }
    // }

    public getThumb(file: File): string {
        return URL.createObjectURL(file);
    }

    /**
     * Default crops size is the complete image.
     */
    public defaultCropSize({imageSize, visibleArea}): { width: number, height: number } {
        return {
            width: (visibleArea || imageSize).width,
            height: (visibleArea || imageSize).height,
        };
    }
}
