import {Component, Prop, Vue} from 'vue-facing-decorator';
import {IIcon} from '@/app/views/components/misc/Icon';

@Component({})

export default class Media extends Vue {

    @Prop({type: Object, required: true})
    public readonly icon: IIcon;

    @Prop({type: String, required: true})
    public readonly caption: string;

    @Prop({type: Boolean, default: false})
    public readonly inverted: boolean;

    @Prop({type: [Number, String, Array], required: false})
    public readonly value: number | string | string[];

    public get isArray(): boolean {
        return Array.isArray(this.value);
    }

    public created(): void {
    }

    public mounted(): void {
    }
}
