import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';

@Component({})
export default class Badge extends Vue {

    @Prop({type: Boolean, default: false})
    public readonly disabled: boolean;
    @Prop({type: String, default: null})
    private readonly size: string;
    @Prop({type: String, default: StyleAppearance.PRIMARY})
    private readonly appearance: StyleAppearance;
    @Prop({type: Boolean, default: false})
    private readonly interactive: boolean;

    @Prop({type: Boolean, default: false})
    private readonly isSolid: boolean;

    public get clss(): string {
        let clss: string = 'badge';
        if (this.appearance) {
            clss += ' ' + this.appearance;
        }
        if (this.size) {
            clss += ' ' + this.size;
        }
        if (this.interactive) {
            clss += ' interactive';
        }
        if (this.isSolid) {
            clss += ' solid';
        }
        if (this.disabled) {
            clss += ' disabled';
        }

        return clss;
    }

}
