/**
 * A shorthand for the console.log method.
 */
const log: Window['log'] = window.log = console.log.bind(console);

/**
 * A shorthand for the console.warn method.
 */
const warn: Window['warn'] = window.warn = console.warn.bind(console);

/**
 * A more versatile log method than 'console.log'.
 * Drawback is that it doesn't supply original line numbers.
 */
const tr: Window['tr'] = window.tr = (...args: any[]): void => {
    try {
        console.log((args.length === 1) ? args[0] : args);
    } catch (err) {
        console.log.apply(null, args);
    }
};
