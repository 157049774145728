import {ChatMessageTypes} from '@/app/constants/ChatMessageTypes';
import {Align} from '@/app/constants/styles/Align';
import {BaseDTO} from '@/app/dto/BaseDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {PurchaseOrderDTO} from '@/app/dto/PurchaseOrderDTO';
import {TranslationDTO} from '@/app/dto/TranslationDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {Uuid} from '@/types/Types';
import {InquiryDTO} from "@/app/dto/InquiryDTO";
import {Main} from "@/app/Main";
import {Lang} from "@/app/lang/Lang";
import {SystemMessageTypes} from "@/app/constants/SystemMessageTypes";
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";

export class MessageDTO extends BaseDTO {

    public sentAt: Date = null;
    public readAt: Date = null;
    public type: ChatMessageTypes = null;
    public body: TranslationDTO = null;
    public sender: CompanyShortDTO = null;
    public senderUserName: string = null;
    public attachments: FileDTO[] = null;
    public replyMessage: MessageDTO = null;
    public sampleOrderUuid: Uuid = null;
    public purchaseOrderUuid: Uuid = null;
    public threadUuid: Uuid = null;
    public purchaseOrder: PurchaseOrderDTO = null;
    public inquiry: InquiryDTO = null;
    // For client-side use only:
    public nextMsg: MessageDTO = null;
    public alignment: Align = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            sentAt: Date,
            body: TranslationDTO,
            sender: CompanyShortDTO,
            attachments: [FileDTO],
            replyMessage: MessageDTO,
            purchaseOrder: PurchaseOrderDTO,
            inquiry: InquiryDTO,
        };
    }

    public get plainBody(): string {
        if (this.type === ChatMessageTypes.TEXT && this.attachments?.[0]) {
            return Main.trans.t(Lang.t.pages.chat.attachment);
        }

        if (this.type === ChatMessageTypes.TEXT && this.body?.originalValue) {
            return this.body.originalValue;
        }

        if (this.type === ChatMessageTypes.SYSTEM) {
            return Main.trans.t('pages.chat.system.' + this.body.originalValue, {varName: this.sender.name});
        }

        if (this.type === ChatMessageTypes.INTERACTIVE) {
            if (this.body?.originalValue == SystemMessageTypes.INQUIRY_FROM_BUYER || this.body?.originalValue == SystemMessageTypes.CHAT_DISCLAIMER) {
                return Main.trans.t('pages.chat.interactive.' + this.body.originalValue + '.general.body', {varName: this.sender.name});
            }

            return Main.trans.t('pages.chat.interactive.' + this.body.originalValue, {varName: this.sender.name});
        }

        return '';
    }

}
