import {CastableProperties} from '@/app/utils/Castable';
import {RfqProductDTO} from "@/app/dto/RfqProductDTO";

export class SellerRfqProductDTO extends RfqProductDTO {

    public price: number = null;
    public quantity: number = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
        };
    }

}
