import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {LocationDTO} from '@/app/dto/LocationDTO';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {Modal} from '@/app/views/modals/Modal';
import {Uuid} from '@/types/Types';

type NewLocationFormModel = {
    name?: string,
    address1?: string,
    address2?: string,
    zipcode?: string,
    city?: string,
    region?: string,
    country?: string,
};

@Component({})
export default class LocationModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
        showNewAddressForm: false,
    };
    public locations: LocationDTO[] = null;
    public formData: FormData<NewLocationFormModel> = null;
    public selectedLocationUuid: Uuid = null;
    private companyUuid: Uuid = null;
    private resolveHandler: (thenableOrResult?: LocationDTO) => void = null;

    public created(): void {
    }

    public mounted(): void {
    }

    /**
     * NOTE: The given locations will be altered in case a new address is added!
     */
    public show(companyUuid: Uuid, selectedUuid: Uuid = null): Bluebird<LocationDTO> {
        this.companyUuid = companyUuid;

        this.selectedLocationUuid = selectedUuid;

        this.loadLocations();

        // define rules:
        const formRules: FormRulesById = {};
        formRules.name = [FormRules.required(), FormRules.maxLength(255)];
        formRules.address1 = [FormRules.required(), FormRules.maxLength(255)];
        formRules.address2 = [FormRules.maxLength(255)];
        formRules.zipcode = [FormRules.required(), FormRules.maxLength(255)];
        formRules.city = [FormRules.required(), FormRules.maxLength(255)];
        formRules.region = [FormRules.maxLength(255)];
        formRules.country = [FormRules.required()];

        this.formData = new FormData({}, formRules);

        this.flags.showNewAddressForm = false;
        this.flags.show = true;

        const promiseCallback = (resolve: (thenableOrResult?: LocationDTO) => void, reject: (error?: any) => void, onCancel?: (callback: () => void) => void): void => {
            this.resolveHandler = resolve;
        };

        return new Bluebird<LocationDTO>(promiseCallback);
    }

    public showNewAddressForm(): void {
        this.selectedLocationUuid = null;
        this.flags.showNewAddressForm = true;
    }

    public setLocation(locationUuid: Uuid): void {
        if (this.selectedLocationUuid == locationUuid) {    // Deselect if current selection clicked
            locationUuid = null;
        }
        this.selectedLocationUuid = locationUuid;
        this.flags.showNewAddressForm = false;
    }

    public close(): void {
        this.hide(true);

        const selectedLocation: LocationDTO = this.locations.find((locationDto: LocationDTO) => {
            return (locationDto.uuid == this.selectedLocationUuid);
        });

        this.resolveHandler(selectedLocation);
    }

    public async submit(): Promise<void> {
        const formIsValid: boolean = this.formData.validate();
        if (!formIsValid) {
            return;
        }

        this.flags.loading = true;

        const newLocation: LocationDTO = new LocationDTO();
        newLocation.name = this.formData.model.name;
        newLocation.address1 = this.formData.model.address1;
        newLocation.address2 = this.formData.model.address2;
        newLocation.zipcode = this.formData.model.zipcode;
        newLocation.city = this.formData.model.city;
        newLocation.region = this.formData.model.region;
        newLocation.country = this.formData.model.country;

        let location: LocationDTO = await Store.locations.addLocation(this.companyUuid, newLocation);

        this.locations.push(location);
        this.selectedLocationUuid = location.uuid;

        this.flags.showNewAddressForm = false;

        this.flags.loading = false;
    }

    private async loadLocations(): Promise<void> {
        this.locations = await Store.locations.getLocations(this.companyUuid);
    }

}
