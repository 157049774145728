<script lang="ts" src="./KYCModal.ts"></script>


<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-large secondary">
      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.kycModal.title') }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <div class="dialog-body">
        <span v-html="$trans.t('app.kycModal.description')"></span>

        <div class="m-gutter-wrapper vertical flex flex-col mt-3">
          <div class="m-input-group">
            <h6>{{ $trans.t('app.order.companyLegalName.title') }}<span class="text-red-400">*</span></h6>
            <m-form-field
              v-model="formData.model.companyLegalName"
              :errors="formData.errors.companyLegalName"
              class="mt-3"
              type="text"
            >
            </m-form-field>
            <small class="support-text">{{ $trans.t('app.order.companyLegalName.subtitle') }}</small>
          </div>

          <div class="m-input-group">
            <h6>{{ $trans.t('app.order.cocNumber.title') }}<span class="text-red-400">*</span></h6>
            <m-form-field
              v-model="formData.model.cocNumber"
              :errors="formData.errors.cocNumber"
              class="mt-3"
              type="text"
            >
              <template #append>
                <span class="append">
                  <m-tooltip :label="$trans.t('pages.registerCompany.cocNumber.info')">
                    <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="ml-1 text-tiny text-teal-300 cursor-pointer" name="circle-info"/>
                  </m-tooltip>
                </span>
              </template>
            </m-form-field>
            <small class="support-text">{{ $trans.t('app.order.cocNumber.subtitle') }}</small>
          </div>

          <div class="m-input-group">
            <m-form-field-checkbox
              v-model="formData.model.agreeOppTerms"
              :errors="formData.errors.agreeOppTerms"
            >
              <span v-html="$trans.t('app.order.agreeOppTerms', { varUrlTerms: $settings.urlOppTerms, varNameOpp: $settings.nameOpp })"></span>
              <span class="text-red-400">*</span>
            </m-form-field-checkbox>
          </div>
        </div>
      </div>

      <div class="dialog-footer">
        <div class="item">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :size="$const.StyleSize.SMALL"
            _disabled="!formData.model.accept"
            @click="submit()"
          >
            {{ $trans.t('app.labels.submit') }}
          </m-button>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.dialog-footer {
  @apply flex flex-wrap -mb-3 -ml-3 justify-end rounded-b-xl;

  .item {
    @apply pb-3 pl-3;
  }
}

</style>
