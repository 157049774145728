<script lang="ts" src="./FormLabel.ts"></script>


<template>
  <label :class="['label', disabled ? 'disabled' : '']" :for="id">
    <span>
      <slot></slot>
    </span>
    <span v-if="required" :class="[disabled ? 'text-current' : 'text-red-400']">*</span>

    <m-link v-if="tooltipLink && !tooltipLabel" :newWindow="true" :to="tooltipLink">
      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="ml-1 text-tiny text-teal-300 cursor-pointer" name="circle-question"/>
    </m-link>
    <m-tooltip v-if="tooltipLabel && !tooltipLink" :label="tooltipLabel">
      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="ml-1 text-tiny text-teal-300 cursor-pointer" name="circle-info"/>
    </m-tooltip>
  </label>
</template>


<style lang="scss" scoped>
</style>
