import {BaseDTO} from '@/app/dto/BaseDTO';
import {CastableProperties} from '@/app/utils/Castable';

export class FabricDTO extends BaseDTO {

    public name: string = null;
    public percentage: number = null;
    public grammage: number = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
        };
    }

}
