import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {StyleShapes} from '@/app/constants/styles/StyleShapes';
import {StyleSize} from '@/app/constants/styles/StyleSize';

@Component({})
export default class ManufyValuePill extends Vue {

    @Prop({type: String, default: null})
    public readonly icon: string;

    @Prop({type: String, default: StyleAppearance.PRIMARY})
    public readonly appearance: StyleAppearance;

    @Prop({type: String, default: StyleShapes.DEFAULT})
    public readonly shape: StyleShapes;

    @Prop({type: [String, Object], default: StyleSize.MEDIUM})
    public readonly size: StyleSize | Object;

    @Prop({type: Boolean, default: false})
    public readonly disabled: boolean;

    @Prop({type: Boolean, default: false})
    public readonly full: boolean;

    @Prop({type: Boolean, default: false})
    public readonly sharp: boolean;

    @Prop({type: Boolean, default: false})
    public readonly solid: boolean;

    public get class(): string {
        let clss: string = 'm-value-pill';
        if (this.appearance) {
            clss += ' ' + this.appearance;
        }
        if (this.size) {
            if (typeof this.size === 'object') {
                for (let key in this.size) {
                    if (key !== 'default') {
                        clss += ' ' + key + ':' + this.size[key];
                    } else {
                        clss += ' ' + this.size[key];
                    }
                }
            } else {
                clss += ' ' + this.size;
            }

        }
        if (this.shape) {
            clss += ' ' + this.shape;
        }
        if (this.disabled) {
            clss += ' disabled';
        }
        if (this.full) {
            clss += ' full';
        }
        if (this.sharp) {
            clss += ' sharp';
        }
        if (this.solid) {
            clss += ' solid';
        }

        return clss;
    }

}
