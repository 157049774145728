<script lang="ts" src="./CategoryListModal.ts"/>

<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-medium secondary">

      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('pages.company.categories.label') }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <div class="dialog-body mb-3">
        <template v-for="category in categories" v-if="categories.length > 0">
          <m-value-pill
            :appearance="$const.StyleAppearance.PRIMARY"
            :icon="category"
            :size="$const.StyleSize.XSMALL"
            class="mr-1 mb-1 cursor-pointer"
            @click="showSellersWithCategory(category)"
          >
            {{ $trans.t('meta.categories.' + category) }}
          </m-value-pill>
        </template>
      </div>

    </div>
  </div>

</template>
