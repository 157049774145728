<script lang="ts" src="./PurchaseOrderModal.ts"></script>

<template>
  <div v-if="flags.show" class="modal top" @click.self="hide()">
    <div :class="`modal-dialog w-x-large ${page} ${showCostEstimation() ? 'cost-estimation' : ''} ${flags.hasScrollbar ? 'has-scrollbar' : ''}`" :data-scrollbar-width="$store.app.browserScrollbarWidth">
      <div class="dialog-header">
        <h2 v-if="page === Pages.LIST" class="title">
          {{ $trans.t('app.menus.purchaseOrders') }}
        </h2>
        <h2 v-if="page === Pages.COLLABORATION_LIST" class="title">
          {{ $trans.t('app.menus.projects') }}
        </h2>
        <h2 v-else class="title flex flex-col xs:flex-row xs:items-center xs:space-x-2">
          <span>{{ type === 'purchase_order' ? $trans.t('app.purchaseOrder.modal.title') : $trans.t('app.sampleModal.title') }}</span>
          <span v-if="purchaseOrder" class="inline-flex items-center">
            <m-icon :solid="true" :type="$const.IconTypes.HERO" class="text-green-400" name="hashtag"/>
            <strong class="font-body italic text-tiny text-neutral-400">
              {{ purchaseOrder.getFormattedOrderNumber() }}
            </strong>
          </span>
        </h2>

        <h2 v-if="showCostEstimation()" class="side-title">
          {{ $trans.t('app.order.costs.title') }}
        </h2>

        <m-button
          :appearance="$const.StyleAppearance.NEUTRAL"
          :shape="$const.StyleShapes.SQUARE"
          :size="$const.StyleSize.XSMALL"
          :variant="$const.StyleVariants.TEXT"
          class="close"
          @click.prevent="hide()"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </m-button>
      </div>

      <div v-if="!showCostEstimation()" ref="dialogBody" class="dialog-body">

        <!-- START LIST PAGE-->
        <template v-if="page === Pages.LIST">
          <table id="purchase-order-list-table" class="m-grid-table striped bordered mb-6">
            <thead class="table-header">
            <tr class="table-row">
              <td class="table-col p-2 md:p-3 xl:p-4">
                <strong>{{ $trans.t('app.order.list.number') }}</strong>
              </td>
              <td class="table-col p-2 md:p-3 xl:p-4">
                <strong>{{ $trans.t('app.order.list.status') }}</strong>
              </td>
              <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                <strong>{{ $trans.t('app.order.list.updatedAt') }}</strong>
              </td>
              <td class="table-col p-2 md:p-3 xl:p-4 text-right">
                <strong>{{ $trans.t('app.order.list.totalCosts') }}</strong>
              </td>
              <td class="table-col p-2 md:p-3 xl:p-4 text-right">
              </td>
            </tr>
            </thead>

            <tbody class="table-body">

            <template v-if="purchaseOrders.length > 0">
              <tr v-for="purchaseOrder in purchaseOrders" class="table-row" v-bind="purchaseOrder">
                <td class="table-col p-2 md:p-3 xl:p-4">
                <span class="flex items-center h-full text-blue-400 cursor-pointer" @click="showPurchaseOrderReadPage(purchaseOrder.uuid)">
                  {{ $trans.t('app.purchaseOrder.type.' + purchaseOrder.type) }} #{{ purchaseOrder.getFormattedOrderNumber() }}
                </span>
                </td>
                <td class="table-col p-2 md:p-3 xl:p-4">
                <span class="flex items-center h-full">
                  {{ $trans.t('app.purchaseOrder.status.' + purchaseOrder.status) }}
                </span>
                </td>
                <td class="table-col p-2 text-center md:p-3 xl:p-4">
                  <span class="flex items-center justify-center h-full">{{ $trans.d(purchaseOrder.updatedAt) }}</span>
                </td>
                <td class="table-col p-2 md:p-3 xl:p-4">
                <span class="flex items-center h-full justify-end">
                  {{ purchaseOrder.totalCosts ? $store.user.isBuyer ? $trans.n(purchaseOrder.totalCosts, 'eur') : $trans.n(purchaseOrder.sellerTotalCostsNet, 'eur') : $trans.t('app.labels.tbd') }}
                </span>
                </td>
                <td class="table-col p-2 sticky top-0 right-0 bottom-0 sm:static md:p-3 md:pl-1 xl:p-4 xl:pl-1">
                  <div class="flex flex-col px-1 space-y-1 sm:flex-row sm:p-0 sm:space-x-2 sm:space-y-0 sm:justify-end items-center h-full">
                    <m-button
                      v-if="purchaseOrder.status === $const.PurchaseOrderStatus.WAITING_FOR_COST_ESTIMATION || purchaseOrder.status === $const.PurchaseOrderStatus.PRICE_NEGOTIATION"
                      :appearance="$const.StyleAppearance.DANGER"
                      :flex-button="true"
                      :icon-before="{ name: 'trash', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :size="$const.StyleSize.XSMALL"
                      :variant="$const.StyleVariants.OUTLINE"
                      @click="confirmCancelPurchaseOrder(purchaseOrder.uuid)"
                    >
                      {{ $trans.t('app.labels.cancel') }}
                    </m-button>
                    <m-button
                      :appearance="$const.StyleAppearance.SECONDARY"
                      :flex-button="true"
                      :icon-before="{ name: 'eye', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :size="$const.StyleSize.XSMALL"
                      @click="showPurchaseOrderReadPage(purchaseOrder.uuid)"
                    >
                      {{ $trans.t('app.labels.view') }}
                    </m-button>
                  </div>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr class="table-row">
                <td class="table-col p-2 md:p-3 xl:p-4" colspan="5">
                  {{ $trans.t('pages.company.orders.noOrders') }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </template>
        <!-- END LIST PAGE-->

        <!-- START COLLABORATION LIST PAGE-->
        <template v-if="page === Pages.COLLABORATION_LIST">
          <ul class="project-list w-full">
            <li v-for="project in projects" :key="project.uuid">
              <div class="project">
                <div class="project__header flex flex-row justify-between items-center">
                  <div class="flex flex-col">
                    <small class="m-caption mb-1">{{ $trans.t('pages.buyerDashboard.projects.project.title') }}</small>
                    <h2 class="title">{{ project.name }}</h2>
                  </div>
                </div>

                <ul class="project__products">
                  <li v-for="product in project.products" :key="product.uuid" :class="['product', { 'expanded': !product.expanded}]">
                    <div
                      class="product__header cursor-pointer"
                      @click="(product.offers?.length > 0) ? (product.expanded = (product.expanded == 'undefined') ? true : !product.expanded) : ''"
                    >

                      <div class="flex flex-1 items-start">
                        <m-icon :name="product.category" :solid="true" :type="$const.IconTypes.MANUFY" class="mt-0.5 mr-2 text-lg text-blue-400"/>
                        <h3 class="title">{{ product.name }}</h3>
                      </div>
                      <small v-if="product.offers?.length > 0" class="chevron-icon">
                        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="chevron-down"/>
                      </small>
                    </div>

                    <ul v-if="!product.expanded" class="product__offers justify-space-between">
                      <li v-for="offers in product.offers" :key="offers.uuid">
                        <div class="offer border-t px-4 py-3">

                          <div class="offer__header">
                            <!-- TODO: Display offer status -->

                            <m-avatar
                              :appearance="$const.StyleAppearance.PRIMARY"
                              :partner="offers.seller.isPartner"
                              :size="$const.StyleSize.SMALL"
                              :url="offers.seller.avatar?.getThumbUrl()"
                              :verified="offers.seller.isVerified"
                              class="mr-3"
                            />

                            <h4 class="seller-name">
                              <m-link
                                :newWindow="true"
                                :to="{ name: 'publicSellerRead', params: { uuid: offers.seller.uuid } }"
                              >{{ offers.seller.name }}
                              </m-link>
                            </h4>
                          </div>

                          <ul class="offer__meta">
                            <li class="item">
                              <small class="m-caption">{{ $trans.t('pages.buyerDashboard.projects.project.unitPrice') }}</small>
                              <h5 class="value sm:justify-center">
                                <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="euro-sign"/>
                                <span>{{ offers.price }}</span>
                              </h5>
                            </li>
                            <li class="item">
                              <small class="m-caption">{{ $trans.t('pages.buyerDashboard.projects.project.quantity') }}</small>
                              <h5 class="value sm:justify-center">
                                <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="boxes"/>
                                <span>{{ offers.quantity }}</span>
                              </h5>
                            </li>
                          </ul>

                          <!-- TODO: clean up buttons -->
                          <div class="offer__actions space-x-1 py-2 md:py-0">
                            <m-button
                              :appearance="$const.StyleAppearance.SECONDARY"
                              :size="$const.StyleSize.XSMALL"
                              :variant="$const.StyleVariants.OUTLINE"
                              class="w-full sm:w-max"
                              @click="show(offers.collaborationUuid, 'sample_order')"
                            >
                              {{ $trans.t('app.labels.requestSample') }}
                            </m-button>
                            <m-button
                              :appearance="$const.StyleAppearance.DARK"
                              :size="$const.StyleSize.XSMALL"
                              class="w-full sm:w-max"
                              @click="show(offers.collaborationUuid, 'purchase_order')"
                            >
                              {{ $trans.t('pages.chat.purchaseOrder.initiated') }}
                            </m-button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </template>
        <!-- END COLLABORATION LIST PAGE-->

        <!-- START SHIPMENT PAGE-->
        <template v-if="page === Pages.SHIPMENT">
          <div class="m-gutter-wrapper vertical flex-col flex-1 sm:w-0">
            <div class="m-input-group">
              <h6>{{ $trans.t('app.order.shipmentSent.title') }}</h6>

              <div class="space-y-4 mt-3">
                <div class="m-input-group">
                  <label class="label">{{ $trans.t('app.order.shipmentSent.date.label') }}<span class="text-red-400">*</span></label>
                  <m-form-field
                    v-model="sellerShipmentFormData.model.shipmentDate"
                    :errors="sellerShipmentFormData.errors.shipmentDate"
                    type="date"
                  >
                    <template #prepend>
                      <div class="prepend">
                        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="calendar"/>
                      </div>
                    </template>
                  </m-form-field>
                </div>

                <div class="m-input-group">
                  <label class="label">{{ $trans.t('app.form.courierName.label') }}<span class="text-red-400">*</span></label>
                  <m-form-field
                    v-model="sellerShipmentFormData.model.shipmentCourierName"
                    :errors="sellerShipmentFormData.errors.shipmentCourierName"
                    :placeholder="$trans.t('app.form.courierName.placeholder')"
                    type="text"
                  />
                </div>

                <div class="m-input-group">
                  <label class="label">{{ $trans.t('app.form.trackAndTraceNumber.label') }}<span class="text-red-400">*</span></label>
                  <m-form-field
                    v-model="sellerShipmentFormData.model.shipmentTrackAndTrace"
                    :errors="sellerShipmentFormData.errors.shipmentTrackAndTrace"
                    :placeholder="$trans.t('app.form.trackAndTraceNumber.placeholder')"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- END SHIPMENT PAGE-->

        <!-- START DISPUTE PAGE-->
        <template v-if="page === Pages.DISPUTE">
          <div class="m-gutter-wrapper vertical flex-col flex-1 sm:w-0">
            <div class="m-input-group">
              <h6>{{ $trans.t('app.order.startDispute.title') }}</h6>

              <div class="mt-3">
                <m-form-field-text-area
                  id="message"
                  v-model="buyerDisputeFormData.model.message"
                  :errors="buyerDisputeFormData.errors.message"
                  :placeholder="$trans.t('app.order.startDispute.description.placeholder')"
                  name="message"
                />
              </div>
            </div>
          </div>
        </template>
        <!-- END DISPUTE PAGE-->

        <!-- START REVIEW PAGE-->
        <template v-if="page === Pages.REVIEW">
          <div class="m-gutter-wrapper vertical flex-col flex-1 sm:w-0">
            <div class="text-center">
              <m-avatar
                :appearance="$const.StyleAppearance.PRIMARY"
                :partner="purchaseOrder.seller.isPartner"
                :shape="$const.StyleShapes.DEFAULT"
                :size="$const.StyleSize.XLARGE"
                :url="purchaseOrder.seller.avatar?.getThumbUrl()"
                :verified="purchaseOrder.seller.isVerified"
                class="mx-auto"
              />

              <h3 class="mt-4 text-base sm:text-lg">{{ purchaseOrder.seller.name }}</h3>
              <p v-if="purchaseOrder.seller.primaryLocation" class="mt-1.5 text-lg text-center leading-tight mb-3">
                <m-icon :solid="true" :type="$const.IconTypes.HERO" class="inline-block mb-1 text-green-400" name="map-pin"/>
                {{ purchaseOrder.seller.primaryLocation?.formattedString }}
              </p>

              <template class="flex justify-center">
                <m-form-field-review
                  v-model="reviewFormData.model.score"
                  :clearable="true"
                  :errors="reviewFormData.errors.score"
                  :max="5"
                  class="mt-4"
                />
              </template>
            </div>

            <div class="m-input-group">
              <h6>{{ $trans.t('app.order.leaveReview.body.title') }}</h6>

              <div class="mt-3">
                <m-form-field-text-area
                  id="body"
                  v-model="reviewFormData.model.body"
                  :errors="reviewFormData.errors.body"
                  :placeholder="$trans.t('app.order.leaveReview.body.placeholder')"
                  name="body"
                />
              </div>
            </div>

            <div class="m-input-group">
              <m-form-field-checkbox v-model="reviewFormData.model.hasNameDisplayed">{{ $trans.t('app.order.leaveReview.body.nameDisplayToggle') }}</m-form-field-checkbox>
            </div>
          </div>
        </template>
        <!-- END REVIEW PAGE-->

        <!-- START REVIEW PREVIEW PAGE-->
        <template v-if="page === Pages.REVIEW_SENT">
          <div class="m-gutter-wrapper vertical flex-col flex-1 sm:w-0">
            <div>
              <div class="inline-flex relative mb-2">
                <ul class="star-list star-list--light">
                  <li
                    v-for="star in 5"
                    :key="`${star}-light`"
                  >
                    <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
                  </li>
                </ul>
                <ul class="star-list star-list--solid">
                  <li
                    v-for="star in purchaseOrder.review.score"
                    :key="`${star}-solid`"
                  >
                    <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
                  </li>
                </ul>
              </div>

              <h4 class="username">{{ purchaseOrder.review.senderName ?? $trans.t('pages.sellerIndex.reviewFrom', {countryName: $trans.t('meta.countries.' + purchaseOrder.review.senderCountry)}) }}</h4>
              <p>{{ purchaseOrder.review.body.value }}</p>
            </div>
          </div>
        </template>
        <!-- END REVIEW PREVIEW PAGE-->

        <!-- START ATTACHMENT UPLOAD PAGE-->
        <template v-if="page === Pages.ATTACHMENTS">
          <div class="m-gutter-wrapper vertical flex-col flex-1 sm:w-0">
            <h2><label for="attachments">{{ $trans.t('app.order.attachments.title') }}</label></h2>
            <template v-if="purchaseOrder.attachments.length > 0">
              <m-link v-for="attachment in purchaseOrder.attachments" :to="'download::' + attachment.originalUrl">
                <div>
                  <span>{{ $trans.d(attachment.createdAt, 'date') }}:</span>
                  <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="file"/>
                  <strong>{{ attachment.fileName }}</strong> <i>{{ attachment.fileSize }}</i>
                </div>
              </m-link>
            </template>
            <span v-else class="text-neutral-80 italic">{{ $trans.t('app.order.attachments.empty') }}</span>

            <div class="m-input-group">
              <span class="subtitle">{{ $trans.t('app.order.attachments.subtitle') }}</span>

              <div class="mt-3">
                <m-form-file-upload
                  id="attachments"
                  v-model="attachmentsFormData.model.attachments"
                  :accept="$const.FileMimes.FILES"
                  :errors="attachmentsFormData.errors.attachments"
                  :multiple="true"
                />
              </div>
            </div>
          </div>
        </template>
        <!-- END ATTACHMENT UPLOAD PAGE-->

      </div>

      <div v-else ref="dialogBody" class="dialog-body sm:-mt-4">
        <div class="m-gutter-wrapper vertical flex-col flex-1 sm:w-0">

          <!-- START PRODUCT PAGE -->
          <template v-if="page === Pages.PRODUCTS">
            <div class="m-input-group">
              <h2>{{ $trans.t('app.purchaseOrder.modal.products.title') }}<span class="text-red-400">*</span></h2>
              <span class="subtitle">{{ $trans.t('app.purchaseOrder.modal.products.subtitle.' + state) }}</span>

              <template v-if="state === States.CREATING">
                <table
                  id="purchase-order-brand-table"
                  :class="['m-grid-table bordered mt-3 mb-0 relative', { 'has-errors': (buyerProductsFormData?.errors?.products?.length > 0) }]"
                >
                  <thead class="table-header">
                  <tr class="table-row">
                    <td class="table-col p-2 md:p-3 xl:p-4">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.name') }}</strong>
                    </td>
                    <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.size') }}</strong>
                    </td>
                    <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.quantity') }}</strong>
                    </td>
                    <td class="table-col p-2 md:p-3 xl:p-4">
                    </td>
                  </tr>
                  </thead>

                  <tbody class="table-body">
                  <tr v-for="(currentProduct, index) in buyerProductsFormData.model.products" class="table-row relative">
                    <td class="table-col p-2 pr-1 md:p-3 xl:p-4">
                      <m-form-select-picker
                        v-model="buyerProductsFormData.model.products[index].productUuid"
                        :dropdownMaxCols="1"
                        :errors="buyerProductsFormData.errors.products?._[index]?.productUuid"
                        :handle-resize="true"
                        :items="collaboration.products.map((product) => { return {'label': product.product.name, 'id': product.product.uuid} })"
                        :size="$const.StyleSize.XSMALL"
                        class="w-full"
                      />
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <m-form-field
                        v-model="buyerProductsFormData.model.products[index].size"
                        :errors="buyerProductsFormData.errors.products?._[index]?.size"
                        :placeholder="$trans.t('app.purchaseOrder.modal.products.size')"
                        :size="$const.StyleSize.XSMALL"
                        type="text"
                      />
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4 xl:pr-2">
                      <m-form-field-number
                        v-model="buyerProductsFormData.model.products[index].quantity"
                        :errors="buyerProductsFormData.errors.products?._[index]?.quantity"
                        :min="1"
                        :size="$const.StyleSize.XSMALL"
                        :step="1"
                        type="number"
                      >
                        <template #prepend>
                          <div class="prepend">
                            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="boxes"/>
                          </div>
                        </template>
                      </m-form-field-number>
                    </td>
                    <td class="table-col p-3 pl-2 sticky top-0 right-0 bottom-0 md:p-3 md:pl-1 xl:p-4 xl:pl-1">
                        <span class="flex items-center justify-center h-full">
                          <m-button
                            :appearance="$const.StyleAppearance.DANGER"
                            :flex-button="true"
                            :is-light="true"
                            :shape="$const.StyleShapes.SQUARE"
                            :size="$const.StyleSize.XSMALL"
                            @click="removeProduct(index)"
                          >
                            <m-icon :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.HERO" class="ml-1 text-neutral-0 mx-auto" name="trash"/>
                          </m-button>
                        </span>
                    </td>
                  </tr>

                  <tr class="table-row">
                    <td class="table-col transparent pb-4 xs:pb-5 xl:pb-6"></td>
                    <td class="table-col transparent pb-4 xs:pb-5 xl:pb-6"></td>
                    <td class="table-col transparent pb-4 xs:pb-5 xl:pb-6"></td>
                    <td class="table-col transparent pb-4 xs:pb-5 xl:pb-6"></td>
                  </tr>
                  </tbody>
                </table>

                <div class="flex items-center justify-center relative">
                  <span class="absolute -top-4">
                    <m-button
                      :appearance="$const.StyleAppearance.PRIMARY"
                      :shape="$const.StyleShapes.CIRCLE"
                      :size="$const.StyleSize.SMALL"
                      @click="addProduct()"
                    >
                      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="plus"/>
                    </m-button>
                  </span>
                </div>

                <span v-if="buyerProductsFormData.errors.products" class="errors">
                  <m-form-error :errors="buyerProductsFormData.errors.products"/>
                </span>
              </template>

              <template v-else-if="state === States.UPDATING_COSTS">
                <table id="purchase-order-manufacturer-table" class="m-grid-table mt-3 bordered relative">
                  <thead class="table-header">
                  <tr class="table-row">
                    <td class="table-col p-2 md:p-3 xl:p-4">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.name') }}</strong>
                    </td>
                    <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.size') }}</strong>
                    </td>
                    <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.quantity') }}</strong>
                    </td>
                    <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.unitPrice') }}</strong>
                    </td>
                    <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                      <strong>{{ $trans.t('app.purchaseOrder.modal.products.totalPrice') }}</strong>
                    </td>
                  </tr>
                  </thead>

                  <tbody class="table-body">
                  <tr v-for="(product, index) in sellerCostsFormData.model.products" class="table-row">
                    <td class="table-col p-2 pr-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center text-tiny md:text-base">{{ product.productName }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center text-tiny md:text-base">{{ product.size }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center text-tiny md:text-base">{{ product.quantity }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center justify-center">
                          <m-form-field-number
                            v-model="sellerCostsFormData.model.products[index].unitPrice"
                            :errors="sellerCostsFormData.errors.products?._[index]?.unitPrice"
                            :size="$const.StyleSize.XSMALL"
                            :step="0.01"
                            type="number"
                          >
                            <template #prepend>
                              <div class="prepend">
                                <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="euro-sign"/>
                              </div>
                            </template>
                          </m-form-field-number>
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-end">{{ $trans.n(productSubtotal(product), 'eur') }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>

            <div class="m-input-group">
              <h2><label for="remarks">{{ $trans.t('app.order.remarks.title') }}</label></h2>
              <span v-if="state === States.CREATING" class="subtitle">{{ $trans.t('app.order.remarks.subtitle') }}</span>

              <div>
                <m-form-field-text-area
                  v-if="state === States.CREATING"
                  id="remarks"
                  v-model="buyerProductsFormData.model.remark"
                  :errors="buyerProductsFormData.errors.remark"
                  :placeholder="$trans.t('app.bidModal.message.placeholder')"
                  class="mt-3"
                  name="remarks"
                />
                <span v-else-if="state === States.UPDATING_COSTS">
                  <m-text-clamp v-if="purchaseOrder.buyerRemark" :max-lines="5">{{ purchaseOrder.buyerRemark }}</m-text-clamp>
                  <span v-else class="text-neutral-80 italic">{{ $trans.t('app.order.remarks.empty') }}</span>
                </span>
              </div>
            </div>

            <div class="m-input-group">
              <h2><label for="attachments">{{ $trans.t('app.order.attachments.title') }}</label></h2>

              <template v-if="state === States.CREATING">
                <span class="subtitle">{{ $trans.t('app.order.attachments.subtitle') }}</span>

                <div class="mt-3">
                  <m-form-file-upload
                    id="attachments"
                    v-model="buyerProductsFormData.model.attachments"
                    :accept="$const.FileMimes.FILES"
                    :errors="buyerProductsFormData.errors.attachments"
                    :multiple="true"
                  />
                </div>
              </template>
              <span v-else>
                <template v-if="purchaseOrder.attachments.length > 0">
                  <m-link v-for="attachment in purchaseOrder.attachments" :to="'download::' + attachment.originalUrl">
                    <div>
                      <span>{{ $trans.d(attachment.createdAt, 'date') }}:</span> <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="file"/>
                      <strong>{{ attachment.fileName }}</strong> <i>{{ attachment.fileSize }}</i>
                    </div>
                  </m-link>
                </template>
                <span v-else class="text-neutral-80 italic">{{ $trans.t('app.order.attachments.empty') }}</span>
              </span>
            </div>
          </template>
          <!-- END PRODUCT PAGE -->

          <!-- START DELIVERY PAGE -->
          <template v-if="page === Pages.DELIVERY">

            <!-- START SELECT LOCATION -->
            <div class="m-input-group">
              <template v-if="state === States.CREATING">
                <h6 class="text-base">{{ $trans.t('app.order.deliveryAddress.title') }}<span class="text-red-400">*</span></h6>
                <p class="subtitle">{{ $trans.t('app.order.deliveryAddress.subtitle') }}</p>

                <m-form-location-picker
                  v-model="buyerDeliveryFormData.model.locationUuid"
                  :companyUuid="$store.user.currentCompanyUuid"
                  :errors="buyerDeliveryFormData.errors.locationUuid"
                />

                <m-form-field-checkbox
                  v-model="buyerDeliveryFormData.model._hasSameBillingAddress"
                  class="mt-3"
                >
                  {{ $trans.t('app.order.billingAddress.hasSameBillingAddress') }}
                </m-form-field-checkbox>

                <template v-if="!buyerDeliveryFormData.model._hasSameBillingAddress">
                  <h6 class="text-base mt-3">{{ $trans.t('app.order.billingAddress.title') }}<span class="text-red-400">*</span></h6>
                  <p class="subtitle">{{ $trans.t('app.order.billingAddress.subtitle') }}</p>

                  <m-form-location-picker
                    v-model="buyerDeliveryFormData.model.billingLocationUuid"
                    :companyUuid="$store.user.currentCompanyUuid"
                    :errors="buyerDeliveryFormData.errors.billingLocationUuid"
                  />
                </template>
              </template>

              <template v-else-if="state === States.UPDATING_COSTS">
                <h6 class="text-base">{{ $trans.t('app.order.shippingFromAddress.title') }}<span class="text-red-400">*</span></h6>
                <p class="subtitle">{{ $trans.t('app.order.shippingFromAddress.subtitle') }}</p>

                <m-form-location-picker
                  v-model="sellerDeliveryFormData.model.locationUuid"
                  :companyUuid="$store.user.currentCompanyUuid"
                  :errors="sellerDeliveryFormData.errors.locationUuid"
                />

                <m-form-field-checkbox
                  v-model="sellerDeliveryFormData.model._hasSameBillingAddress"
                  class="mt-3"
                >
                  {{ $trans.t('app.order.billingAddress.hasSameBillingAddress') }}
                </m-form-field-checkbox>

                <template v-if="!sellerDeliveryFormData.model._hasSameBillingAddress">
                  <h6 class="text-base mt-3">{{ $trans.t('app.order.billingAddress.title') }}<span class="text-red-400">*</span></h6>
                  <p class="subtitle">{{ $trans.t('app.order.billingAddress.subtitle') }}</p>

                  <m-form-location-picker
                    v-model="sellerDeliveryFormData.model.billingLocationUuid"
                    :companyUuid="$store.user.currentCompanyUuid"
                    :errors="sellerDeliveryFormData.errors.billingLocationUuid"
                  />
                </template>
              </template>
            </div>
            <!-- END SELECT LOCATION -->

            <template v-if="state === States.CREATING">

              <div class="m-input-group">
                <h6>{{ $trans.t('app.order.buyerCourier.title') }}<span class="text-red-400">*</span></h6>

                <div class="flex flex-col mt-2 space-y-3 md:flex-row md:space-x-6  md:space-y-0">
                  <m-form-field-radio-button v-model="buyerDeliveryFormData.model.useOwnCourier" :val="false" class="flex-1 w-max max-w-full" group="useOwnCourier">
                    {{ $trans.t('app.order.buyerCourier.no') }}
                  </m-form-field-radio-button>
                  <m-form-field-radio-button v-model="buyerDeliveryFormData.model.useOwnCourier" :val="true" class="flex-1 w-max max-w-full" group="useOwnCourier">
                    {{ $trans.t('app.order.buyerCourier.yes') }}
                  </m-form-field-radio-button>
                </div>
                <m-form-error :errors="buyerDeliveryFormData.errors.useOwnCourier"/>

                <div v-if="buyerDeliveryFormData.model.useOwnCourier" class="space-y-4 mt-3">
                  <div class="m-input-group">
                    <label class="label">{{ $trans.t('app.form.courierName.label') }}<span class="text-red-400">*</span></label>
                    <m-form-field
                      v-model="buyerDeliveryFormData.model.buyerCourierName"
                      :errors="buyerDeliveryFormData.errors.buyerCourierName"
                      :placeholder="$trans.t('app.form.courierName.placeholder')"
                      type="text"
                    />
                  </div>

                  <div class="m-input-group">
                    <label class="label">{{ $trans.t('app.order.buyerCourier.number') }}<span class="text-red-400">*</span></label>
                    <m-form-field
                      v-model="buyerDeliveryFormData.model.buyerCourierNumber"
                      :errors="buyerDeliveryFormData.errors.buyerCourierNumber"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="m-gutter-wrapper vertical flex flex-col md:flex-row md:horizontal">
                <div class="m-input-group flex-1">
                  <h6>{{ $trans.t('app.order.preferredDeliveryDate.title') }}<span class="text-red-400">*</span></h6>
                  <m-form-field
                    v-model="buyerDeliveryFormData.model.preferredDeliveryDate"
                    :errors="buyerDeliveryFormData.errors.preferredDeliveryDate"
                    :min="Date.tomorrow()"
                    class="mt-2"
                    type="date"
                  >
                    <template #prepend>
                      <div class="prepend">
                        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="calendar"/>
                      </div>
                    </template>
                  </m-form-field>
                  <small class="support-text">{{ $trans.t('app.order.preferredDeliveryDate.subtitle') }}</small>
                </div>
              </div>
            </template>

            <template v-else-if="state === States.UPDATING_COSTS">

              <div class="m-input-group">
                <h6>{{ $trans.t('app.order.estimatedShippingDate.title') }}<span class="text-red-400">*</span></h6>
                <m-form-field
                  v-model="sellerDeliveryFormData.model.estimatedShippingDate"
                  :errors="sellerDeliveryFormData.errors.estimatedShippingDate"
                  :min="Date.tomorrow()"
                  class="mt-3"
                  type="date"
                />
                <small class="support-text">{{ $trans.t('app.order.estimatedShippingDate.subtitle') }}</small>
              </div>

              <div v-if="!flags.alreadyAgreedToOppTerms" class="m-input-group">
                <h6>{{ $trans.t('app.order.companyLegalName.title') }}<span class="text-red-400">*</span></h6>
                <m-form-field
                  v-model="sellerDeliveryFormData.model.companyLegalName"
                  :errors="sellerDeliveryFormData.errors.companyLegalName"
                  class="mt-3"
                  type="text"
                />
                <small class="support-text">{{ $trans.t('app.order.companyLegalName.subtitle') }}</small>
              </div>

              <div v-if="!flags.alreadyAgreedToOppTerms" class="m-input-group">
                <h6>{{ $trans.t('app.order.cocNumber.title') }}<span class="text-red-400">*</span></h6>
                <m-form-field
                  v-model="sellerDeliveryFormData.model.cocNumber"
                  :errors="sellerDeliveryFormData.errors.cocNumber"
                  class="mt-3"
                  type="text"
                />
                <small class="support-text">{{ $trans.t('app.order.cocNumber.subtitle') }}</small>
              </div>

              <div v-if="!flags.alreadyAgreedToOppTerms" class="m-input-group">
                <m-form-field-checkbox
                  v-model="sellerDeliveryFormData.model.agreeOppTerms"
                  :errors="sellerDeliveryFormData.errors.agreeOppTerms"
                >
                  <span v-html="$trans.t('app.order.agreeOppTerms', { varUrlTerms: $settings.urlOppTerms, varNameOpp: $settings.nameOpp })"></span>
                  <span class="text-red-400">*</span>
                </m-form-field-checkbox>
              </div>

              <div class="m-input-group">
                <h5>{{ $trans.t('app.order.deliveryDetails.title') }}</h5>

                <ul class="m-gutter-wrapper vertical flex-col mt-3">
                  <li class="m-input-group p-4 rounded-xl border bg-neutral-0">
                    <m-media
                      v-if="purchaseOrder.buyerLocation?.name"
                      :caption="$trans.t('app.order.deliveryAddress.title')"
                      :icon="{ name: 'map-pin', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createAddressString(purchaseOrder.buyerLocation)"
                    />
                  </li>
                  <li v-if="purchaseOrder.buyerCourierName" class="m-input-group p-4 rounded-xl border bg-neutral-0">
                    <m-media
                      :caption="$trans.t('app.order.buyerCourier.title')"
                      :icon="{ name: 'truck', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="purchaseOrder.buyerCourierName + '<br>' + '#' + purchaseOrder.buyerCourierNumber"
                    />
                  </li>
                  <li class="m-input-group p-4 rounded-xl border bg-neutral-0">
                    <m-media
                      :caption="$trans.t('app.order.preferredDeliveryDate.title')"
                      :icon="{ name: 'calendar-check', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="$trans.d(purchaseOrder.preferredDeliveryDate, 'date')"
                    />
                  </li>
                </ul>
              </div>

              <div class="m-input-group">
                <h5>{{ $trans.t('app.order.paymentDetails.title') }}</h5>

                <ul class="m-gutter-wrapper vertical flex-col mt-3">
                  <li class="m-input-group p-4 rounded-xl border bg-neutral-0">
                    <m-media
                      :caption="$trans.t('app.order.billingAddress.title')"
                      :icon="{ name: 'file-invoice', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createAddressString(purchaseOrder?.buyerBillingLocation, $trans.t('app.labels.sameAddressAsDelivery'))"
                    />
                  </li>
                </ul>
              </div>
            </template>

          </template>
          <!-- END DELIVERY PAGE -->

          <!-- START READ & OVERVIEW PAGE -->
          <template v-if="(page === Pages.READ && purchaseOrder) || page === Pages.OVERVIEW">
            <template v-if="page === Pages.READ && !$store.user.isBuyer">
              <template v-if="purchaseOrder.sellerKycStatus === 'inactive' || purchaseOrder.sellerKycStatus === 'incomplete'">
                <m-section-message
                  :appearance="$const.StyleAppearance.DANGER"
                  :buttonA="$trans.t('app.purchaseOrder.modal.sellerKycStatus.inactive.btn')"
                  :buttonATo="purchaseOrder.sellerKycUrl"
                  :message="$trans.t('app.purchaseOrder.modal.sellerKycStatus.inactive.msg')"
                  :title="$trans.t('app.purchaseOrder.modal.sellerKycStatus.inactive.title')"
                />
              </template>
              <template v-else-if="purchaseOrder.sellerKycStatus === 'unverified'">
                <m-section-message
                  :appearance="$const.StyleAppearance.PRIMARY"
                  :buttonA="$trans.t('app.purchaseOrder.modal.sellerKycStatus.pending.btn')"
                  :buttonATo="purchaseOrder.sellerKycUrl"
                  :message="$trans.t('app.purchaseOrder.modal.sellerKycStatus.pending.msg')"
                  :title="$trans.t('app.purchaseOrder.modal.sellerKycStatus.pending.title')"
                />
              </template>
            </template>

            <div class="m-input-group">
              <h2>{{ $trans.t('app.menus.products') }}</h2>

              <table id="purchase-order-final-table" class="m-grid-table mt-3 bordered relative">
                <thead class="table-header">
                <tr class="table-row">
                  <td class="table-col p-2 md:p-3 xl:p-4">
                    <strong>{{ $trans.t('app.purchaseOrder.modal.products.name') }}</strong>
                  </td>
                  <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                    <strong>{{ $trans.t('app.purchaseOrder.modal.products.size') }}</strong>
                  </td>
                  <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                    <strong>{{ $trans.t('app.purchaseOrder.modal.products.quantity') }}</strong>
                  </td>
                  <td class="table-col p-2 md:p-3 xl:p-4 text-center">
                    <strong>{{ $trans.t('app.purchaseOrder.modal.products.unitPrice') }}</strong>
                  </td>
                  <td class="table-col p-2 md:p-3 xl:p-4 text-right">
                    <strong>{{ $trans.t('app.purchaseOrder.modal.products.totalPrice') }}</strong>
                  </td>
                </tr>
                </thead>

                <tbody class="table-body">
                <template v-if="state === States.CREATING">
                  <tr v-for="(product) in buyerProductsFormData?.model.products" class="table-row">
                    <td class="table-col p-2 pr-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center text-tiny md:text-base">
                          {{ getProductName(product.productUuid) }}
                          <span></span>
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center text-tiny md:text-base">{{ product.size }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center justify-center text-tiny md:text-base">
                          <span>{{ product.quantity }}</span>
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center font-medium text-yellow-300 text-tiny md:text-base">{{ $trans.t('app.labels.tbd') }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center font-medium text-yellow-300 text-tiny md:text-base">{{ $trans.t('app.labels.tbd') }}</span>
                    </td>
                  </tr>
                </template>

                <template v-else-if="state === States.UPDATING_COSTS">
                  <tr v-for="(product, index) in sellerCostsFormData.model.products" class="table-row">
                    <td class="table-col p-2 pr-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center text-tiny md:text-base">
                          <span>{{ product.productName }}</span>
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center text-tiny md:text-base">{{ product.size }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center justify-center text-tiny md:text-base">
                          <span>{{ product.quantity }}</span>
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center text-tiny md:text-base">{{ $trans.n(product.unitPrice, 'eur') }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-end text-tiny font-medium md:text-base">{{ $trans.n(productSubtotal(product), 'eur') }}</span>
                    </td>
                  </tr>
                </template>

                <template v-else-if="state === States.READ">
                  <tr v-for="product in purchaseOrder.products" class="table-row" v-bind="product">
                    <td class="table-col p-2 pr-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center text-tiny md:text-base">
                          {{ product.productName }}
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span class="h-full flex items-center justify-center text-tiny md:text-base">{{ product.size ?? $trans.t('app.labels.tbd') }}</span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center justify-center text-tiny md:text-base">
                          <span>{{ product.quantity ?? $trans.t('app.labels.tbd') }}</span>
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                        <span class="h-full flex items-center justify-center">
                          <span v-if="product.unitPrice">{{ $trans.n(product.unitPrice, 'eur') }}</span>
                          <span v-else class="text-yellow-300">{{ $trans.t('app.labels.tbd') }}</span>
                        </span>
                    </td>
                    <td class="table-col py-2 px-1 md:p-3 xl:p-4">
                      <span v-if="product.totalPrice" class="h-full flex items-center justify-end">{{ $trans.n(product.totalPrice, 'eur') }}</span>
                      <span v-else class="h-full flex items-center justify-center text-yellow-300">{{ $trans.t('app.labels.tbd') }}</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>

            <div class="m-input-group">
              <h5 class="mb-1.5">{{ $trans.t('app.order.remarks.title') }}</h5>
              <span v-if="state === States.CREATING">
                <m-text-clamp v-if="buyerProductsFormData.model.remark" :max-lines="5">{{ buyerProductsFormData.model.remark }}</m-text-clamp>
                <span v-else class="text-neutral-80 italic">{{ $trans.t('app.order.remarks.empty') }}</span>
              </span>
              <span v-else class="text-neutral-80 italic">
                <m-text-clamp v-if="purchaseOrder.buyerRemark" :max-lines="5">{{ purchaseOrder.buyerRemark }}</m-text-clamp>
                <span v-else class="text-neutral-80 italic">{{ $trans.t('app.order.remarks.empty') }}</span>
              </span>
            </div>

            <div class="m-input-group">
              <h5 class="mb-1.5">{{ $trans.t('app.order.attachments.title') }}</h5>

              <span v-if="state === States.CREATING">
                <template v-if="buyerProductsFormData.model.attachments.length > 0">
                  <m-link v-for="attachment in buyerProductsFormData.model.attachments" :newWindow="true" :to="attachment.originalUrl">
                    <div>
                      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="file"/> <strong>{{ attachment.fileName }}</strong> <i>{{ attachment.fileSize }}</i>
                    </div>
                  </m-link>
                </template>
                <span v-else class="text-neutral-80 italic">{{ $trans.t('app.order.attachments.empty') }}</span>
              </span>
              <span v-else>
                <template v-if="purchaseOrder.attachments.length > 0">
                  <m-link v-for="attachment in purchaseOrder.attachments" :to="'download::' + attachment.originalUrl">
                    <div>
                      <span>{{ $trans.d(attachment.createdAt, 'date') }}:</span> <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="file"/>
                      <strong>{{ attachment.fileName }}</strong> <i>{{ attachment.fileSize }}</i>
                    </div>
                  </m-link>
                </template>
                <span v-else class="text-neutral-80 italic">{{ $trans.t('app.order.attachments.empty') }}</span>
              </span>
            </div>

            <m-button
              v-if="purchaseOrder && page === Pages.READ"
              :appearance="$const.StyleAppearance.NEUTRAL"
              :icon-before="{ name: 'circle-plus', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
              :size="$const.StyleSize.SMALL"
              :variant="$const.StyleVariants.OUTLINE"
              @click="showAttachmentsPage()"
            >
              {{ $trans.t('pages.chat.uploadFiles') }}
            </m-button>

            <div v-if="page === Pages.READ && purchaseOrder.shipmentSentAt != null" class="m-input-group">
              <h5>{{ $trans.t('app.order.shipmentDetails.title') }}</h5>

              <ul class="mt-4">
                <li>
                  <m-media
                    :caption="$trans.t('app.order.shipmentDetails.trackAndTrace')"
                    :icon="{ name: 'box', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                    :inverted="true"
                    :value="purchaseOrder.shipmentTrackAndTrace + ' (' + purchaseOrder.shipmentCourierName + ')<br>' + $trans.t('app.order.shipmentDetails.sentOn') + ': ' + $trans.d(purchaseOrder.shipmentSentAt, 'date') "
                  />
                </li>
              </ul>
            </div>

            <div class="m-input-group">
              <h5>{{ $trans.t('app.order.deliveryDetails.title') }}</h5>

              <ul class="mt-4 mb-2">
                <template v-if="state === States.CREATING">
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.shippingFromAddress.title')"
                      :icon="{ name: 'building', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="'<span class=\'font-medium text-yellow-300\'>' + $trans.t('app.labels.toBeDetermined') + '</span>'"
                      class="mb-6"
                    />
                  </li>

                  <li v-if="buyerDeliveryFormData.model.buyerCourierName">
                    <m-media
                      :caption="$trans.t('app.order.buyerCourier.title')"
                      :icon="{ name: 'truck', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="buyerDeliveryFormData.model.buyerCourierName + '<br>' + '#' + buyerDeliveryFormData.model.buyerCourierNumber"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.estimatedShippingDate.title')"
                      :icon="{ name: 'plane-departure', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="'<span class=\'font-medium text-yellow-300\'>' + $trans.t('app.labels.toBeDetermined') + '</span>'"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.preferredDeliveryDate.title')"
                      :icon="{ name: 'calendar-check', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="$trans.d(buyerDeliveryFormData.model.preferredDeliveryDate, 'date')"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.deliveryAddress.title')"
                      :icon="{ name: 'map-pin', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="selectedLocationStr"
                    />
                  </li>
                </template>

                <template v-else-if="state === States.UPDATING_COSTS || (state === States.READ && $store.user.isSeller)">
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.shippingFromAddress.title')"
                      :icon="{ name: 'building', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="(state === States.UPDATING_COSTS) ? selectedLocationStr : createAddressString(purchaseOrder.sellerLocation)"
                      class="mb-6"
                    />
                  </li>
                  <li v-if="purchaseOrder.buyerCourierName">
                    <m-media
                      :caption="$trans.t('app.order.buyerCourier.title')"
                      :icon="{ name: 'truck', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="purchaseOrder.buyerCourierName + '<br>' + '#' + purchaseOrder.buyerCourierNumber"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.estimatedShippingDate.title')"
                      :icon="{ name: 'plane-departure', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createDateString( (state === States.UPDATING_COSTS) ? sellerDeliveryFormData.model.estimatedShippingDate : purchaseOrder.estimatedShippingDate )"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.preferredDeliveryDate.title')"
                      :icon="{ name: 'calendar-check', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="$trans.d(purchaseOrder.preferredDeliveryDate, 'date')"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.deliveryAddress.title')"
                      :icon="{ name: 'map-pin', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createAddressString(purchaseOrder.buyerLocation)"
                    />
                  </li>
                </template>

                <template v-else>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.shippingFromAddress.title')"
                      :icon="{ name: 'building', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createAddressString(purchaseOrder.sellerLocation)"
                      class="mb-6"
                    />
                  </li>
                  <li v-if="purchaseOrder.buyerCourierName">
                    <m-media
                      :caption="$trans.t('app.order.buyerCourier.title')"
                      :icon="{ name: 'truck', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="purchaseOrder.buyerCourierName + '<br>' + '#' + purchaseOrder.buyerCourierNumber"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.estimatedShippingDate.title')"
                      :icon="{ name: 'plane-departure', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createDateString(purchaseOrder.estimatedShippingDate)"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.preferredDeliveryDate.title')"
                      :icon="{ name: 'calendar-check', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createDateString(purchaseOrder.preferredDeliveryDate)"
                      class="mb-6"
                    />
                  </li>
                  <li>
                    <m-media
                      :caption="$trans.t('app.order.deliveryAddress.title')"
                      :icon="{ name: 'map-pin', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                      :inverted="true"
                      :value="createAddressString(purchaseOrder.buyerLocation)"
                    />
                  </li>
                </template>
              </ul>
            </div>

            <div class="m-input-group">
              <h5>{{ $trans.t('app.order.paymentDetails.title') }}</h5>

              <ul class="m-gutter-wrapper vertical flex-col mt-4">
                <li v-if="sellerDeliveryFormData?.model?.cocNumber" class="m-input-group p-4 rounded-xl border bg-neutral-0">
                  <m-media
                    :caption="$trans.t('app.order.cocNumber.title')"
                    :icon="{ name: 'building', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                    :inverted="true"
                    :value="sellerDeliveryFormData.model.cocNumber"
                  />
                </li>
                <li class="m-input-group p-4 rounded-xl border bg-neutral-0">
                  <m-media
                    :caption="$trans.t('app.order.billingAddress.title')"
                    :icon="{ name: 'file-invoice', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
                    :inverted="true"
                    :value="(state === States.CREATING || state === States.UPDATING_COSTS) ? selectedBillingLocationStr : createAddressString(purchaseOrder.buyerBillingLocation, $trans.t('app.labels.sameAddressAsDelivery'))"
                  />
                </li>
              </ul>
            </div>
          </template>
          <!-- END READ & OVERVIEW PAGE-->

          <!-- START PAYMENT PAGE-->
          <template v-if="page === Pages.PAYMENT && purchaseOrder">
            <div class="flex flex-col">
              <div class="m-input-group">
                <label class="label">
                  {{ $trans.t('app.order.paymentSubtitle') }}
                </label>

                <div class="flex flex-col space-y-3">
                  <m-form-field-radio-button v-model="buyerPaymentFormData.model.paymentMethod" :val="PurchaseOrderPaymentTypes.BANK_TRANSFER">
                    <div class="payment-provider-radio-label">
                      <div class="label-container">
                        <div class="icon-container">
                          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon text-green-400" name="money-bill-transfer"/>
                        </div>
                        <span class="ml-1">Bank transfer</span>
                      </div>
                    </div>
                  </m-form-field-radio-button>

                  <m-form-field-radio-button v-model="buyerPaymentFormData.model.paymentMethod" :disabled="!sellerKycVerified" :val="PurchaseOrderPaymentTypes.IDEAL">
                    <div class="payment-provider-radio-label">
                      <div class="label-container">
                        <div class="icon-container">
                          <img :class="'icon ' + (!sellerKycVerified ? 'grayscale' : '')" src="@/assets/website/payment_logos/ideal-logo.svg"/>
                        </div>
                        <span>iDEAL ({{ $trans.n(fixedFees[PurchaseOrderPaymentTypes.IDEAL], 'eur') }})</span>
                      </div>
                      <span v-if="!sellerKycVerified" class="disclaimer">{{ $trans.t('app.order.paymentOptionStatus.verificationRequired') }}</span>
                    </div>
                  </m-form-field-radio-button>

                  <m-form-field-radio-button v-model="buyerPaymentFormData.model.paymentMethod" :disabled="!sellerKycVerified" :val="PurchaseOrderPaymentTypes.GIROPAY">
                    <div class="payment-provider-radio-label">
                      <div class="label-container">
                        <div class="icon-container">
                          <img :class="'icon ' + (!sellerKycVerified ? 'grayscale' : '')" src="@/assets/website/payment_logos/giropay-logo.svg"/>
                        </div>
                        <span>Giropay ({{ $trans.n(fixedFees[PurchaseOrderPaymentTypes.GIROPAY], 'eur') }} + {{ percentageFees[PurchaseOrderPaymentTypes.GIROPAY] }}%)</span>
                      </div>
                      <span v-if="!sellerKycVerified" class="disclaimer">{{ $trans.t('app.order.paymentOptionStatus.verificationRequired') }}</span>
                    </div>
                  </m-form-field-radio-button>

                  <m-form-field-radio-button v-model="buyerPaymentFormData.model.paymentMethod" :disabled="!sellerKycVerified" :val="PurchaseOrderPaymentTypes.BANCONTACT">
                    <div class="payment-provider-radio-label">
                      <div class="label-container">
                        <div class="icon-container">
                          <img :class="'icon ' + (!sellerKycVerified ? 'grayscale' : '')" src="@/assets/website/payment_logos/bancontact-logo.svg"/>
                        </div>
                        <span>Bancontact ({{ $trans.n(fixedFees[PurchaseOrderPaymentTypes.BANCONTACT], 'eur') }})</span>
                      </div>
                      <span v-if="!sellerKycVerified" class="disclaimer">{{ $trans.t('app.order.paymentOptionStatus.verificationRequired') }}</span>
                    </div>
                  </m-form-field-radio-button>

                  <m-form-field-radio-button v-model="buyerPaymentFormData.model.paymentMethod" :disabled="true" :val="PurchaseOrderPaymentTypes.CREDIT_CARD">
                    <div class="payment-provider-radio-label">
                      <div class="label-container">
                        <div class="icon-container">
                          <img :class="'icon grayscale'" src="@/assets/website/payment_logos/mastercard-logo.svg"/>
                        </div>
                        <span>Credit Card ({{ $trans.n(fixedFees[PurchaseOrderPaymentTypes.CREDIT_CARD], 'eur') }} + {{ percentageFees[PurchaseOrderPaymentTypes.CREDIT_CARD] }}%)</span>
                      </div>
                      <span class="disclaimer">{{ $trans.t('app.order.paymentOptionStatus.comingSoon') }}</span>
                    </div>
                  </m-form-field-radio-button>

                  <m-form-field-radio-button v-model="buyerPaymentFormData.model.paymentMethod" :disabled="true" :val="PurchaseOrderPaymentTypes.PAYPAL">
                    <div class="payment-provider-radio-label">
                      <div class="label-container">
                        <div class="icon-container">
                          <img :class="'icon grayscale'" src="@/assets/website/payment_logos/paypal-logo.svg"/>
                        </div>
                        <span>PayPal ({{ $trans.n(fixedFees[PurchaseOrderPaymentTypes.PAYPAL], 'eur') }} + {{ percentageFees[PurchaseOrderPaymentTypes.PAYPAL] }}%)</span>
                      </div>
                      <span class="disclaimer">{{ $trans.t('app.order.paymentOptionStatus.comingSoon') }}</span>
                    </div>
                  </m-form-field-radio-button>

                  <m-form-field-radio-button v-model="buyerPaymentFormData.model.paymentMethod" :disabled="true" :val="PurchaseOrderPaymentTypes.SOFORT">
                    <div class="payment-provider-radio-label">
                      <div class="label-container">
                        <div class="icon-container">
                          <img :class="'icon grayscale'" src="@/assets/website/payment_logos/sofort-logo.svg"/>
                        </div>
                        <span>Sofort ({{ $trans.n(fixedFees[PurchaseOrderPaymentTypes.SOFORT], 'eur') }} + {{ percentageFees[PurchaseOrderPaymentTypes.SOFORT] }}%)</span>
                      </div>
                      <span class="disclaimer">{{ $trans.t('app.order.paymentOptionStatus.comingSoon') }}</span>
                    </div>
                  </m-form-field-radio-button>

                  <m-form-error :errors="buyerPaymentFormData.errors.paymentMethod"/>
                </div>

                <m-section-message
                  v-if="buyerPaymentFormData.model.paymentMethod === PurchaseOrderPaymentTypes.BANK_TRANSFER"
                  :appearance="$const.StyleAppearance.WARNING"
                  :message="$trans.t('app.notice.orderBankTransferPayment.message')"
                  :title="$trans.t('app.notice.orderBankTransferPayment.title')"
                  class="mt-5"
                />
              </div>
            </div>
          </template>
          <!-- END PAYMENT PAGE-->

        </div>

        <div :style="{ height: mainContentScrollHeight > 0 ? mainContentScrollHeight + 'px' : 'auto' }" class="side-form">
          <div class="z-10 relative sm:sticky sm:top-0">
            <h2 class="text-xl sm:hidden">
              {{ $trans.t('app.order.costs.title') }}
            </h2>

            <ul v-if="state === States.UPDATING_COSTS && page === Pages.PRODUCTS" class="m-gutter-wrapper vertical mb-3">
              <li class="m-input-group">
                <label class="label">{{ $trans.t('app.order.costs.other.title') }}<span class="text-red-400">*</span></label>
                <m-form-field-number
                  v-model="sellerCostsFormData.model.extraProductionCosts"
                  :errors="sellerCostsFormData.errors.extraProductionCosts"
                  :min="0"
                  :step="0.01"
                >
                  <template #prepend>
                    <div class="prepend">
                      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="euro-sign"/>
                    </div>
                  </template>
                </m-form-field-number>
              </li>
              <li class="m-input-group">
                <label class="label">{{ $trans.t('app.order.costs.shipping.title') }}<span class="text-red-400">*</span></label>
                <m-form-field-number
                  v-model="sellerCostsFormData.model.shippingCosts"
                  :errors="sellerCostsFormData.errors.shippingCosts"
                  :min="0"
                  :step="0.01"
                >
                  <template #prepend>
                    <div class="prepend">
                      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="euro-sign"/>
                    </div>
                  </template>
                </m-form-field-number>
              </li>
              <li class="m-input-group">
                <label class="label">{{ $trans.t('app.order.costs.vat.label') }}<span class="text-red-400">*</span></label>
                <m-form-field-number
                  v-model="sellerCostsFormData.model.vatPercentage"
                  :errors="sellerCostsFormData.errors.vatPercentage"
                  :max="100"
                  :min="0"
                  :rtl="true"
                  :step="1"
                >
                  <template #append>
                    <div class="append">
                      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="percent"/>
                    </div>
                  </template>
                </m-form-field-number>
              </li>
              <li>
                <hr class="w-full">
              </li>
            </ul>

            <ul class="costs-list mb-6">
              <li class="costs-row">
                <span>{{ $trans.t('app.order.costs.production.title') }}</span>
                <span class="cost">{{ state === States.CREATING ? $trans.t('app.labels.tbd') : $trans.n(costsProduction, 'eur') }}</span>
              </li>

              <li class="costs-row">
                <span>{{ $trans.t('app.order.costs.other.title') }}</span>
                <span class="cost">{{ state === States.CREATING ? $trans.t('app.labels.tbd') : $trans.n(costsOther, 'eur') }}</span>
              </li>

              <li class="costs-row">
                <span>{{ $trans.t('app.order.costs.shipping.title') }}</span>
                <span class="cost">{{ state === States.CREATING ? $trans.t('app.labels.tbd') : $trans.n(costsShipping, 'eur') }}</span>
              </li>

              <li class="costs-row">
                <hr class="w-full">
              </li>

              <li class="costs-row">
                <span>{{ $trans.t('app.order.costs.subtotal.title') }}</span>
                <span class="cost">{{ state === States.CREATING ? $trans.t('app.labels.tbd') : $trans.n(costsSubtotal, 'eur') }}</span>
              </li>

              <li class="costs-row">
                <span v-if="state === States.UPDATING_COSTS">
                  {{ $trans.t('app.order.costs.vat.title', {percentage: sellerCostsFormData.model.vatPercentage}) }}
                </span>

                <span v-else-if="purchaseOrder?.vatPercentage">
                  {{ $trans.t('app.order.costs.vat.title', {percentage: purchaseOrder?.vatPercentage ?? 0}) }}
                </span>

                <span v-else>
                  {{ $trans.t('app.order.costs.vat.label') }}
                </span>

                <span class="cost">{{ state === States.CREATING ? $trans.t('app.labels.tbd') : $trans.n(costsVAT, 'eur') }}</span>
              </li>

              <li class="costs-row">
                <hr class="w-full">
              </li>

              <li class="costs-row">
                <span class="text-green-400">
                  {{ $trans.t('app.order.costs.carbonCompensation') }}
                  <m-link :newWindow="true" :to="$settings.urlCO2Compensation">
                    <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="self-center ml-1 text-tiny text-teal-300" name="circle-info"/>
                  </m-link>
                </span>
                <span class="cost green">{{ $trans.t('app.labels.free') }}</span>
              </li>

              <li class="costs-row">
                <hr class="w-full">
              </li>

              <template v-if="state !== States.CREATING && $store.user.isBuyer && purchaseOrder?.buyerFeePercentage !== 0 && purchaseOrder">
                <li class="costs-row">
                  <span>
                    {{ $trans.t('app.order.costs.buyerProtectionFee.title', {percentage: purchaseOrder.buyerFeePercentage}) }}
                    <m-link :newWindow="true" :to="$settings.urlBuyerProtection">
                      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="self-center ml-1 text-tiny text-teal-300" name="circle-info"/>
                    </m-link>
                  </span>
                  <span class="cost">{{ $trans.n(purchaseOrder.buyerFeeSubtotalCosts, 'eur') }}</span>
                </li>

                <li class="costs-row">
                  <span>
                    {{ $trans.t('app.order.costs.buyerProtectionFeeVat.title', {percentage: purchaseOrder.buyerFeeVatPercentage}) }}
                    <m-tooltip :label="$trans.t('app.order.costs.buyerProtectionFeeVat.disclaimer')">
                      <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="ml-1 text-tiny text-teal-300 cursor-pointer" name="circle-info"/>
                    </m-tooltip>
                  </span>
                  <span class="cost">{{ $trans.n(purchaseOrder.buyerFeeVatCosts, 'eur') }}</span>
                </li>

                <li class="costs-row">
                  <hr class="w-full">
                </li>
              </template>

              <template v-if="state === States.PAYMENT && costsTransaction">
                <li class="costs-row">
                  <span>Transaction Fee</span>
                  <span class="cost">{{ $trans.n(costsTransaction, 'eur') }}</span>
                </li>

                <li class="costs-row">
                  <hr class="w-full">
                </li>
              </template>

              <li class="costs-row">
                <span :class="[{ 'cost dark': $store.user.isBuyer }]">Total</span>
                <span :class="[{ 'cost dark': $store.user.isBuyer }]">{{ state === States.CREATING ? $trans.t('app.labels.tbd') : $trans.n(costsTotal, 'eur') }}</span>
              </li>

              <template v-if="$store.user.isSeller">
                <template v-if="purchaseOrder?.sellerFeePercentage && purchaseOrder">
                  <li class="costs-row">
                    <hr class="w-full">
                  </li>

                  <li class="costs-row">
                    <span>
                      {{ $trans.t('app.order.costs.sellerFee.title', {percentage: purchaseOrder?.sellerFeePercentage ?? 0}) }}
                      <m-tooltip :label="$trans.t('app.order.costs.sellerFee.disclaimer')">
                        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="ml-1 text-tiny text-teal-300 cursor-pointer" name="circle-info"/>
                      </m-tooltip>
                    </span>
                    <span class="cost">-{{ $trans.n(costsSellerFee, 'eur') }}</span>
                  </li>

                  <li class="costs-row">
                    <span>
                      {{ $trans.t('app.order.costs.sellerFeeVat.title', {percentage: purchaseOrder?.sellerFeeVatPercentage ?? 0}) }}
                    </span>
                    <span class="cost">-{{ $trans.n(costsSellerFeeVAT, 'eur') }}</span>
                  </li>
                </template>

                <li class="costs-row">
                  <hr class="w-full">
                </li>

                <li class="costs-row">
                  <span class="cost dark">{{ $trans.t('app.order.costs.sellerTotal.title') }}</span>
                  <span class="cost dark">{{ $trans.n(costsSellerTotal, 'eur') }}</span>
                </li>
              </template>
            </ul>

            <m-section-message
              v-if="state === States.CREATING"
              :appearance="$const.StyleAppearance.PRIMARY"
              :message="$trans.t('app.purchaseOrder.modal.buyerCreating.msg')"
              :title="$trans.t('app.purchaseOrder.modal.buyerCreating.title')"
            />
          </div>
        </div>
      </div>

      <div :class="'dialog-footer flex flex-row w-full ' + (page === Pages.PRODUCTS || page === Pages.DELIVERY || page === Pages.OVERVIEW  || (statusIndicatorShow && page === Pages.READ) ? 'justify-between' : 'justify-end')">
        <div
          v-if="statusIndicatorShow && page === Pages.READ"
          :class="['status-indicator small current ', purchaseOrder.status ]"
        >
          <m-icon
            :name="statusIndicatorIcon"
            :solid="true"
            :type="$const.IconTypes.FONT_AWESOME"
            class="icon"
          />
          <strong class="label">{{ $trans.t(`app.purchaseOrder.status.${purchaseOrder.status}`) }}</strong>
        </div>

        <ul
          v-if="page === Pages.PRODUCTS || page === Pages.DELIVERY || page === Pages.OVERVIEW"
          class="chain-horizontal"
          start="1"
          type="number"
        >
          <li :class="['chain-item', { active: (page === Pages.PRODUCTS || page === Pages.DELIVERY || page === Pages.OVERVIEW) }]">
            <div
              :class="['page-indicator status-indicator small secondary', { current: page === Pages.PRODUCTS, passed: (page === Pages.DELIVERY || page === Pages.OVERVIEW) }]">
              <span class="circle">1</span>
              <transition mode="out-in" name="slide-from-right-fade">
                <strong v-if="page === Pages.PRODUCTS" class="label">{{ $trans.t('app.purchaseOrder.modal.pages.productDetails') }}</strong>
              </transition>
            </div>
          </li>
          <li :class="['chain-item', { active: (page === Pages.DELIVERY || page === Pages.OVERVIEW) }]">
            <div
              :class="['page-indicator status-indicator small secondary', { current: page === Pages.DELIVERY, passed: (page === Pages.DELIVERY || page === Pages.OVERVIEW) }]">
              <span class="circle">2</span>
              <transition mode="out-in" name="slide-from-right-fade">
                <strong v-if="page === Pages.DELIVERY" class="label">{{ $trans.t('app.purchaseOrder.modal.pages.deliveryAndPayment') }}</strong>
              </transition>
            </div>
          </li>
          <li :class="['chain-item', { active: (page === Pages.OVERVIEW) }]">
            <div :class="['page-indicator status-indicator small secondary', { current: page === Pages.OVERVIEW }]">
              <span class="circle">3</span>
              <transition mode="out-in" name="slide-from-right-fade">
                <strong v-if="page === Pages.OVERVIEW" class="label">{{ $trans.t('app.purchaseOrder.modal.pages.summary') }}</strong>
              </transition>
            </div>
          </li>
        </ul>

        <div class="flex flex-row justify-between space-x-2">
          <div class="w-full flex flex-row space-x-2 justify-end">
            <m-button
              v-if="buttonBShow"
              :appearance="$const.StyleAppearance.NEUTRAL"
              :size="$const.StyleSize.SMALL"
              :variant="$const.StyleVariants.OUTLINE"
              @click="buttonBClick()"
            >
              {{ buttonBLabel }}
            </m-button>

            <m-button
              v-if="buttonAShow"
              :appearance="$const.StyleAppearance.PRIMARY"
              :size="$const.StyleSize.SMALL"
              @click="buttonAClick()"
            >
              {{ buttonALabel }}
            </m-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./PurchaseOrderModal.scss"></style>
