import {Component, Prop, Vue} from 'vue-facing-decorator';
import {Store} from "@/app/stores/Store";
import {DeviceTypes} from "@/app/constants/DeviceTypes";

@Component({})
export default class Tooltip extends Vue {

    @Prop({type: String, default: null})
    public readonly label: string;

    @Prop({type: Boolean, default: false})
    public readonly disableOnMobile: boolean;

    private tooltipCount: number = 0;

    public get isMobile(): boolean {
        return Store.app.device === DeviceTypes.MOBILE;
    }

    public get shouldDisableOnMobile(): boolean {
        return this.disableOnMobile && this.isMobile;
    }

    public onMobileTooltipShow(): void {
        if (this.tooltipCount === 0) {
            document.body.classList.add('overflow-hidden');
        }

        this.tooltipCount += 1;
    }

    public onMobileTooltipHide(): void {
        this.tooltipCount -= 1;
        if (this.tooltipCount === 0) {
            document.body.classList.remove('overflow-hidden');
        }
    }
}
