<script lang="ts" src="./FormSelectCountryCodePicker.ts"></script>


<template>
  <div
    v-click-outside="close"
    :class="[
      'form-field-group',
      size,
      {
        'has-error': (errors?.length > 0),
        'has-data': (value?.length > 0),
        disabled: disabled,
        opened: flags.opened,
      },
    ]"
  >
    <!-- Input field -->
    <div ref="formField" class="form-field" @click="handleFieldClick($event)">
      <div class="prepend input" @click="handleChevronClick($event)">
        <div
          v-if="selectedItems?.length > 0"
          class="max-w-none w-7 h-4 inline-flex items-center justify-center mr-2"
        >
          <img :src="selectedFlagSrc" alt="" class="max-w-full max-h-full rounded-sm shadow"/>
        </div>
        <m-icon
          v-else
          :size="$const.StyleSize.LARGE"
          :solid="true"
          :type="$const.IconTypes.HERO"
          class="mr-1"
          name="globe-europe-africa"
        />
      </div>


      <input
        :id="id"
        :placeholder="placeholder ?? $trans.t('components.selectPicker.placeholder')"
        :value="selectedLabel"
        class="input hidden"
        type="text"
      />

      <div class="append">
        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="chevron-down" @click="handleChevronClick($event)"/>
      </div>
    </div>

    <!-- Dropdown -->
    <div ref="dropdown" :class="['dropdown', direction]">
      <ol class="max-h-44 flex flex-col pb-2 overflow-auto md:max-h-48 md:pb-1.5 md:space-y-1.5">

        <li class="flex items-center px-3 py-2 col-span-full sticky top-0 text-tiny leading-tight bg-neutral-0">
          <span>{{ $trans.t('app.labels.searchingFor') }}:&nbsp;</span>
          <input
            :id="id"
            ref="filterInput"
            v-model="filterStr"
            :autocomplete="AutocompleteStandard.PHONE_COUNTRY_CODE"
            :disabled="disabled"
            class="h-6 flex-1 px-1.5 py-0 ml-1 border rounded-lg text-tiny focus:border-neutral-400"
            type="search"
          />
        </li>

        <!-- List items: -->
        <li
          v-for="item in visibleItems"
          :key="item.id"
          :class="{ 'col-span-full': item.id === undefined }"
        >
          <div v-if="item.id === undefined" class="item -mb-2.5 border-t">
            <span class="m-caption">{{ item.label }}</span>
          </div>
          <div
            v-else
            :class="[
              'item interactive',
              {
                checked: checkItemSelected(item),
              }
            ]"
            :dusk="item.id"
            @click="clickItem(item)"
          >
            <input
              :checked="checkItemSelected(item)"
              :disabled="disabled"
              class="hidden"
              type="radio"
            />

            <div class="max-w-none w-7 h-4 inline-flex items-center justify-center">
              <img :src="`${$settings.appPath}/images/country-flags/${item.countryCode.toLowerCase()}.svg`" alt="" class="max-w-full max-h-full rounded-sm shadow"/>
            </div>

            <span class="flex-1 ml-2">{{ item.label }}</span>
            <span>{{ item.id }}</span>
          </div>
        </li>

        <!-- More/less buttons: -->
        <li v-if="visibleItems.length == 0" class="col-span-full">
          <div class="item">
            {{ $trans.t('components.selectPicker.noResults') }}
          </div>
        </li>
      </ol>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>

  </div>
</template>


<!-- TODO: @Krasi - we have a lot of repeating calls to such files -> minimize this -->
<style lang="scss" scoped src="./FormFieldRadio.scss"></style>
<style lang="scss" scoped>

.form-field-group {
  @apply static;
}

.dropdown {
  @apply flex flex-col absolute top-12 left-0 right-0 z-20 bg-neutral-0 border rounded-xl opacity-0 shadow-xl invisible transition-all overflow-hidden sm:max-w-md;

  .item {
    @apply h-10 flex grow items-center px-3 py-2 leading-tight text-tiny;

    &.interactive {
      @apply cursor-pointer hover:bg-smoke-0 transition-colors ease-in-out;
    }

    &.checked {
      @apply bg-neutral-10;
    }
  }

  &.top {
    @apply bottom-12 top-auto;
  }
}

.form-field {
  @apply cursor-pointer;

  .input {
    @apply bg-transparent active:bg-transparent focus:bg-transparent;
  }

  .append .icon {
    @apply text-neutral-60 transform transition-transform;
  }
}

.opened {
  .append .icon {
    @apply rotate-180;
  }

  .dropdown {
    @apply opacity-100 visible;
  }
}

.form-field-group.x-small .dropdown {
  @apply top-10 rounded-lg;
}

.form-field-group.small .dropdown {
  @apply top-12;
}

</style>
