import {Component, Prop, Vue} from 'vue-facing-decorator';
import algoliasearch from 'algoliasearch/lite'
import {PublicProductDTO} from "@/app/dto/PublicProductDTO";
import {TranslationDTO} from "@/app/dto/TranslationDTO";
import {FileDTO} from "@/app/dto/FileDTO";
import {SearchClient} from "algoliasearch/dist/algoliasearch-lite";
import {createInsightsMiddleware} from 'instantsearch.js/es/middlewares';
import {Store} from "@/app/stores/Store";
import {PublicSellerShortDTO} from "@/app/dto/PublicSellerShortDTO";
import {LocationDTO} from "@/app/dto/LocationDTO";
import {SearchResultTypes} from "@/app/views/components/misc/BuyerSearchBar";
import {DeviceTypes} from '@/app/constants/DeviceTypes';
import ProductListSupportBlock from '@/app/views/components/misc/ProductListSupportBlock.vue';

export enum SearchResultTypes {
    PRODUCTS = 'products',
    SELLERS = 'sellers',
}

@Component({
    components: {
        'm-product-list-support-block': ProductListSupportBlock,
    },
    emits: [
        'resultTypeChange',
    ],
})
export default class BuyerSearchBar extends Vue {

    @Prop({type: String, default: null, required: true})
    public readonly title: string;

    public readonly flags = {
        loading: true,
    };

    public readonly SearchResultTypes = SearchResultTypes;

    public searchClient: SearchClient;
    public middlewares;

    public searchResultType: SearchResultTypes = SearchResultTypes.PRODUCTS;

    // TODO: These do not get filled in at the same time - only when the tab is active does the related function get called which then sets the list length value
    public productListLength: number = null;
    public sellerListLength: number = null;

    constructor(...args: any[]) {
        super(args);
    }

    public showSupportBlock(index: number, listLength: number): boolean {
        if (index === 0) return false;

        const device: number = Store.app.device;
        const deviceWidth: number = Store.app.deviceWidth;

        switch (device) {
            case DeviceTypes.MOBILE:
                return (index % 4 === 0);
            case DeviceTypes.TABLET:
                return (index % 3 === 0);
            case DeviceTypes.DESKTOP:
                if (deviceWidth >= 1920) {
                    return (index % 8 === 0);
                } else {
                    return (index % 6 === 0);
                }
        }

        return false;
    }

    public created() {
        this.searchClient = algoliasearch(
            this.$settings.algoliaAppId,
            this.$settings.algoliaSearchKey,
        );

        const insightsMiddleware = createInsightsMiddleware({
            insightsClient: window.aa,
        });

        this.middlewares = [insightsMiddleware];

        const userToken = Store.user.user.uuid;

        window.aa('setUserToken', userToken);
    }

    public parseProducts(items: any): [PublicProductDTO] {
        this.productListLength = items.length;

        let result = items.map((item: any) => {
            let productDto: PublicProductDTO = new PublicProductDTO();
            productDto.objectID = item.objectID;
            productDto.uuid = item.uuid;

            let nameDto = new TranslationDTO()
            nameDto.lang = item.language;
            nameDto.value = item.translations.name;

            let descriptionDto = new TranslationDTO()
            descriptionDto.lang = item.language;
            descriptionDto.value = item.translations.description;

            productDto.name = nameDto;
            productDto.description = descriptionDto;
            productDto.category = item.category;
            productDto.madeFor = item.made_for;
            productDto.slug = item.slug;
            productDto.moq = item.moq;

            productDto.profileImages = item.profileImages.map((profileImageInput: any) => {
                let profileImage = new FileDTO();
                profileImage.uuid = profileImageInput.uuid;
                profileImage.fileName = profileImageInput.fileName;
                profileImage.fileSize = profileImageInput.fileSize;
                profileImage.mimeType = profileImageInput.mimeType;
                profileImage.originalUrl = profileImageInput.originalUrl;
                profileImage.thumbUrl = profileImageInput.thumbUrl;
                profileImage.isTemp = profileImageInput.isTemp;

                return profileImage;
            })

            productDto.originalObject = item;

            return productDto;
        });

        this.flags.loading = false;

        return result;
    }

    public parseSellers(items: any): [PublicSellerShortDTO] {
        this.sellerListLength = items.length;

        let result = items.map((item: any) => {
            let sellerDto: PublicSellerShortDTO = new PublicSellerShortDTO();
            sellerDto.objectID = item.objectID;
            sellerDto.uuid = item.uuid;
            sellerDto.name = item.name;

            let introductionDto: TranslationDTO = new TranslationDTO()
            introductionDto.lang = item.language;
            introductionDto.value = item.translations.introduction_brief;

            sellerDto.introductionBrief = introductionDto;

            let primaryLocationDto: LocationDTO = new LocationDTO()
            primaryLocationDto.uuid = item.primaryLocation.uuid;
            primaryLocationDto.name = item.primaryLocation.name;
            primaryLocationDto.address1 = item.primaryLocation.address1;
            primaryLocationDto.address2 = item.primaryLocation.address2;
            primaryLocationDto.zipcode = item.primaryLocation.zipcode;
            primaryLocationDto.city = item.primaryLocation.city;
            primaryLocationDto.region = item.primaryLocation.region;
            primaryLocationDto.country = item.primaryLocation.country;

            sellerDto.primaryLocation = primaryLocationDto;

            sellerDto.profileImages = item.profileImages.map((profileImageInput: any) => {
                let profileImage: FileDTO = new FileDTO();
                profileImage.uuid = profileImageInput.uuid;
                profileImage.fileName = profileImageInput.fileName;
                profileImage.fileSize = profileImageInput.fileSize;
                profileImage.mimeType = profileImageInput.mimeType;
                profileImage.originalUrl = profileImageInput.originalUrl;
                profileImage.thumbUrl = profileImageInput.thumbUrl;
                profileImage.isTemp = profileImageInput.isTemp;

                return profileImage;
            })

            sellerDto.categories = item.categories;
            sellerDto.manufyValues = item.manufyValues;

            sellerDto.isPartner = item.isPartner;
            sellerDto.isVerified = item.isVerified;

            sellerDto.avatarUrl = item.avatarUrl;

            sellerDto.originalObject = item;

            return sellerDto;
        });

        this.flags.loading = false;

        return result;
    }
}
