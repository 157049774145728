<script lang="ts" src="./Tooltip.ts"></script>


<template>
  <VTooltip
    :auto-hide="true"
    :delay="{ show: 100, hide: 0 }"
    :disabled="shouldDisableOnMobile"
    :distance="8"
    :hide-triggers="['touch', isMobile ? 'click' : 'hover']"
    :positioning-disabled="isMobile"
    :triggers="['touch', isMobile ? 'click' : 'hover']"
    @apply-show="isMobile && onMobileTooltipShow()"
    @apply-hide="isMobile && onMobileTooltipHide()"
  >
    <slot></slot>

    <template #popper="{ hide }">
      <p class="text-center sm:text-left">{{ label }}</p>
      <m-button v-if="isMobile" :appearance="$const.StyleAppearance.NEUTRAL" class="w-full mt-5" @click="hide()">{{ $trans.t('app.labels.close') }}</m-button>
    </template>
  </VTooltip>
</template>


<style lang="scss" scoped>


</style>
