import {PurchaseOrderPaymentStatus} from '@/app/constants/PurchaseOrderPaymentStatus';
import {PurchaseOrderShipmentStatus} from '@/app/constants/PurchaseOrderShipmentStatus';
import {PurchaseOrderStatus} from '@/app/constants/PurchaseOrderStatus';
import {BaseDTO} from '@/app/dto/BaseDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {LocationDTO} from '@/app/dto/LocationDTO';
import {OppTransactionDTO} from '@/app/dto/OppTransactionDTO';
import {PurchaseOrderProductDTO} from '@/app/dto/PurchaseOrderProductDTO';
import {ReviewDTO} from '@/app/dto/ReviewDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {Uuid} from '@/types/Types';
import {PurchaseOrderTypes} from "@/app/constants/PurchaseOrderTypes";
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";

export class PurchaseOrderDTO extends BaseDTO {

    public orderNumber: number = null;
    public type: PurchaseOrderTypes = null;
    public status: PurchaseOrderStatus = null;
    public paymentStatus: PurchaseOrderPaymentStatus = null;
    public shipmentStatus: PurchaseOrderShipmentStatus = null;
    public createdAt: Date = null;
    public updatedAt: Date = null;
    public preferredDeliveryDate: Date = null;
    public estimatedShippingDate: Date = null;
    public isCurrentStep: boolean = null;
    public buyerLocation: LocationDTO = null;
    public buyerBillingLocation: LocationDTO = null;
    public sellerLocation: LocationDTO = null;
    public buyerCourierName: string = null;
    public buyerCourierNumber: string = null;
    public productionCosts: number = null;
    public extraProductionCosts: number = null;
    public shippingCosts: number = null;
    public vatPercentage: number = null;
    public vatCosts: number = null;
    public buyerFeePercentage: number = null;
    public buyerFeeSubtotalCosts: number = null;
    public buyerFeeVatPercentage: number = null;
    public buyerFeeVatCosts: number = null;
    public buyerFeeCosts: number = null;
    public sellerFeePercentage: number = null;
    public sellerFeeSubtotalCosts: number = null;
    public sellerFeeVatPercentage: number = null;
    public sellerFeeVatCosts: number = null;
    public sellerFeeCosts: number = null;
    public sellerTotalCostsGross: number = null;
    public sellerTotalCostsNet: number = null;
    public subtotalCosts: number = null;
    public totalCosts: number = null;
    public buyerRemark: string = null;
    public sellerKycStatus: string = null;
    public sellerKycUrl: string = null;
    public products: PurchaseOrderProductDTO[] = null;
    public attachments: FileDTO[] = null;
    public ticketUuid: string = null;
    public review: ReviewDTO = null;
    public seller: CompanyShortDTO = null;
    public shipmentCourierName: string = null;
    public shipmentTrackAndTrace: string = null;
    public shipmentSentAt: Date = null;
    public shipmentReceivedAt: Date = null;
    public collaborationUuid: Uuid = null;
    // ADMIN PROPERTIES
    public buyerName: string = null;
    public buyerUuid: string = null;
    public sellerName: string = null;
    public sellerUuid: string = null;
    public projectName: string = null;
    public threadUuid: string = null;
    public transactions: OppTransactionDTO[] = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            createdAt: Date,
            updatedAt: Date,
            preferredDeliveryDate: Date,
            estimatedShippingDate: Date,
            seller: CompanyShortDTO,
            buyerLocation: LocationDTO,
            buyerBillingLocation: LocationDTO,
            sellerLocation: LocationDTO,
            products: [PurchaseOrderProductDTO],
            attachments: [FileDTO],
        };
    }

    public getFormattedOrderNumber(): string {
        let result = this.orderNumber.toString().match(/.{1,4}/g);

        return result.join(' ');
    }

}
