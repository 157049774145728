import {reactive} from 'vue';

/**
 *
 */
export abstract class StoreBase<State> {

    public state: State;

    constructor(state: State = null) {
        this.state = <State>reactive(state ?? {});
    }
}
