import {Component, Prop} from "vue-facing-decorator";
import {AutocompleteStandard} from "@/app/constants/AutocompleteStandard";
import {StylePosition} from '@/app/constants/Constants';
import {FormElementWrapper} from "@/app/views/components/misc/form/FormElementWrapper";
import FormFieldPhoneNumber from "@/app/views/components/form/FormFieldPhoneNumber";
import FormSelectCountryCodePicker from "@/app/views/components/form/FormSelectCountryCodePicker";

type CompanyPhoneFormData = {
    phoneNumber: number,
    phoneCountryCode: string,
}

@Component({
    emits: [
        'update:phoneNumber',
        'update:phoneCountryCode',
    ],
})
export default class FormFieldPhoneNumberWrapper extends FormElementWrapper<CompanyPhoneFormData> {

    public readonly AutocompleteStandard = AutocompleteStandard;

    @Prop({type: Number, default: null})
    public readonly phoneNumber: number = null;

    @Prop({type: String, default: null})
    public readonly phoneCountryCode: string = null;

    @Prop({type: String, default: StylePosition.BOTTOM})
    public readonly direction: StylePosition;

    @Prop({type: String, default: null})
    public readonly autocomplete: AutocompleteStandard;

    public localPhoneNumber: number = null;
    public localPhoneCountryCode: string = null;

    declare public $refs: {
        phoneCountryCode: FormSelectCountryCodePicker,
        phoneNumber: FormFieldPhoneNumber,
    }

    public created(): void {
        this.localPhoneCountryCode = this.phoneCountryCode;
        this.localPhoneNumber = this.phoneNumber;
    }

    public focus(event: MouseEvent): void {
        this.$refs.phoneCountryCode.handleFieldClick(event);
    }

    public updateCountryCode(countryCode: string): void {
        if (this.localPhoneCountryCode === countryCode) {
            // Set localPhoneCountryCode to `undefined` first to trigger responsiveness
            this.localPhoneCountryCode = undefined;
        }

        const oldValue: string = this.localPhoneCountryCode;
        this.localPhoneCountryCode = countryCode;
        this.$emit('update:phoneCountryCode', countryCode)
    }

    public updatePhoneNumber(phoneNumber: number): void {
        if (this.localPhoneNumber === phoneNumber) {
            // Set localPhoneNumber to `undefined` first to trigger responsiveness
            this.localPhoneNumber = undefined;
        }

        const oldValue: number = this.localPhoneNumber;
        this.localPhoneNumber = phoneNumber;
        this.$emit('update:phoneNumber', phoneNumber)
    }

}
