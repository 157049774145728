/**
 * Extend the Number class with some handy functions.
 * @author Arno van Oordt
 * @version 1.0.1
 */
interface Number {
    decimalPlaces(): number;
}

/**
 * Get the number of decimal places of the number.
 * @returns The number of decimal places.
 * @example
 *   const numA:number = 123.45;
 *   numA.decimalPlaces()    //output: 2
 *
 *   const numB:number = 123;
 *   numB.decimalPlaces()    //output: 0
 *
 *   const numC:number = 123.10;
 *   numC.decimalPlaces()    //output: 1
 */
Number.prototype.decimalPlaces = function (): number {
    if (Math.floor(this.valueOf()) === this.valueOf()) {
        return 0;
    }
    return this.toString().split('.')[1].length || 0;
}
