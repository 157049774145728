<script lang="ts" src="./CategoryIcons.ts"></script>

<template>
  <div :class="[{'flex z-10': !withLabel }]">
    <ul :class="['flex flex-row space-x-2', { 'flex-wrap -mt-2 -ml-2': withLabel }]">
      <li v-for="(category, index) in categories.slice(0, maxIcons)" :class="[{'inline-flex mt-2 ml-2': withLabel }]">
        <m-tooltip v-if="!withLabel" :label="$trans.t('meta.categories.' + category)" class="tooltip">
          <m-button
            :appearance="appearance"
            :isLight="isLight"
            :shape="hasShape"
            :size="size"
            :variant="variant"
            @click="openList()"
          >
            <m-icon :name="category" :solid="true" :type="$const.IconTypes.MANUFY"/>
          </m-button>
        </m-tooltip>
        <m-button
          v-else
          :appearance="appearance"
          :icon-before="{ name: category, solid: true, type: $const.IconTypes.MANUFY, }"
          :isLight="isLight"
          :shape="hasShape"
          :size="size"
          :variant="variant"
          @click="openList()"
        >
          {{ $trans.t('meta.categories.' + category) }}
        </m-button>
      </li>

      <li :class="[{'inline-flex mt-2 ml-2': withLabel }]">
        <m-button
          v-if="categories.length > maxIcons"
          :appearance="$const.StyleAppearance.DISABLED"
          :isLight="isLight"
          :shape="hasShape"
          :size="size"
          :variant="variant"
          class="body-font"
          @click="openList()"
        >
          + {{ categories.length - maxIcons }}
        </m-button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
</style>
