<script lang="ts" src="./FormFieldNumber.ts"></script>


<template>
  <div
    :class="[
      'form-field-group',
      size,
      {
        'has-error': (errors?.length > 0),
        'has-data': (value >= min),
        disabled: disabled,
      },
    ]"
  >
    <div class="form-field">
      <slot name="prepend"></slot>

      <input
        :id="id"
        :class="[
          'input',
          {
            'text-right': rtl,
          }
        ]"
        :disabled="disabled"
        :dusk="dusk"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        :step="step"
        :value="value"
        type="number"
        @input="handleInput($event)"
      />

      <slot name="append"></slot>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </div>
</template>
