<script lang="ts" src="./TextClamp.ts"></script>


<template>

  <div class="flex flex-col items-start space-y-1">
    <div ref="content" :class="{ clampable: !flags.expanded }" :style="`-webkit-line-clamp: ${maxLines};`">
      <slot></slot>
    </div>

    <m-button
      v-if="flags.needsClamping && flags.expanded"
      :appearance="$const.StyleAppearance.PRIMARY"
      :size="$const.StyleSize.XSMALL"
      :variant="$const.StyleVariants.TEXT"
      class="clamp-toggle"
      @click="showLess()"
    >
      {{ $trans.t('components.textClamp.showLess') }}
    </m-button>
    <m-button
      v-if="flags.needsClamping && !flags.expanded"
      :appearance="$const.StyleAppearance.PRIMARY"
      :size="$const.StyleSize.XSMALL"
      :variant="$const.StyleVariants.TEXT"
      class="clamp-toggle"
      @click="showMore()"
    >
      {{ $trans.t('components.textClamp.showMore') }}
    </m-button>
  </div>

</template>


<style lang="scss" scoped>

.clampable {
  @apply overflow-hidden break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.clamp-toggle {
  @apply leading-tight;
  min-height: 0.5rem !important; // TODO: We might need to improve the button TEXT variant by removing the preset height as in this example
}

</style>
