<script lang="ts" src="./FabricCard.ts"></script>

<template>
  <div :class="['flex flex-1 flex-col p-4 relative border rounded-xl bg-neutral-0', hasShadow ? 'shadow-md hover:shadow-lg transition-shadow' : '']">

    <div v-if="fabric.profileImages?.length > 0" class="h-48 flex space-x-3">
      <div class="h-full flex-1 relative">
        <figure class="figure">
          <template v-if="fabric.profileImages[0].thumbUrl">
            <img
              :alt="fabric.profileImages[0].fileName"
              :src="fabric.profileImages[0].thumbUrl"
              class="image"
            />
          </template>
          <template v-else>
            <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
          </template>
        </figure>
      </div>

      <ul v-if="fabric.profileImages?.length > 1" class="flex flex-col space-y-3">
        <li v-for="(image, index) in fabric.profileImages.slice(1, 3)" :key="image.uuid" class="w-20 h-20 flex-1 relative">
          <figure class="figure">
            <template v-if="image.thumbUrl">
              <img
                :alt="image.fileName"
                :src="image.thumbUrl"
                class="image"
              />
            </template>
            <template v-else>
              <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
            </template>
          </figure>
        </li>
      </ul>
    </div>

    <div v-else class="h-48 relative">
      <figure class="figure">
        <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
      </figure>
    </div>

    <div class="flex flex-col flex-1 my-4 space-y-4">
      <div class="space-y-1">
        <h3 class="font-body line-clamp-1">
          <m-link
            :new-window="true"
            :to="{ name: 'seoFabricRead', params: { slug: fabric.slug } }"
            v-html="fabric.name.value"
          />
        </h3>

        <ul v-if="fabric?.tfcLength && fabric?.tfcWeight" class="flex flex-row">
          <li class="flex-1">
            <h2>Weight</h2>
            <p class="flex items-baseline">
              <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="pr-2 my-auto" name="fa-scale-balanced"/>
              <strong class="meta__value font-medium">{{ fabric.tfcWeight }} {{ $trans.t('meta.grammageType.g_m2') }}</strong>
            </p>
          </li>

          <li class="flex-1">
            <h2>Length</h2>
            <p class="flex items-baseline">
              <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="pr-2 my-auto" name="fa-ruler-vertical"/>
              <strong class="meta__value font-medium">{{ fabric.tfcLength }} {{ $trans.t('meta.dimensions.m') }}</strong>
            </p>
          </li>
        </ul>

        <p v-else class="text-tiny leading-tight line-clamp-5" v-html="fabric.description.value"></p>
      </div>
    </div>

    <m-button
      :appearance="$const.StyleAppearance.NEUTRAL_NEW"
      :icon-before="{ name: 'eye', solid: true, type: $const.IconTypes.HERO, }"
      :new-window="true"
      :size="$const.StyleSize.SMALL"
      :to="{ name: 'seoFabricRead', params: { slug: fabric.slug } }"
    >
      {{ $trans.t('app.buttonLabels.readMore') }}
    </m-button>

  </div>
</template>

<style lang="scss" scoped>

.figure {
  @apply h-full flex items-center justify-center overflow-hidden relative rounded-xl border bg-neutral-10 bg-opacity-40;

  .image {
    @apply min-w-full h-full object-cover;
  }
}

.missing-image-icon {
  @apply w-14 h-14 text-neutral-30;
}

</style>
