import {Component, Prop} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {LocationDTO} from '@/app/dto/LocationDTO';
import {Store} from '@/app/stores/Store';
import {FormItem} from '@/app/views/components/form/FormItem';
import {Uuid} from '@/types/Types';

@Component({})
export default class FormLocationPicker extends FormItem<Uuid> {

    @Prop({type: String, required: true})
    declare public readonly companyUuid: Uuid;

    @Prop({type: String, default: null})
    declare public readonly modelValue: Uuid;

    public locationDto: LocationDTO = null;

    public async created(): Promise<void> {
        if (this.modelValue) {
            this.locationDto = await Store.locations.getLocationByUuid(this.modelValue, this.companyUuid);
        } else {
            // Set the first location by default:

            const locationDtos: LocationDTO[] = await Store.locations.getLocations(this.companyUuid);
            // TODO: Select primary location as default
            this.locationDto = locationDtos?.[0];
            this.updateValue(this.locationDto?.uuid);
        }
    }

    public mounted(): void {
    }

    public async showLocations(): Promise<void> {
        this.locationDto = await Main.app.openLocationModal(this.companyUuid, this.modelValue);
        this.updateValue(this.locationDto?.uuid);
    }

}
