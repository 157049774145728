import {Component, Prop, Watch} from 'vue-facing-decorator';
import {FormItem} from '@/app/views/components/form/FormItem';
import {CompanyAvailabilityStatus} from '@/app/constants/CompanyAvailabilityStatus';
import {FormSelectPickerItem} from '@/app/views/components/form/FormSelectPicker';
import {StylePosition, StyleSize} from '@/app/constants/Constants';

@Component({
    emits: [
        'open',
        'close',
    ],
})
export default class FormSelectAvailabilityStatusPicker extends FormItem<string | string[]> {

    @Prop({type: Array, required: true})
    declare public readonly items: FormSelectPickerItem<string>[];

    @Prop({type: String, default: null})
    declare public readonly modelValue: string;

    @Prop({type: String, default: StylePosition.BOTTOM})
    public readonly direction: StylePosition;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: StyleSize;

    public readonly flags = {
        opened: false,
    };

    public selectedItem: FormSelectPickerItem<string> = null;

    /**
     * The label to be displayed in the field.
     */
    public get selectedLabel(): string {
        return this.selectedItem?.label;
    }

    public created(): void {
    }

    public handleFieldClick($event: MouseEvent): void {
        this.open();
    }

    public close(): void {
        this.flags.opened = false;
    }

    public clickItem(item: FormSelectPickerItem<string> = null): void {
        this.selectedItem = item;
        this.flags.opened = false;
        this.updateValue(item.id);
    }

    public checkItemSelected(item: FormSelectPickerItem<string> = null): boolean {
        return !!(this.value && (this.value === item.id));   // Use !! to make sure it doesn't return null
    }

    public iconColor(item: FormSelectPickerItem<string>): string {
        switch (item?.id) {
            case CompanyAvailabilityStatus.FULL_CAPACITY:
            case CompanyAvailabilityStatus.NOT_ACTIVELY_SEARCHING:
                return 'text-red-400';
            case CompanyAvailabilityStatus.ON_HOLIDAY:
            case CompanyAvailabilityStatus.PROJECT_IN_PROCESS:
                return 'text-yellow-300';
            case CompanyAvailabilityStatus.LOOKING_FOR_PARTNERS:
            case CompanyAvailabilityStatus.OPEN_CAPACITY:
                return 'text-green-400';
            default:
                return 'text-neutral-80';
        }
    }

    /**
     * Select initial item based on the value.
     */
    @Watch('value')
    private selectInitialItem(newValue: string, oldValue: string): void {
        if (newValue === oldValue) return;

        for (let i: number = this.items.length; i--;) {
            if (this.items[i].id == this.value) {
                this.selectedItem = this.items[i];
                return;
            }
        }
    }

    private open(): void {
        this.flags.opened = true;
    }

}
