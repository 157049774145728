import {Constructor, FixedSizeArray} from '@/types/Types';

export type CastableValue = Castable | Date | Castable[] | Date[];
export type CastableType = typeof Castable | typeof Date | FixedSizeArray<typeof Castable, 1> | FixedSizeArray<typeof Date, 1>;
export type CastableProperties = Record<string, CastableType>;

export abstract class Castable {

    protected static get castParams(): CastableProperties {
        return {};
    }

    public static cast<T extends Castable>(o: CastableValue): T {
        if (o === undefined || o === null || Object.keys(o).length === 0) {
            return null;
        }   // Check if it's a non-existing or empty object, then return null

        const castParams = this.castParams;
        const castOjb = (value, targetType) => {
            if (targetType === Date) {
                return new Date(value);
            } else if (targetType.cast) {
                return targetType.cast(value);
            }
            return value;
        };

        // Cast to the sub-class:
        const c: T = <T>Object.assign(new (<Constructor<unknown>>this.prototype.constructor)(), o);
        // Cast to the properties according to the castParams definition:
        for (const key in castParams) {
            const value: CastableValue = c[key];
            if (value) {
                const targetType: CastableType = castParams[key];
                if (targetType instanceof Array) { // Assume the value is an array of the given instances as well
                    if (!(value instanceof Array)) {
                        throw new Error(`Cast Error! [${key}] can't be cast as an array!`);
                    }

                    for (let i: number = value.length; i--;) {
                        c[key][i] = castOjb(value[i], targetType[0]);
                    }
                } else {
                    c[key] = castOjb(value, targetType);
                }
            }
        }
        return c;
    }

}
