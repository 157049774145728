<script lang="ts" src="./FormFieldStepper.ts"></script>


<template>
  <div
    :class="[
      'form-field-group',
      size,
      {
        'has-error': (errors?.length > 0),
        disabled: disabled,
      },
    ]"
  >
    <div class="form-field">
      <div
        :class="['prepend', { disabled: min != null && value <= min }]"
        @click="stepDown()"
      >
        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="minus"/>
      </div>

      <input
        :id="id"
        :disabled="disabled"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        :step="step"
        :value="value"
        class="input text-center"
        type="number"
        v-on:input="handleInput($event)"
      />

      <div
        :class="['append', { disabled: max != null && value >= max }]"
        @click="stepUp()"
      >
        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="plus"/>
      </div>
    </div>

    <slot></slot>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-field .prepend,
.form-field .append {
  @apply text-green-400 hover:text-green-500 active:text-green-600 cursor-pointer transition-colors;

  &.disabled {
    @apply text-neutral-60 pointer-events-none;
  }
}
</style>
