import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {LabelDTO} from '@/app/dto/LabelDTO';
import {ThreadDTO} from '@/app/dto/ThreadDTO';
import {Store} from '@/app/stores/Store';
import {ListResult} from '@/app/utils/ListResult';
import LabelEdit from '@/app/views/components/misc/label/LabelEdit';
import LabelEditVue from '@/app/views/components/misc/label/LabelEdit.vue';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Uuid} from '@/types/Types';

@Component({
    components: {
        'm-label-edit': LabelEditVue,
    },
})
export default class LabelModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
        showNewAddressForm: false,
    };

    public thread: ThreadDTO = null;
    public labels: LabelDTO[] = [];
    public selectedByUuid: Record<Uuid, boolean> = {};
    declare public $refs: {
        labelEditItems: LabelEdit[];
    };
    private resolveHandler: (thenableOrResult?: ThreadDTO) => void = null;
    private syncTimeoutId: number = null;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(thread: ThreadDTO): Bluebird<ThreadDTO> {
        this.thread = thread;

        this.selectedByUuid = {};
        for (let i: number = thread.labels.length; i--;) {
            this.selectedByUuid[thread.labels[i].uuid] = true;
        }

        const payload: Record<string, any> = {};

        payload.perPage = -1;

        const promise: Bluebird<ListResult<LabelDTO>> = Main.callApi('company/labels', RequestMethods.GET, payload, [LabelDTO]);
        promise.then(this.handleLabelsLoaded.bind(this));

        this.flags.show = true;

        const promiseCallback = (resolve: (thenableOrResult?: ThreadDTO) => void, _reject: (error?: any) => void, _onCancel?: (callback: () => void) => void): void => {
            this.resolveHandler = resolve;
        };

        return new Bluebird<ThreadDTO>(promiseCallback);
    }

    public addNew(): void {
        this.saveAll();
        window.requestAnimationFrame(() => {    // Wait one tick for a possible old empty item to be resolved
            this.labels.push(new LabelDTO());
        });
    }

    public handleLabelSelectChange(selected: boolean, labelUuid: Uuid): void {
        this.selectedByUuid[labelUuid] = selected;
        this.syncThreadLabels();
    }

    public handleLabelSave(savedLabel: LabelDTO, selected: boolean): void {
        const payload: Record<string, any> = {};
        payload.companyUuid = Store.user.currentCompanyUuid; // Used in case of new
        payload.uuid = savedLabel.uuid; // Used in case of update
        payload.name = savedLabel.name;

        const promise: Bluebird<LabelDTO> = Main.callApi('company/label', (savedLabel.uuid) ? RequestMethods.PATCH : RequestMethods.POST, payload, LabelDTO);
        promise.then(this.handleLabelSaved.bind(this, selected));
    }

    public handleLabelDelete(labelUuid: Uuid): void {
        if (labelUuid) {
            const payload: Record<string, any> = {};
            payload.uuid = labelUuid;
            payload.companyUuid = Store.user.currentCompanyUuid;

            const promise: Bluebird<{}> = Main.callApi('company/label', RequestMethods.DELETE, payload);
            promise.then(this.handleLabelDeleted.bind(this, labelUuid));
        } else {
            this.handleLabelDeleted(labelUuid);
        }
    }

    public handleLabelSaved(selected: boolean, savedLabel: LabelDTO): void {
        let index: number = this.labels.findIndex((label: LabelDTO) => label.uuid == savedLabel.uuid);
        if (index == -1) {
            // Find the new (empty) label:
            index = this.labels.findIndex((label: LabelDTO) => label.uuid == null);
        }

        if (index >= 0) {   // update / replace:
            this.labels.splice(index, 1, savedLabel);
        } else {  // new
            // In case the empty label was already gone for some reason..
            this.labels.push(savedLabel);
        }
        this.selectedByUuid[savedLabel.uuid] = selected;
        this.syncThreadLabels();
    }

    public handleLabelDeleted(labelUuid: Uuid): void {
        this.labels = this.labels.filter((label: LabelDTO) => label.uuid != labelUuid);
        this.selectedByUuid[labelUuid] = false; // make sure it's not send to the BE on sync
    }

    public syncThreadLabels(): void {
        window.cancelAnimationFrame(this.syncTimeoutId);
        this.syncTimeoutId = window.requestAnimationFrame(() => {
            const uuids: Uuid[] = [];
            for (const uuid in this.selectedByUuid) {
                if (!Object.hasOwn(this.selectedByUuid, uuid) || !this.selectedByUuid[uuid] || this.labels.findIndex((label: LabelDTO) => label.uuid == uuid) == -1) {
                    continue;
                }
                uuids.push(uuid as Uuid);
            }

            const payload: Record<string, any> = {};
            payload.threadUuid = this.thread.uuid;
            payload.labelUuids = uuids;

            let promise: Bluebird<ThreadDTO> = Main.callApi('thread/labels', RequestMethods.PATCH, payload, ThreadDTO);
            promise.then((result: ThreadDTO) => {
                this.thread = result;
            });
        });
    }

    public close(): void {
        this.saveAll();
        this.hide(true);

        this.resolveHandler(this.thread);
    }

    private handleLabelsLoaded(result: ListResult<LabelDTO>): void {
        this.labels = result.items;
    }

    private saveAll(): void {
        if (!this.$refs.labelEditItems) {
            return;
        }

        for (let i: number = this.$refs.labelEditItems.length; i--;) {
            this.$refs.labelEditItems[i].save();
        }
    }

}
