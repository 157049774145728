<script lang="ts" src="./FormFieldReview.ts"></script>


<template>
  <div
    :class="[
      'form-field-group',
      size,
      {
        'has-error': errors?.length > 0,
        disabled: disabled,
      },
    ]"
  >
    <div class="form-field">
      <ul :class="['m-star-list', size]">
        <li
          v-for="n in max"
          :id="'' + n"
          :class="[
            'm-star-list__star',
            {
              'text-yellow-100 hover:text-yellow-100': (!ratingSelected) && (n <= currentRating),
              'text-yellow-300 hover:text-yellow-300': (ratingSelected) && (n <= currentRating) || (n <= oldRating),
            },
          ]"
          @click="setRating(n, true)"
          @mouseleave="resetRating"
          @mouseover="setRating(n, false)"
        >
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
        </li>
      </ul>

      <input
        :id="id"
        :disabled="disabled"
        :max="max"
        :value="selectedRating"
        class="input hidden"
        min="0"
        step="1"
        type="number"
      />
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.form-field-group {
  @apply items-start;

  .m-star-list {
    @apply text-blue-100;

    .m-star-list__star {
      @apply cursor-pointer transition-colors duration-200 ease-in-out
    }
  }

  &.x-small .form-field {
    @apply h-7 px-1.5 rounded-full;
  }

  &.small .form-field {
    @apply h-8 px-2 rounded-full;
  }

  &.medium .form-field {
    @apply h-10 px-2.5 rounded-full;
  }
}

.form-field {
  @apply bg-blue-25 border-blue-25;
}

</style>
