<script lang="ts" src="./FormFieldSwitch.ts"></script>


<template>
  <label
    :class="{ disabled: disabled, }"
    :for="id"
    class="inline-flex flex-row cursor-pointer"
    @click="$refs.checkbox.checked = !$refs.checkbox.checked"
  >
    <span v-if="label" class="flex-1 mr-2 leading-tight">{{ label }}</span>

    <span class="switch">
      <input
        :id="id"
        ref="checkbox"
        :checked="value"
        :disabled="disabled"
        :name="id"
        class="input"
        type="checkbox"
        @input="handleInput($event)"
      />

      <span class="slider"></span>
    </span>
  </label>
</template>


<style lang="scss" scoped>

.switch {
  @apply inline-block w-10 h-5 relative;

  .input {
    @apply w-0 h-0 opacity-0;

    &:focus + .slider {
      @apply shadow-sm;
    }

    &:checked + .slider {
      @apply bg-green-400;

      &::before {
        @apply translate-x-5;
      }
    }
  }

  .slider {
    @apply absolute top-0 right-0 bottom-0 left-0 rounded-2xl cursor-pointer bg-neutral-20;

    &::before {
      @apply w-3 h-3 absolute bottom-1 left-1 rounded-full bg-neutral-0;
      content: "";
    }
  }

  .slider,
  .slider::before {
    @apply transform transition-all duration-300;
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
  }
}

</style>
