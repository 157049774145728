import {Component, Prop} from 'vue-facing-decorator';
import {StyleSize} from '@/app/constants/Constants';
import {FormItem} from '@/app/views/components/form/FormItem';

@Component({})
export default class FormFieldTextArea extends FormItem<string> {

    @Prop({type: [String], default: null})
    declare public modelValue: string;

    @Prop({type: String, default: null})
    public readonly placeholder: string;

    @Prop({type: Number, default: 5})
    public readonly rows: number;

    @Prop({type: Boolean, default: false})
    declare public autoGrow: boolean;

    @Prop({type: Boolean, default: true})
    declare public manualResize: boolean;

    @Prop({type: Boolean, default: false})
    declare public fullHeight: boolean;

    @Prop({type: String, default: "64"})
    public readonly maxHeight: [string | number];

    @Prop({type: String, default: StyleSize.MEDIUM})
    declare public size: StyleSize;

    declare public readonly $refs: {
        textareaWrapper: HTMLDivElement;
        textarea: HTMLTextAreaElement;
    };

    public updateFromValue(evt: Event): void {
        const target: HTMLInputElement = (evt.target as HTMLInputElement);
        this.updateValue(target.value);
    }

    public adjustTextareaHeight(): void {
        this.$refs.textareaWrapper.dataset.replicatedValue = this.$refs.textarea.value;
    }

    public maxHeightClass(): string {
        return `max-h-${this.maxHeight}`;
    }

}
