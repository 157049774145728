/**
 * Extend the String class with some handy functions.
 * Use: `import 'PATH_TO_LIBRARY/core/extensions/String';`.
 * @author Arno van Oordt
 * @version 1.0.2
 */
interface String {
    toKebabCase(devider?: string): string;

    toCamelCase(devider?: string, usePascalCase?: boolean): string;

    escapeHTML(): string;

    unescapeHTML(): string;
}

/**
 * Convert a cameCase or PascalCase to kebab-case or snake_case.
 * @param devider                The divider to use.
 * @returns The converted string.
 * @example
 *   const str:string = 'SomeCameCaseString';
 *   str.toKebabCase()   //output: some-came-case-string
 *   str.toKebabCase('_')    //output: some_came_case_string
 */
String.prototype.toKebabCase = function (devider: string = '-'): string {
    return this.replace(/(\B[A-Z])/g, devider + '$1').toLowerCase();
}

/**
 * Convert a kebab-case or snake_case cameCase or PascalCase.
 * @param devider                The divider to use. Multiple characters can be used like '_-'.
 * @param usePascalCase          Whether to capatalize the first character as well.
 * @returns The converted string.
 * @example
 *   const str:string = 'some-kebab_case-string';
 *   str.toCamelCase()              //output: someKebab_caseString
 *   str.toCamelCase('_')           //output: some-kebabCase-string
 *   str.toCamelCase('_-')          //output: someKebabCaseString
 *   str.toCamelCase(null, true)    //output: SomeKebab_caseString
 */
String.prototype.toCamelCase = function (devider: string = '-', usePascalCase: boolean = false): string {
    const pascalCase: string = (usePascalCase) ? '^|' : '';
    devider = (devider) ? devider : '-';
    return this.replace(new RegExp(`(?:${pascalCase}[${devider}])([a-z])`, 'g'), (_all: string, char: string) => {
        return char.toUpperCase();
    });
}

/**
 * Escape all html in the string and return the escaped result.
 * @returns The escaped string.
 * @example
 *   const str:string = 'Hello <strong>World</strong>';
 *   str.escapeHTML()    //output: Hello &lt;strong&gt;World&lt;/strong&gt;
 */
String.prototype.escapeHTML = function (): string {
    const escape = document.createElement('textarea');
    escape.textContent = this.toString();
    return escape.innerHTML;
}

/**
 * Unescape all escaped values in the string and return the unescaped result.
 * @returns The unescaped string.
 * @example
 *   const str:string = 'Hello &lt;strong&gt;World&lt;/strong&gt;';
 *   str.unescapeHTML()    //output: Hello <strong>World</strong>
 */
String.prototype.unescapeHTML = function (): string {
    const escape = document.createElement('textarea');
    escape.innerHTML = this.toString();
    return escape.textContent;
}
