/**
 * Style: Color types.
 */
export const enum StyleColor {

    DARK = '#181C24',
    NEUTRAL = '#384355',
    PRIMARY = '#1D477E',
    SECONDARY = '#75BD8F',
    INFO = '#00BADB',
    WARNING = '#F9B329',
    DANGER = '#FF7C62',
    HELP = '#7151DC',
    DISABLED = '#EEEFF2',
    DOUGHNUT_CHART_1 = '#7D7F7D',
    DOUGHNUT_CHART_2 = '#BDECB6',
    DOUGHNUT_CHART_3 = '#C35831',
    DOUGHNUT_CHART_4 = '#CFD3CD',
    DOUGHNUT_CHART_5 = '#FE0000',
    DOUGHNUT_CHART_6 = '#39352A',
    DOUGHNUT_CHART_7 = '#EFA94A',
    DOUGHNUT_CHART_8 = '#015D52',
    DOUGHNUT_CHART_9 = '#999950',
    DOUGHNUT_CHART_10 = '#9C9C9C',
    DOUGHNUT_CHART_11 = '#A52019',
    DOUGHNUT_CHART_12 = '#999950',
    DOUGHNUT_CHART_13 = '#1F3A3D',
    DOUGHNUT_CHART_14 = '#6F4F28',
    DOUGHNUT_CHART_15 = '#781F19',
    DOUGHNUT_CHART_16 = '#FE0000',
    DOUGHNUT_CHART_17 = '#781F19',
    DOUGHNUT_CHART_18 = '#474B4E',
    DOUGHNUT_CHART_19 = '#4E5754',
    DOUGHNUT_CHART_20 = '#D6AE01',
    DOUGHNUT_CHART_21 = '#592321',
    DOUGHNUT_CHART_22 = '#6C3B2A',
    DOUGHNUT_CHART_23 = '#7FB5B5',
    DOUGHNUT_CHART_24 = '#8673A1',
    DOUGHNUT_CHART_25 = '#45322E',
    DOUGHNUT_CHART_26 = '#26252D',
    DOUGHNUT_CHART_27 = '#C93C20',
    DOUGHNUT_CHART_28 = '#642424',
    DOUGHNUT_CHART_29 = '#D95030',
}
